import React, { Component } from "react";
import logo from "./assets/icons/Charlie.png";
import "./assets/css/login.css";

import { Form, Whisper, Tooltip, Schema, InputGroup } from "rsuite";
import { connect } from "react-redux";
import { resetPassword, checkResetToken } from "../Features/Login/LoginThunk";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        password: "",
        co_password: "",
      },
      expired: 0,
      eyetitle: true,
      eyetitleconfirm: true,
      isSuccess: false,
    };
  }

  async componentDidMount() {
    let { checkResetToken, token, alertRef } = this.props;
    let response = await checkResetToken({ token });

    if (response.payload.status === 0) {
      this.setState({
        expired: true,
      });
      alertRef.current.showToaster(response.payload);
    }
  }

  showpassword = () => {
    var password = document.getElementById("password");

    if (password.type === "password") {
      password.type = "text";
      this.setState({
        eyetitle: false,
      });
    } else {
      password.type = "password";
      this.setState({
        eyetitle: true,
      });
    }
  };

  confirmShowpassword = () => {
    var password = document.getElementById("confirmpassword");

    if (password.type === "password") {
      password.type = "text";
      this.setState({
        eyetitleconfirm: false,
      });
    } else {
      password.type = "password";
      this.setState({
        eyetitleconfirm: true,
      });
    }
  };

  handleSubmit = async () => {
    let { password } = this.state.formValue;
    let { alertRef, resetPassword, token } = this.props;
    if (!this.form.check()) {
      return;
    }
    let response = await resetPassword({ password, token });
    if (response.payload.status === 1)
      this.setState({
        isSuccess: true,
      });
    alertRef.current.showToaster(response.payload);
  };

  render() {
    let { alertRef } = this.props;
    let { formValue, isSuccess, expired } = this.state;
    if (expired) return <Navigate to="/forgotpassword" replace />;
    if (isSuccess)
      return (
        <Navigate
          to={`/password-request/?t=changed&s=${isSuccess === true ? 1 : 0}`}
          replace
        />
      );
      const model = Schema.Model({
        password: Schema.Types.StringType()
          .isRequired("This field is required.")
          .addRule((value, data) => {
            if (value.length < 8) {
              return false;
            }
      
            // Check if password contains at least one special character
            if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
              return false;
            }
      
            // Check if password contains at least one capital letter
            if (!/[A-Z]/.test(value)) {
              return false;
            }
      
            return true;
          }, "Password must contain at least 8 characters, one special character, and one capital letter"),
          
        confirmpassword: Schema.Types.StringType()
          .addRule((value, data) => {
            if (value !== data.password) {
              return false;
            }
      
            return true;
          }, "The confirm passwords do not match")
          .isRequired("This field is required."),
      });
      
    const showPassword = <Tooltip>Show Password</Tooltip>;

    const hidePassword = <Tooltip>Hide Password</Tooltip>;
    return (
      <div className="wrapper">
        <div className="box left-side-form">
          <div className="login-logo-div">
            <img src={logo} alt="" className="login-logo" />
          </div>

          <Form
            fluid
            model={model}
            ref={(ref) => (this.form = ref)}
            onChange={(formValue, e) => {
              this.setState({
                formValue,
              });
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 32) {
                e.preventDefault();
                let message = {
                  status: 0,
                  message: "Whitespace is not allowed",
                };
                alertRef.current.showToaster(message);
              }
            }}
            onCheck={(formError) => {
              this.setState({ formError });
            }}
            formValue={formValue}
          >
            <Form.Group className="loginInput">
              <InputGroup>
                <Form.Control
                  name="password"
                  placeholder="Password"
                  className="input-field-login"
                  type="password"
                  autoComplete="off"
                  id="password"
                />
                <InputGroup.Addon className="eye" onClick={this.showpassword}>
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={this.state.eyetitle ? showPassword : hidePassword}
                  >
                    <i
                      className={
                        !this.state.eyetitle
                          ? "fa fa-eye showHideEye"
                          : "fa fa-eye-slash showHideEye"
                      }
                    ></i>
                  </Whisper>
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>
            <Form.Group className="loginInput">
              <InputGroup>
                <Form.Control
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  className="input-field-login"
                  type="password"
                  autoComplete="off"
                  id="confirmpassword"
                />
                <InputGroup.Addon
                  className="eye"
                  onClick={this.confirmShowpassword}
                >
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      this.state.eyetitleconfirm ? showPassword : hidePassword
                    }
                  >
                    <i
                      className={
                        !this.state.eyetitleconfirm
                          ? "fa fa-eye showHideEye"
                          : "fa fa-eye-slash showHideEye"
                      }
                    ></i>
                  </Whisper>
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>
            <div className="login-button-div" style={{ marginTop: -15 }}>
              <button
                className={`loginButton ${
                  !formValue.email ? "field-empty-button-disable " : ""
                }`}
                onClick={this.handleSubmit}
                style={{ padding: "10px 2em" }}
              >
                Submit
              </button>
              &nbsp;{" "}
              <Link to="/login" style={{ textAlign: "center", marginTop: -15 }}>
                <span className="forget-paswword" style={{ cursor: "pointer" }}>
                  Back to Login
                </span>
              </Link>
            </div>
          </Form>
        </div>
        <div className="box right-side-content">
          <div className="text">
            <p>
              "We are what we repeatedly do.
              <br />
              Excellence, then is not an act, but a habit"
            </p>
          </div>
          <div className="DSC-text">
            {/* <p>Powered by DSC India Private limited</p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.LoginSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: ({ password, token }) =>
    dispatch(resetPassword({ password, token })),
  checkResetToken: ({ token }) => dispatch(checkResetToken({ token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
