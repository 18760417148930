import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

// export const getTemplateData = () => JSON.parse(localStorage.getItem("eTD"));

export const uploadImages = createAsyncThunk("images", async (images) => {
  try {
    const response = await axios.post("/images/", images, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (e) {}
});

export const getImages = createAsyncThunk("images", async ({ type, page, limit, value }) => {
  try {
    const response = await axios.get(
      `/images?type=${type}&limit=${Number(limit)}&page=${page}&value=${value}`
    );
    return response.data;
  } catch (e) {}
});

export const deleteImages = createAsyncThunk("images/deleteImage", async (keyId) => {
  try {
    const response = await axios.post(
      "/images/deleteImage/",
      { keyId },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
});

export const updateImageDetails = createAsyncThunk("images/editimage", async (fdata) => {
  try {
    const response = await axios.post("/images/editimage/", fdata, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
});
