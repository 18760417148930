import React, { Component } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";

import EditDataInfo from "./EditDataInfo";
import { Row, Col, Nav, Dropdown, IconButton, Badge } from "rsuite";

import { Detail, Edit, FileUpload, Page, Trash } from "@rsuite/icons";
// import Back from "../../CreateNewProject/Email/assets/icons/Back.png";
import PlayIcon from "@rsuite/icons/legacy/Play";

import { connect } from "react-redux";

import { getDataById, deleteDataSet } from "../../Features/Data/DataThunk";

import Loading from "../../Loading/Loading";

import EditDataStructure from "./EditDataStructure";
import DeleteConfirmationModal from "../../Utils/DeleteConfirmationModal";
import DataOperation from "./DataOperation";
import { checkDataIdInDatabase } from "../../Features/CampaignManagement/CampaignPropertiesThunk";

// console.log(response);

const CustomNav = ({ active, id, onSelect, isInDatabase, ...props }) => {
  console.log(isInDatabase);
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ padding: 10, display: "flex" }}
      className="nav-item-edit"
    >
      <Link to={`${id}/info`} className="nav-item-link">
        <Nav.Item
          as="li"
          eventKey="info"
          title="EDIT PROPERTIES"
          icon={<Detail />}
        >
          <b>EDIT PROPERTIES</b>
        </Nav.Item>
      </Link>
      &nbsp; &nbsp; &nbsp;
      <Link to={`${id}/structure`} className="nav-item-link">
        <Nav.Item
          as="li"
          eventKey="structure"
          title="EDIT STRUCTURE"
          icon={<Edit />}
        >
          <b>EDIT STRUCTURE</b>
        </Nav.Item>
      </Link>
      &nbsp; &nbsp; &nbsp;
      <Link to={`${id}/operation`} className="nav-item-link">
        <Nav.Item
          as="li"
          eventKey="operation"
          title="UPLOAD LIST"
          icon={<FileUpload />}
        >
          <b>UPLOAD LIST</b>
        </Nav.Item>
      </Link>
      {/* {campaignPropertiesCheckedInDataId[
                                  val.data_list_id
                                ] && (
                                  <Badge
                                    content="ACTIVE"
                                    color="red"
                                    title="Active with Campaign"
                                    style={{ width: "33%" }}
                                  />
                                )} */}
      {isInDatabase && (
        <Badge
          content="ACTIVE"
          color="red"
          title="Active with Campaign"
          style={{
            width: "10%",
            textAlign: "center",
            padding: 7,
            borderRadius: 19,
            fontSize: 13,
            fontWeight: "bold",
            height: 30,
            marginTop: 4,
            marginLeft: 25,
          }}
        />
      )}
    </Nav>
  );
};

class EditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.dynamicRoute[0].params.type,
      confirmation: false,
      loading: "fetchById",
      isDeleted: false,
      isInDatabase: false, // Add a state to track if the dataId is in the database
    };
  }

  async componentDidMount() {
    let { getDataById, dynamicRoute } = this.props;
    const { selectedDataProperties } = this.props;
    const isInDatabase = await this.checkDataIdInDatabase(
      dynamicRoute[0].params.id
    );
    console.log(isInDatabase);

    await getDataById({
      type: "byId",
      value: dynamicRoute[0].params.id,
    });

    this.setState({
      loading: null,
      isInDatabase,
    });
  }
  // Method to check if dataId exists in the database
  checkDataIdInDatabase = async (dataId) => {
    try {
      const response = await this.props.checkDataIdInDatabase(dataId);
      console.log(response);
      // Check if the payload exists and update the isInDatabase state accordingly
      const exists = response.payload && response.payload.exists;
      console.log(exists);
      this.setState({
        isInDatabase: exists,
      });
      return exists; // Return the response from the database check
    } catch (error) {
      console.error("Error checking dataId in database:", error);
      return false;
    }
  };
  handleDelete = async () => {
    let { deleteDataSet, dynamicRoute, alertRef } = this.props;
    this.setState({
      loading: "delete",
    });
    let response = await deleteDataSet(dynamicRoute[0].params.id);
    this.setState({
      loading: null,
      confirmation: false,
      isDeleted: true,
    });
    alertRef.current.showToaster(response.payload);
  };

  render() {
    let { active, confirmation, isDeleted, loading, isInDatabase } = this.state;
    let { dynamicRoute, dataset, alertRef } = this.props;
    let { id } = dynamicRoute[0].params;
    if (dataset.loading || loading !== null) return <Loading />;
    if (isDeleted && loading === null)
      return <Navigate to="/assetlibrary/data" replace />;
    if (Object.keys(dataset.dataById).length === 0 && loading === null)
      return <Navigate to="/assetlibrary/data" replace />;
    return (
      <div className="content-container ">
        <Row className="border-bottom">
          <Col md={3}>
            <div className="next-back-button-horizontal">
              <div
                className="next-back-button"
                style={{ justifyContent: "flex-start", paddingTop: 10 }}
              >
                <Link to="/assetlibrary/data" className="link-color-font">
                  <span style={{ cursor: "pointer" }} title="Back">
                    <IconButton placement="left">
                      <PlayIcon style={{ transform: "rotate(180deg)" }} />
                      Back
                    </IconButton>
                  </span>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={14}>
            <CustomNav
              active={active}
              id={id}
              appereance="default"
              onSelect={(e) =>
                this.setState({
                  active: e,
                })
              }
              isInDatabase={isInDatabase}
            />
          </Col>
          <Col md={7} style={{ textAlign: "right" }}>
            <Dropdown
              title="More"
              icon={<Page />}
              placement="bottomEnd"
              className="dropdown-data-more"
            >
              {/* #NOTE Nedd to figureout delete conditonal rendering */}
              <Dropdown.Item
                icon={<Trash />}
                onClick={() =>
                  this.setState({
                    confirmation: true,
                  })
                }
                // Conditionally render the delete button based on isInDatabase
                disabled={isInDatabase} // Disable the button if dataId is in the database
              >
                Delete
              </Dropdown.Item>

              <Dropdown.Item
                icon={<Detail />}
                onClick={() => this.clickChild()}
              >
                Export Data
              </Dropdown.Item>
            </Dropdown>
          </Col>
        </Row>
        {/* {!dataset.loading && ( */}
        <Routes>
          <Route
            index
            path=":id/info"
            element={
              <EditDataInfo
                id={id}
                alertRef={alertRef}
                export={(click) => (this.clickChild = click)}
              />
            }
          />
          <Route
            path=":id/structure"
            element={
              <EditDataStructure
                id={id}
                alertRef={alertRef}
                export={(click) => (this.clickChild = click)}
              />
            }
          />

          <Route
            path=":id/operation"
            element={
              <DataOperation
                alertRef={alertRef}
                id={id}
                export={(click) => (this.clickChild = click)}
              />
            }
          />
        </Routes>
        {confirmation && (
          <DeleteConfirmationModal
            confirmation={confirmation}
            hide={() =>
              this.setState({
                confirmation: false,
              })
            }
            handleDelete={this.handleDelete}
            component="Data Set"
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dataset: state.DataSlice,
  selectedDataProperties: state.CampaignPropertiesSlice.selectedDataProperties,
});

const mapDispatchToProps = (dispatch) => ({
  getDataById: (type, value) => dispatch(getDataById(type, value)),
  deleteDataSet: (id) => dispatch(deleteDataSet(id)),
  checkDataIdInDatabase: (dataId) => dispatch(checkDataIdInDatabase(dataId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditData);
