import React, { Component } from "react";
import { Col, Row } from "rsuite";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

class PreviewDataSet extends Component {
  render() {
    let { datafield, emailfield, dataName, description, location } = this.props;
    if (!dataName.textName || !location.path || !description.textDes || !emailfield.value)
      return <Navigate to="/create/data-set/info" replace />;
    return (
      <>
        <Row style={{ marginTop: 30 }}>
          <Col md={24}>
            <Row style={{ marginTop: 10 }}>
              <Col md={1}></Col>
              <Col xs={2} className="text-align-center font-weight-bold">
                #
              </Col>
              <Col xs={4} className="text-align-center font-weight-bold">
                Name{" "}
              </Col>
              <Col xs={4} className="text-align-center font-weight-bold">
                Type
              </Col>
              <Col xs={4} className="text-align-center font-weight-bold">
                Length
              </Col>
              <Col xs={4} className="text-align-center font-weight-bold">
                Primary
              </Col>
              <Col xs={4} className="text-align-center font-weight-bold">
                Required
              </Col>
              <Col md={1}></Col>
            </Row>

            <div style={{ marginTop: 10 }}>
              {Object.keys(datafield).map((val, idx) => {
                return (
                  <div key={idx} style={{ marginBottom: 10 }}>
                    <Row>
                      <Col md={1}></Col>
                      <Col xs={2} className="text-align-center">
                        {idx}
                      </Col>
                      <Col xs={4} className="text-align-center">
                        {datafield[val].FieldName}
                      </Col>
                      <Col xs={4} className="text-align-center">
                        {datafield[val].DataType}
                      </Col>
                      <Col xs={4} className="text-align-center">
                        {datafield[val].Length === "" ||
                          datafield[val].DataType === "text" ||
                          datafield[val].DataType === "date" ||
                          datafield[val].DataType === "decimal" ||
                          datafield[val].DataType === "boolean" ? (
                          <>Pre Defined</>
                        ) : (
                          datafield[val].Length
                        )}
                      </Col>
                      <Col xs={4} className="text-align-center">
                        {datafield[val].Primary === " " || datafield[val].Primary === "" ? (
                          <>False</>
                        ) : (
                          datafield[val].Primary
                        )}
                      </Col>
                      <Col xs={4} className="text-align-center">
                        {datafield[val].Required}
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <hr style={{ color: "black" }} />
            <div style={{ marginTop: 0, fontWeight: "bold" }}>
              <span style={{ float: "left" }}>&nbsp;Selected Email Field &nbsp;: &nbsp;</span>
              <span style={{ display: "block" }}>
                <label>&nbsp; {emailfield.value} </label>
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { datafield, emailfield, dataName, description, location } = state.DataSlice;
  return {
    datafield,
    emailfield,
    dataName,
    description,
    location
  };
}

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(PreviewDataSet);
