import axios from "../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const editorTemplates = createAsyncThunk(
  "editor/template",
  async ({ type, page, limit, value }) => {
    const response = await axios.get(
      `/assetlibrary/template?type=${type}&limit=${Number(
        limit
      )}&page=${page}&value=${value}`
    );
    return response.data;
  }
);

// LINK - Look into TemplateService.js Byid
export const editorTemplateById = createAsyncThunk(
  "editor/templateById",
  async ({ type, value }) => {
    const response = await axios.get(
      `/assetlibrary/template?type=${type}&value=${value}`
    );
    return response.data;
  }
);

export function setEditor() {
  localStorage.setItem("gjsProject", JSON.stringify({}));
}

