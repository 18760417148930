import { collapseSidebar, editorPath } from "./Paths/Paths";

export const viewChange = (location, route, dynamicRoute) => {
  if (collapseSidebar.includes(location.pathname) || dynamicRoute !== null) {
    document
      .getElementById("for-create-change-sidebar")
      .classList.remove("rs-container-has-sidebar");
    document.getElementById("for-create-change-header").classList.remove("content-container");

    document.getElementById("for-create-change-header").classList.add("rs-container-has-sidebar");

    document.getElementById("for-hide-sidebar").classList.remove("hide-sidebar-header");
    document.getElementById("for-hide-header").classList.remove("hide-sidebar-header");
    // document.getElementById("for-change-editor").classList.add("content-home");
  } else if (editorPath.includes(location.pathname)) {
    // alert("2nd");
  } else if (route === null) {
    document
      .getElementById("for-create-change-header")
      .classList.remove("rs-container-has-sidebar");
    document.getElementById("for-create-change-sidebar").classList.add("rs-container-has-sidebar");
    document.getElementById("for-create-change-header").classList.add("content-container");
    document.getElementById("for-hide-sidebar").classList.remove("hide-sidebar-header");
    document.getElementById("for-hide-header").classList.remove("hide-sidebar-header");
  } else if (route !== null) {
    document
      .getElementById("for-create-change-sidebar")
      .classList.remove("rs-container-has-sidebar");
  } else {
    document
      .getElementById("for-create-change-header")
      .classList.remove("rs-container-has-sidebar");
    document.getElementById("for-create-change-sidebar").classList.add("rs-container-has-sidebar");
    document.getElementById("for-create-change-header").classList.add("content-container");
    document.getElementById("for-hide-sidebar").classList.remove("hide-sidebar-header");
    document.getElementById("for-hide-header").classList.remove("hide-sidebar-header");
    // document.getElementById("for-change-editor").classList.add("content-home");
  }
};
