import { createSlice } from "@reduxjs/toolkit";
import { fetchTemplateFolder } from "./TemplateFolderThunk";

const TemplateFolderSlice = createSlice({
  name: "template-folder",
  initialState: {
    loading: false,
    templateFolder: [],
    error: null,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateFolder.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchTemplateFolder.fulfilled, (state, action) => {
        state.templateFolder = action.payload.data.template;
        state.success = true;
        state.loading = false;
      })

      .addCase(
        fetchTemplateFolder.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.templateFolder = [];
        }
      );

    // and provide a default case if no other handlers matched
    //   .addDefaultCase((state, action) => {
    //     state.loading = false;
    //     state.template = [];
    //     state.success = false;
    //     state.error = null;
    //   });
  }
});

export default TemplateFolderSlice.reducer;
