import React, { Component } from "react";

import { connect } from "react-redux";

import { Alert } from "../Utils/Alert";
import { Loader, Placeholder } from "rsuite";
import {
  fetchTemplateFolder,
  createTemplateFolder,
  deleteTemplateFolder,
  renameTemplateFolder,
} from "../Features/Folder/TemplateFolder/TemplateFolderThunk";

import {
  TreeViewComponent,
  ContextMenuComponent,
} from "@syncfusion/ej2-react-navigations";
import ConfirmationModal from "../Utils/DeleteConfirmationModal"; // Import DeleteConfirmationModal

class TemplateFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedFolder: null,
      loading: false,
      type: "",
      id: "",
      showDeleteConfirmationModal: false, // State for showing delete confirmation modal
      folderToDelete: null, // State to store folder id to delete
    };

    this.menuclick = this.menuclick.bind(this);
    this.tree = false;
    this.treeObj = null;
    this.menuItems = [
      { text: "Add Folder" },
      { text: "Rename Folder" },
      { text: "Remove Folder" },
    ];
    this.index = 1;
    this.node = null;
    // this.props.clickTemplate(this.deselectFolder);
    this.alertRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isCreated) {
      return {
        ...nextProps,
        formValue: {
          name: "",
          parentId: "",
        },
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  onCreated() {
    this.tree = true;
    this.treeRender();
  }

  // deselectFolder = () => {
  //   this.treeObj.selectedNodes = [];
  // };

  async componentDidMount() {
    const { fetchTemplateFolder } = this.props;
    await fetchTemplateFolder();
    // await this.treeRender();
  }

  // async treeRender() {
  //   let { FolderSlice, edit, location } = this.props;
  //   let { templateFolder } = FolderSlice;
  //   if (this.treeObj === null || this.tree === false) return;
  //   let mainExpendable = [];
  //   for (let i = 0; i < templateFolder.length; i++) {
  //     if (templateFolder[i].parentId === null) {
  //       mainExpendable.push(`${templateFolder[i].id}`);
  //     }
  //   }
  //   this.treeObj.expandedNodes = mainExpendable;
  //   if (edit && location !== null) {
  //     let selected = [];
  //     selected.push(location.toString());
  //     this.treeObj.selectedNodes = selected;
  //   }
  // }

   /* REVIEW : CHAR-136 : Folder Should be Selected if Goes back without Saving */
  async treeRender() {
    let { FolderSlice, edit, location } = this.props;
    let { templateFolder } = FolderSlice;

    if (this.treeObj === null || this.tree === false) return;
    let mainExpendable = templateFolder
      .filter((folder) => folder.parentId === null)
      .map((folder) => folder.id);
    this.treeObj.expandedNodes = mainExpendable;
    if (edit) {
      // When editing, select the specified location if available
      if (location && location.toString) {
        this.treeObj.selectedNodes = [location.toString()];
      }
    } else {
      // When creating for the first time, select the specified location if available
      if (location && location.toString) {
        this.treeObj.selectedNodes = [location.toString()];
      }
    }
  }

  targetExpansion = (payload) => {
    // console.log(this.treeObj, this.tree);
    if (this.treeObj === null || this.tree === false) return;
    let expandablefolder = [];

    for (let i = 0; i < payload.data.result.length; i++) {
      expandablefolder.push(`${payload.data.result[i].id}`);
    }
    console.log(expandablefolder);
    this.treeObj.expandedNodes = expandablefolder;
  };

  createFolder = async (folderData) => {
    try {
      let { currentSelectedFolder } = this.state;
      const { dispatch, fetchTemplateFolder } = this.props;
      this.setState({
        loading: true,
      });
      // Validate folder name length
      let folderName = folderData.newText; // Remove leading/trailing spaces
      //  console.log("Folder Name:", folderName); // Log the folder name
      if (folderName === "" || !folderName.replace(/\s/g, "").length) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot be blank or consist only of spaces.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (folderName.length > 250) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot exceed 250 characters.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (/\s{2,}/.test(folderName)) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot contain consecutive spaces.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (/^\s/.test(folderName) || /\s$/.test(folderName)) {
        // Show error message if folder name starts or ends with a space
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot start or end with a space.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      let create = await dispatch(
        createTemplateFolder({
          name: folderData.newText,
          parentId: currentSelectedFolder,
          is_folder_or_file: "folder",
        })
      );
      let { payload } = create;
      await fetchTemplateFolder();
      this.setState({
        loading: false,
      });

      this.alertRef.current.showToaster(payload);
      if (payload.status === 1) {
        // Scroll to the newly created folder
        const scrollOptions = {
          block: "end",
          behavior: "smooth",
        };
        document.getElementById(payload.data.id).scrollIntoView(scrollOptions);
      } else {
        this.treeRender();
      }
      this.setState({
        type: "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  deleteFolder = async (targetNodeId) => {
    try {
      var parentID = this.treeObj.treeData[0].id;
      if (parentID === +targetNodeId) {
        let message = {
          status: "error",
          message: "Cannot Delete the Parent Folder",
        };
        this.alertRef.current.showToaster(message);
        return;
      }

      this.setState({
        showDeleteConfirmationModal: true,
        folderToDelete: targetNodeId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  menuclick(args) {
    let targetNodeId = this.node;
    if (args.item.text === "Add Folder") {
      // Close any existing editing sessions before adding a new folder
      if (this.treeObj.isEditing) {
        this.treeObj.endEdit();
      }
      this.setState({
        type: "add",
      });
      let nodeId = "tree_" + this.index;
      this.setState({ currentSelectedFolder: targetNodeId });
      let item = { id: nodeId, name: "New Folder" };
      this.treeObj.addNodes([item], targetNodeId, null);
      this.index++;
      this.treeObj.beginEdit(nodeId);
    } else if (args.item.text === "Remove Folder") {
      this.deleteFolder(targetNodeId);
    } else if (args.item.text === "Rename Folder") {
      this.setState({
        type: "rename",
        id: targetNodeId,
      });
      this.treeObj.beginEdit(targetNodeId);
    }
  }

  renameFolder = async (folderData) => {
    try {
      let { id } = this.state;
      const { dispatch, fetchTemplateFolder } = this.props;
      this.setState({
        loading: true,
      });
      // Validate folder name length
      let folderName = folderData.newText; // Remove leading/trailing spaces
      if (folderName === "" || !folderName.replace(/\s/g, "").length) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot be blank or consist only of spaces.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (folderName.length > 250) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot exceed 250 characters.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (/\s{2,}/.test(folderName)) {
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot contain consecutive spaces.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return; // Exit function
      } else if (/^\s/.test(folderName) || /\s$/.test(folderName)) {
        // Show error message if folder name starts or ends with a space
        this.alertRef.current.showToaster({
          type: "error",
          message: "Folder name cannot start or end with a space.",
          duration: 1,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      let rename = await dispatch(
        renameTemplateFolder({
          id,
          text: folderData.newText,
        })
      );
      let { payload } = rename;

      await fetchTemplateFolder();
      this.setState({
        type: "",
        id: "",
        loading: false,
      });

      this.alertRef.current.showToaster(payload);
      if (payload.status === 1) {
        this.targetExpansion(payload);
      } else {
        this.treeRender();
      }
    } catch (e) {}
  };

  beforeopen(args) {
    if (args.event.target.closest("li"))
      this.node = args.event.target.closest("li").getAttribute("data-uid");
  }

  confirmDeleteFolder = async () => {
    const { folderToDelete } = this.state;
    try {
      this.setState({ loading: true }); // Set loading to true when starting the deletion process

      // Proceed with the delete operation
      let deletefolder = await this.props.dispatch(
        deleteTemplateFolder(folderToDelete)
      );
      let { payload } = deletefolder;
      await this.props.fetchTemplateFolder(); // Fetch updated folder structure
      this.alertRef.current.showToaster(payload);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        showDeleteConfirmationModal: false, // Hide confirmation modal after deletion
        folderToDelete: null, // Reset folderToDelete state
        loading: false, // Set loading to false regardless of success or failure
      });
    }
  };

  render() {
    let { FolderSlice, from } = this.props;
    let { templateFolder } = FolderSlice;
    let { loading, showDeleteConfirmationModal } = this.state;

    this.hierarchicalData = templateFolder;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Alert ref={this.alertRef} />
        <ConfirmationModal
          confirmation={showDeleteConfirmationModal}
          hide={() => this.setState({ showDeleteConfirmationModal: false })}
          handleDelete={this.confirmDeleteFolder}
          component="folder"
          // loading={loading}
        />
        {!loading ? (
          <>
            {templateFolder && templateFolder.length > 0 && (
              <>
                <TreeViewComponent
                  className={`${
                    from !== "assets"
                      ? "folder-structure-sync"
                      : "folder-structure-sync-assets"
                  }`}
                  nodeSelected={this.props.selectFolder}
                  nodeEdited={(fsd) => {
                    if (this.state.type === "add") {
                      this.createFolder(fsd);
                    } else {
                      this.renameFolder(fsd);
                    }
                  }}
                  id="treeEmailTmp"
                  created={this.onCreated.bind(this)}
                  ref={(list) => {
                    this.treeObj = list;
                  }}
                  fields={{
                    dataSource: this.hierarchicalData,
                    id: "id",
                    text: "name",
                    child: "children",
                    iconCss: "is_folder_or_file",
                  }}
                />
                <ContextMenuComponent
                  id="contentmenutreeEmailTmp"
                  target="#treeEmailTmp"
                  items={this.menuItems}
                  beforeOpen={this.beforeopen.bind(this)}
                  select={this.menuclick.bind(this)}
                  ref={(contextmenu) => {
                    this.menuObj = contextmenu;
                  }}
                />
              </>
            )}{" "}
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={8} />
            <Loader backdrop content="loading..." vertical />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  FolderSlice: state.TemplateFolderSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});
const mapDispatchToProps = (dispatch) => ({
  fetchTemplateFolder: () => dispatch(fetchTemplateFolder()),
  dispatch,
});
//exporting the app and mapping the redux state to the App Component
export default connect(mapStateToProps, mapDispatchToProps)(TemplateFolder);
