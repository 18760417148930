import React, { Component } from "react";
import { connect } from "react-redux";
import "./assets/css/newemail.css";
import {
  Row,
  Col,
  Schema,
  Content,
  Button,
  Form,
  SelectPicker,
  Drawer,
} from "rsuite";
import { getEmails } from "../../Features/Asset/AssetThunk";
import EmailFolder from "../../Folder/EmailFolder";
import DataSetFolder from "../../Folder/DataFolder";
import CampaignFolder from "../../Folder/CampaignFolder";

import SuppressionFolder from "../../Folder/SuppressionFolder";

import { getDataLists } from "../../Features/Data/DataThunk";
import { isCampaignNameExist } from "../../Features/CampaignManagement/CampaignPropertiesThunk";
import {
  emailSelected,
  campaignnameText,
  selectCampaignCategory,
  // selectCampaignGroup,
  setSenderProfile,
  setSenderProfileReplyTo,
  // setSuppressionData,
  campaignSelectFolderId,
  errorCampFolder,
  errorStepEmail,
  errorStepData,
  dataSelected,
  suppressionState,
  setSupressionSelected,
  errorSuppression,
  errorCampaignName,
  subjectLineValues,
  setThrottleSelected,
} from "../../Features/CampaignManagement/CampaignPropertiesSlice";
// import{setSenderProfile} from "../../Features/SenderManagement/SenderSlice"

import Loading from "../../Loading/Loading";
import ErrorPage from "../../errorPage/ErrorPage";

class CamDefineProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignname: this.props.campaignname,
      subjectLine: this.props.subjectLine,
      emailName: this.props.selectedEmailProperties.emailName,
      camp_Category: this.props.camp_Category,
      // camp_Group: this.props.camp_Group,
      suppression_Include: this.props.suppression_Include,
      senderProfile: this.props.senderProfile,
      // SuppressionData: this.props.SuppressionData,
      suppressionList: this.props.suppressionList,
      error: "",
      formError: {},
      loading: false,
      errorType: false,
      dataId: "",
      dataCount: "",
      dataEmail: "",
      dataName: "",
      typeComponent: true,
      show: false,
      showData: false,
      DataList: [],
      emailId: "",
      emailHtmlPath: "",
      emailSubject: "",
      emailImgPath: "",
      emailList: [],
      showDataType: false,
      suppressionFolder: null,
      dataSetFolder: null,
      emailFolder: null,
      senderProfiles: this.props.senderProfiles
    };

    this.props.setClick(this.setInput);
  }

  fetchEmails = async ({ type, page, value }) => {
    const { getEmails } = this.props;
    this.setState({
      loading: true,
    });
    let { limit } = this.state;

    try {
      const edata = await getEmails({ type, page, limit, value });
      let { email } = edata.payload.data;

      let { emails } = this.props;
      this.setState({
        loading: false,
        page: Number(emails.page),
        total: emails.count,
        emailList: email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async ({ type, page, value }) => {
    const { getDataLists } = this.props;
    this.setState({
      loading: true,
    });
    let { limit } = this.state;

    try {
      const Fdata = await getDataLists({ type, page, limit, value });
      let { data } = Fdata.payload.data;
      // console.log(data);
      this.setState({
        loading: false,
        page: Number(data.page),
        total: data.count,
        DataList: data,
      });
    } catch (error) {
      console.error(error);
    }

    // this.setState({
    //   loading: false,
    //   page: Number(dataList.page),
    //   total: dataList.count,
    // });
  };

  selectFolderData = async (e) => {
    this.setState({
      showData: true,
      showDataType: true,
      dataSetFolder: e.nodeData.id,
    });
    await this.fetchData({
      type: "campaign",
      limit: 2,
      page: 1,
      value: e.nodeData.id,
    });
  };

  selectFolderEmail = async (e) => {
    this.fetchEmails({
      type: "campaign",
      limit: 5,
      page: 1,
      value: e.nodeData.id,
    });
    this.setState({
      show: true,
      emailFolder: e.nodeData.id,
    });
  };

  selectFolderSupression = async (e) => {
    this.setState({
      showData: true,
      showDataType: false,
      suppressionFolder: e.nodeData.id,
    });
    await this.fetchData({
      type: "campaign",
      limit: 5,
      page: 1,
      value: e.nodeData.id,
    });
  };

  setInput = () => {
    // console.log(
    //   "CAMP_CATEGORY::::::::::::::DEFINE:::::::::::::",
    //   this.state.camp_Category
    // );
    // console.log(
    //   "campaignname:::::::::::::DEFINE:::::::::::::",
    //   this.state.campaignname
    // );
    // console.log(
    //   "senderProfile:::::::::::DEFINE:::::",
    //   this.state.senderProfile
    // );
    document.getElementById("submitbutton").click();
  };

  checkCampaignname = async () => {
    try {
      let { isCampaignNameExist, errorCampaignName } = this.props;
      const campaignname = this.props.campaignname;

      let dataCheck = await isCampaignNameExist({ campaignname });
      let { data } = dataCheck.payload;
      this.setState({
        error: data.message,
        errorType: data.error,
      });
      errorCampaignName(data.error);
    } catch (error) {
      console.error(error);
    }
  };

  campaignName = (e) => {
    let { campaignnameText } = this.props;
    var campaignname = document.getElementById("campaignname").value;
    this.setState({
      campaignname: campaignname,
    });

    campaignnameText(campaignname);
  };



  selectEmail = (e) => {
    this.setState({
      emailName: e.target.getAttribute("data-name"),
      emailId: e.target.getAttribute("data-id"),
      emailHtmlPath: e.target.getAttribute("data-path"),
      emailSubject: e.target.getAttribute("data-subject"),
      emailImgPath: e.target.getAttribute("data-imgpath"),
    });
  };

  confirmEmail = () => {
    let { errorStepEmail, emailSelected, alertRef } = this.props;

    if (this.state.emailName !== "") {
      this.setState({ show: false });
      const selectedEmailProperties = {
        emailName: this.state.emailName,
        emailId: this.state.emailId,
        emailHtmlPath: this.state.emailHtmlPath,
        emailSubject: this.state.emailSubject,
        emailImgPath: this.state.emailImgPath,
        emailFolder: this.state.emailFolder,
      };

      emailSelected(selectedEmailProperties);
      errorStepEmail(false);
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Email ",
      });
    }
  };

  selectDataList = (e) => {
    this.setState({
      dataName: e.target.getAttribute("data-name"),
      dataId: e.target.getAttribute("data-id"),
      dataCount: e.target.getAttribute("data-count"),
      dataEmail: e.target.getAttribute("data-email"),
    });
  };

  confirmData = () => {
    let { dataSelected, errorStepData, alertRef } = this.props;

    if (this.state.dataName !== "") {
      const selectedDataProperties = {
        dataName: this.state.dataName,
        dataId: this.state.dataId,
        dataCount: this.state.dataCount,
        dataEmail: this.state.dataEmail,
        dataSetFolder: this.state.dataSetFolder,
      };
      dataSelected(selectedDataProperties);
      this.setState({
        showData: false,
        dataName: "",
        dataId: "",
        dataCount: "",
        dataEmail: "",
        dataSetFolder: "",
      });
      errorStepData(false);
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Data Set",
      });
    }
  };

  confirmSuppression = () => {
    let { suppressionSelected, errorSuppression, alertRef } = this.props;
    if (this.state.dataName !== "") {
      suppressionSelected({
        suppressionId: this.state.dataId,
        suppressionName: this.state.dataName,
        suppressionFolder: this.state.suppressionFolder,
        suppressionCount: this.state.dataCount,
      });
      this.setState({
        showData: false,
        dataName: "",
        dataId: "",
        dataCount: "",
        dataEmail: "",
      });
      errorSuppression(false);
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Suppression List",
      });
    }
  };

  subjectLine = () => {
    let { subjectLineValues } = this.props
    var subjectLine = document.getElementById("subjectLine").value
    // console.log("subjectLineValues", subjectLineValues)
    this.setState({
      subjectLine: subjectLine
    })
    subjectLineValues(subjectLine)
  }

  selectCategory = (e) => {
    let { selectCampaignCategory } = this.props;
    this.setState({
      camp_Category: e,
    });
    selectCampaignCategory(e);
  };

  // selectGroup = (e) => {
  //   let { selectCampaignGroup } = this.props;
  //   // let { camp_Group } = this.state;
  //   this.setState({
  //     camp_Group: e,
  //   });
  //   selectCampaignGroup(e);
  // };

  senderProfile = (e) => {
    let { setSenderProfile } = this.props;
    this.setState({
      senderProfile: e,
    });
    setSenderProfile(e);
  };

  // suppressiondata = (e) => {
  //   console.log("Props:", this.props.SuppressionData);
  //   let { setSuppressionData } = this.props;
  //   this.setState({
  //     SuppressionData: e,
  //   });
  //   setSuppressionData(e);
  // };

  selectCampaignFolder = (args) => {
    let { campaignSelectFolderId } = this.props;

    campaignSelectFolderId(args.nodeData.id);
  };

  render() {
    // console.log("Props:", this.props.camp_Group);
    // let { camp_Group } = this.state;

    var category = [
      {
        label: "Charlie Campaigns",
        value: "21521",
        role: "21521",
      },
      {
        label: "Charlie Newsletters",
        value: "32358",
        role: "32358",
      },
      {
        label: "Charlie Events",
        value: "32359",
        role: "32359",
      },
      {
        label: "Charlie Transactional",
        value: "32449",
        role: "32449",
      },
    ];

    // var suppressionGroup = [
    //   {
    //     label: "Global",
    //     value: "15576",
    //     role: "15576",
    //   },
    //   {
    //     label: "DCG",
    //     value: "21521",
    //     role: "21521",
    //   },
    // ];



    const { StringType } = Schema.Types;
    const model = Schema.Model({
      campaignname: StringType().isRequired("This field is required."),
      senderProfile: StringType().isRequired("This field is required."),
      selectGroup: StringType().isRequired("This field is required."),
      selectCategory: StringType().isRequired("This field is required."),
      subjectLine: StringType().isRequired("This field is required."),
      
    });
    let {
      suppressionState,
      isSuppressionError,
      suppressionSelected,
      errorSuppression,
      userInfo: { user },
      throttleSelected,
      throttle,
      alertRef,
    } = this.props;
    var {
      emailList,
      DataList,
      loading,
      showDataType,
      suppression_Include,
      senderProfiles,
      replyTo,
      subjectLine,
    } = this.state;
    // console.log("replyTo",replyTo)
    // console.log(this.props.throttle);
    // console.log("subject line render", subjectLine)

    const userAccess = JSON.parse(JSON.parse(user.access));

    const senderprofiledetails = senderProfiles.map((profile) => ({
      from: profile.from_name,
      label: profile.from_name,
      value: profile.from_email,
      email: profile.from_email,
      replyTo: profile.reply_to_email,
    }));

    if (!userAccess.includes("campaign")) {
      return <ErrorPage />;
    } else {
      return (
        <>
          <Content className="creat-camp-form">
            <Row
              style={{
                background: this.props.color,

                paddingBottom: 5,
                padding: "4%",
              }}
            >
              <Col md={24}>
                <Row>
                  <Form
                    fluid
                    model={model}
                    ref={(ref) => (this.form = ref)}
                    onCheck={(formError) => {
                      this.setState({ formError });
                    }}
                  >
                    <Col md={11}>
                      <Form.Group>
                        <Form.ControlLabel className="enter-a-valid-email">
                          Campaign Name*
                        </Form.ControlLabel>
                        <Form.Control
                          onFocus={() => {
                            this.setState({
                              errorType: false,
                            });
                          }}
                          onChange={this.campaignName}
                          onBlur={this.checkCampaignname}
                          name="campaignname"
                          style={{ background: this.props.color }}
                          type="text"
                          className="create_newfields"
                          id="campaignname"
                          defaultValue={this.props.campaignname}
                        />
                        <span
                          id="checkerror"
                          className="custom-error-fields"
                          style={{
                            display: `${
                              this.state.errorType ? "block" : "none"
                            }`,
                          }}
                        >
                          {this.state.error}
                        </span>
                      </Form.Group>
                      <Form.Group>
                        <Form.ControlLabel className="enter-a-valid-email">
                          Select Category*
                        </Form.ControlLabel>
                        <Form.Control
                          accepter={SelectPicker}
                          onChange={this.selectCategory}
                          value={this.state.camp_Category}
                          data={category}
                          searchable={true}
                          style={{ width: "100%" }}
                          name="selectCategory"
                          className="forDesc"
                        />
                      </Form.Group>

                      <Row
                        style={{
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      >
                        Location*
                        <Col
                          md={24}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className="location input-text-space"
                            placeholder="Location"
                            style={{
                              background: "#fff",
                              marginTop: 7,
                            }}
                          >
                            <CampaignFolder
                              selectFolder={this.selectCampaignFolder}
                              from="campaign"
                            />
                          </div>
                        </Col>
                      </Row>
                      {this.props.errorFolder === true &&
                        this.props.folderId === "" && (
                          <span className="error">This field is Required</span>
                        )}

                      <Row
                        style={{ marginTop: 30, marginLeft: 0, marginRight: 0 }}
                      >
                        Select Email*
                        <Col
                          md={24}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className="location input-text-space"
                            placeholder="Location"
                            id="select-email"
                            style={{
                              background: "#fff",
                              marginTop: 7,
                            }}
                          >
                            <EmailFolder
                              selectFolder={this.selectFolderEmail}
                              from="campaign"
                              setCancelEmailFolder={(click) =>
                                (this.clickEmailFolder = click)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      {this.props.errorFirstStepEmail === true &&
                        this.props.selectedEmailProperties.emailName === "" && (
                          <span className="error">This field is Required</span>
                        )}
                    </Col>
                    <Col md={2}></Col>
                    <Col md={11}>
                      {/* changing subject line to take as input */}

                      <Form.Group>
                        <Form.ControlLabel className="enter-a-valid-email">
                         Subject Line*
                        </Form.ControlLabel>
                        <Form.Control
                          onFocus={() => {
                            this.setState({
                              errorType: false,
                            });
                          }}
                          onChange={this.subjectLine}
                          name="subjectLine"
                          style={{ background: this.props.color }}
                          type="text"
                          className="create_newfields"
                          id="subjectLine"
                          defaultValue={subjectLine}
                        />
                      </Form.Group>

                      {/* END */}

                      <Form.Group>
                        <Form.ControlLabel className="enter-a-valid-email">
                          <span style={{ fontWeight: 100 }}>
                            Select Sender Profile*
                          </span>
                        </Form.ControlLabel>
                        <Form.Control
                          accepter={SelectPicker}
                          onChange={this.senderProfile}
                          value={this.state.senderProfile}
                          data={senderprofiledetails}
                          searchable={true}
                          style={{ width: "100%" }}
                          name="senderProfile"
                          className="forDesc"
                        // defaultValue = {this.state.method}
                        />{" "}
                      </Form.Group>

                      {/* <Form.Group>
                        <Form.ControlLabel className="enter-a-valid-email">
                          <span style={{ fontWeight: 100 }}>
                            Select Suppression Group
                          </span>
                        </Form.ControlLabel>
                        <Form.Control
                          accepter={SelectPicker}
                          onChange={this.selectGroup}
                          value={this.state.camp_Group}
                          data={suppressionGroup}
                          style={{ width: "100%" }}
                          name="selectGroup"
                          searchable={false}
                          className="forDesc"
                        />
                      </Form.Group> */}

                      <Row style={{ marginLeft: 0, marginRight: 0 }}>
                        Select Data List*
                        <Col
                          md={24}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className="location input-text-space"
                            placeholder="Location"
                            style={{
                              background: "#fff",
                              marginTop: 7,
                            }}
                          >
                            <DataSetFolder
                              selectFolder={this.selectFolderData}
                              from="campaign"
                              setCancelDataFolder={(click) =>
                                (this.clickDataFolder = click)
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      {this.props.selectedDataProperties.dataName === "" &&
                        this.props.errorFirstStepData === true && (
                          <span className="error">This field is Required</span>
                        )}
                      <Row
                        style={{ marginTop: 30, marginLeft: 0, marginRight: 0 }}
                      >
                        Select Suppression List ?
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          style={{ width: 15 }}
                          checked={suppression_Include}
                          name="campaign"
                          id="suppression"
                          onChange={() => {
                            this.setState(
                              {
                                suppression_Include: !suppression_Include,
                              },
                              () => {
                                if (!suppression_Include) {
                                  suppressionSelected({
                                    suppressionId: null,
                                    supressionFolder: null,
                                  });
                                  errorSuppression(false);
                                }
                                suppressionState(
                                  this.state.suppression_Include
                                );
                              }
                            );
                          }}
                        />
                        {suppression_Include && (
                          <>
                            <div
                              className="location input-text-space"
                              placeholder="Location"
                              style={{
                                background: "#fff",
                                marginTop: 5,
                              }}
                            >
                              <SuppressionFolder
                                selectFolder={this.selectFolderSupression}
                                from="campaign"
                                setCancelSuppressionFolder={(click) =>
                                  (this.clickSuppressionFolder = click)
                                }
                              />
                            </div>
                            {suppression_Include && isSuppressionError && (
                              <span className="error">
                                This field is Required
                              </span>
                            )}
                          </>
                        )}
                      </Row>
                      <Row
                        style={{ marginTop: 30, marginLeft: 0, marginRight: 0 }}
                      >
                        This email will be throttled at the rate specified
                        below? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="throttle"
                          style={{ width: 15 }}
                          checked={throttle.isThrottle}
                          onChange={() => {
                            throttleSelected({
                              throttleLimit: 500,
                              throttleProgress: null,
                              isThrottle: !throttle.isThrottle,
                              throttleError: false,
                            });
                          }}
                        />
                        {throttle.isThrottle && (
                          <>
                            <br />
                            <br />
                            <input
                              type="number"
                              id="throttle"
                              name="throttle"
                              min="1"
                              max="1000"
                              onChange={(e) => {
                                if (e.target.value <= 1000) {
                                  throttleSelected({
                                    throttleLimit: Number(e.target.value),
                                    throttleProgress: throttle.throttleProgress,
                                    isThrottle: throttle.isThrottle,
                                    throttleError: false,
                                  });
                                } else {
                                  alertRef.current.showToaster({
                                    status: 0,
                                    message:
                                      "Maximum emails per 20 minutes allowed are 1000",
                                      duration:3
                                  });
                                }
                              }}
                              value={throttle.throttleLimit}
                            />
                            &nbsp;&nbsp;/ 20 minutes
                            {throttle.isThrottle && throttle.throttleError && (
                              <span className="error">
                                This field value is need to be more than 0
                              </span>
                            )}
                          </>
                        )}
                        <br />
                        <br />
                        {/* <span
                          style={{ marginTop: 15, fontSize: 12, color: "red" }}
                        >
                          *We are unable to send more than 1000 emails in one
                          go. Please consider using throttling instead of
                          sending all the emails at once.
                        </span> */}
                      </Row>
                      <Button
                        type="submit"
                        style={{ display: "none" }}
                        onClick={this.handleSubmit}
                        id="submitbutton"
                      >
                        Hello
                      </Button>
                    </Col>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Content>
          <Drawer
            backdrop={true}
            open={this.state.show}
            onClose={() => {
              this.setState({
                show: false,
              });
              this.clickEmailFolder();
            }}
          >
            <Drawer.Header>
              <Drawer.Title>Select Email</Drawer.Title>
              <Drawer.Actions>
                <Button onClick={this.confirmEmail} style={{ backgroundColor: "#415670", color: "#fff" }}>
                  Confirm
                </Button>

                <Button
                  onClick={() => {
                    this.setState({
                      show: false,
                    });
                    this.clickEmailFolder();
                  }}
                  color="red"
                  style={{ backgroundColor: "#415670", color: "#fff" }}
                >
                  Cancel
                </Button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body style={{ padding: 30 }}>
              <Col md={24} sm={24}>
                {loading === false ? (
                  emailList.length > 0 ? (
                    <Row className="TemplateList2">
                      <Form>
                        {Object.keys(emailList).map((val, index) => {
                          return (
                            <div key={index}>
                              <Col
                                md={11}
                                style={{
                                  marginBottom: 20,
                                  height: 220,
                                }}
                              >
                                <div
                                  style={{
                                    overflow: "hidden",
                                    height: 200,
                                    width: 200,
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="template-id"
                                    id={`emailList${emailList[val].email_id}`}
                                    data-id={emailList[val].email_id}
                                    data-name={emailList[val].email_name}
                                    data-path={emailList[val].email_json_path}
                                    data-subject={
                                      emailList[val].email_subject_line
                                    }
                                    data-imgpath={emailList[val].email_img_path}
                                    onClick={this.selectEmail}
                                    className="radio-custom"
                                  />
                                  <label
                                    htmlFor={`emailList${emailList[val].email_id}`}
                                    className="radio-custom-label"
                                  >
                                    <img
                                      src={`${emailList[val].email_img_path}`}
                                      title={emailList[val].email_name}
                                      alt={emailList[val].email_name}
                                      id={`emailList${emailList[val].email_id}`}
                                      data-id={emailList[val].email_id}
                                      data-name={emailList[val].email_name}
                                      data-path={emailList[val].email_json_path}
                                      data-subject={
                                        emailList[val].email_subject_line
                                      }
                                      loading="lazy"
                                      data-imgpath={
                                        emailList[val].email_img_path
                                      }
                                      style={{ width: "100%", height: "auto" }}
                                      onClick={this.selectEmail}
                                    />
                                  </label>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: 0,
                                    color: "#000",
                                  }}
                                >
                                  {emailList[val].email_name}
                                </p>
                              </Col>
                              <Col md={1}></Col>
                            </div>
                          );
                        })}
                      </Form>
                    </Row>
                  ) : (
                    <Row>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: 30,
                          color: "#000",
                        }}
                      >
                        No Email found.
                      </h3>
                    </Row>
                  )
                ) : (
                  <Loading />
                )}
              </Col>
            </Drawer.Body>
          </Drawer>

          {/* DATALIST SELECTION */}

          <Drawer
            backdrop
            open={this.state.showData}
            onClose={() => {
              this.setState({
                showData: false,
                dataName: "",
                dataId: "",
                dataCount: "",
                dataEmail: "",
              });
              if (showDataType) {
                this.clickDataFolder();
              } else {
                this.clickSuppressionFolder();
              }
            }}
          >
            <Drawer.Header>
              <Drawer.Title>
                {showDataType ? "Select Data List" : "Select Suppression List"}
              </Drawer.Title>

              <Drawer.Actions>
                <Button
                  onClick={
                    showDataType ? this.confirmData : this.confirmSuppression
                  }
                  style={{ backgroundColor: "#415670", color: "#fff" }}
                >
                  Confirm
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      showData: false,
                      dataName: "",
                      dataId: "",
                      dataCount: "",
                      dataEmail: "",
                    });
                    if (showDataType) {
                      this.clickDataFolder();
                    } else {
                      this.clickSuppressionFolder();
                    }
                  }}
                  style={{ backgroundColor: "#415670", color: "#fff" }}
                >
                  Cancel
                </Button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
              <Col md={24} sm={24}>
                {loading === false ? (
                  DataList.length > 0 ? (
                    <Row className="TemplateList2">
                      <Form>
                        {Object.keys(DataList).map((val, index) => {
                          return (
                            <>
                              <Col md={11} sm={12} key={index}>
                                <input
                                  type="radio"
                                  name="template-id"
                                  id={`dataList${DataList[val].data_list_id}`}
                                  data-id={DataList[val].data_list_id}
                                  data-name={DataList[val].data_list_name}
                                  data-count={DataList[val].data_list_count}
                                  data-email={
                                    DataList[val].data_list_email_field
                                  }
                                  onClick={this.selectDataList}
                                  className="radio-custom"
                                ></input>
                                <label
                                  htmlFor={`dataList${DataList[val].data_list_id}`}
                                  className="radio-custom-label"
                                >
                                  <img
                                    title={DataList[val].data_list_name}
                                    src="https://charlie-dave.s3.amazonaws.com/database.png"
                                    alt={DataList[val].data_list_name}
                                    id={`dataList${DataList[val].data_list_id}`}
                                    data-id={DataList[val].data_list_id}
                                    data-name={DataList[val].data_list_name}
                                    data-count={DataList[val].data_list_count}
                                    data-email={
                                      DataList[val].data_list_email_field
                                    }
                                    style={{ width: 150 }}
                                    onClick={this.selectDataList}
                                  />
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    {DataList[val].data_list_name}
                                  </p>
                                </label>
                              </Col>
                            </>
                          );
                        })}
                      </Form>
                    </Row>
                  ) : (
                    <Row>
                      <h3 style={{ textAlign: "center", marginTop: 30 }}>
                        No Data List found.
                      </h3>
                    </Row>
                  )
                ) : (
                  <Loading />
                )}
              </Col>
            </Drawer.Body>
          </Drawer>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.LoginSlice.userInfo,
  emails: state.AssetSlice.emails,
  selectedEmailProperties:
    state.CampaignPropertiesSlice.selectedEmailProperties,
  campaignname: state.CampaignPropertiesSlice.campaignname,
  subjectLine:state.CampaignPropertiesSlice.subjectLine,
  errorFolder: state.CampaignPropertiesSlice.errorFolder,
  folderId: state.CampaignPropertiesSlice.folderId,
  emailList: state.CampaignPropertiesSlice.emailList,
  loadingCampaign: state.CampaignPropertiesSlice.loadingCampaign,
  dataList: state.CampaignPropertiesSlice.loadingCampaign,
  suppression_Include: state.CampaignPropertiesSlice.suppression_Include,
  camp_Category: state.CampaignPropertiesSlice.camp_Category,
  senderProfile: state.CampaignPropertiesSlice.senderProfile,
  // camp_Group: state.CampaignPropertiesSlice.camp_Group,
  senderProfiles: state.SenderSlice.senderProfiles,
  // senderProfiles: state.CampaignSlice.senderProfiles,
  error: state.CampaignPropertiesSlice.error,
  selectedDataProperties: state.CampaignPropertiesSlice.selectedDataProperties,
  errorFirstStepEmail: state.CampaignPropertiesSlice.errorFirstStepEmail,
  errorFirstStepData: state.CampaignPropertiesSlice.errorFirstStepData,
  suppressionId: state.CampaignPropertiesSlice.suppressionId,
  isSuppressionError: state.CampaignPropertiesSlice.isSuppressionError,
  throttle: state.CampaignPropertiesSlice.throttle,
});

const mapDispatchToProps = (dispatch) => ({
  getEmails: (type, page, limit, value) =>
    dispatch(getEmails(type, page, limit, value)),
  isCampaignNameExist: (campaignname) =>
    dispatch(isCampaignNameExist(campaignname)),
  getDataLists: (type, page, limit, value) =>
    dispatch(getDataLists(type, page, limit, value)),
  campaignnameText: (text) => {
    dispatch(campaignnameText(text));
  },
  subjectLineValues:(text)=> dispatch(subjectLineValues(text)),
  emailSelected: (selectedEmailProperties) =>
    dispatch(emailSelected(selectedEmailProperties)),
  dataSelected: (selectedDataProperties) =>
    dispatch(dataSelected(selectedDataProperties)),
  campaignSelectFolderId: (campaignSelectFolder) =>
    dispatch(campaignSelectFolderId(campaignSelectFolder)),
  selectCampaignCategory: (category) =>
    dispatch(selectCampaignCategory(category)),
  setSenderProfile: (profile) => dispatch(setSenderProfile(profile)),
  setSenderProfileReplyTo: (profile) =>
    dispatch(setSenderProfileReplyTo(profile)),
  // selectCampaignGroup: (suppressionGroup) =>
  //   dispatch(selectCampaignGroup(suppressionGroup)),
  errorCampFolder: (error) => dispatch(errorCampFolder(error)),
  errorStepEmail: (emailError) => dispatch(errorStepEmail(emailError)),
  errorStepData: (errorData) => dispatch(errorStepData(errorData)),
  errorCampaignName: (errorInName) => dispatch(errorCampaignName(errorInName)),
  suppressionState: (supressionData) =>
    dispatch(suppressionState(supressionData)),
  suppressionSelected: (dataId, suppressionFolder) =>
    dispatch(setSupressionSelected(dataId, suppressionFolder)),
  throttleSelected: (
    throttleLimit,
    throttleProgress,
    isThrottle,
    throttleError
  ) =>
    dispatch(
      setThrottleSelected(
        throttleLimit,
        throttleProgress,
        isThrottle,
        throttleError
      )
    ),

  errorSuppression: (isError) => dispatch(errorSuppression(isError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CamDefineProperties);
