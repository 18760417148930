import React, { Component } from "react";

import { Pagination } from "rsuite";
import { connect } from "react-redux";

class PaginationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: ["Total Items", "|", "pager"]
    };
  }

  render() {
    let { layout } = this.state;
    let { limit, page, setNewPage, total } = this.props;
    return (
      <Pagination
        className="pagination-component"
        layout={layout}
        size="xs"
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        limit={limit}
        total={total}
        maxButtons={5}
        activePage={page}
        onChangePage={(e) => setNewPage(e)}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  // editorSlice: state.EditorSlice,
});

const mapDispatchToProps = (dispatch) => ({
  // editorTemplates: (type, page, limit, value) =>
  //   dispatch(editorTemplates(type, page, limit, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationComponent);
