import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import defaultAxios from "axios";
import { api_url } from "../../Utils/Interceptor/AxiosInterceptor";

// details - getting to be saved
export const login = createAsyncThunk("auth/login", async (credentials) => {
  // SECTION Credentials coming from Login.js in form { email, password }
  const response = await axios.post("/auth/signin", credentials);
  // console.log("LoginThunk response.data.data.user", response.data.data.user);

  return response.data;
});
export const userStatus = createAsyncThunk("auth/login", async () => {
  // FIXME issue of auth - it needs user_id
  const response = await axios.get("/auth/status");
  // console.log("LoginThunk response:", response);

  return response.data;
});

export const logout = createAsyncThunk("auth/login", async ({ id }) => {
  try {
    const response = await axios.post("/auth/logout", { id });
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});

export const forgotPassword = createAsyncThunk("auth/forgot", async (email) => {
  try {
    const response = await axios.post("/auth/forgot-password", {
      email
    });
    return response.data;
  } catch (e) {
    // console.error(e.response.data);
    // let response = {};
    return e.response.data;
    // return e.response["data"] = {
    //   status: 0,
    //   message: "Something went wrong",
    // });
  }
});

export const checkResetToken = createAsyncThunk("auth/checkResetToken", async ({ token }) => {
  try {
    const response = await defaultAxios.get(
      `${api_url}/auth/reset-status`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const resetPassword = createAsyncThunk("auth/reset", async ({ password, token }) => {
  try {
    const response = await defaultAxios.post(
      `${api_url}/auth/reset-password`,
      {
        password
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
