import React, { Component } from "react";
import logo from "./assets/icons/Charlie.png";
import "./assets/css/login.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword } from "../Features/Login/LoginThunk";

class ConfirmationPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactAdmin: false,
      hideDidntReceiveEmail: true,
      remainingTime: 180, // 3 minutes in seconds
      clickCount: 0
    };
  }

  componentDidMount() {
    // Start the countdown timer
    this.startTimer();
  }

  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearInterval(this.timer);
  }

  startTimer = () => {
    this.timer = setInterval(this.updateTimer, 1000);
  };

  updateTimer = () => {
    const { remainingTime } = this.state;
    if (remainingTime > 0) {
      this.setState({ remainingTime: remainingTime - 1 });
    } else {
      clearInterval(this.timer);
      this.setState({ hideDidntReceiveEmail: false });
    }
  };

  handleContactAdmin = () => {
    this.setState({ showContactAdmin: true });
  };

  handleDidntReceiveEmail = async () => {
    const { clickCount } = this.state;
    const { forgotPassword, resetemail } = this.props;
  
    // Increment click count
    this.setState(prevState => ({
      clickCount: prevState.clickCount + 1
    }));
  
    // Clear existing timer and start a new one
    clearInterval(this.timer);
    this.setState({ remainingTime: 180 }, () => {
      this.startTimer();
    });
  
    // Check if click count is greater than or equal to 2
    if (clickCount >= 1) {
      this.showContactAdmin();
      const response = await forgotPassword(resetemail);
      return; // Exit the function early
    }
  
    try {
      // Send email for password reset
      const response = await forgotPassword(resetemail);
      // Handle response if necessary
    } catch (error) {
      console.error("Error sending password reset email:", error);
      // Handle error if necessary
    }
  };
  
  
  
  

  showContactAdmin = () => {
    this.setState({ showContactAdmin: true, hideDidntReceiveEmail: true });
  };

  render() {
    const { showContactAdmin, hideDidntReceiveEmail, remainingTime, clickCount } = this.state;
    // console.log(this.props.t);
    return (
      <div className="wrapper">
        <div className="box left-side-form">
          <div className="login-logo-div">
            <img src={logo} alt="" className="login-logo" />
          </div>
          <div style={{ margin: "0 10%", color: "#000" }}>
            <h4 style={{ color: "#000" }}>
              {this.props.t === "changed"
                ? "Password Changed Successfully"
                : "Reset Password email sent"}
            </h4>
            <p>
              {this.props.t === "changed" ? (
                <>
                  Try Login using new password <br />
                  <b>Thank You</b>
                </>
              ) : (
                <>
                  <p style={{paddingTop:5}}>We have sent you an email in response to your request.</p>
                  <br />
                  <li>
                    Please check in your email's inbox.
                  </li>
                  <li>
                    Please check in your email's junk folder.
                  </li>
                  <li>
                    Please check in your email's spam folder.
                  </li>
                  <li>
                  If you didn't receive the email wait for 3 minutes before resend.
                  </li>
                  <br />
                  <b>
                    Valid for {Math.floor(remainingTime / 60)} minutes and{" "}
                    {remainingTime % 60} seconds
                  </b>
                </>
              )}
            </p>
          </div>
          <Link to="/login" style={{ textAlign: "center", marginTop: -15 }}>
            <span className="forget-paswword" style={{ cursor: "pointer" }}>
              Back to Login
            </span>
          </Link>
          {this.props.t !== "changed" && !hideDidntReceiveEmail && remainingTime <= 0 && clickCount <= 1 && (
  <span
    style={{
      cursor: "pointer",
      textAlign: "center",
      marginTop: -15
    }}
    className="forget-paswword"
    onClick={this.handleDidntReceiveEmail}
  >
    Didn't receive an email?
  </span>
)}
{showContactAdmin && remainingTime <= 0 && !hideDidntReceiveEmail && (
  <span
    className="forget-paswword"
    style={{
     
      color: "#001A62",
      marginTop: -15,
      textAlign: "center"
    }}
  > You have reached your maximum attempted please
    <a
      href="mailto:support@charlie.dealercontentgroup.com"
      className="contactAdminForgotpassword"
      
    >
       &nbsp;Contact admin
    </a>
  </span>
)}


        </div>
        <div className="box right-side-content">
          <div className="text">
            <p>
              "We are what we repeatedly do.
              <br />
              Excellence, then is not an act, but a habit"
            </p>
          </div>
          <div className="DSC-text">
            {/* <p>Powered by DSC India Private limited</p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.LoginSlice
  
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(null, { forgotPassword })(ConfirmationPassword);