import React, { useEffect, useState } from "react";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import {
  Button,
  Col,
  Dropdown,
  Grid,
  IconButton,
  Row,
  Stack,
  Tooltip,
  Whisper,
  Badge,
} from "rsuite";
import { connect } from "react-redux";
import { emailSend, deleteEmail } from "../../Features/Email/EmailThunk";
import SendTestEmail from "./NewModal/SendTestEmail";
import SendIcon from "@rsuite/icons/Send";
import EmailEditProperty from "./Modal/EmailEditProperty";
import EditIcon from "@rsuite/icons/Edit";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import ConfirmationModal from "../ConfirmationModal";
import TemplateEditProperty from "./Modal/TemplateEditProperty";
import { deleteTemplate } from "../../Features/Template/TemplateThunk";
import { Link, Navigate } from "react-router-dom";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import TemplateLoad from "./Modal/TemplateLoad";
import LoadDataSetModal from "./Modal/LoadDataSetModal";
import logoWhite from "../../Navbar/assets/icons/Charlie_logo_white.png";
import logo from "../../Navbar/assets/icons/Charlie_logo_black.png";
import ArrowLeftIcon from "@rsuite/icons/ArrowLeft";
import useFullscreenToggle from "../../../hooks/useFullscreenToggle";
import { checkEmailIdInDatabase } from "../../Features/CampaignManagement/CampaignPropertiesThunk";

const tooltip = (
  <Tooltip>
    Send <i>Test Email</i>
  </Tooltip>
);

function TestSend(props) {
  // console.log(props);
  const {
    component,
    edit,
    saveEditor,
    params,
    alertRef,
    editEditor,
    handleReset,
    handleSave,
    autoSaveLoading,
  } = props;
  // console.log(params);
  const { isFullscreen, handleFullscreenToggle } = useFullscreenToggle();
  const [state, setState] = useState({
    templateLoadModal: false,
    importCodeModal: false,
    editor: {},
    confirmation: false,
    editproperties: false,
    openEmailEditModal: false,
    openTemplateEditModal: false,
    openSendModal: false,
    deleteConfirmation: false,
    isDeleted: false,
    isInDatabase: false,
    // REVIEW Loading state
    loading: false,
    emailId: params, // Store email_id in state
    // isSent: 0,
  });

  // #NOTE This code will check the emailid is align with camapaign or not

  useEffect(() => {
    const checkEmailId = async () => {
      try {
        const response = await props.checkEmailIdInDatabase(state.emailId.id);
        // console.log(response.payload);
        const responseValue = response.payload.exists;
        // const checkCampSent = response.payload.isSent;
        // console.log(responseValue, checkCampSent);
        setState(
          (prevState) => ({
            ...prevState,
            isInDatabase: responseValue,
            // isSent: checkCampSent,
          }),
          () => {
            // console.log("Updated state:", state.isInDatabase);
          }
        );
      } catch (error) {
        console.error("Error checking email ID in database:", error);
      }
    };

    checkEmailId();
  }, [state.emailId, props]);

  // NOTE
  // When you connect your component with Redux using connect(mapStateToProps, mapDispatchToProps), these action creators become available as props to your component. In your component, you can call these action creators using props.deleteEmail, props.downloadEmailPdf, etc., to dispatch actions to the Redux store.

  const toggleSendModal = () => {
    setState((prevState) => ({
      ...prevState,
      openSendModal: !prevState.openSendModal,
    }));
  };
  // SECTION Function To Handle Delete
  const handleDelete = async () => {
    const { alertRef, params, component } = props;
    let response = null;
    try {
      setState({
        ...state,
        loading: true,
      });
      if (component === "email") {
        response = await props.deleteEmail(params); // Dispatch the deleteEmail action
      }
      if (component === "template") {
        response = await props.deleteTemplate(params);
      }
      setState({
        ...state,
        isDeleted: true,
        loading: false,
        deleteConfirmation: true,
      });
      alertRef.current.showToaster(response.payload);
    } catch (e) {
      console.error(e);
    }
  };

  // SECTION Handle Edit Modal
  const handleEditModal = () => {
    const { component } = props;
    // console.log(component);
    setState((prevState) => ({
      ...prevState,
      [component === "template"
        ? "openTemplateEditModal"
        : "openEmailEditModal"]:
        !prevState[
          component === "template"
            ? "openTemplateEditModal"
            : "openEmailEditModal"
        ],
    }));
    // console.log(state);
  };

  // SECTION Template Loading Modal in Asset Archive => Editor
  const handleTemplateLoadModal = () => {
    let editor = props.returnReference();
    setState((prevState) => ({
      ...prevState,
      templateLoadModal: !prevState.templateLoadModal,
      editor: editor,
    }));
    // console.log("handleTemplateLoadModal", state);
  };

  // NOTE Import Code -  Should be attached with legacy editor
  const handleImportCodeModal = () => {
    let editor = props.returnReference();
    // console.log(editor);
    setState((prevState) => ({
      ...prevState,
      importCodeModal: !prevState.importCodeModal,
      editor,
    }));
  };

  const handleDataSetforMergeTags = () => {
    let editor = props.returnReference();
    // console.log(editor);
    setState((prevState) => ({
      ...prevState,
      loadMergeTagsDatasetModal: !prevState.loadMergeTagsDatasetModal,
      editor,
    }));
    // console.log("handleDataSetforMergeTags", state);
  };

  // console.log(edit, assetSlice);
  if (state.isDeleted && component === "email") {
    return <Navigate to="/assetlibrary/emails" replace />;
  }
  if (state.isDeleted && component === "template") {
    return <Navigate to="/assetlibrary/templates" replace />;
  }

  return (
    // ANCHOR Bootstrap Old Styling:  navbar navbar-expand-lg navbar-light bg-light nav-padding
    <nav>
      {/* NOTE This Row is for Navbar in Editor Page */}
      <Grid
        fluid
        style={{
          padding: "0.5em 4em 0.5em 0.5em",
          borderBottom: "1px solid #c4c4c4",
        }}
      >
        <Row>
          {/* NOTE For Equal Distribution on various Devices make xxl / xl = 12 as it works on 24 total distribution */}
          <Col xl={4}>
            {state.confirmation && (
              <ConfirmationModal
                confirmation={state.confirmation}
                hide={() =>
                  setState({
                    ...state,
                    confirmation: false,
                  })
                }
                component={component}
              />
            )}
            <Link
              to="/dashboard"
              onClick={(e) => {
                if (!edit) {
                  setState({
                    ...state,
                    confirmation: true,
                  });
                  e.preventDefault();
                }
              }}
            >
              <picture style={{ cursor: "pointer" }}>
                <source
                  srcSet={logoWhite}
                  alt="Home"
                  media={`(prefers-color-scheme: ${"white"})`}
                  className="logo"
                  style={{ width: 200 }}
                />
                <img src={logo} alt="Home" style={{ width: 200 }} />
              </picture>
            </Link>
               
          </Col>
          <Col xl={4} style={{marginTop:"0.5%"}}>{/* Conditionally render the 'Active' button */}
               {props.component === "email" && state.isInDatabase && (
                <Badge
                  content="ACTIVE"
                  color="red"
                  title="Active with Campaign"
                />
              )}</Col>
          <Col xl={16}>
            <Stack
              direction="row"
              spacing={32}
              alignItems="center"
              justifyContent="right"
            >
              <IconButton
                as={Link}
                to={`${
                  component === "template"
                    ? !edit
                      ? "/createnewproject/create/template/info"
                      : "/assetlibrary/templates"
                    : !edit
                    ? "/createnewproject/create/email/info"
                    : "/assetlibrary/emails"
                }`}
                style={{ backgroundColor: "#415670", color: "#fff" }}
                title="Back"
                // icon={<ArrowLeftIcon />}
                placement="left"
              >
                <ArrowLeftIcon style={{ backgroundColor: "#415670" }} />
                BACK
              </IconButton>
              <IconButton
                onClick={handleFullscreenToggle}
                type="button"
                icon={isFullscreen ? <BsFullscreenExit /> : <BsFullscreen />}
                title={isFullscreen ? "Exit Screen" : "Full Screen"}
                circle
              />

              {edit && (
                <IconButton
                  icon={<EditIcon />}
                  onClick={handleEditModal}
                  title="Edit Properties"
                  circle
                  // disabled={state.isSent === 1 && props.component === "email"}
                />
              )}
              {/* NOTE Conditional Logic Sending Test Email Button in Navbar */}
              {props.component === "email" && props.edit && (
                <Whisper
                  placement="bottom"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={tooltip}
                >
                  <IconButton
                    icon={<SendIcon />}
                    circle
                    size="lg"
                    type="button"
                    className="btn btn-defalut"
                    onClick={toggleSendModal}
                  />
                </Whisper>
              )}

           
              {/* NOTE Delete and Clear Canvas Functionality */}
              {state.isInDatabase !== null &&
                (props.component === "email" ? (
                  <Dropdown
                    title="Delete"
                    appearance="default"
                    placement="bottomEnd"
                    disabled={state.isInDatabase} // Disable the dropdown if isInDatabase is true
                  >
                    <Dropdown.Item
                      onClick={() =>
                        setState({
                          ...state,
                          deleteConfirmation: true,
                        })
                      }
                    >
                      Delete Email
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleReset()}>
                      Clear Canvas
                    </Dropdown.Item>
                  </Dropdown>
                ) : (
                  props.component === "template" && (
                    <Dropdown
                      title="Delete"
                      appearance="default"
                      placement="bottomEnd"
                    >
                      <Dropdown.Item
                        onClick={() =>
                          setState({
                            ...state,
                            deleteConfirmation: true,
                          })
                        }
                      >
                        Delete Template
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleReset()}>
                        Clear Canvas
                      </Dropdown.Item>
                    </Dropdown>
                  )
                ))}

              {/* NOTE Not Working TemplateLoading */}
              <IconButton
                type="button"
                icon={<FolderFillIcon />}
                onClick={handleTemplateLoadModal}
                circle
                title="Load Template"
                // disabled={state.isSent === 1 && props.component === "email"}
              />
              <IconButton
                className="btn btn-default"
                type="button"
                onClick={handleDataSetforMergeTags}
                style={{ padding: 8 }}
                title="Load DataSet"
                // disabled={state.isSent === 1 && props.component === "email"}
              >
                <img
                  src="https://charlie-dave.s3.amazonaws.com/database.png"
                  alt="Database"
                  style={{
                    width: "24px",
                    height: "24px",
                    
                  }}
                />
              </IconButton>

              <Dropdown
                title="Export"
                style={{ backgroundColor: "#415670", color: "#000" }}
                placement="bottomEnd"
              >
                <Dropdown.Item onClick={() => handleSave("html")}>
                  Export HTML
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSave("json")}>
                  Export JSON
                </Dropdown.Item>
              </Dropdown>
              <Button
                style={{
                  backgroundColor:"#415670",color:"#fff"

                    
                }}
                onClick={() => (!edit ? saveEditor() : editEditor())}
                loading={autoSaveLoading}
                // disabled={state.isSent === 1 && props.component === "email"}
              >
                Save 
              </Button>
            </Stack>
          </Col>
        </Row>
      </Grid>

      {state.templateLoadModal && (
        <TemplateLoad
          editor={props.editor}
          templateLoadModal={state.templateLoadModal}
          handleTemplateLoadModal={handleTemplateLoadModal}
          emailEditorRef={props.emailEditorRef}
        />
      )}

      {/* NOTE Template Edit Property Modal */}
      {state.openTemplateEditModal && (
        <TemplateEditProperty
          open={state.openTemplateEditModal}
          component={component}
          handleEditModal={handleEditModal}
          params={params}
          alertRef={alertRef}
        />
      )}
      {/* NOTE Email Edit Property inside editor Page */}
      {state.openEmailEditModal && (
        <EmailEditProperty
          open={state.openEmailEditModal}
          component={component}
          handleEditModal={handleEditModal}
          params={params}
          alertRef={alertRef}
        />
      )}

      {/* NOTE Conditional Check for Sending Test Email*/}
      {state.openSendModal && (
        <SendTestEmail
          open={state.openSendModal}
          params={props.params}
          alertRef={props.alertRef}
          handleSendModal={toggleSendModal}
        />
      )}

      {/* NOTE Check for Deleting Email/Template - Trigger Confirmation Modal Before Deleting  */}
      {state.deleteConfirmation && props.edit && (
        <DeleteConfirmationModal
          confirmation={state.deleteConfirmation}
          hide={() =>
            setState((prevState) => ({
              ...prevState,
              deleteConfirmation: false,
            }))
          }
          loading={state.loading}
          handleDelete={handleDelete}
          component={component === "email" ? "Email" : "Template"}
        />
      )}

      {/* NOTE - Load Data For Merge Tags*/}
      {state.loadMergeTagsDatasetModal && (
        <LoadDataSetModal
          editor={props.editor}
          dataLoadModal={state.loadMergeTagsDatasetModal}
          handleDataSetforMergeTags={handleDataSetforMergeTags}
          emailEditorRef={props.emailEditorRef}
        />
      )}
    </nav>
  );
}

const mapStateToProps = (state) => ({
  email: state.AssetSlice.emailById.email,
  loadingSlice: state.EditorSlice.loading,
  // emailId: state.AssetSlice.emailById.email.id,
});

const mapDispatchToProps = (dispatch) => ({
  checkEmailIdInDatabase: (emailId) =>
    dispatch(checkEmailIdInDatabase(emailId)),
  // deleteEmail: (email_id) => dispatch(deleteEmail(email_id)),
  // emailSend: ({ id, emailAddress }) =>
  //   dispatch(emailSend({ id, emailAddress })),
  downloadEmailPdf: (email_id) => dispatch(downloadEmailPdf(email_id)),
  downloadTemplatePdf: (template_id) =>
    dispatch(downloadTemplatePdf(template_id)),
  downloadTemplateImage: (template_id) =>
    dispatch(downloadTemplateImage(template_id)),
  downloadEmailImage: (email_id) => dispatch(downloadEmailImage(email_id)),
  deleteEmail: (email_id) => dispatch(deleteEmail(email_id)),
  deleteTemplate: (template_id) => dispatch(deleteTemplate(template_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestSend);
