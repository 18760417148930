import React, { Component } from "react";
import {
  Col,
  Row,
  InputGroup,
  Input,
  TagGroup,
  Tag,
  Popover,
  Whisper,
  Button,
} from "rsuite";
import TemplateFolder from "../../Folder/TemplateFolder";
import { editorTemplates, setEditor } from "../../Features/Editor/EditorThunk";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/view-asset-blocks.css";
import PaginationComponent from "../../Utils/PaginationComponent";
import Loading from "../../Loading/Loading";
import { reset } from "../../Features/Template/TemplateSlice";
import SearchIcon from "@rsuite/icons/Search";
import convertToUserTimeZone from "../../../hooks/convertToUserTimeZone";

const ImageStyle = (width, height) => {
  return {
    width,
    height,
  };
};

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 4,
      loading: false,
      total: null,
      tags: [],
      tagText: "",
    };
  }
  componentDidMount() {
    let { page } = this.state;
    this.fetchTemplates({ type: "recent", page, value: null });
    setEditor();
  }

  fetchTemplates = async ({ type, page, value }) => {
    const { editorTemplates } = this.props;
    this.setState({
      loading: true,
    });
    let { limit } = this.state;

    await editorTemplates({ type, page, limit, value });
    let { template } = this.props.editorSlice;
    this.setState({
      loading: false,
      page: Number(template.page),
      total: template.count,
    });
  };

  selectFolder = async (e) => {
    await this.fetchTemplates({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
    });
  };

  setNewPage = async (page) => {
    await this.fetchTemplates({
      type: "recent",
      page,
      value: null,
    });
  };


  
  search = async (e) => {

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    let page = 1
    this.searchTimeout = setTimeout(async () => {
      await this.fetchTemplates({
        type: "search",
        page,
        value: e,
      });
    }, 800);

    this.setNewPage = async (page) => {
      await this.fetchTemplates({
        type: "search",
        page,
        value: e,
      });
    };
  };



  sort = async (type, index) => {
    let { tagText, tags } = this.state;
    let newCategories = [],
      newCategory = "";
    if (type === "delete") {
      newCategories = tags.filter((item, i) => i !== index);
      this.setState({
        tags: newCategories,
      });
      await this.fetchTemplates({
        type: "sort",
        page: this.state.page,
        value: newCategories,
      });
    } else {
      if (tagText.trim().replace(/\s/g, "").length > 0)
        if (!tags.includes(tagText.trim().toLowerCase())) {
          newCategory = tagText.trim().toLowerCase()
            ? [...tags, tagText.trim().toLowerCase()]
            : tags;

          this.setState({
            tagText: "",
            tags: newCategory,
          });
          await this.fetchTemplates({
            type: "sort",
            page: this.state.page,
            value: newCategory,
          });
        }
    }
  };

  render() {
    let { editorSlice, grid, reset } = this.props;
    let { template } = editorSlice;
    let { limit, page, loading, total, tags, tagText } = this.state;

    return (
      <div>
        <Row className="new-campaign-row">
          <Col md={5} className="side-folder">
            <TemplateFolder
              selectFolder={this.selectFolder}
              from="assets"
              fetchTemplates={this.fetchTemplates}
            />
          </Col>
          <Col
            md={19}
            style={{ paddingLeft: "25px" }}
            className={
              grid
                ? "grid-view-filter-template-image block-bg-font-color"
                : "list-view-filter-template-image block-bg-font-color"
            }
          >
            <Row style={{ marginBottom: 20 }}>
              <Col md={10}>
                <TagGroup style={{ margin: 0 }}>
                  <Input
                    placeholder="Add categories and press enter"
                    name="categories"
                    value={tagText}
                    onChange={(e) =>
                      this.setState({
                        tagText: e,
                      })
                    }
                    onBlur={() => this.sort("add", undefined)}
                    onPressEnter={() => this.sort("add", undefined)}
                  />
                  {tags.map((item, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={(tag) => this.sort("delete", index)}
                    >
                      {item}
                    </Tag>
                  ))}
                </TagGroup>
              </Col>
              <Col md={4}></Col>
              <Col md={10}>
                {/* <InputGroup placeholder="Search via name...">
                  <Input
                    placeholder="Search"
                    onChange={(e) => this.search(e)}
                  />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup> */}
                <InputGroup placeholder="Search via name">
                  <Input
                    placeholder="Search via name"
                    onChange={(e) => this.search(e)}
                  />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </Row>
            {!loading ? (
              <>
                {template.template.length > 0 ? (
                  <>
                    {template.template.map((val) => (
                      <div
                        className={`${grid ? `grid` : "list"} pointer-for`}
                        key={val.template_id}
                      >
                        <Link
                          to={`/assetlibrary/edit/template/${val.template_id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className={`${
                              grid
                                ? "rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border"
                                : ""
                            }`}
                            style={{
                              display: `${grid ? "" : "flex"}`,
                            }}
                          >
                            <div
                              className={`${
                                grid ? "DataResize " : "email_images_list"
                              }`}
                              style={{
                                display: `${grid ? "" : "inline-block"}`,
                              }}
                            >
                              <img
                                className={`${grid ? "email_images" : ""}`}
                                src={`${
                                  val.template_img_path
                                }?${Math.random()}`}
                                data-id={val.template_id}
                                alt={val.template_name}
                                title={val.template_name}
                                style={ImageStyle("100%", "auto")}
                              />
                            </div>
                            {grid && (
                              <>
                                <div
                                  role="rowheader"
                                  className={` ${
                                    grid
                                      ? "rs-panel-heading asset-name"
                                      : "asset-name"
                                  }`}
                                  style={{ padding: "10px 10px" }}
                                  tabIndex="-1"
                                >
                                  <b>
                                    {val.template_name.length < 15
                                      ? val.template_name
                                      : `${val.template_name.substring(
                                          0,
                                          15
                                        )}...`}
                                  </b>
                                  <Whisper
                                    trigger="hover"
                                    placement="topEnd"
                                    speaker={
                                      <Popover
                                        title="Info"
                                        visible
                                        style={{ color: "#000" }}
                                      >
                                        <b> Created By</b> :{" "}
                                        {val.template_created_by}
                                        <br />
                                        <b>Modified By</b> :
                                        {val.template_modified_by}
                                        <br />
                                        <b>Created At </b>:{" "}
                                        {convertToUserTimeZone(
                                          val.template_created_at
                                        )}
                                        <br />
                                        <b>Modified At </b>:
                                        {convertToUserTimeZone(
                                          val.template_modified_at
                                        )}
                                        <br />
                                        <b>Category </b> :{" "}
                                        {val.template_category}
                                      </Popover>
                                    }
                                  >
                                    <Button
                                      appearance="subtle"
                                      style={{
                                        float: "right",
                                        padding: 0,
                                        color: "#000",
                                      }}
                                    >
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  </Whisper>
                                </div>
                                <div
                                  className="rs-panel-body"
                                  style={{ padding: 10 }}
                                ></div>
                              </>
                            )}

                            {!grid && (
                              <span
                                style={{ paddingLeft: 5, padding: 16 }}
                                className="asset-name"
                              >
                                <b>Template : </b>
                                {val.template_name.length < 15
                                  ? val.template_name
                                  : `${val.template_name.substring(0, 15)}....`}
                                <b> | Category : </b> {val.template_category}
                                <b> | Created at : </b>
                                {convertToUserTimeZone(val.template_created_at)}
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7}></Col>
                    <Col md={7} style={{ textAlign: "center" }}>
                      <h5>No Template Found !</h5> <br />
                      <Link
                        onClick={() => reset()}
                        className="link-color-font"
                        to="/createnewproject/create/template/info"
                        style={{ fontSize: 25, cursor: "pointer" }}
                      >
                        Click to Create
                      </Link>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                )}
              </>
            ) : (
              <Loading />
            )}
            {tagText === "" && tags.length === 0 && (
              <div className="fixed-pagination">
                <PaginationComponent
                  limit={limit}
                  page={page}
                  setNewPage={this.setNewPage}
                  total={total}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  editorSlice: state.EditorSlice,
  grid: state.AssetSlice.isGrid,
});

const mapDispatchToProps = (dispatch) => ({
  editorTemplates: (type, page, limit, value) =>
    dispatch(editorTemplates(type, page, limit, value)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
