import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMontlyReport } from "../Features/Reports/ReportThunk";
import { Col, Row, Dropdown, Placeholder, Loader } from "rsuite";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

import { userStatus } from "../Features/Login/LoginThunk";

class MonthlyReport extends Component {
  constructor() {
    super();
    let month = (new Date().getMonth() + 1).toString();
    this.state = {
      chartDataOpen: {},
      totalOpens: "",
      unique_opens: "",
      totalUnsubscribes: "",
      totalDelivered: "",
      unique_clicks: "",
      clicks: "",
      bounce: "",

      selectedYear: new Date().getFullYear(),
      aggregated_by: "day",
      selectedMonth: `${month.length === 1 ? "0" + month : month}`,
      loading: true,
    };
  }

  async componentDidMount() {
    // console.log(new Date().getMonth() + 1);
    await this.getChartData();
  }

  getChartData = async () => {
    const { selectedMonth, selectedYear, aggregated_by } = this.state;
    const startDate = `${selectedYear}-${selectedMonth}-01`;

    function isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    let daysInMonth = 30;
    if (selectedMonth === "02") {
      daysInMonth = isLeapYear(selectedYear) ? 29 : 28;
    } else if (
      selectedMonth === "01" ||
      selectedMonth === "03" ||
      selectedMonth === "05" ||
      selectedMonth === "07" ||
      selectedMonth === "08" ||
      selectedMonth === "10" ||
      selectedMonth === "12"
    ) {
      daysInMonth = 31;
    }

    const endDate = `${selectedYear}-${selectedMonth}-${daysInMonth}`;

    let { fetchMontlyReport } = this.props;
    try {
      let responseData = await fetchMontlyReport({
        startDate,
        endDate,
        aggregated_by,
      });

      if (responseData.payload.status === 1) {
        const stats = responseData.payload.data.responses[0];
        let labels = [];
        let opens = [];
        let click = [];
        let bounce = [];

        stats.forEach((element) => {
          const shortDate = new Date(element.date).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          });
          labels.push(shortDate);
          opens.push(
            element.stats.reduce(
              (accumulator, current) => accumulator + current.metrics.opens,
              0
            )
          );

          click.push(
            element.stats.reduce(
              (accumulator, current) => accumulator + current.metrics.clicks,
              0
            )
          );
          bounce.push(
            element.stats.reduce(
              (accumulator, current) => accumulator + current.metrics.bounces,
              0
            )
          );
        });

        let sumDelivered = 0,
          unique_opens = 0,
          clicks = 0,
          unique_clicks = 0,
          totalOpens = 0,
          totalUnsubscribes = 0;
        bounce = 0;
        for (let num of stats) {
          for (let i of num.stats) {
            sumDelivered += i.metrics.delivered;
            totalOpens += i.metrics.opens;
            unique_opens += i.metrics.unique_opens;
            totalUnsubscribes += i.metrics.unsubscribes;
            clicks += i.metrics.clicks;
            unique_clicks += i.metrics.unique_clicks;
            bounce += i.metrics.bounces;
          }
        }

        this.setState({
          chartDataOpen: {
            labels: labels,
            datasets: [
              {
                label: "Opens",
                data: opens,
                backgroundColor: ["#00ACFF"],
              },

              {
                label: "Clicks",
                data: click,
                backgroundColor: ["#7D3C98"],
              },
            ],
          },
          loading: false,
          unique_opens,
          totalOpens,
          unique_clicks,
          clicks,
          totalUnsubscribes,
          totalDelivered: sumDelivered,
          bounce,
        });
      } else {
        console.log("Not Working");
      }
    } catch (error) {}
  };

  handleMonthChange = (eventKey, event) => {
    this.setState({ selectedMonth: eventKey, loading: true }, () =>
      this.getChartData()
    );
  };

  handleYearChange = (eventKey, event) => {
    this.setState({ selectedYear: eventKey, loading: true }, this.getChartData);
  };

  getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[parseInt(monthNumber, 10) - 1] || "";
  };

  render() {
    let { loading, chartDataOpen } = this.state;
    let { theme } = this.props;
    return (
      <>
        {!loading && chartDataOpen !== "" ? (
          <>
            <Row style={{ paddingTop: 14 }}>
              <Col md={14}>
                <b className="RecentE-number">Monthly Performance</b>
              </Col>
              <Col md={6} style={{ textAlign: "right" }}>
                <Dropdown
                  title={`${this.getMonthName(this.state.selectedMonth)}`}
                >
                  <Dropdown.Item
                    eventKey="01"
                    onSelect={this.handleMonthChange}
                  >
                    January
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="02"
                    onSelect={this.handleMonthChange}
                  >
                    February
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="03"
                    onSelect={this.handleMonthChange}
                  >
                    March
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="04"
                    onSelect={this.handleMonthChange}
                  >
                    April
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="05"
                    onSelect={this.handleMonthChange}
                  >
                    May
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="06"
                    onSelect={this.handleMonthChange}
                  >
                    June
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="07"
                    onSelect={this.handleMonthChange}
                  >
                    July
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="08"
                    onSelect={this.handleMonthChange}
                  >
                    August
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="09"
                    onSelect={this.handleMonthChange}
                  >
                    September
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="10"
                    onSelect={this.handleMonthChange}
                  >
                    October
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="11"
                    onSelect={this.handleMonthChange}
                  >
                    November
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="12"
                    onSelect={this.handleMonthChange}
                  >
                    December
                  </Dropdown.Item>
                </Dropdown>
              </Col>
              <Col md={4}>
                <Dropdown title={`${this.state.selectedYear}`}>
                  <Dropdown.Item
                    eventKey="2024"
                    onSelect={this.handleYearChange}
                  >
                    2024
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey="2023"
                    onSelect={this.handleYearChange}
                  >
                    2023
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2022"
                    onSelect={this.handleYearChange}
                  >
                    2022
                  </Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>{" "}
            <Row>
              <Col md={24}>
                <Bar
                  data={chartDataOpen}
                  options={{
                    title: {
                      display: true,
                      text: "Open Rate",
                      fontSize: 20,
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    color: theme === "dark" ? "#fff" : "#000",
                    scales: {
                      y: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                      x: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                    },
                  }}
                />
              </Col>
            </Row>
            {/* <Row style={{ marginTop: 20 }} className="showDataMon">
              <Col className="displayInline">
                <p>
                  <span>Opens:</span> {this.state.totalOpens}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Bounce:</span> {this.state.bounce}
                </p>
              </Col>
              <Col className="displayInline">
                <p>
                  <span>Delivered:</span> {this.state.totalDelivered}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Total Clicks:</span> {this.state.clicks}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Unique Clicks:</span> {this.state.unique_clicks}
                </p>
              </Col>
            </Row> */}
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={10} />
            <Loader center content="loading" />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo, theme } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
    theme,
  };
}
// const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, { fetchMontlyReport, userStatus })(
  MonthlyReport
);
