import { createSlice } from "@reduxjs/toolkit";
import { editorTemplates, editorTemplateById } from "./EditorThunk";
const initialState = {
  editor: {},
  loading: false,
  template: {
    template: [],
    count: 0,
    pages: 0,
    page: 0
  },
  error: null,
  success: false,
  templateById: {},
  hideLoading: false
};

const EditorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    initializeEditor(state, data) {
      // if (data !== undefined)
      //   if (
      //     Number(data.payload[0].params.id) ===
      //     state.templateById.template.template_id
      //   )
      //     return;
      //   else {
      //     state.templateById = {};
      //   }
      // templateById:{}
      return initialState;
    },
    hideLoading(state, data) {
      state.hideLoading = data.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editorTemplates.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(editorTemplates.fulfilled, (state, action) => {
        state.template = action.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      })

      .addCase(
        editorTemplates.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.template = [];
        }
      )

      .addCase(editorTemplateById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editorTemplateById.fulfilled, (state, action) => {
        state.templateById = action.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(
        editorTemplateById.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.templateById = {};
        }
      );

    // and provide a default case if no other handlers matched
    //   .addDefaultCase((state, action) => {
    //     state.loading = false;
    //     state.template = [];
    //     state.success = false;
    //     state.error = null;
    //   });
  }
});
const { actions, reducer } = EditorSlice;
export const { initializeEditor, hideLoading } = actions;

export default reducer;
