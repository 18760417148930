import React, { Component } from "react";
import { Row, Col, ButtonToolbar, Button, Modal } from "rsuite";
import { connect } from "react-redux";
import $ from "jquery";

import {
  campaignById,
  scheduleCampaign,
  campaignSend,
} from "../../../Features/Campaign/CampaignThunk";
import LeftSideDetails from "./LeftSideDetails";

class SendCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      datetime: "",
      hr: -1,
      mi: -1,
      checkDate: "",
      dm: -1,
      loading: false,
      loadingSubmit: false,
    };
  }

  sendCampaign = async () => {
    let {
      alertRef,
      dynamicRoute: campaignId,
      count,
      campaignSend,
      campaignById,
      suppressionList
    } = this.props;
    if (count !== 0 && suppressionList?.data_list_count !== 0) {
      this.setState({ loadingSubmit: true });
      const response = await campaignSend({ campaignId });
      await campaignById(campaignId);
      this.setState({ loadingSubmit: false });
      alertRef.current.showToaster(response.payload);
    } else {
      alertRef.current.showToaster({
        status: 0,
        duration: 2,
        message: "Suppression count is zero",
      });
    }
  };


  setDateTime = async () => {
    let {
      dynamicRoute: campaignId,
      campaignById,
      scheduleCampaign,
      alertRef,
      suppressionList,
      count
    } = this.props;
    let date = document.getElementById("getDate").value;
    let hour = document.getElementById("getHour").value;
    let min = document.getElementById("getMin").value;

    if (date !== "" && hour !== "" && min !== "" && count !== 0 && suppressionList?.data_list_count !== 0) {
      this.setState({ loading: true, show: false });
      // // Combine date, hour, and minute into a single string
      // const userTimez = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log(userTimez)
      // // scheduledAt should be of GMT
      // let scheduledAt = date + " " + hour + ":" + min;
      // const dateString = `${date} ${hour}:${min}`; //time input provided by user based on his timezone
      const dateString = `${date} ${hour}:${min}`;
      console.log("dateString", dateString);
      // console.log("scheduledAt-GMT", scheduledAt); //2024-03-27 00:04
      // Combine date, hour, and minute into a single string
      const userTimezone1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(userTimezone1);

      // Convert dateString to GMT
      const userDate = new Date(dateString);
      const utcDate = new Date(userDate.toISOString().slice(0, 19) + "Z");

      // Format the GMT date as a string
      const scheduledAt = utcDate.toISOString().slice(0, 16).replace("T", " ");
      console.log("scheduledAt-GMT", scheduledAt);

      let response = await scheduleCampaign({ scheduledAt, campaignId });
      await campaignById(campaignId);
      this.setState({
        loading: false,
        checkDate: "",
        hr: -1,
        mi: -1,
        dm: -1,
      });
      alertRef.current.showToaster(response.payload);

    } else if (suppressionList?.data_list_count === 0) {
      alertRef.current.showToaster({
        status: 0,
        duration:2,
        message: "Suppression count is zero",
      });
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: `Please select ${date === "" ? "date" : ""} ${date === "" && (hour === "" || min === "") ? "&" : ""
          }   ${hour === "" || min === "" ? "time" : ""}`,
      });
    }
  };

  removeScheduled = async () => {
    let {
      dynamicRoute: campaignId,
      campaignById,
      scheduleCampaign,
      campaignByID,
      alertRef,
    } = this.props;
    console.log(
      "Campagin Actual Time from Backend GMT",
      campaignByID.actual_time
    );
    if (campaignByID.actual_time !== "") {
      let response = await scheduleCampaign({
        scheduledAt: "remove",
        campaignId,
      });
      await campaignById(campaignId);
      this.setState({
        show: false,
        loading: false,
      });
      alertRef.current.showToaster(response.payload);
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: `This Campaign is already unscheduled`,
      });
    }
  };

  datePicker = (e) => {
    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth() + 1;
    var d = today.getDate();
    let hour = today.getHours();
    let mi = today.getMinutes() + 5;
    var fecha = y + "-" + m + "-" + d;
    if (
      new Date(fecha).toDateString() === new Date(e.target.value).toDateString()
    ) {
      this.setState({
        checkDate: new Date(fecha).toDateString(),
        hr: hour,
        mi,
        dm: mi,
      });
      this.checkHour(hour, mi);
    } else {
      this.setState({
        checkDate: -1,
        hr: "",
        mi: -1,
        dm: -1,
      });
    }
  };

  checkHour = (hour, mi) => {
    if (
      document.getElementById("getHour").value !== "" ||
      document.getElementById("getMin").value !== ""
    ) {
      if (document.getElementById("getHour").value < hour) {
        document.getElementById("getHour").value = "";
      }
      if (document.getElementById("getMin").value < mi + 5) {
        document.getElementById("getMin").value = "";
      }
    }
  };
  getHour = (e) => {
    if (this.state.hr < e.target.value) {
      this.setState({ mi: -1 });
    } else {
      this.setState({
        mi: this.state.dm,
      });
      document.getElementById("getMin").value = "";
    }
  };

  schedule = () => {
    this.setState(
      {
        show: true,
      },
      () => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10) month = "0" + month.toString();
        if (day < 10) day = "0" + day.toString();
        var maxDate = year + "-" + month + "-" + day;
        $("#getDate").attr("min", maxDate);
      }
    );
  };

  render() {
    let {
      campaignByID: campaignById,
      emailByID: emailById,
      dataListById: datalistById,
      // suppression,
      // suppressionList,
    } = this.props;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let emailText = emailById === null ? "email" : "";
    let listText = datalistById === null ? "list" : "";
    let text =
      emailById === null || datalistById === null
        ? `Please select ${emailText}${emailById === null && datalistById === null ? " & " : ""
        }${listText} before Sending`
        : "Send";
    return (
      <div>
        <Row>
          <LeftSideDetails
            text={text}
            display={true}
            suppression={campaignById.supression}
            timeStamp={campaignById.timeStamp}
            isSent={campaignById.isSent}
            throttleProgress={campaignById.throttleProgress}
            schedule={() => this.schedule()}
            send={this.sendCampaign}
            loading={this.state.loading}
            loadingSubmit={this.state.loadingSubmit}
          />

          <Col md={2}></Col>

          <Col md={11}>
            {this.props.emailById !== null && (
              <div style={{ height: 500, overflowY: "auto" }}>
                <img
                  src={`${campaignById.selected_email_img}?${Math.random()}`}
                  alt={campaignById.selected_email_img}
                  style={{ width: "80%", marginLeft:"11%" }}
                />
              </div>
            )}
          </Col>
          <Col md={2}></Col>
        </Row>

        <Modal
          size="xs"
          className="ScheduleModel"
          open={this.state.show}
          onClose={() =>
            this.setState({ show: false, hr: -1, mi: -1, checkDate: "" })
          }
        >
          <Modal.Header>
            <Modal.Title>Select Date & Time ({userTimeZone})</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <input
              type="date"
              id="getDate"
              onChange={(e) => this.datePicker(e)}
              name="Date"
            />
            &nbsp;&nbsp;
            <select
              id="getHour"
              disabled={this.state.checkDate === ""}
              onChange={(e) => this.getHour(e)}
            >
              <option value="" disabled selected>
                hh
              </option>
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
            </select>
            &nbsp;:&nbsp;
            <select id="getMin" disabled={this.state.hr === -1}>
              <option value="" disabled selected>
                mm
              </option>
              <option>00</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
              <option>31</option>
              <option>32</option>
              <option>33</option>
              <option>34</option>
              <option>35</option>
              <option>36</option>
              <option>37</option>
              <option>38</option>
              <option>39</option>
              <option>40</option>
              <option>41</option>
              <option>42</option>
              <option>43</option>
              <option>44</option>
              <option>45</option>
              <option>46</option>
              <option>47</option>
              <option>48</option>
              <option>49</option>
              <option>50</option>
              <option>51</option>
              <option>52</option>
              <option>53</option>
              <option>54</option>
              <option>55</option>
              <option>56</option>
              <option>57</option>
              <option>58</option>
              <option>59</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar style={{ textAlign: "center" }}>
              {campaignById.actual_time !== "" && (
                <Button
                  onClick={this.removeScheduled}
                  title="Remove"
                  appearance="primary"
                >
                  Remove
                </Button>
              )}
              <Button
                onClick={this.setDateTime}
                appearance="primary"
                title="Confirm"
                loading={this.state.loading}
              >
                Confirm
              </Button>
              <Button
                title="Cancel"
                appearance="primary"
                onClick={() =>
                  this.setState({
                    show: false,
                    hr: -1,
                    mi: -1,
                    checkDate: "",
                  })
                }
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    campaignByID,
    emailByID,
    dataListById,
    count,
    loadingById,
    suppressionList,
  } = state.CampaignSlice;
  return {
    campaignByID,
    emailByID,
    dataListById,
    count,
    suppressionList,
    loadingById,
  };
}
export default connect(mapStateToProps, {
  campaignById,
  scheduleCampaign,
  campaignSend,
})(SendCampaign);
