import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userInfo = createAsyncThunk("usermanagement/getUser", async (data) => {
  try {
    const response = await axios.get("/accountmanagement/getUser");
    return response.data;
  } catch (e) {
    return { status: 0, message: "Account Information Not Updated" };
  }
});
export const userDelete = createAsyncThunk("usermanagement/getUser", async (data) => {
  try {
    const response = await axios.post("/accountmanagement/deleteUser", {
      key_id: data
    });
    return response.data;
  } catch (e) {
    return { status: 0, message: "User not deleted." };
  }
});
export const getUserById = createAsyncThunk("usermanagement/getUserById", async (data) => {
  try {
    const response = await axios.post("/accountmanagement/getUserById", {
      key_id: data
    });
    return response.data;
  } catch (e) {
    return { status: 0, message: "User not fetched." };
  }
});
export const updateUser = createAsyncThunk("usermanagement/updateUser", async (data) => {
  try {
    const response = await axios.post("/accountmanagement/updateUser", {
      data
    });
    return response.data;
  } catch (e) {
    return { status: 0, message: "User not fetched." };
  }
});
export const editemailusercheck = createAsyncThunk(
  "usermanagement/editemailusercheck",
  async (data) => {
    try {
      const response = await axios.post("/accountmanagement/editemailusercheck", {
        data
      });
      return response.data;
    } catch (e) {
      return { status: 0, message: "User not fetched." };
    }
  }
);
export const createUser = createAsyncThunk("usermanagement/createUser", async (data) => {
  console.log(data);
  let [data1, access] = data;

  try {
    const response = await axios.post("/accountmanagement/createUser", {
      data1,
      access
    });
    return response.data;
  } catch (e) {
    return { status: 0, message: "User not create." };
  }
});
