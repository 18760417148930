import { createSlice } from "@reduxjs/toolkit";
import { isTemplateExist, getTemplateData, saveTemplate } from "./TemplateThunk";
import { setCreateNewProjectData } from "../CreateNewProjectService";
import { template } from "../const";

let eTD = getTemplateData() || template;
let initialState = {
  templateName: eTD.templateName,
  description: eTD.description,
  categories: eTD.categories,
  location: eTD.location,
  loading: false,
  error: null,
  message: ""
};

const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    reset() {
      setCreateNewProjectData();

      const eTD = getTemplateData();
      return {
        templateName: eTD.templateName,
        description: eTD.description,
        categories: eTD.categories,
        location: eTD.location,
        loading: false,
        error: null,
        message: ""
      };
    },
    feedTemplate(state, data) {
      let eTD = getTemplateData();
      eTD.templateName = data.payload;
      localStorage.setItem("eTD", JSON.stringify(eTD));
      state.templateName = data.payload;
    },
    addCategory(state, data) {
      let eTD = getTemplateData();
      eTD["categories"] = data.payload;
      localStorage.setItem("eTD", JSON.stringify(eTD));
      state.categories = data.payload;
    },
    selectLocation(state, data) {
      let eTD = getTemplateData();
      eTD["location"] = data.payload;
      localStorage.setItem("eTD", JSON.stringify(eTD));
      state.location = data.payload;
    },
    feedDescription(state, data) {
      let eTD = getTemplateData();
      eTD.description = data.payload;
      localStorage.setItem("eTD", JSON.stringify(eTD));
      state.description = data.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(isTemplateExist.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isTemplateExist.fulfilled, (state, action) => {
        state.templateName.isExistError = action.payload.data.error;
        state.templateName.errMessageName = action.payload.data.message;
        state.loading = false;
        state.error = null;
        if (action.payload.data.error) {
          let eTD = getTemplateData();
          eTD.templateName["isExistError"] = true;
          eTD.templateName["errMessageName"] = action.payload.data.message;
          localStorage.setItem("eTD", JSON.stringify(eTD));
        }
      })

      .addCase(isTemplateExist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      // SAVE Template
      .addCase(saveTemplate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        reset();
      })

      .addCase(saveTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
const { actions, reducer } = TemplateSlice;
export const { reset, feedTemplate, addCategory, selectLocation, feedDescription } = actions;

export default reducer;
