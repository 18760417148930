import React, { Component } from "react";
import { Col, Row, Panel } from "rsuite";
 
import DigitalImg from "./assets/icons/digital.png";
import DigitalWhiteImg from "./assets/icons/digital_white.png";
 
import SocialImg from "./assets/icons/social.png";
import SocialWhiteImg from "./assets/icons/social_white.png";
 
import EmailImg from "./assets/icons/email.png";
import EmailWhiteImg from "./assets/icons/email_white.png";
import TemplateImg from "./assets/icons/template.png";
import TemplateImgWhite from "./assets/icons/templateWhite.png";
import { Link, Outlet } from "react-router-dom";
import "./assets/css/createnewproject.css";
import { reset } from "../Features/Template/TemplateSlice";
import { resetEmail } from "../Features/Email/EmailSlice";
import { resetData } from "../Features/Data/DataSlice";
 
import { connect } from "react-redux";
 
class CreateNewProject extends Component {
  async componentDidMount() {
    let { reset } = this.props;
    await reset();
  }
  render() {
    let {
      user: { access, role },
      isLoggedIn,
      success,
    } = this.props;
    let userAccess = JSON.parse(JSON.parse(access));
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8%",
            marginBottom: "5%",
          }}
        >
          <h3 className="hero-head">User Interactive</h3>
        </div>
        <div className="container-parent" style={{ textAlign: "center" }}>
          <div className="create-new-project container-flex">
            {userAccess.includes("template") && (
              <div className="new-campaign-detail ">
                <Link
                  to="create/template/info"
                  className="rs-dropdown-item-content"
                >
                  <div className="center-div-img-text">
                    <picture>
                      {/* <source
                        srcSet={TemplateImgWhite}
                        media={`(prefers-color-scheme: ${"dark"})`}
                        alt="New Email"
                      /> */}
 
                      <img src={TemplateImg} alt="New Email" loading="lazy" />
                    </picture>
 
                    <p className="create-new-project-type">
                      <b>Create Email Template</b>
                    </p>
                  </div>
                </Link>
                <p>
                  Create customizable email layouts easily by dragging and
                  dropping pre-designed elements onto a canvas interface.
                </p>
              </div>
            )}
 
            {userAccess.includes("email") && (
              <div className="new-campaign-detail ">
                <Link
                  to="create/email/info"
                  className="rs-dropdown-item-content"
                >
                  <div className="center-div-img-text">
                    <picture>
                      {/* <source
                        srcSet={EmailWhiteImg}
                        media={`(prefers-color-scheme: ${"dark"})`}
                        alt="New Email"
                      /> */}
 
                      <img src={EmailImg} alt="New Email" loading="lazy" />
                    </picture>
 
                    <p className="create-new-project-type">
                      <b>Create Test Email</b>
                    </p>
                  </div>
                </Link>
                <p>
                  Test email templates' appearance, functionality, and
                  responsiveness across five test accounts in one send for
                  quality assurance before deployment.
                </p>
              </div>
            )}
 
            {userAccess.includes("digitalmedia") && (
              <div className="new-campaign-detail">
                <Link to="#" className="rs-dropdown-item-content" style={{cursor:"auto"}}>
                  <div className="center-div-img-text">
                    <picture>
                      {/* <source
                        srcSet={DigitalWhiteImg}
                        media={`(prefers-color-scheme: ${"dark"})`}
                        alt="Digital Media"
                      /> */}
 
                      <img src={DigitalImg} alt="Digital Media" loading="lazy"/>
                    </picture>
                    <p className="create-new-project-type">
                      <b>Create Landing Page</b>
                    </p>
                  </div>
                </Link>
                <p>
                  Build landing pages easily with drag-and-drop for customizing
                  text, images, and forms on a canvas.
                  <br />
                  <b>Coming Soon</b>
                </p>
              </div>
            )}
 
            {/* {userAccess.includes("social") && (
            <div className="new-campaign-detail pointer-for">
              <div className="center-div-img-text">
                <picture>
                  <source
                    srcSet={SocialWhiteImg}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Social Media"
                  />
 
                  <img src={SocialImg} alt="Social Media" />
                </picture>
 
                <p className="create-new-project-type">
                  <b>SOCIAL MEDIA POST</b>
                </p>
              </div>
            </div>
          )}
 
          {userAccess.includes("digitalmedia") && (
            <div className="new-campaign-detail pointer-for">
              <div className="center-div-img-text">
                <picture>
                  <source
                    srcSet={DigitalWhiteImg}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Digital Media"
                  />
 
                  <img src={DigitalImg} alt="Digital Media" />
                </picture>
                <p className="create-new-project-type">
                  <b>DIGITAL MEDIA</b>
                </p>
              </div>
            </div>
          )} */}
          </div>
          <Outlet />
        </div>
      </>
    );
  }
}
 
function mapStateToProps(state) {
  const {
    isLoggedIn,
    success,
    userInfo: { user },
  } = state.LoginSlice;
 
  return {
    isLoggedIn,
    success,
    user,
  };
}
 
const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch(reset());
    dispatch(resetEmail());
    dispatch(resetData());
  },
});
 
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewProject);
 
