import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMontlyReport } from "../Features/Reports/ReportThunk";
import { Col, Row, Dropdown, Placeholder, Loader } from "rsuite";

import { Bar } from "react-chartjs-2";

import { userStatus } from "../Features/Login/LoginThunk";

class OverallReport extends Component {
  constructor() {
    super();
    this.state = {
      chartDataOpen: {},
      totalOpens: "",
      unique_opens: "",
      totalUnsubscribes: "",
      totalDelivered: "",
      unique_clicks: "",
      clicks: "",
      selectedYear: new Date().getFullYear(),
      aggregated_by: "month",
      loading: true,
      bounce: "",
      requests: "",
      created_count: "",
    };
  }
  async componentDidMount() {
    await this.getChartOverAllData();
  }

  getChartOverAllData = async () => {
    const { selectedYear, aggregated_by } = this.state;
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    let { fetchMontlyReport } = this.props;
    try {
      let responseData = await fetchMontlyReport({
        startDate,
        endDate,
        aggregated_by,
      });

      // console.log(responseData);

      if (responseData.payload.status === 1) {
        const stats = responseData.payload.data.responses[0];

        const devicestats = responseData.payload.data.responses[3];
        // console.log(stats);
        // console.log(devicestats);
        let labels = [];
        let opens = [];
        let bounce = [];
        let click = [];
        let requests = [];
        stats.map((entry) => {
          const date = new Date(entry.date);
          labels.push(date.toLocaleString("default", { month: "short" }));

          entry.stats.map((stat) => {
            opens.push(stat.metrics.opens);
            click.push(stat.metrics.clicks);
            requests.push(stat.metrics.requests);
            bounce.push(stat.metrics.bounces);
          });
        });

        let sumDelivered = 0,
          unique_opens = 0,
          clicks = 0,
          unique_clicks = 0,
          totalOpens = 0,
          totalUnsubscribes = 0;
        requests = 0;
        bounce = 0;

        for (let num of stats) {
          for (let i of num.stats) {
            sumDelivered += i.metrics.delivered;
            totalOpens += i.metrics.opens;
            unique_opens += i.metrics.unique_opens;
            totalUnsubscribes += i.metrics.unsubscribes;
            clicks += i.metrics.clicks;
            unique_clicks += i.metrics.unique_clicks;
            bounce += i.metrics.bounces;
            requests += i.metrics.requests;
          }
        }
        this.setState({
          chartDataOpen: {
            labels: labels,
            datasets: [
              {
                label: "Opens",
                data: opens,
                backgroundColor: ["#239B56"],
              },

              {
                label: "Clicks",
                data: click,
                backgroundColor: ["#76DDFB"],
              },
            ],
          },
          loading: false,
          unique_opens,
          totalOpens,
          unique_clicks,
          clicks,
          totalUnsubscribes,
          totalDelivered: sumDelivered,
          bounce,
          requests,
          created_count: responseData.payload.data.count,
          latest_campaign: responseData.payload.data.campaign,
        });
        await this.props.getEmailSent(
          this.state.requests,
          this.state.totalOpens,
          this.state.created_count,
          this.state.latest_campaign,
          this.state.unique_opens,
          this.state.unique_clicks,
          this.state.clicks
          // this.state.totalUnsubscribes
        );
      } else {
        console.log("Not Working");
      }
    } catch (error) {}
  };

  handleYearChange = async (eventKey, event) => {
    this.setState(
      { selectedYear: eventKey, loading: true },
      this.getChartOverAllData,
      await this.props.getEmailSent(
        this.state.requests,
        this.state.totalOpens,
        this.state.created_count,
        this.state.latest_campaign,
        this.state.unique_opens,
        this.state.unique_clicks,
        this.state.clicks
        // this.state.totalUnsubscribes
      )
    );
  };

  render() {
    const { loading, chartDataOpen, selectedYear } = this.state;
    let { theme } = this.props;
    return (
      <>
        {!loading && chartDataOpen !== "" ? (
          <>
            <Row style={{ paddingTop: 14 }}>
              <Col md={16}>
                <b className="RecentE-number">Overall Performance</b>
              </Col>
              <Col md={8} style={{ textAlign: "right" }}>
                <Dropdown
                  eventKey="1"
                  title={`${selectedYear}`}
                  onSelect={this.handleYearChange}
                >
                  <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                  <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <Bar
                  data={chartDataOpen}
                  options={{
                    title: {
                      display: true,
                      text: "Open Rate",
                      fontSize: 25,
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    color: theme === "dark" ? "#fff" : "#000",
                    scales: {
                      y: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                      x: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                    },
                  }}
                />
              </Col>
            </Row>
            {/* <Row style={{ marginTop: 10 }} className="showDataMon">
              <Col className="displayInline">
                <p>
                  <span>Total Opens:</span> {this.state.totalOpens}
                </p>
              </Col>
              <Col>
                <p>Unique Opens: {this.state.unique_opens}</p>
              </Col>
              <Col>
                <p>Total Unsubscribes: {this.state.totalUnsubscribes}</p>
              </Col>
              <Col className="displayInline">
                <p>
                  <span>Total Delivered:</span> {this.state.totalDelivered}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Total Clicks:</span> {this.state.clicks}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Unique Clicks:</span> {this.state.unique_clicks}
                </p>
              </Col>

              <Col className="displayInline">
                <p>
                  <span>Bounce:</span> {this.state.bounce}
                </p>
              </Col>
            </Row> */}
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={10} />
            <Loader center content="loading" />
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { theme } = state.LoginSlice;
  return {
    theme,
  };
}
// const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, { fetchMontlyReport, userStatus })(
  OverallReport
);
