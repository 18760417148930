import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchImageFolder = createAsyncThunk("image", async () => {
  const response = await axios.get(`/folder/image-folder`);
  return response.data;
});

export const createImageFolder = createAsyncThunk(
  "image/create",
  async (name, parentId, is_folder_or_file) => {
    try {
      const response = await axios.post(`/folder/image-folder`, name, parentId, is_folder_or_file);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteImageFolder = createAsyncThunk("image/delete", async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`/folder/image-folder/?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const renameImageFolder = createAsyncThunk("image/rename", async ({ id, text }) => {
  console.log(id);
  try {
    const response = await axios.put(`/folder/image-folder/?id=${id}&name=${text}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
