import React, { Component } from "react";
import { Container } from "rsuite";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import AccountInfo from "./AccountInfo";
import AccountInfoEdit from "./AccountInfoEdit";

class AccountMangement extends Component {
  render() {
    return (
      <>
        <Container>
          <Routes>
            <Route path="/" element={<AccountInfo />} />
            <Route path="/infoedit" element={<AccountInfoEdit />} />
          </Routes>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo
  };
}

export default connect(mapStateToProps)(AccountMangement);
