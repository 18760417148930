import React, { Component } from "react";
import { Col, Row, InputGroup, Input, Popover, Whisper, Button } from "rsuite";
import EmailFolder from "../../Folder/EmailFolder";
import { setEditor } from "../../Features/Editor/EditorThunk";
import { getEmails } from "../../Features/Asset/AssetThunk";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/view-asset-blocks.css";
import PaginationComponent from "../../Utils/PaginationComponent";
import Loading from "../../Loading/Loading";
import SearchIcon from "@rsuite/icons/Search";

import { resetEmail } from "../../Features/Email/EmailSlice";
import convertToUserTimeZone from "../../../hooks/convertToUserTimeZone";

const ImageStyle = (width, height) => {
  return {
    width,
    height,
  };
};

class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 4,
      loading: false,
      total: null,
    };
  }
  componentDidMount() {
    let { page } = this.state;
    this.fetchEmails({ type: "recent", page, value: null });
    setEditor();
  }

  fetchEmails = async ({ type, page, value }) => {
    const { getEmails } = this.props;
    this.setState({
      loading: true,
    });
    let { limit } = this.state;

    await getEmails({ type, page, limit, value });
    let { emails } = this.props;
    this.setState({
      loading: false,
      page: Number(emails.page),
      total: emails.count,
    });
  };

  selectFolder = async (e) => {
    await this.fetchEmails({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
    });
  };

  setNewPage = async (page) => {
    await this.fetchEmails({
      type: "recent",
      page,
      value: null,
    });
  };

  search = async (e) => {

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    let page = 1
    this.searchTimeout = setTimeout(async () => {
      await this.fetchEmails({
        type: "search",
        page,
        value: e,
      });
    }, 800);

    this.setNewPage = async (page) => {
      await this.fetchEmails({
        type: "search",
        page,
        value: e,
      });
    };
  };

  render() {
    let { emails, grid, resetEmail } = this.props;
    let { limit, page, loading, total } = this.state;
    return (
      <div>
        <Row className="new-campaign-row">
          <Col md={5} className="side-folder">
            <EmailFolder
              selectFolder={this.selectFolder}
              from="assets"
              fetchEmails={this.fetchEmails}
              setCancelEmailFolder={(click) => (this.clickEmailFolder = click)}
            />
          </Col>
          <Col
            md={19}
            style={{ paddingLeft: "25px" }}
            className={
              grid
                ? "grid-view-filter-template-image block-bg-font-color"
                : "list-view-filter-template-image block-bg-font-color"
            }
          >
            <Row style={{ marginBottom: 20 }}>
              <Col md={12}></Col>
              <Col md={12}>
                <InputGroup placeholder="Search via name">
                  <Input
                    placeholder="Search via name"
                    onChange={(e) => this.search(e)}
                  />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </Row>
            {!loading ? (
              <>
                {emails && emails.email.length > 0 ? (
                  <>
                    {emails.email.map((val) => (
                      <div
                        className={`${grid ? `grid` : "list"} pointer-for`}
                        key={val.email_id}
                      >
                        <Link
                          to={`/assetlibrary/edit/email/${val.email_id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className={`${grid
                                ? "rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border"
                                : ""
                              }`}
                            style={{
                              display: `${grid ? "" : "flex"}`,
                            }}
                          >
                            <div
                              className={`${grid ? "ImageResize " : "email_images_list"
                                }`}
                              style={{
                                display: `${grid ? "" : "inline-block"}`,
                              }}
                            >
                              <img
                                className={`${grid ? "email_images" : ""}`}
                                src={`${val.email_img_path}?${Math.random()}`}
                                data-id={val.email_id}
                                alt={val.email_name}
                                title={val.email_name}
                                style={ImageStyle("100%", "auto")}
                              />
                            </div>
                            {grid && (
                              <>
                                <div
                                  role="rowheader"
                                  className={` ${grid
                                      ? "rs-panel-heading asset-name"
                                      : "asset-name"
                                    }`}
                                  style={{ padding: "10px 10px" }}
                                  tabIndex="-1"
                                >
                                  <b>
                                    {val.email_name.length < 15
                                      ? val.email_name
                                      : `${val.email_name.substring(
                                        0,
                                        15
                                      )}....`}
                                  </b>
                                  <Whisper
                                    trigger="hover"
                                    placement="topEnd"
                                    speaker={
                                      <Popover
                                        title="Info"
                                        visible
                                        style={{ color: "#000" }}
                                      >
                                        <b> Created By</b> :{" "}
                                        {val.email_created_by}
                                        <br />
                                        <b>Modified By</b> :
                                        {val.email_modified_by}
                                        <br />
                                        <b>Created At </b>:{" "}
                                        {convertToUserTimeZone(
                                          val.email_created_at
                                        )}
                                        <br />
                                        <b>Modified At </b>:
                                        {convertToUserTimeZone(
                                          val.email_modified_at
                                        )}
                                        <br />
                                        <b>Subject </b> :{" "}
                                        {val.email_subject_line}
                                      </Popover>
                                    }
                                  >
                                    <Button
                                      appearance="subtle"
                                      style={{
                                        float: "right",
                                        padding: 0,
                                        color: "#000",
                                      }}
                                    >
                                      <i
                                        class="fa fa-info-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  </Whisper>
                                </div>
                                <div
                                  className="rs-panel-body"
                                  style={{ padding: 10 }}
                                >
                                  {/* <p>
                                    <small className="asset-name">
                                      Category: {val.template_category}
                                    </small>
                                  </p> */}
                                </div>
                              </>
                            )}

                            {!grid && (
                              <span
                                style={{ paddingLeft: 5, padding: 16 }}
                                className="asset-name"
                              >
                                <b>Email : </b>
                                {val.email_name.length < 15
                                  ? val.email_name
                                  : `${val.email_name.substring(0, 150)}....`}
                                {/* <b> | Category : </b> {val.template_category} */}
                                <b> | Created at : </b>
                                {convertToUserTimeZone(val.email_created_at)}
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7}></Col>
                    <Col md={7} style={{ textAlign: "center" }}>
                      <h5>No Email Found !</h5> <br />
                      <Link
                        onClick={() => resetEmail()}
                        className="link-color-font"
                        to="/createnewproject/create/email/info"
                        style={{ fontSize: 25, cursor: "pointer" }}
                      >
                        Click to Create
                      </Link>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                )}
              </>
            ) : (
              <Loading />
            )}
            <div className="fixed-pagination">
              <PaginationComponent
                limit={limit}
                page={page}
                setNewPage={this.setNewPage}
                total={total}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  emails: state.AssetSlice.emails,
  grid: state.AssetSlice.isGrid,
});

const mapDispatchToProps = (dispatch) => ({
  getEmails: (type, page, limit, value) =>
    dispatch(getEmails(type, page, limit, value)),
  resetEmail: () => {
    dispatch(resetEmail());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
