import React, { useEffect, useRef, useState } from "react";

import "./App.css";
import { Content, Container, Header, Sidebar } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import { Route, Routes } from "react-router-dom";
import CreateNewProject from "./components/CreateNewProject/CreateNewProject";
import Dashboard from "./components/Dashboard/Dashboard";
import SideNavbar from "./components/Navbar/Navbar";
import HeaderTop from "./components/Header/HeaderTop";
import { useLocation, matchRoutes } from "react-router-dom";
import ContinueProject from "./components/ContinueProject/ContinueProject";
import CreateEmail from "./components/CreateNewProject/Email/CreateEmail";
import {
  collapseSidebar,
  editorPath,
  editorEditPath,
  dynamicCollapseSidebar,
} from "./components/Utils/Paths/Paths";
import IconNavbar from "./components/Navbar/IconNavbar";
import { viewChange } from "./components/Utils/ViewServices";

import { ThemeService } from "./components/Utils/Theme/ThemeContext";
import Login from "./components/Auth/Login";
import { useSelector, useDispatch } from "react-redux";

import { userStatus } from "./components/Features/Login/LoginThunk";
import { getSenderprofile } from "./components/Features/SenderManagement/SenderThunk"
import AuthRoutes from "./components/Auth/AuthRoutes";
import AdminRoute from "./components/Auth/AdminRoute";

import EditorContainer from "./components/Utils/EditorContainer";
import CreateTemplate from "./components/CreateNewProject/Template/CreateTemplate";
import Loading from "./components/Loading/Loading";
import AssetsArchive from "./components/AssetArchive/AssetArchive";
import DataArchive from "./components/DataList/DataList";

import { Alert } from "./components/Utils/Alert";
import CreateData from "./components/DataList/Create/CreateData";
import EditData from "./components/DataList/Edit/EditData";

import ImageUploader from "./components/AssetArchive/Image/ImageUploader";

// import CreateDataStructure from "./components/DataList/Create/CreateDataStructure";

import UserManagement from "./components/AccountManagement/UserManagement";
import AccountManagement from "./components/AccountManagement/AccountManagement";

import CreateCampaign from "./components/CreateCampaign/CreateCampaign";
import CreateEmailCampaign from "./components/CreateCampaign/Email/CreateEmailCampaign";
import Reporting from "./components/Reports/Reporting";
// import CampaignReporting from "./components/Reports/CampaignReport/CampaignReporting";

import CampaignCanvas from "./components/AssetArchive/Campaign/EditCampaign/CampaignCanvas";
import Settings from "./components/Settings/Settings";
import ErrorPage from "./components/errorPage/ErrorPage";

import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import ConfirmationPassword from "./components/Auth/ConfirmationPassword";
import IPSetting from "./components/Settings/IPSetting"
import SenderProfile from "./components/Settings/SenderProfile"

function App() {
  const location = useLocation();
  const route = matchRoutes(editorEditPath, location);
  const dynamicRoute = matchRoutes(dynamicCollapseSidebar, location);
  const alertRef = useRef(null);
  const loginSlice = useSelector((props) => props.LoginSlice);
  const { isLoggedIn, loading } = loginSlice;
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const token = query.get("t");
  const [resetemail, setResetemail] = useState('')
  const CampaignSlice = useSelector((props) => props.CampaignSlice);
  //  console.log("CampaignSlice",CampaignSlice.senderProfiles)

  //  console.log(resetemail)
  useEffect(() => {
    // FIXME  userid - auth error -
    try {
      dispatch(userStatus());
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      if (!loading) {
        viewChange(location, route, dynamicRoute);
      }
      ThemeService();

      document.body.classList.remove("before-sigin-bg-img");
    } else {
      document.body.classList.add("before-sigin-bg-img");
    }
  });


  // get the senderprofile Details here.DB 

  useEffect(() => {
    const fetchSenderProfile = async () => {
      try {
        const respdata = await dispatch(getSenderprofile());
        //  console.log("respdata", respdata.payload.data)
      } catch (error) {
        console.error('Error fetching sender profile:', error);
      }
    };
    fetchSenderProfile();
  }, [])


  return (
    <div
      className={`App ${!editorPath.includes(location.pathname) && route === null
        ? "app-main-padding"
        : ""
        }`}
    >
      <Alert ref={alertRef} />

      {loading ? (
        <Loading />
      ) : (
        <>
          <Container id="for-create-change-sidebar">
            {!editorPath.includes(location.pathname) && route === null ? (
              <>
                {isLoggedIn && (
                  <>
                    {dynamicRoute === null ? (
                      <>
                        {!collapseSidebar.includes(location.pathname) ? (
                          <Sidebar className="sidenav " id="for-hide-sidebar">
                            <SideNavbar location={location.pathname} />
                          </Sidebar>
                        ) : (
                          <Header id="for-hide-header">
                            {/* <HeaderTop
                              location={location.pathname}
                              dynamicRoute={dynamicRoute}
                              isLoggedIn={isLoggedIn}
                            /> */}
                          </Header>
                        )}
                      </>
                    ) : (
                      <Header id="for-hide-header">
                        {/* <HeaderTop
                          location={location.pathname}
                          dynamicRoute={dynamicRoute}
                          isLoggedIn={isLoggedIn}
                        /> */}
                      </Header>
                    )}
                  </>
                )}

                <Container
                  className={`${dynamicRoute == null
                    ? !collapseSidebar.includes(location.pathname)
                      ? "content-container "
                      : ""
                    : ""
                    }`}
                  id="for-create-change-header"
                >
                  {isLoggedIn && (
                    <>
                      {dynamicRoute === null ? (
                        <>
                          {!collapseSidebar.includes(location.pathname) ? (
                            <Header id="for-hide-header">
                              {/* <HeaderTop
                                location={location.pathname}
                                dynamicRoute={dynamicRoute}
                                isLoggedIn={isLoggedIn}
                              /> */}
                            </Header>
                          ) : (
                            <Sidebar className="sidenav " id="for-hide-sidebar">
                              <SideNavbar location={location.pathname} />
                            </Sidebar>
                          )}
                        </>
                      ) : (
                        <Sidebar className="sidenav " id="for-hide-sidebar">
                          <SideNavbar location={location.pathname} />
                        </Sidebar>
                      )}
                    </>
                  )}

                  <Content className="content-home" id="for-change-editor">
                    <Routes>
                      <Route path="not-authorized" element={<ErrorPage />} />
                      <Route
                        path="/"
                        element={<Login alertRef={alertRef} />}
                      ></Route>
                      <Route
                        path="/login"
                        element={<Login alertRef={alertRef} />}
                      ></Route>
                      <Route
                        path="/forgotpassword"
                        element={<ForgotPassword alertRef={alertRef} setResetemail={setResetemail} />}
                      ></Route>
                      <Route
                        path="/resetpassword"
                        element={
                          <ResetPassword alertRef={alertRef} token={token} />
                        }
                      ></Route>
                      <Route
                        path="/password-request"
                        element={
                          <ConfirmationPassword
                            t={query.get("t")}
                            s={query.get("s")}
                            resetemail={resetemail}
                          />
                        }
                      ></Route>

                      <Route
                        path="dashboard"
                        element={
                          <AuthRoutes component={null}>
                            <Dashboard />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="createnewproject"
                        element={
                          <AuthRoutes component={null}>
                            <CreateNewProject />
                          </AuthRoutes>
                        }
                      ></Route>

                      <Route
                        path="createnewproject/create/template/info"
                        element={
                          <AuthRoutes component="template">
                            <CreateTemplate />
                          </AuthRoutes>
                        }
                      ></Route>

                      <Route
                        path="createnewproject/create/email/info"
                        element={
                          <AuthRoutes component="email">
                            <CreateEmail />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="continueproject"
                        element={
                          <AuthRoutes>
                            <ContinueProject />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="createcampaign"
                        element={
                          <AuthRoutes component="campaign">
                            <CreateCampaign />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="createcampaign/email/info"
                        element={
                          <AuthRoutes component="campaign">
                            <CreateEmailCampaign alertRef={alertRef} />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="assetlibrary/edit/campaign/*"
                        element={
                          <AuthRoutes component="campaign">
                            <CampaignCanvas
                              location={location.pathname}
                              alertRef={alertRef}
                              dynamicRoute={dynamicRoute}
                            />
                          </AuthRoutes>
                        }
                      ></Route>

                      <Route
                        path="image-upload"
                        element={
                          <AuthRoutes component="image">
                            <ImageUploader />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="assetlibrary/*"
                        element={
                          <AuthRoutes component={null}>
                            <AssetsArchive location={location.pathname} />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="data/"
                        element={
                          <AuthRoutes component="data">
                            <DataArchive location={location.pathname} />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="create/data-set/*"
                        element={
                          <AuthRoutes component="data">
                            <CreateData
                              location={location.pathname}
                              alertRef={alertRef}
                            />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="assetlibrary/data/edit/*"
                        element={
                          <AuthRoutes component="data">
                            <EditData
                              location={location.pathname}
                              alertRef={alertRef}
                              dynamicRoute={dynamicRoute}
                            />
                          </AuthRoutes>
                        }
                      ></Route>

                      <Route
                        path="reports/*"
                        element={
                          <AuthRoutes component="social">
                            <Reporting location={location.pathname} />
                          </AuthRoutes>
                        }
                      ></Route>

                      <Route
                        path="accountmanagement/*"
                        element={
                          <AuthRoutes component={null}>
                            <AccountManagement />
                          </AuthRoutes>
                        }
                      ></Route>
                      <Route
                        path="usermanagement/*"
                        element={
                          <AdminRoute component="admin">
                            <UserManagement />
                          </AdminRoute>
                        }
                      ></Route>
                      <Route
                        path="settings/*"
                        element={
                          <AdminRoute component="admin">
                            <Settings location={location.pathname} />
                          </AdminRoute>
                        }
                      ></Route>

                       <Route
                        path="settings/ip"
                        element={
                          <AdminRoute component="admin">
                            <IPSetting location={location.pathname} />
                          </AdminRoute>
                        }
                      ></Route>
                      
                       <Route
                        path="settings/senderprofile"
                        element={
                          <AdminRoute component="admin">
                            <SenderProfile location={location.pathname} />
                          </AdminRoute>
                        }
                      ></Route>


                      {/* <Route
                        path="settings/*"
                        element={
                          <AuthRoutes component={null}>
                            <Settings location={location.pathname} />
                          </AuthRoutes>
                        }
                      ></Route> */}

                      <Route path="/*" element={<ErrorPage />} />
                    </Routes>
                  </Content>
                </Container>
              </>
            ) : (
              <Routes>
                <Route
                  path="createnewproject/create/template/editor"
                  element={
                    <AuthRoutes component="template">
                      <EditorContainer
                        component="template"
                        edit={false}
                        alertRef={alertRef}
                      />
                    </AuthRoutes>
                  }
                ></Route>
                <Route
                  path="assetlibrary/edit/template/:id"
                  element={
                    <AuthRoutes component="template">
                      <EditorContainer
                        component="template"
                        edit={true}
                        editParam={route}
                        alertRef={alertRef}
                      />
                    </AuthRoutes>
                  }
                />
                <Route
                  path="createnewproject/create/email/editor"
                  element={
                    <AuthRoutes component="email">
                      <EditorContainer
                        component="email"
                        edit={false}
                        alertRef={alertRef}
                      />
                    </AuthRoutes>
                  }
                ></Route>
                <Route
                  path="assetlibrary/edit/email/:id"
                  element={
                    <AuthRoutes component="email">
                      <EditorContainer
                        component="email"
                        edit={true}
                        editParam={route}
                        alertRef={alertRef}
                      />
                    </AuthRoutes>
                  }
                />
              </Routes>
            )}
          </Container>
        </>
      )}
    </div>
  );
}

export default App;
