import React, { Component } from "react";
import "./assets/loading.css";

export default class Loading extends Component {
  render() {
    return (
      <div className="center">
        <div className="ring"></div>
        <span>Charlie...</span>
      </div>
    );
  }
}
