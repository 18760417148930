import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

class DataService {
  ignoreOrderCompare = (a, b) => {
    if (a.length !== b.length) return false;
    const elements = new Set([...a, ...b]);
    for (const x of elements) {
      const count1 = a.filter((e) => e === x).length;
      const count2 = b.filter((e) => e === x).length;
      if (count1 !== count2) return false;
    }
    return true;
  };

  removeFirst = (list) => {
    let [, ...arr] = list;
    return arr;
  };

  createPreview = async (file, email, fields) => {
    return new Promise((resolve, reject) => {
      let heading = null,
        body = [],
        duplicate = 0,
        emailFieldIndex = null,
        validatedArray = [],
        validated = 0;
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        heading = data[0];
        const newFields = this.removeFirst(fields);
        let isFieldsAreSame = this.ignoreOrderCompare(heading, newFields);
        // console.log(isFieldsAreSame);
        if (isFieldsAreSame) {
          emailFieldIndex = heading.indexOf(email);
          if (emailFieldIndex > -1) {
            const [, ...values] = data;

            for (let i = 0; i < values.length; i++) {
              if (values[i][emailFieldIndex] === "" || values[i][emailFieldIndex] === undefined) {
                validated++;
              } else {
                validatedArray.push(values[i]);
              }
            }

            for (var i = 0; i < validatedArray.length; i++) {
              if (body.length > 0) {
                let isExist = [];
                for (let j = 0; j < body.length; j++) {
                  let oneBody = body[j];

                  let isSameField = oneBody[emailFieldIndex]
                    .toLowerCase()
                    .includes(validatedArray[i][emailFieldIndex].toLowerCase());
                  if (isSameField) {
                    duplicate++;
                    isExist.push(true);
                    break;
                  } else {
                    isExist.push(false);
                  }
                }
                if (!isExist.includes(true)) body.push(validatedArray[i]);
              } else {
                body.push(validatedArray[i]);
              }
            }
            // console.log("body:::::::::::", body);
          }
          resolve({
            heading,
            body,
            duplicate,
            emailFieldIndex,
            validated,
            isFieldsAreSame
          });
        } else {
          resolve({
            isFieldsAreSame
          });
        }
      };
      reader.readAsBinaryString(file.blobFile);
    });
  };


  // 2nd part-

  dbCheck = async (data, values, heading, duplicate, tableStructure, emailField, emailFieldIndex) => {
    // console.log(tableStructure);

    const requiredFieldsWithNullValues = tableStructure.filter(fieldInfo => fieldInfo.IS_NULLABLE === 'NO')
      .filter(fieldInfo => {
        const fieldName = fieldInfo.COLUMN_NAME;
        const fieldIndex = heading.indexOf(fieldName);
        const hasNullValues = values.some(row => row[fieldIndex] === null || row[fieldIndex] === undefined || row[fieldIndex] === 'null' || row[fieldIndex] === '');
        // console.log(`Field '${fieldName}' is required but has ${hasNullValues ? 'null' : 'non-null'} value(s).`);
        return hasNullValues;
      })
      .map(fieldInfo => fieldInfo.COLUMN_NAME);

    // console.log('Required fields with null values:', requiredFieldsWithNullValues);

    // Initialize an array to store removed rows with corresponding headers
    const removedRowsWithHeaders = [];

    // Filter out rows containing null values in required fields
    const updatedValues = values.filter(row => {
      // Check if any required field in the row contains null value
      const containsNull = requiredFieldsWithNullValues.some(fieldName => {
        // Exclude material ID field from null check
        if (fieldName === 'material_table_id') return false;

        const fieldIndex = heading.indexOf(fieldName);
        const value = row[fieldIndex];
        // console.log(`Field '${fieldName}' in row ${JSON.stringify(row)} has value: ${value}`);
        return value === null || value === undefined || value === 'null' || value === '';
      });

      // If row contains null value in required fields, store it in removedRowsWithHeaders array
      if (containsNull) {
        const removedRow = {};
        heading.forEach((header, index) => {
          removedRow[header] = row[index];
        });
        removedRowsWithHeaders.push(removedRow);
      }

      // console.log(`Row ${JSON.stringify(row)} contains null value in required fields: ${containsNull}`);
      // console.log('Required fields with null values:', requiredFieldsWithNullValues);
      // console.log('Heading:', heading);

      // Return true if row does not contain null value in required fields, false otherwise
      return !containsNull;
    });

    // console.log('Updated values after removing rows with null values in required fields:', updatedValues);
    // console.warn('Removed rows with headers:', removedRowsWithHeaders);

    // Check for duplicate emails
    if (updatedValues.length > 0) {
      let [upload] = [[]];
      let emailValues = data.map(el => el[emailField]);
      let duplicateEmails = [];

      for (let i = 0; i < updatedValues.length; i++) {
        let found = emailValues.findIndex(e => e.toLowerCase() === updatedValues[i][emailFieldIndex].toLowerCase());
        if (found === -1) {
          upload.push(updatedValues[i]);
        } else {
          duplicate++;
          duplicateEmails.push(emailValues[found]);
        }
      }
      // console.warn(duplicateEmails)
      return { updatedValues: upload, duplicate,removedRowsWithHeaders, duplicateEmails };
    } else {
      return { updatedValues,removedRowsWithHeaders, duplicate };
    }


  };






  //   dbCheck = async (data, values,heading, duplicate,tableStructure, emailField, emailFieldIndex) => {
  //     console.log(tableStructure)

  //     //TODO -  Okay so now i'm recieving tableStructure here, well, so now we can implement the logic here,
  //     // look this function is alreday build that is being used for checking duplicate values, 
  //     // alright, with same way need to implement the same logic, like, we have all values and heading, 
  //     // so we just check if frome table strucures file is required and here, if there is null values available for
  //     // that filed, means we will simply remove that field, and update the values and requiredfiledmissing something like to store those, got it ?
  //       console.log(data)
  //       console.log(values)
  //       console.log(heading)
  //       console.log(tableStructure)

  //       // okay so just to show you, i'm getting all the resp here, that will help you to build the logic.

  //       //resp of console.log(values)- 
  //       // ex- 
  //       // 0
  //       // : 
  //       // Array(3)
  //       // 0
  //       // : 
  //       // "divyankkumar@gmail.com"
  //       // 1
  //       // : 
  //       // "gg"
  //       // 2
  //       // : 
  //       // "null"
  //       // length
  //       // : 
  //       // 3
  //       // [[Prototype]]
  //       // : 
  //       // Array(0)
  //       // 1
  //       // : 
  //       // Array(3)
  //       // 0
  //       // : 
  //       // "divyankkumarsingh@gmail.com"
  //       // 1
  //       // : 
  //       // "ff"
  //       // 2
  //       // : 
  //       // "gg"
  //       // length
  //       // : 
  //       // 3
  //       // [[Prototype]]
  //       // : 
  //       // Array(0)
  //       // 2
  //       // : 
  //       // Array(3)
  //       // 0
  //       // : 
  //       // "singhdivyank@gmail.com"
  //       // 1
  //       // : 
  //       // "fghh"
  //       // 2
  //       // : 
  //       // "null"
  //       // length
  //       // : 
  //       // 3

  //       // res of console.log(heading)
  //       //ex- ['email', 'name', 'fname']
  //       // ['email', 'name', 'fname']
  //       // 0
  //       // : 
  //       // "email"
  //       // 1
  //       // : 
  //       // "name"
  //       // 2
  //       // : 
  //       // "fname"
  //       // length
  //       // : 
  //       // 3

  //       //res of  console.log(tableStructure)
  //          //ex-(4) [{…}, {…}, {…}, {…}]
  // // 0
  // // : 
  // // CHARACTER_MAXIMUM_LENGTH
  // // : 
  // // null
  // // CHARACTER_OCTET_LENGTH
  // // : 
  // // null
  // // CHARACTER_SET_NAME
  // // : 
  // // null
  // // COLLATION_NAME
  // // : 
  // // null
  // // COLUMN_COMMENT
  // // : 
  // // ""
  // // COLUMN_DEFAULT
  // // : 
  // // null
  // // COLUMN_KEY
  // // : 
  // // "PRI"
  // // COLUMN_NAME
  // // : 
  // // "material_table_id"
  // // COLUMN_TYPE
  // // : 
  // // "int(11)"
  // // DATA_TYPE
  // // : 
  // // "int"
  // // DATETIME_PRECISION
  // // : 
  // // null
  // // EXTRA
  // // : 
  // // "auto_increment"
  // // GENERATION_EXPRESSION
  // // : 
  // // null
  // // IS_GENERATED
  // // : 
  // // "NEVER"
  // // IS_NULLABLE
  // // : 
  // // "NO"
  // // NUMERIC_PRECISION
  // // : 
  // // 10
  // // NUMERIC_SCALE
  // // : 
  // // 0
  // // ORDINAL_POSITION
  // // : 
  // // 1
  // // PRIVILEGES
  // // : 
  // // "select,insert,update,references"
  // // TABLE_CATALOG
  // // : 
  // // "def"
  // // TABLE_NAME
  // // : 
  // // "dktestdata"
  // // TABLE_SCHEMA
  // // : 
  // // "charlie"
  // // [[Prototype]]
  // // : 
  // // Object
  // // 1
  // // : 
  // // {TABLE_CATALOG: 'def', TABLE_SCHEMA: 'charlie', TABLE_NAME: 'dktestdata', COLUMN_NAME: 'email', ORDINAL_POSITION: 2, …}
  // // 2
  // // : 
  // // {TABLE_CATALOG: 'def', TABLE_SCHEMA: 'charlie', TABLE_NAME: 'dktestdata', COLUMN_NAME: 'name', ORDINAL_POSITION: 3, …}
  // // 3
  // // : 
  // // {TABLE_CATALOG: 'def', TABLE_SCHEMA: 'charlie', TABLE_NAME: 'dktestdata', COLUMN_NAME: 'fname', ORDINAL_POSITION: 4, …}
  // // length
  // // : 
  // // 4




  //     return new Promise((resolve, reject) => {
  //       // console.log(values);
  //       // console.log("data::::::::::::", data);
  //       if (values.length > 0) {
  //         // console.log("values:::::::::IF", values);
  //         let [upload] = [[]];
  //         let emailValues = data.map(function (el) {
  //           console.log(el)
  //           return el[emailField];
  //         });

  //          // console.log("emailValues:::::::::::::::::", emailValues);
  //                 for (let i = 0; i < values.length; i++) {
  //           let found = emailValues.findIndex(
  //             (e) => e.toLowerCase() === values[i][emailFieldIndex].toLowerCase()
  //           );

  //           if (found === -1) {
  //             upload.push(values[i]);
  //             /* data contains the element we're looking for, at index "i" */
  //           } else {
  //                         // console.log(found);
  //             duplicate++;
  //           }
  //         }

  //         // console.log(duplicate);
  //         resolve({ upload, duplicate });
  //       } else {
  //         resolve({ upload: values, duplicate });
  //       }
  //     });
  //   };

  exportFieldToCsv = async (dataById, datafield, rawData) => {
    let exportAsXlsx = [];
    for (let i = 0; i < datafield.length; i++) {
      if (datafield[i].ordinalPos !== null && datafield[i].ordinalPos !== 1) {
        exportAsXlsx.push(datafield[i].FieldName);
      }
    }

    for (let i = 0; i < rawData.length; i++) {
      delete rawData[i]["material_table_id"];
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.book_new();
    const fileExtension = ".xlsx";
    XLSX.utils.sheet_add_aoa(ws, [exportAsXlsx]);
    XLSX.utils.sheet_add_json(ws, rawData, { origin: "A2", skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true
    });
    const finalData = new Blob([excelBuffer], { type: fileType });
    // let exportX = [exportAsXlsx];
    // const fileType =
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".xlsx";
    // const ws = XLSX.utils.aoa_to_sheet(exportX);
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(finalData, dataById.data_list_name + fileExtension);
  };
}

export default new DataService();
