import React, { useEffect, useRef, useState } from "react";
import { Table, Button, FlexboxGrid, Stack } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import {
  UpdateSenderprofileData,
  deleteSenderProfile,
  getSenderprofile,
} from "../Features/SenderManagement/SenderThunk";
import SenderProfileDrawer from "./SenderProfileDrawer";
import { Alert } from "../Utils/Alert";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;

const SenderProfile = () => {
  const [open, setOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [senderRowId, setSenderRowId] = useState();
  const dispatch = useDispatch();
  const alertRef = useRef();
  const {
    senderProfiles,
    loading,
    error,
    createdSenerProfile,
    updatedSenderProfile,
  } = useSelector((state) => state.SenderSlice);
  // console.log()
  // console.log("createdSenerProfile", createdSenerProfile, updatedSenderProfile);
  // console.log(senderProfiles);
  // useEffect(() => {
  //   dispatch(getSenderprofile());
  // }, [dispatch]);

  // SECTION Create New Sender
  const handleCreateNewSender = () => {
    setOpen(true);
    setDrawerTitle("Add");
  };

  // SECTION Edit Sender Profile
  const handleEditSender = (senderProfileId) => {
    setOpen(true);
    setDrawerTitle("Edit");
    setSenderRowId(senderProfileId);
  };

  // SECTION Delete Sender Profile
  const handleDeleteSender = async (senderProfileId) => {
    const restrictedIds = [
      5510801, 5510739, 5364660, 5320240, 5255746, 5255726, 3667768, 3667765,
      1838044, 1116568, 819760, 5726465,
    ];

    if (restrictedIds.includes(senderProfileId)) {
      let message = {
        status: "error",
        duration: 2,
        message: "Prohibited to Delete This Profile",
      };
      alertRef.current.showToaster(message);
      return;
    }
    const response = await dispatch(deleteSenderProfile(senderProfileId));
    await alertRef.current.showToaster({
      status: 'success',
      duration: 3,
      message: response.payload.message,
    });
    dispatch(getSenderprofile());
  };

  // SECTION Update Sender Profiles: To Fetch the Latest Profiles from Sendgrid
  const UpdateSenderProfile = async () => {
    try {
      const response = await dispatch(UpdateSenderprofileData());
      if (response.payload.status !== 1) {
        throw new Error(response.payload.message);
      }
      await dispatch(getSenderprofile());
      await alertRef.current.showToaster({
        status: response.payload.status,
        duration: 3,
        message: response.payload.message,
      });
    } catch (error) {
      // Log the error
      console.error("Error updating sender profile:", error);
    }
  };
  

  // get the senderprofile Details here.DB
  useEffect(() => {
    const fetchSenderProfile = async () => {
      try {
        const respdata = await dispatch(getSenderprofile());
        // console.log("respdata", respdata.payload.data);
      } catch (error) {
        console.error("Error fetching sender profile:", error);
      }
    };
    fetchSenderProfile();
  }, []);

  return (
    <>
      <Alert ref={alertRef} />
      <FlexboxGrid
        justify="space-between"
        align="middle"
        style={{ marginBottom: "10px" }}
      >
        <FlexboxGrid.Item>
          {" "}
          <h3 style={{}}>Sender Management</h3>
          {/* <span style={{}}>Note: Not ready for test yet.</span> */}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          <Stack spacing={20}>
            <Button
              size="lg"
              onClick={handleCreateNewSender}
              style={{
                backgroundColor: "#415670",
                color: "#fff",
                // margin: "0px 0px 14px 0px",
                marginBottom: "10px",
              }}
            >
              Create New Sender
            </Button>
            <Button
              size="lg"
              onClick={UpdateSenderProfile}
              style={{
                backgroundColor: "#415670",
                color: "#fff",
                // margin: "0px 0px 14px 0px",
                marginBottom: "10px",
              }}
            >
              Update Sender Profiles
            </Button>
          </Stack>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        cellBordered
        autoHeight
        data={senderProfiles}
        onRowClick={(rowData) => {
          // console.log(rowData);
        }}
        loading={loading}
        bordered
      >
        <Column width={70} align="center" fixed>
          <HeaderCell>#</HeaderCell>
          <Cell dataKey="sender_id" />
        </Column>
        <Column width={250} fullText fixed>
          <HeaderCell>NICKNAME</HeaderCell>
          <Cell dataKey="nickname" />
        </Column>
        <Column width={300} fullText>
          <HeaderCell>FROM NAME</HeaderCell>
          <Cell dataKey="from_name" />
        </Column>
        <Column width={300} fullText>
          <HeaderCell>FROM EMAIL</HeaderCell>
          <Cell dataKey="from_email" />
        </Column>
        <Column width={300} fullText>
          <HeaderCell>REPLY EMAIL</HeaderCell>
          <Cell dataKey="reply_to_email" />
        </Column>
        <Column width={100} fullText>
          <HeaderCell>REPLY NAME</HeaderCell>
          <Cell dataKey="reply_to_name" />
        </Column>
        <Column width={250} fullText>
          <HeaderCell>ADDRESS</HeaderCell>
          <Cell dataKey="address" />
        </Column>
        <Column width={200} fullText>
          <HeaderCell>CITY</HeaderCell>
          <Cell dataKey="city" />
        </Column>
        <Column width={100} fullText>
          <HeaderCell>STATE</HeaderCell>
          <Cell dataKey="state" />
        </Column>
        <Column width={100} fullText>
          <HeaderCell>ZIP</HeaderCell>
          <Cell dataKey="zip" />
        </Column>
        <Column width={100} fullText>
          <HeaderCell>COUNTRY</HeaderCell>
          <Cell dataKey="country" />
        </Column>
        <Column width={200} fixed="right">
          <HeaderCell>ACTIONS</HeaderCell>

          <Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <>
                <Button
                  appearance="link"
                  onClick={() => handleEditSender(rowData.id)}
                  style={{
                    backgroundColor: "#415670",
                    color: "#fff",
                    marginLeft: "20px",
                  }}
                >
                  Edit
                </Button>
                <Button
                  appearance="link"
                  color="red"
                  onClick={() => handleDeleteSender(rowData.sender_id)}
                  style={{
                    backgroundColor: "#415670",
                    color: "#fff",
                    // margin: "0px 0px 14px 0px",
                    marginLeft: "20px",
                  }}
                >
                  Delete
                </Button>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <SenderProfileDrawer
        backdrop={"static"}
        open={open}
        onClose={() => setOpen(false)}
        title={drawerTitle}
        senderRowId={senderRowId}
      />
    </>
  );
};

export default SenderProfile;
