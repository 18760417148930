import React, { Component } from "react";
import logo from "./assets/icons/Charlie.png";
import "./assets/css/login.css";

import { Form, Whisper, Tooltip, Schema, InputGroup } from "rsuite";
import { connect } from "react-redux";
import { login } from "../Features/Login/LoginThunk";
import { Navigate, Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        email: "",
        password: ""
      },
      eyetitle: true,
      message: { type: "", text: "" }
    };
    // this.alertRef = React.createRef();
  }

  // START  Password type (hidden or show)

  showpassword = () => {
    var password = document.getElementById("password");

    if (password.type === "password") {
      password.type = "text";
      this.setState({
        eyetitle: false,
        checked: false
      });
    } else {
      password.type = "password";
      this.setState({
        eyetitle: true,
        checked: true
      });
    }
  };
  // END  Password type (hidden or show)
  handleSubmit = async () => {
    let { email, password } = this.state.formValue;
    let { alertRef, login } = this.props;
    if (!this.form.check()) {
      return;
    }

    let response = await login({ email, password });
    if (!response.payload) {
      let failure = {
        status: 0,
        message: "Email and Password combination is incorrect"
      };
      alertRef.current.showToaster(failure);
    }
  };

  render() {
    let { formValue } = this.state;
    let { alertRef } = this.props;
    let { email, password } = formValue;
    let { isLoggedIn } = this.props.data;

    if (isLoggedIn === true) {
      localStorage.setItem("isGrid", JSON.stringify(true));
      return <Navigate to="/dashboard" state={isLoggedIn} replace />;
    }
    const model = Schema.Model({
      password: Schema.Types.StringType().isRequired("This field is required."),
      email: Schema.Types.StringType()
        .isEmail("Please enter a valid email address.")
        .isRequired("This field is required.")
    });
    const showPassword = <Tooltip>Show Password</Tooltip>;

    const hidePassword = <Tooltip>Hide Password</Tooltip>;
    return (
      <div className="wrapper">
        <div className="box left-side-form">
          <div className="login-logo-div">
            <img src={logo} alt="" className="login-logo" />
          </div>
          <Form
            fluid
            model={model}
            ref={(ref) => (this.form = ref)}
            onChange={(formValue, e) => {
              this.setState({
                formValue
              });
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 32) {
                e.preventDefault();
                let message = {
                  status: 0,
                  message: "Whitespace is not allowed"
                };
                alertRef.current.showToaster(message);
              }
            }}
            onCheck={(formError) => {
              this.setState({ formError });
            }}
            formValue={formValue}
          >
            <Form.Group className="loginInput">
              <Form.Control name="email" placeholder="Email" className="input-field-login" />
            </Form.Group>
            <Form.Group className="loginInput">
              <InputGroup>
                <Form.Control
                  name="password"
                  placeholder="Password"
                  className="input-field-login"
                  type="password"
                  autoComplete="off"
                  id="password"
                />
                <InputGroup.Addon className="eye" onClick={this.showpassword}>
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={this.state.eyetitle ? showPassword : hidePassword}
                  >
                    <i
                      className={
                        this.state.checked ? "fa fa-eye showHideEye" : "fa fa-eye-slash showHideEye"
                      }
                    ></i>
                  </Whisper>
                </InputGroup.Addon>
              </InputGroup>
            </Form.Group>

            <div className="login-button-div">
              <button
                className={`loginButton ${
                  !email || !password ? "field-empty-button-disable " : ""
                }`}
                onClick={this.handleSubmit}
              >
                Login
              </button>
              &nbsp;
              <Link to="/forgotpassword">
                <span className="forget-paswword" style={{ cursor: "pointer" }}>
                  Forgot password?
                </span>
              </Link>
             
            </div>
            <div className="login-button-div">&nbsp; <p className="forget-paswword" style={{  color:"#001A62" }}>
            For any assistance please&nbsp;
    <a href="mailto:support@charlie.dealercontentgroup.com" className="contactAdmin">Contact admin</a>
</p></div>


          </Form>
         
        </div>
        <div className="box right-side-content">
          <div className="text">
            <p>
              "We are what we repeatedly do.
              <br />
              Excellence, then is not an act, but a habit"
            </p>
          </div>
          <div className="DSC-text">
            {/* <p>Powered by DSC India Private limited</p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.LoginSlice
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(login(credentials))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
