import React, { Component } from "react";
import { Col, ButtonToolbar, Button, Loader } from "rsuite";
import { connect } from "react-redux";
import SendIcon from "@rsuite/icons/Send";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";
import convertToUserTimeZone from "../../../../hooks/convertToUserTimeZone";

const override = css`
  display: inline-block;
  vertical-align: middle;
`;

class LeftSideDetails extends Component {
  render() {
    let {
      campaignByID: campaignById,
      emailByID,
      dataListById,
      suppression,
      suppressionList,
      text,
      display,
      schedule,
      send,
      loadingSubmit,
      timeStamp,
      isSent,
      throttleProgress,
    } = this.props;
    return (
      <Col md={11} className="defineproperties" style={{ marginLeft: 10 }}>
        <div className="background-color-table">
          <table className="emailDescription">
            <tbody>
              <tr>
                <td>
                  <b>Created by:</b>
                </td>
                <td>{campaignById.created_by} </td>
              </tr>
              <tr>
                <td>
                  <b>Created at:</b>
                </td>
                <td>{convertToUserTimeZone(campaignById.created_at)}</td>
              </tr>
              <tr>
                <td>
                  <b>Email Used:</b>
                </td>
                <td
                  style={{
                    color: emailByID === null ? "red" : "",
                  }}
                  className="camdefDetails"
                  title={emailByID.email_name}
                >
                  {emailByID !== null
                    ? emailByID.email_name
                    : "Selected Email has been deleted"}
                </td>
              </tr>
              <tr>
                <td >
                  <b>Subject Line:</b>
                </td>
                <td className="camdefDetails" title={campaignById.subject_line}>{campaignById.subject_line}</td>
              </tr>

              <tr>
                <td>
                  <b>Data Set :</b>
                </td>
                <td
                  style={{
                    color: dataListById === null ? "red" : "",
                  }}
                >
                  {dataListById === null
                    ? "Selected list has been deleted"
                    : dataListById.data_list_name}
                </td>
              </tr>

              <tr>
                <td>
                  <b>Data Set count:</b>
                </td>
                <td
                  style={{
                    color: dataListById === null ? "red" : "",
                  }}
                >
                  {dataListById === null
                    ? " Selected list has been deleted"
                    : this.props.count}
                </td>
              </tr>
              {suppression && (
                <tr>
                  <td>
                    <b>Suppression List : </b>
                  </td>
                  <td
                    style={{
                      color: suppressionList === null ? "red" : "",
                    }}
                  >
                    {suppressionList === null
                      ? "Selected list has been deleted"
                      : suppressionList.data_list_name}
                  </td>
                </tr>
              )}
              {suppression && (
                <tr>
                  <td>
                    <b>Suppression List Count : </b>
                  </td>
                  <td
                    style={{
                      color: suppressionList === null ? "red" : "",
                    }}
                  >
                    {suppressionList === null
                      ? "Selected list has been deleted"
                      : suppressionList.data_list_count}
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <b>Modified by:</b>
                </td>
                <td>{campaignById.last_modified_by} </td>
              </tr>
              <tr>
                <td>
                  <b>Modified at:</b>
                </td>
                <td> {convertToUserTimeZone(campaignById.last_modified_at)}</td>
              </tr>
              {campaignById.actual_time !== "" && (
                <tr>
                  <td>
                    <b>Scheduled at:</b>
                  </td>
                  <td>{convertToUserTimeZone(campaignById.actual_time)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {display && isSent !== 1 && Number(throttleProgress) === 0 && (
          <ButtonToolbar style={{ width: 300, marginTop: 30 }}>
            <Button
              onClick={send}
              loading={loadingSubmit}
              style={{ backgroundColor: "#415670", color: "#fff" }}
              title={text}
              disabled={
                emailByID === null ||
                dataListById === null ||
                timeStamp > 0 ||
                isSent === 1
              }
            >
              <SendIcon />
              &nbsp;&nbsp;<b>Send</b>
            </Button>

            <Button
              onClick={() => schedule()}
              style={{ backgroundColor: "#415670", color: "#fff" }}
              title="Schedule"
              disabled={isSent === 1}
            >
              &nbsp;&nbsp;<b>Schedule</b>
            </Button>
          </ButtonToolbar>
        )}
        {display && isSent !== 1 && Number(throttleProgress) !== 0 && (
          <div
            style={{
              marginTop: 30,
              textAlign: "center",
              width: "90%",
            }}
          >
            <ScaleLoader
              color={"#3195FD"}
              loading={true}
              css={override}
              height={35}
              width={4}
              radius={2}
              margin={2}
            />
            <p
              style={{
                marginTop: 10,
                color: "#555",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Campaign under process
            </p>
          </div>
        )}

        {display && isSent === 1 && (
          <div style={{ marginTop: 30, textAlign: "center", width: "90%" }}>
            <Link to={`/reports/campaign/email/${campaignById.campaign_id}`}>
              <Button
                color="green"
                style={{ backgroundColor: "#415670", color: "#fff" }}
                title="Schedule"
              >
                &nbsp;&nbsp;<b>Report</b>
              </Button>
            </Link>
          </div>
        )}
      </Col>
    );
  }
}

function mapStateToProps(state) {
  const {
    campaignByID,
    emailByID,
    dataListById,
    count,
    loadingById,
    suppressionList,
  } = state.CampaignSlice;

  return {
    campaignByID,
    emailByID,
    dataListById,
    count,
    suppressionList,
    loadingById,
  };
}

export default connect(mapStateToProps)(LeftSideDetails);
