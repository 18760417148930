import React, { Component } from "react";

import { connect } from "react-redux";

import { Col, Row, Input, Form, InputGroup, Loader } from "rsuite";
import "../../CreateNewProject/Email/assets/css/newemail.css";

import {
  feedDataField,
  selectLocation,
  feedDescription,
} from "../../Features/Data/DataSlice";

import DataFolder from "../../Folder/DataFolder";
import { isDataExist } from "../../Features/Data/DataThunk";
// import { keyPressed } from "../../Utils/keycode";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class CreateDataInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideError: false,
      showErrorOnPaste: false,
    };
  }

  selectFolder = (e) => {
    const { selectLocation } = this.props;
    selectLocation({ path: e.nodeData.id, errorInLocation: false });
  };

  handlePaste = (e) => {
    // Prevent default paste behavior
    e.preventDefault();
    this.setState({ showErrorOnPaste: true });
  };

  checkDataName = async () => {
    let { isDataExist, data } = this.props;
    let { dataName } = data;
    if (dataName.textName.trim().replace(/\s/g, "").length > 0)
      await isDataExist({
        dataName: dataName.textName,
        id: undefined,
      });
    this.setState({
      hideError: false,
    });
  };

  // KeyDownName = (e) => {
  //   let { alertRef, data } = this.props;
  //   if (data.dataName.textName.length > 32 && e.keyCode !== 8) {
  //     alertRef.current.showToaster({
  //       status: 0,
  //       message: "only 32 characters are allowed",
  //     });
  //     e.preventDefault();
  //   }

  //   let regexp = /^[a-zA-Z0-9-_]+$/;

  //   if (e.nativeEvent.key.search(regexp) === -1 || e.keyCode === 189) {
  //     alertRef.current.showToaster({
  //       status: 0,

  //       message:
  //         e.keyCode === 32
  //           ? "White Space is not allowed"
  //           : "Special Characters are not allowed",
  //     });

  //     e.preventDefault();
  //   }
  // };

  KeyDownName = (e) => {
    let { alertRef, data } = this.props;

    // Check if length exceeds 150 characters and not a backspace
    if (data.dataName.textName.length > 150 && e.keyCode !== 8) {
      alertRef.current.showToaster({
        status: 0,
        message: "Only 150 characters are allowed",
        duration: 1,
      });
      e.preventDefault();
      return;
    }

    // Regular expression to allow only letters, digits, hyphens, and underscores
    let regexp = /^[a-zA-Z0-9-_]+$/;

    // Check for invalid characters
    if (e.nativeEvent.key.search(regexp) === -1 || e.keyCode === 189) {
      alertRef.current.showToaster({
        status: 0,
        duration: 1,
        message:
          e.keyCode === 32
            ? "White Space is not allowed"
            : "Special Characters are not allowed",
      });
      e.preventDefault();
      return;
    }

    // If the first character entered is a number, prevent it and show an error message
    if (data.dataName.textName.length === 0 && /^\d$/.test(e.key)) {
      alertRef.current.showToaster({
        status: 0,
        message: "Data Name cannot start with a number",
        duration: 1,
      });
      e.preventDefault();
      return;
    }
  };

  render() {
    let { data, feedDataField, feedDescription } = this.props;
    let { dataName, description, location, loading } = data;
    let { hideError } = this.state;
    return (
      <Row className="create-new-project dataset">
        <Col md={24}>
          <Row>
            <div className="creat-email-form">
              <Form style={{ width: "100%", marginBottom: 50 }} fluid>
                <Col md={4}></Col>

                <Col md={16}>
                  <Form.Group>
                    Data Set Name*
                    <InputGroup inside className="input-group-form">
                      <Form.Control
                        name="datalistName"
                        placeholder=""
                        defaultValue={dataName.textName}
                        onChange={(value) => {
                          feedDataField({
                            textName: value.trimStart(),
                            errorInName: false,
                            isExistError: false,
                            errMessageName: null,
                          });
                          // Hide the paste error message when the user starts typing
                          this.setState({ showErrorOnPaste: false });
                        }}
                        className="new-email-text"
                        onFocus={() => this.setState({ hideError: true })}
                        autoComplete="off"
                        style={{ border: "none !important" }}
                        onBlur={this.checkDataName}
                        onKeyDown={(e) => this.KeyDownName(e)}
                        onPaste={this.handlePaste}
                      />

                      {loading && (
                        <InputGroup.Addon style={{ padding: "8px 12px" }}>
                          <Loader />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${
                          (dataName.errorInName || dataName.isExistError) &&
                          !hideError
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      {dataName.errMessageName}
                    </span>
                    {this.state.showErrorOnPaste ? (
                      <span style={{ color: "red" }}>
                        Pasting is not allowed
                      </span>
                    ) : null}
                  </Form.Group>
                  Location*
                  <div
                    className="location"
                    placeholder="Location"
                    style={{
                      background: "#fff",
                      padding: 5,
                      overflowY: "auto",
                    }}
                  >
                    <DataFolder
                      edit={false}
                      location={location.path}
                      selectFolder={this.selectFolder}
                      setCancelDataFolder={(click) =>
                        (this.clickDataFolder = click)
                      }
                    />
                  </div>
                  <span
                    className="custom-error-fields"
                    style={{
                      display: `${
                        location.errorInLocation && !location.path
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    This field is Required
                  </span>
                  <Form.Group style={{ marginTop: 22 }}>
                    <Form.ControlLabel>Description*</Form.ControlLabel>
                    <Form.Control
                      rows={10}
                      accepter={Textarea}
                      defaultValue={description.textDes}
                      name="description"
                      onChange={(value) =>
                        feedDescription({
                          textDes: value.trimStart(),
                          errorInDescription: false,
                          errMessageDes: null,
                        })
                      }
                      placeholder=""
                      className=""
                      style={{ borderRadius: 0 }}
                    />
                  </Form.Group>
                  <span
                    className="custom-error-fields"
                    style={{
                      display: `${
                        description.errorInDescription ? "block" : "none"
                      }`,
                    }}
                  >
                    {description.errMessageDes}
                  </span>
                </Col>
                <Col md={4}></Col>
              </Form>
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.DataSlice,
});

const mapDispatchToProps = (dispatch) => ({
  // reset: () => dispatch(reset()),
  feedDataField: (textName, errorInName, isExistError, errMessageName) =>
    dispatch(
      feedDataField(textName, errorInName, isExistError, errMessageName)
    ),

  selectLocation: ({ path, errorInLocation }) => {
    dispatch(selectLocation({ path, errorInLocation }));
  },
  feedDescription: (textDes, errorInDescription, errMessageDes) =>
    dispatch(feedDescription(textDes, errorInDescription, errMessageDes)),
  isDataExist: ({ dataName, id }) => {
    dispatch(isDataExist({ dataName, id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDataInfo);
