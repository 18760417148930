import { createSlice } from "@reduxjs/toolkit";
import { fetchEmailFolder } from "./EmailFolderThunk";

const EmailFolderSlice = createSlice({
  name: "email-folder",
  initialState: {
    loading: false,
    emailFolder: [],
    error: null,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailFolder.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchEmailFolder.fulfilled, (state, action) => {
        state.emailFolder = action.payload.data.email;
        state.success = true;
        state.loading = false;
      })

      .addCase(
        fetchEmailFolder.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.emailFolder = [];
        }
      );
  }
});

export default EmailFolderSlice.reducer;
