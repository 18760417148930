import React, { Component, forwardRef } from "react";
import { validate } from "email-validator";
import { Col, Row,toaster } from "rsuite";
import { connect } from "react-redux";
import { Nav } from "rsuite";

import { FileUpload } from "@rsuite/icons";
import { Trash } from "@rsuite/icons";
import EditIcon from "@rsuite/icons/Edit";
import MaterialTable from "material-table";
import CampaignPropertiesSlice from "../../Features/CampaignManagement/CampaignPropertiesSlice";
import Notification from 'rsuite/Notification';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  Edit,
  ViewColumn,
} from "@material-ui/icons";

import AboutDataSet from "./AboutDataSet";
import {
  getDataById,
  fetchRecord,
  uploadData,
  dataModification,
} from "../../Features/Data/DataThunk";
import Loading from "../../Loading/Loading";
import UploaderFile from "./UploaderFile";

import DataService from "./DataService";


const message = (
  <Notification type={'warning'} header={'warning'} closable>
   <p>Data entry cannot be deleted as it is associated with a campaign.</p>
  </Notification>
);


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const CustomNav = ({ active, id, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      className="nav-item-edit"
      onSelect={onSelect}
      activeKey={active}
    >
      <Nav.Item
        as="li"
        eventKey="inline"
        className="nav-item-data"
        title="Inline-Edit"
        icon={<EditIcon />}
      >
        <b>In-line edit</b>
      </Nav.Item>
      <Nav.Item
        as="li"
        eventKey="upload"
        className="nav-item-data"
        title="Upload file"
        icon={<FileUpload />}
      >
        <b>Upload file</b>
      </Nav.Item>
    </Nav>
  );
};

class DataOperation extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isRecords: false,
      records: [],
      fieldName: [],
      active: "inline",
      file: null,
      preview: false,
      duplicate: 0,
      validated: 0,
      tableStructure: null,
      removedRowsWithHeaders: null,
      duplicateEmails: null,
      loadingCsv: false,
    };
    this.props.export(this.exportCsv);
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchRecord();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchRecord = async () => {
    try {
      let { fetchRecordAction, id } = this.props;
      this.setState({
        loading: true,
      });
      let response = await fetchRecordAction(id);
      // console.warn("All fetch data res:::", response);
      if (this._isMounted) {
        let { data } = response.payload;
        if (data.table.length > 0) {
          this.setState({ tableStructure: data.tableStructure[0] });
          await this.fieldArray(data.table[0], data.records, data.table);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  fieldArray = async (records, isRecords, table) => {
    let mainFieldArray = [];
    let fieldArray = isRecords === true ? Object.keys(records) : table;
    for (const key of fieldArray) {
      if (key !== "material_table_id")
        mainFieldArray.push({ title: key, field: key });
    }
    this.setState({
      loading: false,
      fieldArray,
      records: isRecords ? table : [],
      fieldName: mainFieldArray,
      preview: false,
      active: "inline",
    });
  };

  openPreview = async () => {
    const tableStructure = this.state.tableStructure;

    // console.warn("table stru:::", tableStructure);
    let { data_list_email_field } = this.props.dataById;
    let res = this.props.dataById;
    // console.log("all res", res);
    // console.log("data list", { data_list_email_field });
    let { alertRef } = this.props;
    // console.log("all ref", { alertRef });
    this.setState({
      loadingCsv: true,
    });
    let main = await DataService.createPreview(
      this.state.file,
      data_list_email_field,
      this.state.fieldArray
    );
    let {
      heading,
      body,
      duplicate,
      emailFieldIndex,
      validated,
      isFieldsAreSame,
    } = main;
    if (!isFieldsAreSame) {
      alertRef.current.showToaster({
        status: 0,
        duration: 3,
        message: "Data Headers doesn't match",
      });
      return;
    }
    if (emailFieldIndex === -1) {
      alertRef.current.showToaster({
        status: 0,
        duration: 3,
        message: "Email Header doesn't match",
      });
      return;
    }
    let records = [],
      obj = {};

    //SECTION - Updating body to assign null for empty filed values.

    let updatedBody = [];

    // Adjust the logic to handle empty fields and set them to null
    // for (let i = 0; i < body.length; i++) {
    //   let processedRow = {};
    //   for (let j = 0; j < heading.length; j++) {
    //     let processedValue =
    //       body[i][j] !== undefined && body[i][j] !== "" ? body[i][j] : "";
    //     processedRow[heading[j]] = processedValue;
    //   }
    //   updatedBody.push(processedRow);
    // }

    for (let i = 0; i < body.length; i++) {
      let processedRow = {};
      for (let j = 0; j < heading.length; j++) {
          let processedValue;
  
          // Check the data type from tableStructure or another source
          let dataType = tableStructure[j].DataType; // Assuming tableStructure holds information about the data types
  
          // Set processedValue based on the data type
          switch (dataType) {
              case "integer":
              case "decimal":
                  processedValue = body[i][j] !== undefined && body[i][j] !== "" ? body[i][j] : 0;
                  break;
              case "varchar":
              case "text":
                  processedValue = body[i][j] !== undefined ? body[i][j] : ""; // Set empty string for undefined values
                  break;
              // Add cases for other data types if needed
              default:
                  // For other data types, you might want to handle differently or set to null
                  processedValue = body[i][j] !== undefined ? body[i][j] : null;
                  break;
          }
  
          processedRow[heading[j]] = processedValue;
      }
      updatedBody.push(processedRow);
  }



    // console.log(updatedBody);
    let updatedBodyArray = updatedBody.map((row) => Object.values(row));
    // console.log(updatedBodyArray);

    // console.log(body)

    // console.log(records)

    //get the data here,
    //Todo- API call here, and get table structure from here,

    let dbCheck = await DataService.dbCheck(
      this.state.records,
      updatedBodyArray,
      heading,
      duplicate,
      tableStructure,
      data_list_email_field,
      emailFieldIndex
    );
    if (body.length > 0) {
      for (var i = 0; i < body.length; i++) {
        for (var j = 0; j < heading.length; j++) {
          obj[heading[j]] = body[i][j];
        }
        records.push(obj);
      }
      this.setState({
        preview: true,
        loadingCsv: false,
        records,
        heading,
        removedRowsWithHeaders: dbCheck.removedRowsWithHeaders,
        duplicateEmails: dbCheck.duplicateEmails,
        duplicate: dbCheck.duplicate,
        validated: validated,
        values: dbCheck.updatedValues,
        tableStructure,
      });
    } else {
      obj = {};
      for (j = 0; j < heading.length; j++) {
        obj[heading[j]] = heading[j];
      }
      records.push(obj);
      this.setState({
        preview: true,
        records,
        values: false,
        duplicate: 0,
        removedRowsWithHeaders,
        duplicateEmails,
        validated: 0,
        loadingCsv: false,
        tableStructure,
      });
    }
  };

  upload = async () => {
    // console.log(this.state.values);
    try {
      let { id, UploadDataAction, alertRef } = this.props;

      let data = {
        id,
        heading: this.state.heading,
        body: this.state.values,
      };
      this.setState({
        loading: true,
      });
      let response = await UploadDataAction({ data, id });
      // console.log("response::::::::::::", response);

      await this.fetchRecord();

      alertRef.current.showToaster(response.payload);
    } catch (e) {
      console.error("e::::::::::", e);
    }
  };

  exportCsv = () => {
    let { dataById, structureById } = this.props;
    DataService.exportFieldToCsv(dataById, structureById, this.state.records);
  };

  modification = async (details) => {
    try {
      let { dataModification, alertRef, id, getDataById } = this.props;
      this.setState({
        loading: true,
      });
      let response = await dataModification(details);
      await getDataById({
        type: "byId",
        value: id,
      });
      await this.fetchRecord();

      alertRef.current.showToaster(response.payload);
    } catch (e) {
      console.error("e::::::::::", e);
    }
  };

  // document.getElementById("column_ID_or_something_here").scrollIntoView(true);
  render() {
    let {
      loading,
      records,
      fieldName,
      active,
      preview,
      file,
      values,
      validated,
      duplicate,
      loadingCsv,
      removedRowsWithHeaders,
      duplicateEmails,
      tableStructure,
    } = this.state;
    const { campaignPropertiesCheckedInDataId } = this.props;
    if (loading) return <Loading />;
    // console.log(records);
    // console.log(fieldName);
    // console.log("LOLO", this.props);
    return (
      <Row className="create-new-project">
        <Col md={24}>
          <Row>
            <Col md={8} className="description-left-side">
              <AboutDataSet />
            </Col>
            <Col md={1}></Col>

            <Col
              md={14}
              className="block-data-list"
              style={{ marginTop: 25, overflowY: "auto" }}
             
            >
              <Col md={24}>
                <CustomNav
                  appereance="default"
                  onSelect={async (e) => {
                    this.setState({
                      active: e,
                    });
                    if (e === "inline") {
                      if (file !== null) await this.fetchRecord();
                      this.setState({
                        preview: false,
                        file: null,
                      });
                    } else {
                      this.setState({
                        preview: false,
                        file: null,
                      });
                    }
                  }}
                  active={active}
                  records={this.state.records}
                />
              </Col>
              <Col md={24}>
                {active === "inline" ? (
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      columns={fieldName}
                      data={records}
                      title="Data Preview"
                      options={{
                        paging: true,
                        search: true,
                        selection: true,
                      }}
                      style={{
                        margin: 10,
                        overflow: "auto",
                      }}
                      actions={[
                        {
                          tooltip: "Remove All Selected records",
                          icon: DeleteOutline,
                          disabled: campaignPropertiesCheckedInDataId,
                          onClick: async (evt, rowData) => {
                            var detail = {
                              updateData: rowData,
                              actionType: "DELETE",
                              Multiple: true,
                              data_list_id: this.props.id,
                            };
                            this.modification(detail);
                          },
                        },
                      ]}
                      editable={{
                        onRowAdd: async (newData) => {
                          let { alertRef } = this.props;

                          if (!validate(newData.email)) {
                            alertRef.current.showToaster({
                              status: 0,
                              message: "Invalid Email Format",
                              duration: 3,
                            });

                            return;
                          }
                          this.setState({
                            records: [...records, newData],
                          });
                          var detail = {
                            updateData: newData,
                            actionType: "ADD",
                            data_list_id: this.props.id,
                            fieldArray: this.state.fieldArray,
                          };
                          this.modification(detail);
                        },
                        onRowUpdate: async (newData, oldData) => {
                          let { alertRef } = this.props;

                          if (!validate(newData.email)) {
                            alertRef.current.showToaster({
                              status: 0,
                              duration: 3,
                              message: "Invalid Email Format",
                            });

                            return;
                          }

                          const dataUpdate = [...records];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          this.setState({
                            records: [...dataUpdate],
                          });
                          var detail = {
                            updateData: newData,
                            actionType: "UPDATE",
                            data_list_id: this.props.id,
                          };
                          this.modification(detail);
                        },
                        onRowDelete: async (oldData) => {
                          const { campaignPropertiesCheckedInDataId } =
                            this.props;
                            if(campaignPropertiesCheckedInDataId){
                              toaster.push(message, { placement: 'topCenter' });
                            }
                          if (!campaignPropertiesCheckedInDataId) {

                           
                            const dataDelete = [...records];
                            const index = oldData.tableData.id;
                            const materialID =
                              dataDelete[index].material_table_id;
                            dataDelete.splice(index, 1);
                            this.setState({
                              resultArray: [...dataDelete],
                            });
                            var detail = {
                              updateData: materialID,
                              actionType: "DELETE",
                            
                              Multiple: false,
                              data_list_id: this.props.id,
                            };
                            this.modification(detail);
                          }
                        },
                      }}
                    />
                  </>
                ) : (
                  <UploaderFile
                    preview={preview}
                    file={file}
                    fileChange={(value) =>
                      this.setState({
                        file: value[value.length - 1],
                        values: true,
                        value,
                      })
                    }
                    records={records}
                    values={values}
                    openPreview={this.openPreview}
                    remove={() => {
                      document.getElementById("myForm").reset();
                      this.setState({ file: null });
                    }}
                    back={() =>
                      this.setState({
                        preview: false,
                        records: [],
                      })
                    }
                    upload={this.upload}
                    removedRowsWithHeaders={removedRowsWithHeaders}
                    duplicateEmails={duplicateEmails}
                    validated={validated}
                    duplicate={duplicate}
                    loadingCsv={loadingCsv}
                    columnNames={fieldName}
                    tableStructure={tableStructure}
                  />
                )}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  const { dataById, structureById } = state.DataSlice;
  const { campaignPropertiesCheckedInDataId } = state.CampaignPropertiesSlice;
  return {
    dataById,
    structureById,
    campaignPropertiesCheckedInDataId,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getDataById: (type, value) => dispatch(getDataById(type, value)),
  fetchRecordAction: (id) => dispatch(fetchRecord(id)),
  UploadDataAction: ({ data, id }) => dispatch(uploadData(data, id)),
  dataModification: (details) => dispatch(dataModification(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataOperation);
