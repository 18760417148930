import React, { Component } from "react";

import { connect } from "react-redux";

import { Alert } from "../Utils/Alert";
import { Loader, Placeholder } from "rsuite";
import {
  fetchImageFolder,
  createImageFolder,
  deleteImageFolder,
  renameImageFolder
} from "../Features/Folder/ImageFolder/ImageFolderThunk";
import { TreeViewComponent, ContextMenuComponent } from "@syncfusion/ej2-react-navigations";

class ImageFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedFolder: null,
      loading: false,
      type: "",
      id: ""
    };

    this.menuclick = this.menuclick.bind(this);
    this.tree = false;
    this.treeObj = null;
    this.menuItems = [{ text: "Add New Item" }, { text: "Rename Item" }, { text: "Remove Item" }];
    this.index = 1;
    this.node = null;

    // console.log(this.props);
    this.props.clickImage(this.deselectFolder);
    this.alertRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isCreated) {
      return {
        ...nextProps,
        formValue: {
          name: "",
          parentId: ""
        },
        showModal: false
      };
    } else {
      return {
        ...nextProps
      };
    }
  }

  onCreated() {
    this.tree = true;
    this.treeRender();
  }

  deselectFolder = () => {
    this.treeObj.selectedNodes = [];
  };

  async componentDidMount() {
    const { fetchImageFolder } = this.props;
    await fetchImageFolder();
    await this.treeRender();
  }

  async treeRender() {
    let { FolderSlice, edit, location } = this.props;
    let { imageFolder } = FolderSlice;
    if (this.treeObj === null || this.tree === false) return;
    let mainExpendable = [];
    for (let i = 0; i < imageFolder.length; i++) {
      if (imageFolder[i].parentId === null) {
        mainExpendable.push(`${imageFolder[i].id}`);
      }
    }
    this.treeObj.expandedNodes = mainExpendable;
    if (edit) {
      let selected = [];
      selected.push(location.toString());
      this.treeObj.selectedNodes = selected;
    }
  }

  targetExpansion = (payload) => {
    console.log(this.treeObj, this.tree);
    if (this.treeObj === null || this.tree === false) return;
    let expandablefolder = [];

    for (let i = 0; i < payload.data.result.length; i++) {
      expandablefolder.push(`${payload.data.result[i].id}`);
    }
    console.log(expandablefolder);
    this.treeObj.expandedNodes = expandablefolder;
  };

  createFolder = async (folderData) => {
    try {
      let { currentSelectedFolder } = this.state;
      const { dispatch, fetchImageFolder } = this.props;
      this.setState({
        loading: true
      });
      let create = await dispatch(
        createImageFolder({
          name: folderData.newText,
          parentId: currentSelectedFolder,
          is_folder_or_file: "folder"
        })
      );
      let { payload } = create;
      await fetchImageFolder();
      this.setState({
        loading: false
      });
      // let message = {
      //   status: payload.status === 1 ? "success" : "error",
      //   message: payload.message,
      // };

      this.alertRef.current.showToaster(payload);
      if (payload.status === 1) {
        this.targetExpansion(payload);
      } else {
        this.treeRender();
      }
      this.setState({
        type: ""
      });
    } catch (e) {
      console.log(e);
    }
  };

  deleteFolder = async (targetNodeId) => {
    var parentID = this.treeObj.treeData[0].id;

    let { dispatch, fetchImageFolder } = this.props;
    try {
      this.setState({
        loading: true
      });
      if (parentID === +targetNodeId) {
        let message = {
          status: "error",
          message: "Cannot Delete the Parent Folder"
        };
        this.alertRef.current.showToaster(message);
        this.setState({
          loading: false
        });
      } else {
        let deletefolder = await dispatch(deleteImageFolder(targetNodeId));
        let { payload } = deletefolder;
        await fetchImageFolder();
        this.setState({
          loading: false
        });
        this.alertRef.current.showToaster(payload);
      }
      this.treeRender();
    } catch (e) {
      console.log(e);
    }
  };

  menuclick(args) {
    let targetNodeId = this.node;
    if (args.item.text === "Add New Item") {
      this.setState({
        type: "add"
      });
      let nodeId = "tree_" + this.index;
      this.setState({ currentSelectedFolder: targetNodeId });
      let item = { id: nodeId, name: "New Folder" };
      this.treeObj.addNodes([item], targetNodeId, null);
      this.index++;
      this.treeObj.beginEdit(nodeId);
    } else if (args.item.text === "Remove Item") {
      this.deleteFolder(targetNodeId);
      console.log("Remove Item");
    } else if (args.item.text === "Rename Item") {
      this.setState({
        type: "rename",
        id: targetNodeId
      });
      this.treeObj.beginEdit(targetNodeId);
      console.log("Rename Item");
    }
    // console.log(this.menuObj);
  }

  renameFolder = async (folderData) => {
    try {
      let { id } = this.state;
      const { dispatch, fetchImageFolder } = this.props;
      this.setState({
        loading: true
      });
      let rename = await dispatch(
        renameImageFolder({
          id,
          text: folderData.newText
        })
      );
      let { payload } = rename;

      await fetchImageFolder();
      this.setState({
        type: "",
        id: "",
        loading: false
      });
      // let message = {
      //   status: payload.status === 1 ? "1" : "error",
      //   message: payload.message,
      // };
      this.alertRef.current.showToaster(payload);
      if (payload.status === 1) {
        this.targetExpansion(payload);
      } else {
        this.treeRender();
      }
    } catch (e) {}
  };

  beforeopen(args) {
    if (args.event.target.closest("li"))
      this.node = args.event.target.closest("li").getAttribute("data-uid");
  }

  render() {
    let { FolderSlice, from } = this.props;
    let { imageFolder } = FolderSlice;
    console.log();
    let { loading } = this.state;

    this.hierarchicalData = imageFolder;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Alert ref={this.alertRef} />
        {!loading ? (
          <>
            {imageFolder && imageFolder.length > 0 && (
              <>
                <TreeViewComponent
                  // className="folder-structure-sync-assets"
                  className={`${
                    from !== "assets" ? "folder-structure-sync" : "folder-structure-sync-assets"
                  }`}
                  allowEditing={false}
                  nodeSelected={this.props.selectFolder}
                  nodeEdited={(fsd) => {
                    if (this.state.type === "add") {
                      this.createFolder(fsd);
                    } else {
                      this.renameFolder(fsd);
                    }
                  }}
                  id="treeImages"
                  created={this.onCreated.bind(this)}
                  ref={(list) => {
                    this.treeObj = list;
                  }}
                  fields={{
                    dataSource: this.hierarchicalData,
                    id: "id",
                    text: "name",
                    child: "children",
                    iconCss: "is_folder_or_file"
                  }}
                />
                {from !== "emailEditor" && (
                  <ContextMenuComponent
                    id="contentmenutreetreeImages"
                    target="#treeImages"
                    items={this.menuItems}
                    beforeOpen={this.beforeopen.bind(this)}
                    select={this.menuclick.bind(this)}
                    ref={(contextmenu) => {
                      this.menuObj = contextmenu;
                    }}
                  />
                )}
              </>
            )}{" "}
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={8} />
            <Loader backdrop content="loading..." vertical />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  FolderSlice: state.ImageFolderSlice
  // loading: state.data.loading,
  // error: sate.data.error,
});
const mapDispatchToProps = (dispatch) => ({
  fetchImageFolder: () => dispatch(fetchImageFolder()),
  dispatch
});
//exporting the app and mapping the redux state to the App Component
export default connect(mapStateToProps, mapDispatchToProps)(ImageFolder);
