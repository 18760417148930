import React, { Component } from "react";
import { ThemeContext, themes } from "../Utils/Theme/ThemeContext";

import { Col, Dropdown, Avatar } from "rsuite";
import { connect } from "react-redux";
// import SearchIcon from "./assets/icons/search.png";
import bell from "./assets/icons/notification.png";

import "./assets/css/headerTop.css";
import { collapseSidebar } from "../Utils/Paths/Paths";

import logoWhite from "../Navbar/assets/icons/Charlie_logo_white.png";
import logo from "../Navbar/assets/icons/Charlie_logo_black.png";

import { Link } from "react-router-dom";
import { logout } from "../Features/Login/LoginThunk";

import { themeChange } from "../Features/Login/LoginSlice";

class HeaderTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      lightMode: localStorage.getItem("theme") === "dark" ? false : true,
    };
  }

  componentDidMount() {
    let { userInfo } = this.props;
    var fname, lname;

    if (userInfo.user) {
      if (
        userInfo.user.user_first_name !== "" ||
        userInfo.user.user_first_name !== null
      ) {
        fname = userInfo.user.user_first_name;
        lname = userInfo.user.user_last_name;
      }
    } else {
      fname = userInfo.isExist.user_first_name;
      lname = userInfo.isExist.user_last_name;
    }

    var userName = fname.charAt(0) + lname.charAt(0);
    this.setState({
      userName: userName,
    });
  }

  render() {
    let { lightMode } = this.state;
    let { location, dynamicRoute, logout, userInfo, themeChange } = this.props;

    return (
      <div>
        <Col
          md={
            collapseSidebar.includes(location) || dynamicRoute !== null
              ? 18
              : 24
          }
          className="nav-menu-profile"
        >
          <div className="nav-menu-home">
            <Col md={24}>
              <Dropdown
                placement="bottomEnd"
                className="user-avatar"
                title="PROFILE"
              >
                <Link to="/accountmanagement">
                  <Dropdown.Item>Account Management</Dropdown.Item>
                </Link>
                {userInfo.user.role === "admin" && (
                  <Link to="/usermanagement">
                    <Dropdown.Item>User Management</Dropdown.Item>
                  </Link>
                )}

                <Link to="/settings/ip">
                  <Dropdown.Item>Setting</Dropdown.Item>
                </Link>

                {/* <Dropdown.Item>
                  <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <div className="modeType">
                        <input
                          type="checkbox"
                          onChange={() => {
                            this.setState({ lightMode: !lightMode });
                            changeTheme(lightMode ? themes.dark : themes.light);
                            themeChange(lightMode ? themes.dark : themes.light);
                          }}
                          className="checkbox"
                          id="checkbox"
                          checked={!lightMode}
                        ></input>
                        <label htmlFor="checkbox" className="label">
                          <i
                            className="fa fa-sun-o"
                            style={{ color: "#ffffff", paddingRight: 10 }}
                          ></i>{" "}
                          <i
                            className="fa fa-moon-o"
                            style={{ color: "#ffffff" }}
                          ></i>
                          <div className="ball"></div>
                        </label>
                      </div>
                    )}
                  </ThemeContext.Consumer>
                </Dropdown.Item> */}

                <Dropdown.Item
                  onClick={() => logout({ id: userInfo.user.user_id })}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown>
            </Col>
          </div>
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}

export default connect(mapStateToProps, { logout, themeChange })(HeaderTop);
