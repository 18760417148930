import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Dropdown } from "rsuite";
import { Detail, Page, createIconFont } from "@rsuite/icons";

import { Routes, Route, matchRoutes, Link } from "react-router-dom";
import { emailReport } from "../Features/Reports/ReportThunk";
import CampaignReporting from "./CampaignReport/CampaignReporting";
import EmailReport from "./CampaignReport/Email/EmailReport";
import { setViewType } from "../Features/Asset/AssetSlice";

import {
  reportLocationCommon,
  dynamicCollapseSidebar,
} from "../Utils/Paths/Paths";

import EmailById from "./CampaignReport/Email/EmailById";

const dynamicRoutes = (location) => {
  const dynamicRoute = matchRoutes(dynamicCollapseSidebar, location);
  return dynamicRoute;
};

const IconFont = createIconFont({
  scriptUrl: "//at.alicdn.com/t/font_2144422_r174s9i1orl.js",

  // onLoaded: () => {
  //   console.log("onLoaded");
  // },
});

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      record: {},
      emailId: "",
    };
  }

  render() {
    let { location, setViewType, assetSlice } = this.props;
    let { loading } = this.state;
    let path = dynamicRoutes(location);
    return (
      <div>
        {"/reports/campaign" !== location && (
          <Row className="assets_recent_emails">
            <Col md={12} style={{ marginTop: 12 }}>
              <Link to="/reports/campaign/email/" className="link-color-font">
                <h5>{reportLocationCommon[path[0].params.type]}</h5>
              </Link>
            </Col>
            {"/reports/campaign/email" === location && (
              <Col md={12} style={{ textAlign: "right" }}>
                <i
                  className={`bi bi-grid-3x3-gap-fill font-size-icon icon-color active-icon-color
                   `}
                  title="Grid"
                  style={{
                    cursor: "pointer",
                    color: `${assetSlice.isGrid ? "#1e8fe1" : ""}`,
                  }}
                  onClick={() => setViewType("grid")}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i
                  className="bi-hdd-stack-fill font-size-icon icon-color"
                  title="List"
                  style={{
                    cursor: "pointer",
                    color: `${!assetSlice.isGrid ? "#1e8fe1" : ""}`,
                  }}
                  onClick={() => setViewType("list")}
                />
                &nbsp;
              </Col>
            )}

            {/* CAMPAIGN BY ID */}

            {path[0].params.type === "email" &&
              path[0].params.id !== undefined &&
              !loading && (
                <Col md={12} style={{ textAlign: "right" }}>
                  <Dropdown
                    title="More"
                    icon={<Page />}
                    placement="bottomEnd"
                    className="dropdown-report-more"
                  >
                    <Dropdown.Item
                      icon={<IconFont icon="rs-iconimage" />}
                      onClick={() => this.exportPng()}
                    >
                      Export as Image
                    </Dropdown.Item>

                    <Dropdown.Item
                      icon={<Detail />}
                      onClick={() => this.exportXlsx()}
                    >
                      Export Report
                    </Dropdown.Item>
                  </Dropdown>
                </Col>
              )}
          </Row>
        )}

        <Routes>
          <Route path="campaign" index element={<CampaignReporting />} />
          <Route path="campaign/email" element={<EmailReport />} />
          {path !== null && (
            <Route
              path="campaign/email/:id"
              element={
                <EmailById
                  id={path[0].params.id}
                  clickPng={(click) => (this.exportPng = click)}
                  clickXlsx={(click) => (this.exportXlsx = click)}
                  setLoading={() =>
                    this.setState({
                      loading: false,
                    })
                  }
                />
              }
            />
          )}
        </Routes>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  assetSlice: state.AssetSlice,
  userInfo: state.LoginSlice.userInfo,
});
// const mapDispatchToProps = (dispatch) => ({
//   setViewType: (value) => dispatch(setViewType(value)),
// });
export default connect(mapStateToProps, { emailReport, setViewType })(
  Reporting
);
