import React, { Component } from "react";
import { Col, Row, Affix } from "rsuite";
import { Link, Routes, Route } from "react-router-dom";
import email from "./assets/icons/Emails.png";
import emailWhite from "./assets/icons/email-white.png";
import image from "./assets/icons/images.png";
import imageWhite from "./assets/icons/image-white.png";
import template from "./assets/icons/templates.png";
import templateWhite from "./assets/icons/template-white.png";
// import digitalmedia from "./assets/icons/Digital_media.png";
// import digitalWhite from "./assets/icons/digital-white.png";

import DataArchive from "../Navbar/assets/icons/Data Archive.png";
import DataArchiveWhite from "../Navbar/assets/icons/Data_archive_white.png";

import CreateCampaign from "../Navbar/assets/icons/Create campaign.png";
import CreateCampaignWhite from "../Navbar/assets/icons/Create_campaign_white.png";

// import socialmedia from "./assets/icons/Social_media.png";
// import socialWhite from "./assets/icons/social-white.png";
import "./assets/css/assetsarchive.css";
import Recent from "./Recent/Recent";
import Templates from "./Template/Templates";
import {
  collapseSidebar,
  showHideComponentAssets,
  assetLocationCommon,
} from "../Utils/Paths/Paths";
import Emails from "./Email/Emails";
// import Images from "./Image/Images";

import { connect } from "react-redux";

import { setViewType } from "../Features/Asset/AssetSlice";

import DataList from "../DataList/DataList";

import Campaign from "./Campaign/Campaign";
import ErrorPage from "../errorPage/ErrorPage";

class AssetsArchive extends Component {
  render() {
    let {
      user: { access },
    } = this.props;
    let userAccess = JSON.parse(JSON.parse(access));
    // console.log(userAccess);
    let { location, setViewType, assetSlice } = this.props;
    return (
      <div
        className={`${
          collapseSidebar.includes(location) ? "content-container" : ""
        }`}
      >
        {/* <h2 className="hero-head">ASSETS ARCHIVE</h2> */}
        {/* <Affix top={97}> */}
        {/* {userAccess.includes(showHideComponentAssets[location]) ||
          ("/assetarchive" === location && ( */}
        <Row className="asset-archive-option ">
          {userAccess.includes("template") && (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: "center" }}
              className={
                location === "/assetlibrary/templates"
                  ? "content-nav-asset active-asset"
                  : "content-nav-asset"
              }
            >
              <Link to="templates" className="link-color-font">
                {/* <picture>
                  <source
                    srcSet={templateWhite}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Templates"
                    className="dash-icon-dynamic"
                  />

                  <img
                    src={template}
                    alt="Templates"
                    className="dash-icon-dynamic"
                  />
                </picture> */}
                &nbsp;&nbsp; <b>Templates</b>
              </Link>
            </Col>
          )}
          {userAccess.includes("email") && (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: "center" }}
              className={
                location === "/assetlibrary/emails"
                  ? "content-nav-asset active-asset"
                  : "content-nav-asset"
              }
            >
              <Link to="emails" className="link-color-font">
                {/* <picture>
                  <source
                    srcSet={emailWhite}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Emails"
                    className="dash-icon-dynamic"
                  />

                  <img src={email} alt="Emails" className="dash-icon-dynamic" />
                </picture> */}
                &nbsp;&nbsp; <b>Emails</b>
              </Link>
            </Col>
          )}

          {/* {userAccess.includes("image") && (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: "center" }}
              className={
                location === "/assetlibrary/images"
                  ? "content-nav-asset active-asset"
                  : "content-nav-asset"
              }
            >
              <Link to="images" className="link-color-font">
                <picture>
                  <source
                    srcSet={imageWhite}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Images"
                    className="dash-icon-dynamic"
                  />

                  <img src={image} alt="Images" className="dash-icon-dynamic" />
                </picture>
                &nbsp;&nbsp; Images
              </Link>
            </Col>
          )} */}
          {userAccess.includes("data") && (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: "center" }}
              className={
                location === "/assetlibrary/data"
                  ? "content-nav-asset active-asset"
                  : "content-nav-asset"
              }
            >
              <Link to="data" className="link-color-font">
                {/* <picture>
                  <source
                    srcSet={DataArchiveWhite}
                    media="(prefers-color-scheme: dark)"
                    alt="Data Archive"
                    className="dash-icon-dynamic"
                  />
                  <img
                    src={DataArchive}
                    alt="Data Archive"
                    className="dash-icon-dynamic"
                  />
                </picture> */}
                &nbsp;&nbsp; <b>Data Set</b>
              </Link>
            </Col>
          )}
          {userAccess.includes("campaign") && (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: "center" }}
              className={
                location === "/assetlibrary/campaign"
                  ? "content-nav-asset active-asset"
                  : "content-nav-asset"
              }
            >
              <Link to="campaign" className="link-color-font">
                {/* <picture>
                  <source
                    srcSet={CreateCampaignWhite}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="Social-Medias"
                    className="dash-icon-dynamic"
                  />

                  <img
                    src={CreateCampaign}
                    alt="Social-Medias"
                    className="dash-icon-dynamic"
                  />
                </picture> */}
                &nbsp;&nbsp;<b>Campaign</b>
              </Link>
            </Col>
          )}
        </Row>
        {/* ))} */}
        {/* </Affix> */}

        <div>
          {"/assetlibrary/images" !== location &&
            "/assetlibrary" !== location &&
            userAccess.includes(showHideComponentAssets[location]) && (
              <Row className="assets_recent_emails">
                <Col md={12}>
                  <h5>{assetLocationCommon[location]}</h5>
                </Col>
                {location !== "/assetlibrary/data" && (
                  <Col md={12} style={{ textAlign: "right" }}>
                    <i
                      className={`bi bi-grid-3x3-gap-fill font-size-icon icon-color active-icon-color
                   `}
                      title="Grid"
                      style={{
                        cursor: "pointer",
                        color: `${assetSlice.isGrid ? "#1e8fe1" : ""}`,
                      }}
                      onClick={() => setViewType("grid")}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i
                      className="bi-hdd-stack-fill font-size-icon icon-color"
                      title="List"
                      style={{
                        cursor: "pointer",
                        color: `${!assetSlice.isGrid ? "#1e8fe1" : ""}`,
                      }}
                      onClick={() => setViewType("list")}
                    />
                    &nbsp;
                  </Col>
                )}
              </Row>
            )}
          <Routes>
            <Route index element={<Recent />} />
            {userAccess.includes("template") && (
              <Route path="templates" element={<Templates />} />
            )}
            {userAccess.includes("email") && (
              <Route path="emails" element={<Emails />} />
            )}
            {/* {userAccess.includes("image") && (
              <Route path="images" element={<Images />} />
            )} */}
            {userAccess.includes("data") && (
              <Route path="data" element={<DataList />} />
            )}
            {userAccess.includes("campaign") && (
              <Route path="campaign" element={<Campaign />} />
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    isLoggedIn,
    success,
    userInfo: { user },
  } = state.LoginSlice;
  const assetSlice = state.AssetSlice;

  return {
    isLoggedIn,
    success,
    user,
    assetSlice,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setViewType: (value) => dispatch(setViewType(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetsArchive);
