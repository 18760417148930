import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { collapseSidebar } from "../Utils/Paths/Paths";

const Settings = () => {
  const location = useLocation();
  // console.log(location);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8%",
          marginBottom: "5%",
        }}
      >
        <h3 className="hero-head">Admin Setting Management</h3>
      </div>
      <div className="container-parent" style={{ textAlign: "center" }}>
        <div className="SettingContainer container-flex-setting">
          <div className="setting_details ">
            <Link
              to="/settings/ip"
              className="rs-dropdown-item-content"
            >
              <div className="center-div-img-text">
                <p className="setting_text-type">
                  <b>IPSetting</b>
                </p>
              </div>
            </Link>
            <p>
              View IP settings for email delivery. Explore IP pools, start dates, sub-users, warm-up status, and white labeling.
            </p>
          </div>

          <div className="setting_details ">
            <Link
              to="/settings/senderprofile"
              className="rs-dropdown-item-content"
            >
              <div className="center-div-img-text">
                <p className="setting_text-type">
                  <b>Sender Management</b>
                </p>
              </div>
            </Link>
            <p>
              Manage your sender profile to establish trust, credibility, and recognition with recipients. Customize sender name, email address, domain etc.
            </p>
          </div>


        </div>
        {/* <Outlet /> */}
      </div>
    </>
  );
}

export default Settings;
