import React, { Component } from "react";
import { Col, Row } from "rsuite";

import "./assets/css/continueproject.css";

export default class ContinueProject extends Component {
  render() {
    return (
      <div>
        <h2 className="hero-head">CONTINUE PROJECT</h2>

        <Row className="new-campaign-row create-new-project">
          <Col md={7} className="new-campaign-detail">
            <div className="center-div-img-text">
              <p className="create-new-project-type">
                <b>NEW EMAIL</b>
              </p>
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={7} className="new-campaign-detail">
            <img
              src="https://gratisography.com/wp-content/uploads/2019/09/gratisography-rocky-cliff-view.jpg"
              alt=""
              style={{ width: "100%", borderRadius: 22 }}
            />
            <p className="create-new-project-type">
              <b>New campaign name here or the email name here to be</b>
            </p>

            {/* <div className="center-div-img-text">
              
              <p className="create-new-project-type">
                <b>SOCIAL MEDIA POST</b>
              </p>
            </div> */}
          </Col>
          <Col md={1}></Col>
          <Col md={7} className="new-campaign-detail">
            <div className="center-div-img-text">
              <p className="create-new-project-type">
                <b>DIGITAL MEDIA</b>
              </p>
            </div>
          </Col>
        </Row>
        {/* </Col>
        </Row> */}
      </div>
    );
  }
}
