import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";

export default class DataSetConfirmationModal extends Component {
  render() {
    let { confirmation, hide, handleSubmit } = this.props;
    return (
      <Modal backdrop="static" open={confirmation} onClose={hide} size="xs">
        <Modal.Body style={{ color: "#000" }}>
          <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} />
          Deleting this Column will permanently remove all its contents. Are you sure you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} appearance="primary" title="Confirm">
            Confirm
          </Button>
          <Button onClick={hide} title="Cancel">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
