import { createSlice } from "@reduxjs/toolkit";
import { getImages } from "./ImageThunk";

const initialState = {
  loading: false,
  imagesCollection: {
    images: [],
    count: 0,
    pages: 0,
    page: 0
  },
  error: null,
  success: false,
  imagesById: {}
};

const ImageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    initializeImageSlice(state, payload) {
      state.editor = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImages.pending, (state, action) => {
        return initialState;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(getImages.fulfilled, (state, action) => {
        state.imagesCollection = action.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
        console.log("State after fulfillment:", state.imagesCollection.images);
      })

      .addCase(
        getImages.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.data);
          state.loading = false;
          state.error = action.error;
          state.imagesCollection = [];
        }
      );
  }
});
const { actions, reducer } = ImageSlice;
export const { initializeImageSlice } = actions;

export default reducer;
