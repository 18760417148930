import { useEffect, useState, RefObject } from "react";

type EmailEditorData = {
  html: string;
  design: any;
};

const useSaveAs = (emailEditorRef: RefObject<any>) => {
  const [selectedFormat, setSelectedFormat] = useState<string>("");
  // NOTE To download HTML format of File
  const downloadHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: EmailEditorData) => {
      const { html } = data;
      saveAs(html, "email_design.html", "text/html");
    });
  };
  // NOTE To download JSON format of File
  const downloadJson = () => {
    emailEditorRef.current.editor.exportHtml((data: EmailEditorData) => {
      const { design } = data;
      const jsonData = JSON.stringify(design, null, 2);
      saveAs(jsonData, "email_design.json", "application/json");
    });
  };

  const saveAs = (data: any, filename: string, mimeType: string) => {
    const blob = new Blob([data], { type: mimeType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSave = (format: string) => {
    setSelectedFormat(format);
  };

  useEffect(() => {
    if (selectedFormat === "html") {
      downloadHtml();
    } else if (selectedFormat === "json") {
      downloadJson();
    }
    setSelectedFormat("");
  }, [selectedFormat]);

  return { handleSave };
};

export default useSaveAs;
