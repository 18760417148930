import React, { Component } from "react";
import { connect } from "react-redux";
import { editorTemplateById } from "../Features/Editor/EditorThunk";
import { initializeEditor } from "../Features/Editor/EditorSlice";

import { emailById } from "../Features/Asset/AssetThunk";

import Loading from "../Loading/Loading";
import Editor from "./Editor/Editor";

class EditorContainer extends Component {
  async componentDidMount() {
    let { editParam, component, editorTemplateById, initializeEditor, emailById } = this.props;
    if (editParam && component === "template") {
      let { id } = editParam[0].params;

      initializeEditor();
      await editorTemplateById({
        type: "byId",
        value: id
      });
    }
    if (editParam && component === "email") {
      let { id } = editParam[0].params;

      initializeEditor();
      await emailById({
        type: "byId",
        value: id
      });
    }
  }
  render() {
    let { component, edit, editParam, editorSlice, alertRef, assetSlice } = this.props;
    if ((editorSlice.loading || assetSlice.loading) && !editorSlice.hideLoading) return <Loading />;
    return <Editor component={component} edit={edit} editParam={editParam} alertRef={alertRef} />;
  }
}
const mapStateToProps = (state) => ({
  data: state.EditorSlice,
  template: state.TemplateSlice,
  editorSlice: state.EditorSlice,
  assetSlice: state.AssetSlice
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  // saveTemplate: (templateData) => dispatch(saveTemplate(templateData)),
  // reset: () => dispatch(reset()),
  editorTemplateById: (type, value) => dispatch(editorTemplateById(type, value)),
  emailById: (type, value) => dispatch(emailById(type, value)),
  initializeEditor: (param) => dispatch(initializeEditor(param))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditorContainer);
