import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRoute = ({
  component,
  path,
  children,
  isLoggedIn,
  loading,
  userdata,
  userInfo,
  ...rest
}) => {
  if (!isLoggedIn && !loading) {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }
  let userAccess = JSON.parse(JSON.parse(userInfo.user.access));

  if (userAccess.includes(component) || component === null) {
    return children;
  }

  return <Navigate to="/not-authorized" replace />;
};

function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
