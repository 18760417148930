import React, { Component } from "react";
import {
  Col,
  Row,
  Loader,
  Affix,
  FlexboxGrid,
  Panel,
  Placeholder,
  List,
  Button,
  Grid,
} from "rsuite";
import { Link } from "react-router-dom";
import "./assets/css/dashboard.css";

import { userStatus } from "../Features/Login/LoginThunk";
import { connect } from "react-redux";

import MonthlyReport from "../Reports/MonthlyReport";
import OverallReport from "../Reports/OverallReport";
import DeviceReport from "../Reports/DeviceReport";
import BrowserReport from "../Reports/BrowserReport";
import GeoReport from "../Reports/GeoReport";
import PlaceholderParagraph from "rsuite/esm/Placeholder/PlaceholderParagraph";
import UserCircleIcon from "@rsuite/icons/legacy/UserCircleO";
import convertToUserTimeZone from "../../hooks/convertToUserTimeZone";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalEmailSent: "",
      totalEmailOpen: "",
      loading: true,
      created_count: "",
      latest_campaign: [],
      unique_opens: "",
      unique_clicks: "",
      clicks: "",
    };
  }
  // componentDidMount() {
  //   // this.props.userStatus();
  // }
  getEmailSent = (
    totalEmailSent,
    totalEmailOpen,
    created_count,
    latest_campaign,
    unique_opens,
    unique_clicks,
    clicks
  ) => {
    // console.log(created_count);
    this.setState({
      totalEmailSent: totalEmailSent,
      totalEmailOpen: totalEmailOpen,
      created_count: created_count,
      latest_campaign: latest_campaign,
      unique_opens: unique_opens,
      unique_clicks: unique_clicks,
      clicks: clicks,

      loading: false,
    });
  };
  render() {
    const {
      totalEmailSent,
      totalEmailOpen,
      created_count,
      latest_campaign,
      unique_opens,
      unique_clicks,
      clicks,
      loading,
    } = this.state;

    // console.log(latest_campaign);
    const open_percentage =
      !loading && unique_opens ? (unique_opens / totalEmailOpen) * 100 : 0;
    const click_percentage =
      !loading && unique_clicks ? (unique_clicks / clicks) * 100 : 0;

    return (
      <Grid fluid>
        {/* <h2 className="hero-head">DASHBOARD</h2> */}

        {/* SECTION: Dashboard Tiles/Card For Related Documents */}
        <Row>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent
              title="Email Sent"
              loading={loading}
              value={totalEmailSent}
            />
          </Col>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent
              title="Email Opened"
              loading={loading}
              value={totalEmailOpen}
            />
          </Col>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent
              title="Unique Opens Rate"
              loading={loading}
              value={`${open_percentage.toFixed(2)}%`}
            />
          </Col>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent title="Clicks" loading={loading} value={clicks} />
          </Col>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent
              title="Unique Clicks Rate"
              loading={loading}
              value={`${click_percentage.toFixed(2)}%`}
            />
          </Col>
          <Col xs={24} sm={12} md={4}>
            <PanelComponent
              title="Scheduled Campaigns"
              loading={loading}
              value={latest_campaign.length}
              className="ScheduledCampaigns"
            />
          </Col>
        </Row>

        {/* SECTION: Top Two Graphs */}
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col
            md={12}
            style={{
              borderRight: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <OverallReport getEmailSent={this.getEmailSent} />
          </Col>

          <Col
            md={12}
            style={{
              borderLeft: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <MonthlyReport />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col
            md={12}
            style={{
              borderRight: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <BrowserReport />
          </Col>

          <Col
            md={12}
            style={{
              borderLeft: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
              paddingBottom: 19,
            }}
          >
            <h2 className="RecentE-number">Scheduled Campaigns</h2>
            <List hover bordered autoScroll style={{ height: "190px" }}>
              {latest_campaign.length > 0 && !loading ? (
                latest_campaign.map((val, idx) => (
                  <List.Item key={val.name} index={idx + 1}>
                    <FlexboxGrid>
                      <FlexboxGrid.Item
                        colspan={19}
                        style={{
                          ...styleCenter,
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div style={titleStyle}>{val.campaign_name}</div>
                        <div style={slimText}>
                          <div>
                            <UserCircleIcon /> {val.created_by}
                          </div>
                          <div>{`Created at: ${convertToUserTimeZone(
                            val.created_at
                          )}`}</div>
                          <div>{`Scheduled at: ${convertToUserTimeZone(
                            val.actual_time
                          )}`}</div>
                        </div>
                      </FlexboxGrid.Item>

                      <FlexboxGrid.Item
                        colspan={5}
                        style={{
                          ...styleCenter,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Link
                          to={`/assetlibrary/edit/campaign/${val.campaign_id}`}
                        >
                          <Button color="green" appearance="primary">
                            {val.campaign_status}
                          </Button>
                        </Link>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </List.Item>
                ))
              ) : latest_campaign.length === 0 && !loading ? (
                <div className="latest-campaign-center">
                  No Scheduled Campaign
                </div>
              ) : (
                <div className="latest-campaign-center">
                  <PlaceholderParagraph rows={10} />
                </div>
              )}
            </List>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col
            md={12}
            className="new-campaign-detail"
            style={{
              // height: 378,
              borderRight: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <DeviceReport />
          </Col>
          <Col
            md={12}
            className="new-campaign-detail"
            style={{
              borderLeft: "2px solid white",
              minHeight: "250px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <GeoReport />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const PanelComponent = (props) => (
  <Panel
    bordered
    header={
      <span style={{ fontSize: 14, color: "white", padding: 7 }}>
        <strong>{props.title}</strong>
      </span>
    }
    style={{
      textAlign: "center",
      fontSize: "30px",
      backgroundColor: "#415670",
      color: "white",
      padding: "7px",
    }}
  >
    {props.loading ? <Loader /> : <strong> {props.value}</strong>}
  </Panel>
);

const styleCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70px",
};

const slimText = {
  fontSize: "0.9em",
  color: "#888",
  fontWeight: "normal",
  paddingBottom: 5,
};

const titleStyle = {
  paddingBottom: 5,
  whiteSpace: "nowrap",
  fontWeight: 600,
  fontSize: "1.1em",
};
function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}

export default connect(mapStateToProps, { userStatus })(Dashboard);
