import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchEmailFolder = createAsyncThunk("email-folder", async () => {
  const response = await axios.get(`/folder/email-folder`);
  return response.data;
});

export const createEmailFolder = createAsyncThunk(
  "email-folder/create",
  async (name, parentId, is_folder_or_file) => {
    try {
      const response = await axios.post(`/folder/email-folder`, name, parentId, is_folder_or_file);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

// export const deleteEmailFolder = createAsyncThunk("email-folder/delete", async (id) => {
//   console.log(id);
//   try {
//     const response = await axios.delete(`/folder/email-folder/?id=${id}`);
//     console.log(response)
//     return response.data;
//   } catch (e) {
//     return e.response.data;
//   }
// });


export const deleteEmailFolder = createAsyncThunk("email-folder/delete", async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`/folder/email-folder/?id=${id}`);
    console.log(response)
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const renameEmailFolder = createAsyncThunk("email-folder/rename", async ({ id, text }) => {
  console.log(id);
  try {
    const response = await axios.put(`/folder/email-folder/?id=${id}&name=${text}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
