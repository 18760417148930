import { createContext } from "react";

export const themes = {
  dark: "dark",
  light: "white"
};

export const ThemeContext = createContext({
  theme: themes.light,
  changeTheme: () => {}
});

export const ThemeService = () => {
  const pictures = document.querySelectorAll("picture");
  pictures.forEach((picture) => {
    const sources = picture.querySelectorAll(`
        source[media*="prefers-color-scheme"], 
        source[data-media*="prefers-color-scheme"]
      `);
    sources.forEach((source) => {
      // Preserve the source `media` as a data-attribute
      // to be able to switch between preferences
      if (source?.media.includes("prefers-color-scheme")) {
        source.dataset.media = source.media;
      }

      // If the source element `media` target is the `preference`,
      // override it to 'all' to show
      // or set it to 'none' to hide
      if (source?.dataset.media.includes(localStorage.getItem("theme"))) {
        // console.log(source?.dataset.media);
        source.media = "all";
      } else if (source) {
        source.media = "none";
      }
    });
  });
};
