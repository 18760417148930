import React, { useImperativeHandle, forwardRef } from "react";
import { useToaster } from "rsuite/toaster";
import { Message } from "rsuite";

export const Alert = forwardRef((props, ref) => {
  const toaster = useToaster();

  useImperativeHandle(ref, () => ({
    showToaster(propsMessage) {
      const type = getTypeFromStatus(propsMessage.status);
      const duration = getTypeFromduration(propsMessage.duration);
      const message = (
        <Message showIcon type={type} duration={duration}>
          {propsMessage.message}.
        </Message>
      );
      toaster.push(message);
    }
  }));

  const getTypeFromStatus = (status) => {
    if (status === 1) {
      return "success";
    } else if (status === 2) {
      return "warning";
    } else if (status === 3) {
      return "info";
    } else if (status === 4) {
      return "close";
    } else {
      return "error";
    }
  };

  const getTypeFromduration = (duration) => {
    if (duration === 1) {
      return 3000;
    } else if (duration === 2) {
      return 4000;
    } else if (duration === 3) {
      return 5000;
    } else if (duration === 4) {
      return 6000;
    } else if (duration === 5) {
      return 7000;
    }
    else {
      return 1000;
    }
  };

  return <></>;
});
