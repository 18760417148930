

//SECTION - GenerateMergeTags Headers dynamically using DataSet Structures. 

export const generateMergeTags = (structureById) => {
  // console.log("structureById", structureById);
  const mergeTags = {};
  structureById.forEach((structure) => {
    const { FieldName } = structure;
    mergeTags[FieldName] = {
      name: `${FieldName}`,
      value: `{{${FieldName}}}`,
    };
  });
  // console.log("Merge Tag", mergeTags);
  return mergeTags;
};


//not in use.
// export const getkeyvalues=(projectId)=>{
//   console.log("project id",projectId )
//   const projectIds={}
//   projectIds = {
//     projectId: projectId,
//   };
//   console.log("editorOptions", editorOptions)
//   return projectIds;
// };


// editor Optuions part

const editorOptions = {

  customCSS: [
    "https://charlie-unlayer-custom.s3.amazonaws.com/UnlayerCustomCss.css",
    // NOTE : Below is to css for editor while in development mode
    // `.blockbuilder-preview {
    //   background-color: #fff;
    //   background-image: none;
    // }
    // .blockbuilder-content-tools {
    //   background-color: #e5e5e5;
    // }
    // .nav-tabs {
    //   background-color: #e5e5e5;
    // }
    // `,
  ],
  customJS: [
    "https://charlie-unlayer-custom.s3.amazonaws.com/LinkCaptureTool.js",
    "https://charlie-unlayer-custom.s3.amazonaws.com/CustomTab.js",
    "https://charlie-unlayer-custom.s3.amazonaws.com/UnsubscribeTool.js",
  ],

  tools: {
    html: {
      properties: {
        html: {
          value: "<strong>Welcome To Charlie</strong>",
        },
      },
    },
    video: {
      enabled: true,
    },
  },
  // NOTE: Custom Font
  fonts: {
    customFonts: [
      {
        label: "Lemon",
        value: "Lemon",
        url: "https://fonts.googleapis.com/css2?family=Lemon&family=Poppins:wght@500&family=Roboto&display=swap",
      },
    ],
  },
  tabs: {
    // NOTE: Set To be open when using premium version
    "custom#my_tab": {
      enabled: true,
    },
  },
  editor: {
    confirmOnDelete: true,
    autoSelectOnDrop: true,
  },
  devices: ["desktop", "tablet", "mobile"],
  appearance: {
    theme: "modern_light",
    panels: {
      tools: {
        dock: "left",
        collapsible: true,
        tabs: {
          body: {
            visible: true,
          },
        },
      },
    },
    features: {
      preview: true,
    },
  },

  // FIXME Look into it
  // amp: {
  // enabled: true,
  // format: "AMP" | "AMP4EMAIL",
  // html: string | undefined,
  // validation: ValidationResult,
  // },
  // NOTE : designTags are like customized as per the user of the business logics

  designTags: {
    business_name: "Welcome To Charlie Editor",
    current_user_name: "Hello User",
  },
  specialLinks: [
    {
      name: "Manage account",
      href: "https://[my-account]/",
      target: "_self",
    },
    {
      name: "Frequently used",
      specialLinks: [
        // {
        //   name: "Subscribe",
        //   href: "[subscribe]",
        //   target: "_blank",
        // },
        // NOTE :unsub is from Sendgrid's Global API from special Link in unlayer
        // {
        //   name: "Unsubscribe",
        //   href: "unsub",
        //   target: "_blank"
        // }
        {
          name: "Unsubscribe",
          href: "<%asm_preferences_raw_url%>",
          target: "_blank",
        },
      ],
    },
  ],


//NOTE - Utilize that dynamic Mergetag Here. 

  mergeTags: generateMergeTags,


  mergeTagsConfig: {
    autocompleteTriggerChar: "@",
    sort: true,
  },

  projectId: 180023,
  // projectId:144031,
  displayMode: "email",
  user: {
    id: 1,
    signature: "XXX", // Have to make it from nodejs
    name: "John Doe",
    email: "xivan13132@avucon.com",
  },
  features: {
    // userUploads - Set to false as if it required then search functionality is required
    userUploads: false,
    pageAnchors: true,
    ai: true,
    imageEditor: true,
    // stockImages: true, //general use
    stockImages: {
      enabled: true,
      safeSearch: true,
      defaultSearchTerm: "marketing",
    },
    undoRedo: true,
    smartMergeTags: true,
    audit: true,
    svgImageUpload: true,
    sendTestEmail: true,
    textEditor: {
      spellChecker: true,
      cleanPaste: true,
      emojis: true,
      tables: true,
    },
  },

};


export default editorOptions;
