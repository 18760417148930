import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Content,
  Modal,
  IconButton,
} from "rsuite";
import CamDefineProperties from "./CamDefineProperties";

import "./assets/css/newemail.css";
// import Back from "./assets/icons/Back.png";
// import Next from "./assets/icons/Next.png";
import PlayIcon from "@rsuite/icons/legacy/Play";
import { connect } from "react-redux";
import {
  errorCampFolder,
  errorStepEmail,
  errorStepData,
  resetData,
  errorSuppression,
  setThrottleSelected,
} from "../../Features/CampaignManagement/CampaignPropertiesSlice";

import RemindIcon from "@rsuite/icons/legacy/Remind";
import { Link } from "react-router-dom";
import CampaignPreview from "./CampaignPreview";
import { saveCampaign } from "../../Features/CampaignManagement/CampaignPropertiesThunk";
import ErrorPage from "../../errorPage/ErrorPage";

class CreateEmailCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      showBack: false,
      buttonNext: `Next` /* State for Next button  */,
      buttonPrevious: "" /* State for previous button */,
      activePreviousButton:
        "hide" /* State for Type of button (hide or show )  */,
      stepsTitle: "STEP 1: DEFINE PROPERTIES" /* state for Steps Title  */,
      stepSubtitle: "Define the Properties" /* State for steps SubTitle  */,
      confirmation: false,
      //  method: "template"   /* State for create method  */
    };

    this.decline = this.decline.bind(this);
    this.increase = this.increase.bind(this);
  }

  // START function for email campaign steps
  async componentDidMount() {
    let { reset } = this.props;
    await reset();
  }
  changePercent(nextstep) {
    const step = nextstep < 0 ? 0 : nextstep > 3 ? 3 : nextstep;
    if (step >= 1) {
      this.setState({
        step,
        activePreviousButton: "show",
      });
    } else {
      this.setState({
        step,
        activePreviousButton: "hide",
      });
    }
    // this.setText(step);
  }
  // END function for email campaign steps

  // START function for dynamically setting button text & change step title

  // setText(step) {
  //   var stepsTitle = ["STEP 1: DEFINE PROPERTIES", "STEP 2: PREVIEW"];
  //   var stepssubTitle = ["Define the Properties.", "Preview your campaign"];

  //   switch (step) {
  //     case 0:
  //       this.setState({
  //         stepsTitle: stepsTitle[0],
  //         stepSubtitle: stepssubTitle[0],
  //       });
  //       break;
  //     case 1:
  //       this.setState({
  //         stepsTitle: stepsTitle[1],
  //         stepSubtitle: stepssubTitle[1],
  //       });
  //       break;
  //     case 2:
  //       this.setState({
  //         stepsTitle: stepsTitle[2],
  //         stepSubtitle: stepssubTitle[2],
  //       });
  //       break;
  //     case 3:
  //       this.setState({
  //         stepsTitle: stepsTitle[3],
  //         stepSubtitle: stepssubTitle[3],
  //       });
  //       break;
  //     default:
  //       this.setState({
  //         stepsTitle: stepsTitle[0],
  //         stepSubtitle: stepssubTitle[0],
  //       });
  //   }
  // }

  // END function for dynamically setting button text & change step title

  // START function for previous step
  decline() {
    if (this.state.step === 0) {
      this.setState({
        showBack: true,
      });
    }
    this.changePercent(this.state.step - 1);
  }

  // END function for previous step

  // START function for next step

  increase() {
    let {
      campaignname,
      errorStepEmail,
      errorStepData,
      senderProfile,
      camp_Category,
      folderId,
      errorSuppression,
      selectedDataProperties,
      selectedEmailProperties,
      error,
      suppression,
      suppression_Include,
      errorCampaignName,
      throttle,
      setThrottleSelected,
    } = this.props;

    if (this.state.step === 0 && folderId === "") {
      let { errorCampFolder } = this.props;
      errorCampFolder(true);

      this.checkInput();
    }
    if (errorCampaignName) {
      return;
    }
    if (
      error === false &&
      campaignname.trim().length !== 0 &&
      selectedEmailProperties.emailName !== "" &&
      senderProfile !== "" &&
      selectedDataProperties.dataName !== "" &&
      folderId !== "" &&
      camp_Category !== ""
    ) {
      if (suppression.suppressionId === null && suppression_Include) {
        document.getElementById("suppression").scrollIntoView({
          behavior: "smooth",
        });
        errorSuppression(true);
        return;
      }
      if (throttle.throttleLimit === 0 && throttle.isThrottle) {
        setThrottleSelected({
          throttleLimit: throttle.throttleLimit,
          throttleProgress: null,
          isThrottle: throttle.isThrottle,
          throttleError: true,
        });
        document.getElementById("throttle").scrollIntoView({
          behavior: "smooth",
        });
        return;
      } else {
        this.changePercent(this.state.step + 1);
      }
    }

    if (
      this.state.step === 0 &&
      (campaignname.trim().length === 0 ||
        selectedEmailProperties.emailName === "" ||
        senderProfile === "" ||
        selectedDataProperties.dataName === "" ||
        camp_Category === "")
    ) {
      if (selectedEmailProperties.emailName === "") {
        document.getElementById("select-email").scrollIntoView({
          behavior: "smooth",
        });
        errorStepEmail(true);
        return;
      }
      if (selectedDataProperties.dataName === "") errorStepData(true);
      if (throttle.isThrottle && throttle.throttleLimit === 0)
        setThrottleSelected({
          throttleLimit: throttle.throttleLimit,
          throttleProgress: null,
          isThrottle: throttle.isThrottle,
          throttleError: true,
        });
      this.checkInput();
    }
  }
  // END function for next step
  render() {
    let {
      alertRef,
      userInfo: { user },
    } = this.props;
    const userAccess = JSON.parse(JSON.parse(user.access));
    // console.log(userAccess);
    let { showBack } = this.state;
    if (!userAccess.includes("campaign")) {
      return <ErrorPage />;
    } else {
      return (
        <Container className="content-container">
          <Content>
            {showBack && (
              <Modal
                backdrop="static"
                open={showBack}
                onClose={() =>
                  this.setState({
                    showBack: true,
                  })
                }
                size="xs"
              >
                <Modal.Header>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: "#000" }}>
                  <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> Are
                  you sure you want to go back?
                </Modal.Body>
                <Modal.Footer>
                  <Link
                    to="/createcampaign"
                    replace
                    style={{ color: "#000" }}
                    onClick={() => resetData()}
                  >
                    <Button
                      appearance="subtle"
                      title="Confirm"
                      style={{ color: "#000" }}
                    >
                      <b>Confirm</b>
                    </Button>
                  </Link>

                  <Button
                    onClick={() => {
                      this.setState({ showBack: false });
                    }}
                    appearance="subtle"
                    title="Cancel"
                    style={{ color: "#000" }}
                  >
                    <b>Cancel</b>
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            <div className="spacingTop">
              <Row>
                <Col md={17} className="headingPage">
                  {" "}
                  <h2 className="hero-head">Create Email Campaign</h2>
                </Col>
                <Col md={7}>
                  <Row>
                    <Col md={24}>
                      {/* <ButtonGroup> */}
                      <Row style={{ paddingTop: 17 }}>
                        <div className="next-back-button-horizontal">
                          <div className="next-back-button">
                            <span
                              style={{ cursor: "pointer" }}
                              title={`${
                                this.state.step !== 0 ? "Back" : "Back"
                              }`}
                              appearance="primary"
                              onClick={this.decline}
                            >
                              {this.state.step === 0 ? (
                                <>
                                  <IconButton placement="left">
                                    <PlayIcon
                                      style={{ transform: "rotate(180deg)" }}
                                    />
                                    Back
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton placement="left">
                                    <PlayIcon
                                      style={{ transform: "rotate(180deg)" }}
                                    />
                                    Back
                                  </IconButton>
                                </>
                              )}
                            </span>

                            {this.state.step === 0 && (
                              <span
                                style={{ cursor: "pointer" }}
                                disabled={this.props.error}
                                title="Next"
                                onClick={this.increase}
                                id="next"
                              >
                                <IconButton
                                  icon={<PlayIcon />}
                                  placement="right"
                                >
                                  Next
                                </IconButton>
                              </span>
                            )}

                            {/* This will be active when error status for form is true  */}
                            {this.state.step === 1 && (
                              <span
                                style={{ cursor: "pointer" }}
                                disabled={this.props.error}
                                title="save"
                                onClick={() => this.saveDesign()}
                                id="next"
                              >
                                <IconButton placement="center">Save</IconButton>
                              </span>
                            )}
                            {/* END ---> Buttons For Creating Email from Scratch  */}
                            {/* </ButtonGroup> */}
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <div className="show-container">
                    <Container>
                      {this.state.step === 0 && (
                        <CamDefineProperties
                          setClick={(click) => (this.checkInput = click)}
                          alertRef={alertRef}
                        />
                      )}
                      {this.state.step === 1 && (
                        <CampaignPreview
                          setClick={(click) => (this.saveDesign = click)}
                          parentMethod={this.increase}
                        />
                      )}
                    </Container>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.LoginSlice.userInfo,
  emailList: state.CampaignPropertiesSlice.emailList,
  loadingCampaign: state.CampaignPropertiesSlice.loadingCampaign,
  dataList: state.CampaignPropertiesSlice.loadingCampaign,
  campaignname: state.CampaignPropertiesSlice.campaignname,
  suppression_Include: state.CampaignPropertiesSlice.suppression_Include,
  camp_Category: state.CampaignPropertiesSlice.camp_Category,
  senderProfile: state.CampaignPropertiesSlice.senderProfile,
  selectedEmailProperties:
    state.CampaignPropertiesSlice.selectedEmailProperties,
  error: state.CampaignPropertiesSlice.error,
  selectedDataProperties: state.CampaignPropertiesSlice.selectedDataProperties,
  errorFirstStepEmail: state.CampaignPropertiesSlice.errorFirstStepEmail,
  errorFirstStepData: state.CampaignPropertiesSlice.errorFirstStepData,
  folderId: state.CampaignPropertiesSlice.folderId,
  suppression: state.CampaignPropertiesSlice.suppression,
  errorCampaignName: state.CampaignPropertiesSlice.errorCampaignName,
  throttle: state.CampaignPropertiesSlice.throttle,
});
const mapDispatchToProps = (dispatch) => ({
  errorCampFolder: (error) => dispatch(errorCampFolder(error)),
  errorStepEmail: (emailError) => dispatch(errorStepEmail(emailError)),
  errorStepData: (errorData) => dispatch(errorStepData(errorData)),
  errorSuppression: (isError) => dispatch(errorSuppression(isError)),

  reset: () => {
    dispatch(resetData());
  },
  saveCampaign: (data) => {
    dispatch(saveCampaign(data));
  },
  setThrottleSelected: (
    throttleLimit,
    throttleProgress,
    isThrottle,
    throttleError
  ) =>
    dispatch(
      setThrottleSelected(
        throttleLimit,
        throttleProgress,
        isThrottle,
        throttleError
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmailCampaign);
