import React, { Component, useEffect, useState } from "react";

import { Sidenav, Nav, Button, Divider } from "rsuite";

import logoWhite from "./assets/icons/Charlie_logo_white.png";
import logo from "./assets/icons/Charlie_logo_black.png";

import { pathnames } from "../Utils/Paths/Paths";

import { Link, NavLink, useLocation } from "react-router-dom";
import "./assets/css/navbar.css";
import { logout } from "../Features/Login/LoginThunk";
import { resetData } from "../Features/Data/DataSlice";
import { connect } from "react-redux";
const Navbar = ({ user, isLoggedIn, success, resetData, userInfo, logout }) => {
  const location = useLocation();
  const userAccess = JSON.parse(JSON.parse(user.access));


  const [userName, setUserName] = useState("");
  const [expanded, setExpand] = useState(true);
  useEffect(() => {
    let fname, lname;

    if (userInfo.user) {
      fname = userInfo.user.user_first_name || "";
      lname = userInfo.user.user_last_name || "";
    } else {
      fname = userInfo.isExist.user_first_name || "";
      lname = userInfo.isExist.user_last_name || "";
    }

    const initials = fname.charAt(0) + lname.charAt(0);
    setUserName(initials);
  }, [userInfo]);

  const navItems = [
    {
      key: "1",
      label: "Dashboard",
      to: "/dashboard",
      condition: true,
    },
    {
      key: "2",
      label: "Project",
      to: "/createnewproject",
      // condition: true,
      condition: userAccess.includes("email") || userAccess.includes("template"),
    },
    {
      key: "7",
      label: "Data Set",
      to: "/create/data-set/info",
      condition: userAccess.includes("data"),
    },
    // {
    //   key: "3",
    //   label: "Image Upload",
    //   to: "/image-upload",
    //   condition: userAccess.includes("image"),
    // },
    {
      key: "4",
      label: "Campaign",
      to: "/createcampaign",
      condition: userAccess.includes("campaign"),
    },

    {
      key: "5",
      label: "Asset Library",
      to: "/assetlibrary",
      // condition: true,
      condition: userAccess.includes("email") || userAccess.includes("template") || userAccess.includes("data")|| userAccess.includes("campaign"),
    },
    {
      key: "6",
      label: "Reporting",
      to: "/reports/campaign",
      condition: userAccess.includes("social"),
    },
  ];

  const determineActiveKey = () => {
    const currentPath = location.pathname;

    // Find the first matching item in navItems
    const activeNavItem = navItems.find((item) =>
      currentPath.startsWith(item.to)
    );

    return activeNavItem ? activeNavItem.key : null;
  };

  return (
    <Sidenav expanded={true}>
      {expanded ? (
        <Sidenav.Header>
          <div className="companyLogo">
            <picture>
              <source
                srcSet={logoWhite}
                alt="Home"
                media={`(prefers-color-scheme: ${"dark"})`}
              />
              <img src={logo} alt="Home" className="logo" />
            </picture>
          </div>
        </Sidenav.Header>
      ) : null}

      <Sidenav.Body>
        <Nav activeKey={determineActiveKey()}>
          {navItems
            .filter((item) => item.condition)
            .map((item) => (
              <Nav.Item
                key={item.key}
                eventKey={item.key}
                icon={item.icon}
                as={NavLink}
                to={item.to}
                onClick={item.onClick}
              >
                {item.label}
              </Nav.Item>
            ))}
          {/* SECTION For Profile  */}
          <Nav.Menu title="Profile">
            <Nav.Item as={Link} to="/accountmanagement">
              Account Management
            </Nav.Item>
            {userInfo.user.role === "admin" && (
              <Nav.Item as={Link} to="/usermanagement">
                User Management
              </Nav.Item>
            )}
             {userInfo.user.role === "admin" && (
              <Nav.Item as={Link} to="/settings">
               Setting
              </Nav.Item>
            )}

            <Nav.Item onClick={() => logout({ id: userInfo.user.user_id })}>
              Logout
            </Nav.Item>
          </Nav.Menu>
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );
};

const mapStateToProps = (state) => {
  const {
    isLoggedIn,
    success,
    loading,
    userInfo: { user },
    userInfo,
  } = state.LoginSlice;

  return {
    isLoggedIn,
    success,
    loading,
    user,
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetData: () => dispatch(resetData()),
  logout: (userInfo) => dispatch(logout(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
