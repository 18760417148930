import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEmailData = () => JSON.parse(localStorage.getItem("eD"));

export const isEmailExist = createAsyncThunk("email/isExist", async ({ emailName, id }) => {
  try {
    const response = await axios.get(`/email/is-exist?name=${emailName}&id=${id}`);
    return response.data;
  } catch (e) {}
});

export const saveEmail = createAsyncThunk("email/create", async (emailData) => {
  // console.log(emailData);
  try {
    const response = await axios.post("/email/", emailData);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const editEmailProperty = createAsyncThunk("email/edit/property", async (emailData) => {
  try {
    const response = await axios.put("/email/property", emailData);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

// NOTE Function managing design(JSON),id,HTML so saving html and
// HTML is required for sending email and it is happening in backend
// JSON format cuz Unlayer read JSON format only
export const editEmailDesign = createAsyncThunk(
  "email/edit/design",
  async ({ design, id, html }) => {
    try {
      const response = await axios.put("/email/design", { design, id, html });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const emailSend = createAsyncThunk("email/edit/design", async ({ id, emailAddress }) => {
  try {
    const response = await axios.post("/email/test-send", {
      id,
      emailAddress
    });
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});

export const downloadEmailPdf = createAsyncThunk("email/download/pdf", async (id) => {
  try {
    const response = await axios.get(`/email/download-pdf?id=${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});

export const downloadEmailImage = createAsyncThunk("email/download/image", async (id) => {
  try {
    const response = await axios.get(`/email/download-image?id=${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});

export const deleteEmail = createAsyncThunk("email/delete", async ({ id }) => {
  try {
    const response = await axios.delete(`/email/?id=${id}`);
    // console.log(response);
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});
