import React, { Component } from "react";

import { Col, Row, Input, TagGroup, Tag, Button, Uploader } from "rsuite";

import ImageFolder from "../../Folder/ImageFolder";
import { connect } from "react-redux";
import { Alert } from "../../Utils/Alert";
import { uploadImages } from "../../Features/Image/ImageThunk";
import { withRouter } from "../../AccountManagement/withRouter";

const fileList = [];
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      tagCategory: [],
      categoryError: false,
      path: "",
      errorInLocation: false,
      imagePreview: [],
      loading: false,
      errorInUploaderlength: false,
    };

    this.alertRef = React.createRef();
  }

  selectFolder = (e) => {
    this.setState({
      path: e.nodeData.id,
      errorInLocation: false,
    });
  };

  // newCategory = (type, val) => {
  //   var newAddTag = [];
  //   if (type === "add" && val.target.value !== "") {
  //     newAddTag.push(val.target.value);

  //     this.setState({
  //       tagCategory: [...this.state.tagCategory, newAddTag],
  //       category: "",
  //     });
  //   }
  //   if (type === "delete") {
  //     const newCategories = this.state.tagCategory.filter(
  //       (item, i) => i !== val
  //     );
  //     this.setState({
  //       tagCategory: newCategories,
  //     });
  //   }
  // };

  // Function to check if no new categories of duplicate / space/null/undefined to be added
  newCategory = (type, val) => {
    if (type === "add" && val.target.value) {
      const newCategoryValue = val.target.value.trim();

      if (
        newCategoryValue !== "" &&
        !this.state.tagCategory.includes(newCategoryValue)
      ) {
        this.setState((prevState) => ({
          tagCategory: [...prevState.tagCategory, newCategoryValue],

          category: "",
        }));
      } else {
        this.alertRef.current.showToaster({
          status: 0,

          message: "Check if Categories are Unique",
        });
      }
    }

    if (type === "delete") {
      const newCategories = this.state.tagCategory.filter(
        (item, i) => i !== val
      );

      this.setState({
        tagCategory: newCategories,
      });
    }
  };
  // Function to Preview Image
  selectImagePreview = (e) => {
    var files = e;
    var totalSize = 0;

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].blobFile.size;
    }

    if (totalSize > 2097152) {
      this.alertRef.current.showToaster({
        status: 0,
        message: "Size of images is more than 2MB",
      });

      return;
    }

    this.setState({
      imagePreview: files,
      errorInUploader: false,
    });
  };

  checkErrorForm = async (e) => {
    e.preventDefault();
    if (this.state.imagePreview.length > 20) {
      this.setState({
        errorInUploaderlength: true,
        loading: false,
      });
    }

    if (this.state.tagCategory.length === 0) {
      this.setState({
        categoryError: true,
      });
    }

    if (this.state.path === "") {
      this.setState({
        errorInLocation: true,
      });
    }
    if (this.state.imagePreview.length === 0) {
      this.setState({
        errorInUploader: true,
      });
    }
    if (
      this.state.tagCategory.length !== 0 &&
      this.state.path !== "" &&
      this.state.imagePreview.length !== 0
    ) {
      this.setState({
        loading: true,
      });
      let data = {
        Category_name: this.state.tagCategory,
        folderId: this.state.path,
        imagePreview: this.state.imagePreview,
      };
      var imageData = new FormData();
      for (let i = 0; i < data.imagePreview.length; i++) {
        imageData.append(
          "Image",
          this.state.imagePreview[i].blobFile,
          this.state.imagePreview[i].name,
          this.state.imagePreview[i]
        );
      }

      imageData.append("Category_name", data.Category_name.toString());
      imageData.append("folderId", data.folderId);

      const imageUploadedResponse = await this.props.uploadImages(imageData);

      try {
        let { payload } = imageUploadedResponse;
        if (payload.status === 1) {
          this.setState({
            loading: false,
            tagCategory: [],
            path: "",
            imagePreview: [],
          });

          this.alertRef.current.showToaster(payload);
          this.props.navigate("/assetlibrary/images");
        }
      } catch (error) {
        this.alertRef.current.showToaster(error);
      }
    }
  };

  render() {
    let { category } = this.state;

    return (
      <div>
        <Alert ref={this.alertRef} />
        {/* <Row>
          <Col md={12}>
            <h2 className="hero-head">UPLOAD IMAGES</h2>
          </Col>
        </Row> */}
        <Row className="create-new-project">
          <Col md={24}>
            <Row>
              <div className="creat-email-form">
                <form
                  style={{ width: "100%", marginBottom: 50 }}
                  fluid
                  onSubmit={this.checkErrorForm}
                  method="post"
                  enctype="multipart/form-data"
                >
                  <Col md={4}></Col>

                  <Col md={16}>
                    <TagGroup style={{ margin: 0 }}>
                      <Input
                        placeholder="Add-Categories on enter"
                        className="new-email-text"
                        name="categories"
                        value={category}
                        onChange={(e) => {
                          this.setState({
                            category: e,
                          });
                        }}
                        onBlur={(e) => this.newCategory("add", e)}
                        onPressEnter={(e) => this.newCategory("add", e)}
                      />
                      {this.state.tagCategory.map((item, index) => (
                        <Tag
                          key={index}
                          closable
                          onClose={(tag) => this.newCategory("delete", index)}
                        >
                          {item}
                        </Tag>
                      ))}

                      <span
                        className="custom-error-fields"
                        style={{
                          display: `${
                            this.state.categoryError &&
                            this.state.tagCategory.length === 0
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        This field is Required
                      </span>
                    </TagGroup>
                    <div
                      className="input-text-space location"
                      placeholder="Location"
                      style={{
                        background: "#fff",
                        padding: 5,
                      }}
                    >
                      <ImageFolder
                        selectFolder={this.selectFolder}
                        clickImage={(click) => (this.clickImage = click)}
                      />
                    </div>
                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${
                          this.state.errorInLocation ? "block" : "none"
                        }`,
                      }}
                    >
                      This field is Required
                    </span>
                    <Row style={{ marginTop: 30, textAlign: "center" }}>
                      <Col md={24}>
                        <Uploader
                          listType="picture-text"
                          action="//jsonplaceholder.typicode.com/posts/"
                          defaultFileList={fileList}
                          draggable
                          onChange={this.selectImagePreview}
                          accept="image/*"
                          input
                          // maxPreviewFileSize={4000000}
                          type="file"
                          autoUpload={false}
                          multiple={true}
                          id="input"
                          name="myUploader"
                          removable={true}
                          className="imageUploader"
                        >
                          <div
                            style={{
                              display: "inline-block",
                              border: "1px dashed #000",
                              width: "100%",
                              paddingTop: 75,
                              paddingBottom: 75,
                              textAlign: "center",
                              color: "#000",
                            }}
                          >
                            Drop image here, or click <br /> to select files to
                            upload.
                          </div>
                        </Uploader>
                        <span
                          className="custom-error-fields"
                          style={{
                            display: `${
                              this.state.errorInUploader ? "block" : "none"
                            }`,
                          }}
                        >
                          This field is Required
                        </span>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30, textAlign: "center" }}>
                      {this.state.imagePreview.length < 20}
                      <Col md={24}>
                        <Button
                          appearance="primary"
                          type="submit"
                          loading={this.state.loading ? "loading" : ""}
                          disabled={
                            this.state.imagePreview.length > 20 ? true : false
                          }
                        >
                          Upload
                        </Button>
                        {this.state.imagePreview.length > 20 && (
                          <p
                            style={{
                              display: `${
                                this.state.imagePreview.length > 20
                                  ? "block"
                                  : "none"
                              }`,
                              color: "red",
                              fontSize: 11,
                            }}
                          >
                            Maximum limit exceeded (maximum 20 images).
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}></Col>
                </form>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(null, { uploadImages })(withRouter(ImageUploader));
