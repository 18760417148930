import { createSlice } from "@reduxjs/toolkit";
import {
  getSenderprofile,
  createSenderProfile,
  updateSenderProfile,
  UpdateSenderprofileData,
} from "./SenderThunk";

const initialState = {
  senderProfiles: [],
  createdSenerProfile: [],
  loading: false,
  error: false,
  status: true,
  updatedSenderProfile: [],
};

const SenderSlice = createSlice({
  name: "SenderSlice",
  initialState: initialState,

  reducers: {
    //VewPath
  },

  extraReducers: (builder) => {
    builder

      //add case for getting all senderProfiles
      .addCase(getSenderprofile.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSenderprofile.fulfilled, (state, action) => {
        state.loading = false;
        state.senderProfiles = action.payload.data.senderProfiles;
      })
      .addCase(getSenderprofile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //add case for createSenderProfile
      .addCase(createSenderProfile.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSenderProfile.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.loading = false;
        state.createdSenerProfile = action.payload;
      })
      .addCase(createSenderProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SECTION Case for Updating Sender Profile
      .addCase(updateSenderProfile.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSenderProfile.fulfilled, (state, action) => {
        state.loading = false;
        // FIXME Update the Name for the createdSenerProfile as it is used to hold the state for created and updated profile also
        state.updatedSenderProfile = action.payload.data.requestedSenderProfile;
      })
      .addCase(updateSenderProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


      // SECTION Case for Syncing Sender Profile Data From Sendgrid
      .addCase(UpdateSenderprofileData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateSenderprofileData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateSenderprofileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
const { reducer, actions } = SenderSlice;
export const { setViewType } = actions;

export default reducer;
