import React, { Component } from "react";

import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "./assets/icons/Dashboard.png";
import DashboardWhiteIcon from "./assets/icons/Dashboard_white.png";

import NewProject from "./assets/icons/Create New Project.png";
import NewProjectWhite from "./assets/icons/Create_new_project_white.png";

import ContinueProject from "./assets/icons/Continue project.png";
import ContinueProjectWhite from "./assets/icons/Continue_project_white.png";

import image from "../AssetArchive/assets/icons/images.png";
import imageWhite from "../AssetArchive/assets/icons/image-white.png";

import CreateCampaign from "./assets/icons/Create campaign.png";
import CreateCampaignWhite from "./assets/icons/Create_campaign_white.png";

import AssetArchive from "./assets/icons/Asset Archive.png";
import AssetArchiveWhite from "./assets/icons/Asset_archive_white.png";

import DataArchive from "./assets/icons/Data Archive.png";
import DataArchiveWhite from "./assets/icons/Data_archive_white.png";

import Reports from "./assets/icons/Reports.png";
import ReportsWhite from "./assets/icons/Reports_white.png";

import { pathnames } from "../Utils/Paths/Paths";

import { Link } from "react-router-dom";

import "./assets/css/navbar.css";

import { resetData } from "../Features/Data/DataSlice";
import { connect } from "react-redux";
import logo from "./assets/icons/charlielogo.png";
// import Logo from "./assets/icons/charlielogo.svg";

// import HeaderTop from "../Header/HeaderTop";

class IconNavbar extends Component {
  render() {
    let {
      user: { access },
    } = this.props;
    let userAccess = JSON.parse(JSON.parse(access));

    return (
      <Sidenav className="icon-body">
        <img
          src={logo}
          alt="Logo"
          style={{ width: "24%", paddingLeft: "8%", paddingBottom: "1%" }}
        />

        <Sidenav.Body>
          <Nav activeKey={`${pathnames.indexOf(this.props.location)}`}>
            <Link to="/dashboard">
              <Nav.Item eventKey="0" as="div" title="Dashboard">
                <picture>
                  <source
                    srcSet={DashboardWhiteIcon}
                    alt="Dashboard"
                    media={`(prefers-color-scheme: ${"dark"})`}
                    className="Iconsidenav-dash-icon"
                  />

                  <img
                    src={DashboardIcon}
                    alt="Dashboard"
                    className="Iconsidenav-dash-icon"
                  />
                </picture>
              </Nav.Item>
            </Link>

            <Link to="/createnewproject">
              <Nav.Item eventKey="1" as="div" title="New Project">
                <picture>
                  <source
                    srcSet={NewProjectWhite}
                    media="(prefers-color-scheme: dark)"
                    alt="New Project"
                    className="Iconsidenav-dash-icon"
                  />
                  <img
                    src={NewProject}
                    alt="New Project"
                    className="Iconsidenav-dash-icon"
                  />
                </picture>
              </Nav.Item>
            </Link>
            {userAccess.includes("data") && (
              <Link
                to="/create/data-set/info"
                onClick={() => this.props.resetData()}
              >
                <Nav.Item eventKey="6" as="div" title="Create Data">
                  <picture>
                    <source
                      srcSet={DataArchiveWhite}
                      media="(prefers-color-scheme: dark)"
                      alt="Create Data"
                      className="Iconsidenav-dash-icon"
                    />
                    <img
                      src={DataArchive}
                      alt="Create Data"
                      className="Iconsidenav-dash-icon"
                    />
                  </picture>
                </Nav.Item>
              </Link>
            )}
            {/* <Link to="/continueproject">
              <Nav.Item eventKey="2" as="div" title="Continue Project">
                <picture>
                  <source
                    srcSet={ContinueProjectWhite}
                    media="(prefers-color-scheme: dark)"
                    alt="Continue Project"
                    className="Iconsidenav-dash-icon-vertical"
                  />
                  <img
                    src={ContinueProject}
                    alt="Continue Project"
                    className="Iconsidenav-dash-icon-vertical"
                  />
                </picture>
              </Nav.Item>
            </Link> */}

            {userAccess.includes("campaign") && (
              <Link to="/createcampaign">
                <Nav.Item eventKey="4" as="div" title="Create Campaign">
                  <picture>
                    <source
                      srcSet={CreateCampaignWhite}
                      media="(prefers-color-scheme: dark)"
                      alt="Create Campaign"
                      className="Iconsidenav-dash-icon-vertical"
                    />
                    <img
                      src={CreateCampaign}
                      alt="Create Campaign"
                      className="Iconsidenav-dash-icon-vertical"
                    />
                  </picture>
                </Nav.Item>
              </Link>
            )}

            <Link to="/assetlibrary">
              <Nav.Item eventKey="5" as="div" title="Asset Library">
                <picture>
                  <source
                    srcSet={AssetArchiveWhite}
                    media="(prefers-color-scheme: dark)"
                    alt="Asset Library"
                    className="Iconsidenav-dash-icon"
                  />
                  <img
                    src={AssetArchive}
                    alt="Asset Library"
                    className="Iconsidenav-dash-icon"
                  />
                </picture>
              </Nav.Item>
            </Link>

            {userAccess.includes("image") && (
              <Link to="/image-upload">
                <Nav.Item eventKey="3" as="div" title="Upload Image">
                  <picture>
                    <source
                      srcSet={imageWhite}
                      media={`(prefers-color-scheme: ${"dark"})`}
                      alt="Images"
                      className="Iconsidenav-dash-icon-vertical"
                    />

                    <img
                      src={image}
                      alt="Images"
                      className="Iconsidenav-dash-icon-vertical"
                    />
                  </picture>
                </Nav.Item>
              </Link>
            )}

            {userAccess.includes("social") && (
              <Link to="/reports/campaign">
                <Nav.Item eventKey="7" as="div" title="Reports">
                  <picture>
                    <source
                      srcSet={ReportsWhite}
                      media="(prefers-color-scheme: dark)"
                      alt="Reports"
                      className="Iconsidenav-dash-icon-vertical"
                    />
                    <img
                      src={Reports}
                      alt="Reports"
                      className="Iconsidenav-dash-icon-vertical"
                    />
                  </picture>
                </Nav.Item>
              </Link>
            )}
          </Nav>
        </Sidenav.Body>
        {/* <HeaderTop /> */}
      </Sidenav>
    );
  }
}
function mapStateToProps(state) {
  const {
    userInfo: { user },
  } = state.LoginSlice;

  return {
    user,
  };
}
const mapDispatchToProps = (dispatch) => ({
  resetData: () => dispatch(resetData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconNavbar);
