import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Button, Form, Schema } from "rsuite";
import {
  createSenderProfile,
  getSenderprofile,
  updateSenderProfile,
} from "../Features/SenderManagement/SenderThunk";
import { Alert } from "../Utils/Alert";

// custom regular expression pattern for checking blank space
const trimmedPattern = /^[^\s].*[^\s]$/;

const { StringType } = Schema.Types;
const model = Schema.Model({
  from_name: StringType().isRequired("From Name is a required field"),
  from_email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("From Email is a required field"),
  reply_to_name: StringType().isRequired("Reply Name is a required field"),
  reply_to_email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("Reply Email is a required field"),
  address: StringType().isRequired("Address is a required field"),
  address_2: StringType().isRequired("Address 2 is a required field"),
  city: StringType().isRequired("City is a required field"),
  state: StringType().isRequired("State is a required field"),
  country: StringType().isRequired("Country is a required field"),
  nickname: StringType().isRequired("Nickname is a required field"),
  zip: StringType()
    .isRequired("This field is required.")
    .pattern(/^[1-9][0-9]{4,5}$/, "Pincode must be 5 or 6 digits"),
});

function TextField(props) {
  const { name, label, accepter, helperText } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} blur />
      {helperText && <Form.HelpText>{helperText}</Form.HelpText>}
    </Form.Group>
  );
}

const SenderProfileDrawer = (props) => {
  const { backdrop, open, onClose, title, senderRowId } = props;
  const { senderProfiles, createdSenerProfile } = useSelector(
    (state) => state.SenderSlice
  );
  const [formValue, setFormValue] = useState({});
  const alertRef = useRef();
  const formRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (senderRowId) {
      const profile = senderProfiles.find(
        (profile) => profile.id === senderRowId
      );
      if (profile) {
        setFormValue(profile);
      }
    }
  }, [senderRowId, senderProfiles]);


  // SECTION For Creating new Sender Profile
  // const handleSubmit = async () => {
   
  //   try {
  //     // console.log(formValue);
  //     const response = await dispatch(createSenderProfile(formValue));
  //     await alertRef.current.showToaster({
  //       status: response.payload.status,
  //       duration: 3,
  //       message: response.payload.message,
  //     });
  //     dispatch(getSenderprofile());
  //   } catch (error) {
  //     console.log("Error occurred:", error);
  //   }
  //   finally{ onClose();}
  // };

  const handleSubmit = async () => {
    try {
      // Check if the form is valid according to the model schema
      const isValid = await formRef.current.checkAsync();
      
      // console.log(isValid)
      // If form is not valid, return without submitting
      if (isValid.hasError) {
        return;
      }

      const response = await dispatch(createSenderProfile(formValue));
      await alertRef.current.showToaster({
        status: response.payload.status,
        duration: 3,
        message: response.payload.message,
      });
      dispatch(getSenderprofile());
      onClose();
    } catch (error) {
      console.log("Error occurred:", error);
    } 
  };

  const handleDrawerClose = () => {
    setFormValue({});
    onClose();
  };

  const restrictedIds = [
    5510801, 5510739, 5364660, 5320240, 5255746, 5255726, 3667768, 3667765,
    1838044, 1116568, 819760, 5726465,5828191,5787584
  ];
  // SECTION For Updating Sender Profile
  const handleUpdateSenderProfile = async () => {
    onClose();
    // console.log(formValue);
    const restrictedIds = [
      5510801, 5510739, 5364660, 5320240, 5255746, 5255726, 3667768, 3667765,
      1838044, 1116568, 819760, 5726465,5828191,5787584
    ];

    if (restrictedIds.includes(formValue.sender_id)) {
      let message = {
        status: "error",
        duration: 2,
        message: "Not Allowed To Edit",
      };
      alertRef.current.showToaster(message);
      return;
    }
    
    try {
      // console.log(formValue);
      const response = await dispatch(updateSenderProfile(formValue));
      await alertRef.current.showToaster({
        status: response.payload.status,
        duration: 3,
        message: response.payload.message,
      });
      dispatch(getSenderprofile());
    } catch (error) {
      console.log("Error occurred:", error);
    }
  };

  return (
    <>
      {" "}
      <Alert ref={alertRef} />
      <Drawer backdrop={backdrop} open={open} onClose={handleDrawerClose}>
        <Drawer.Header>
          <Drawer.Title>
            {title} Sender {title === "Add" ? null : senderRowId}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <>
            <p>
              You're required to include your contact information, including a
              physical mailing address, inside every promotional email you send
              in order to comply with anti-spam laws such as CAN-SPAM and CASL.
            </p>
            <Form
              model={model}
              formValue={formValue}
              onChange={(formValue) => setFormValue(formValue)}
              onSubmit={
                title === "Add" ? handleSubmit : handleUpdateSenderProfile
              }
              ref={formRef}
            >
              <TextField name="from_name" label="From Name*" />
              <TextField name="from_email" label="From Email Address*" />
              <TextField name="reply_to_name" label="Reply Name*" />
              <TextField name="reply_to_email" label="Reply Email*" />
              <TextField name="address" label="Company Address*" />
              <TextField name="address_2" label="Address 2*" />
              <TextField name="city" label="City*" />
              <TextField name="state" label="State*" />
              <TextField name="zip" label="Zip*" />
              <TextField name="country" label="Country*" />
              <TextField
                name="nickname"
                label="Nickname*"
                helperText="Nickname is for your reference only; this field will not be displayed to recipients."
              />
              <Drawer.Actions>
                <Button onClick={handleDrawerClose}>Cancel</Button>

                <Button
                  onClick={
                    title === "Add" ? handleSubmit : handleUpdateSenderProfile
                  }
                  appearance="primary"
                  style={{
                    backgroundColor: "#415670",
                    color: "#fff",
                  }}
                  type="submit"
                  disabled={
                    restrictedIds.includes(formValue.sender_id) ? true : false
                  }
                >
                  Confirm
                </Button>
              </Drawer.Actions>
            </Form>
          </>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default SenderProfileDrawer;
