import React, { Component } from "react";
// import Back from "../../CreateNewProject/Email/assets/icons/Back.png";
// import Next from "../../CreateNewProject/Email/assets/icons/Next.png";
import ConfirmationModal from "../../Utils/ConfirmationModal";

import { Col, Row, IconButton } from "rsuite";
import PlayIcon from "@rsuite/icons/legacy/Play";

import { Link, Routes, Route, Navigate } from "react-router-dom";

import CreateDataInfo from "./CreateDataInfo";
import CreateDataStructure from "./CreateDataStructure";
import PreviewDataSet from "./PreviewDataSet";

import { connect } from "react-redux";
import {
  resetData,
  feedDataField,
  selectLocation,
  feedDescription,
} from "../../Features/Data/DataSlice";
import { getDataSet } from "../../Features/CreateNewProjectService";

import { saveDataSet } from "../../Features/Data/DataThunk";

import { stepPathDataSet } from "../../Utils/Paths/Paths";
import Loading from "../../Loading/Loading";

class CreateData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: false,
      loading: false,
      isSaved: false,
      whitespaceError:false
    };
    this._isMounted = false;
   
  }

  async componentDidMount() {
    this._isMounted = true;
    let { resetData } = this.props;
    if (!getDataSet() && this._isMounted) {
      resetData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkErrorForm = (event) => {
   
    let { data, selectLocation, feedDataField, feedDescription } = this.props;
    // console.log(data)
   
    let { location, dataName, description } = data;
    if (
      !dataName.textName ||
      dataName.isExistError ||
      !location.path ||
      !description.textDes
    ) {
      if (!dataName.textName) {
        feedDataField({
          textName: "",
          errorInName: true,
          isExistError: false,
          errMessageName: "This field is Required",
        });
      }

      if (!location.path) {
        selectLocation({ path: null, errorInLocation: true });
      }

      if (!description.textDes) {
        feedDescription({
          textDes: "",
          errorInDescription: true,
          errMessageDes: "This field is Required",
        });
      }



      event.preventDefault();
    }
  };

  isError = () => {
    let err = [];

    let { datafield } = this.props.data;
    datafield.forEach((ele) => {
      if (
        ele.DataType === "text" ||
        ele.DataType === "date" ||
        ele.DataType === "boolean" ||
        ele.DataType === "decimal"
      ) {
        if (
          ele.FieldName.replace(/\s/g, "").length !== 0 &&
          ele.DataType !== "" &&
          ele.Required !== ""
        ) {
          err.push(false);
        } else {
          err.push(true);
        }
      } else {
        if (
          ele.FieldName.replace(/\s/g, "").length !== 0 &&
          ele.DataType !== "" &&
          ele.Length !== "" &&
          ele.Required !== ""
        ) {
          err.push(false);
        } else {
          err.push(true);
        }
      }
    });

    return err.includes(true);
  };

  submit = (event) => {
    // alert(this.state.whitespaceError)
    let { errorFieldName, emailfield } = this.props.data;
    if (
      this.state.whitespaceError||
      this.isError() ||
      errorFieldName.length > 0 ||
      emailfield.value === "" 
    ) {
      event.preventDefault();

      document.getElementById("submitbutton").click();
    }
  };

  nextStep = async (event) => {
    let { location } = this.props;
    if (location === stepPathDataSet[1]) this.checkErrorForm(event);
    if (location === stepPathDataSet[2]) this.submit(event);
    if (location === stepPathDataSet[3]) this.save(event);
  };

  save = async () => {
    let { saveDataSet, data, alertRef } = this.props;

    let dataForm = {
      name: data.dataName.textName,
      location: data.location.path,
      description: data.description.textDes,
      datafield: data.datafield,
      emailfield: data.emailfield,
    };
    let response = await saveDataSet(dataForm);
    let { payload } = response;
    this.setState({
      loading: false,
      isSaved: true,
    });
    alertRef.current.showToaster(payload);
  };

  setWhitespaceError = (errorStatus) => {
    this.setState({ whitespaceError: errorStatus });
  };
  render() {
    let { location, alertRef } = this.props;
    let { confirmation, loading, isSaved,whitespaceError } = this.state;
    let indexLocation = stepPathDataSet.indexOf(location);
    if (loading) return <Loading />;


    // console.info(whitespaceError)
    if (!loading && isSaved)
      return <Navigate to={stepPathDataSet[0]} replace />;
    return (
      <div className="content-container">
        {confirmation && (
          <ConfirmationModal
            confirmation={confirmation}
            component="dataset"
            hide={() =>
              this.setState({
                confirmation: false,
              })
            }
          />
        )}
        <Row>
          <Col md={12}>
            <h2 className="hero-head">Create Data Set</h2>
          </Col>

          <Col md={12}>
            <div className="next-back-button-horizontal">
              <div className="next-back-button">
                <Link
                  to={stepPathDataSet[indexLocation - 1]}
                  className="link-color-font"
                  onClick={(event) => {
                    if (location === "/create/data-set/info") {
                      this.setState({
                        confirmation: true,
                      });
                      event.preventDefault();
                    }
                  }}
                >
                  <span style={{ cursor: "pointer" }} title="Back">
                    <IconButton placement="left">
                      <PlayIcon style={{ transform: "rotate(180deg)" }} />
                      Back
                    </IconButton>
                  </span>
                </Link>
                <Link
                  to={
                    indexLocation !== stepPathDataSet.length - 1
                      ? stepPathDataSet[indexLocation + 1]
                      : "#"
                  }
                  className="link-color-font"
                  onClick={(event) => this.nextStep(event)}
                >
                  <IconButton
                    icon={<PlayIcon />}
                    placement="right"
                    title={indexLocation === 3 ? "Save" : "Next"}
                  >
                    {indexLocation === 3 ? "Save" : "Next"}
                  </IconButton>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Routes>
          <Route
            index
            path="info"
            element={<CreateDataInfo alertRef={alertRef} />}
          />
          <Route
            path="structure"
            element={<CreateDataStructure alertRef={alertRef} whitespaceError = {whitespaceError} setWhitespaceError={this.setWhitespaceError}/>}
          />

          <Route
            path="preview"
            element={<PreviewDataSet alertRef={alertRef} />}
          />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.DataSlice,
});

const mapDispatchToProps = (dispatch) => ({
  resetData: () => dispatch(resetData()),
  feedDataField: (textName, errorInName, isExistError, errMessageName) =>
    dispatch(
      feedDataField(textName, errorInName, isExistError, errMessageName)
    ),

  selectLocation: ({ path, errorInLocation }) => {
    dispatch(selectLocation({ path, errorInLocation }));
  },
  feedDescription: (textDes, errorInDescription, errMessageDes) =>
    dispatch(feedDescription(textDes, errorInDescription, errMessageDes)),

  saveDataSet: (data) => dispatch(saveDataSet(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateData);
