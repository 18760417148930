import React, { Component } from "react";
import { Col, Row, Form, Grid, Button, Message } from "rsuite";
import { connect } from "react-redux";
import "./assets/css/dataset.css";

import { Plus, Trash } from "@rsuite/icons";
import { Navigate } from "react-router-dom";

import {
  addField,
  removeField,
  handleFieldValue,
  setPrimary,
  setEmailField,
  setErrorFields,
} from "../../Features/Data/DataSlice";

class CreateDataStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryField: [],
      primarySet: "",
      errorFieldName: [],
    };
  }

  componentDidMount() {
    const { setEmailField, datafield } = this.props;
    if (datafield.length > 0) {
      setEmailField({
        value: datafield[0].FieldName,
        index: 0,
      });
    }
    for (let j = 0; j < this.props.errorFieldName.length; j++) {
      for (let i = 0; i < this.props.errorFieldName[j].indexes.length; i++) {
        document.getElementById(
          `checkerrorField-${this.props.errorFieldName[j].indexes[i]}`
        ).style.display = "block";
      }
    }
  }

  //   validateLength = (e) => {
  //     const {alertRef} = this.props;
  //     const { name, value } = e.target;

  //     const index = parseInt(e.target.dataset.id, 10);

  //     // Additional validation to ensure the value is within the range of 1 to 255
  //     if (name.startsWith("Length") && index === 0 && (value < 50 || value > 255)) {
  //         // Display an error message or handle the validation as needed
  //         alertRef.current.showToaster({
  //             status: 2,
  //             duration: 1,
  //             message: "The Length Value must be between or equal 50 and 255",
  //         });
  //     }
  // };

  handleChange = (e) => {
    const { handleFieldValue, setPrimary, alertRef } = this.props;
    const { name, value } = e.target;
    // console.log("value", name)
    const index = parseInt(e.target.dataset.id, 10);
    // console.log(index)

    // Additional validation to ensure the value is within the range of 1 to 255
    // Additional validation to ensure the value is within the range of 1 to 255
    // Additional validation to ensure the value is within the range of 1 to 255

    // Additional validation to ensure the value is within the range of 1 to 255
    if (name.startsWith("Length") && (value < 1 || value > 255)) {
      // Display an error message or handle the validation as needed
      alertRef.current.showToaster({
        status: 2,
        duration: 1,
        message: "The Length Value must be between or equal 1 and 255",
      });
      return; // Prevent further processing if the value is invalid
    }

    // if (name.startsWith("Length")) {
    //   let min = index === 0 ? 50 : 1;
    //   if (value < min || value > 255) {
    //     // Display an error message or handle the validation as needed
    //     let errorMessage =
    //     index === 0
    //         ? "The Length Value must be between or equal 50 and 255"
    //         : "The Length Value must be between or equal 1 and 255";

    //     alertRef.current.showToaster({
    //       status: 2,
    //       duration: 1,
    //       message: errorMessage,
    //     });
    //     return; // Prevent further processing if the value is invalid
    //   }
    // }

    if (name === `FieldName-${index}` && index === 0) {
      // alertRef.current.showToaster({
      //   status: 2,
      //   duration: 1,
      //   message: "You can't change, this field is default set for email",
      // });

      handleFieldValue({
        id: e.target.dataset.id,
        class: e.target.dataset.class,
        value: "email",
      });
    } else {
      // console.log("value",alertRef)
      handleFieldValue({
        id: e.target.dataset.id,
        class: e.target.dataset.class,
        value: value,
      });
    }

    if (e.target.dataset.class === "Primary") {
      setPrimary(e.target.dataset.id);
    }
  };

  remove = (i) => {
    let { datafield, removeField, alertRef } = this.props;
    if (datafield.length >= 1) {
      if (i === 0) {
        alertRef.current.showToaster({
          duration: 1,
          status: 0,
          message: "Sorry, You can't delete the email field",
        });
      } else {
        removeField(i);
      }
    }
  };

  hideError = (e) => {
    // this.checkfieldName(event)
    document.getElementById(
      `checkerrorField-${e.target.dataset.id}`
    ).style.display = "none";
  };

  KeyDownName = (e) => {
    let { alertRef } = this.props;
    let regexp = new RegExp("^[a-zA-Z]+$");

    if (e.keyCode === 32) {
      alertRef.current.showToaster({
        status: 0,
        message: "White Space is not allowed",
      });
      e.preventDefault();
    } else if (!regexp.test(e.nativeEvent.key)) {
      alertRef.current.showToaster({
        status: 0,
        message: "Number and Special Characters are not allowed",
      });
      e.preventDefault();
    }
  };

  checkfieldName = async (e) => {
    let { alertRef, setWhitespaceError } = this.props;
    // console.log(setWhitespaceError);
    if (e.target.value.includes(" ")) {
      alertRef.current.showToaster({
        status: 0,
        duration: 3,
        message: "White space is not allowed",
      });

      // Set the state for whitespaceError to true
      // this.setState({ whitespaceError: true }, () => {
      //   console.log(this.state.whitespaceError); // Log updated state
      // });
      setWhitespaceError(true);
      return;
    }
    setWhitespaceError(false); 
    let indexOfDuplicates = [];
    let { datafield, setErrorFields } = this.props;
    for (let j = 0; j < datafield.length; j++) {
      document.getElementById(`checkerrorField-${j}`).style.display = "none";
    }
    let fieldNameArray = [];
    if (datafield.length > 1) {
      for (let i = 0; i < datafield.length; i++) {
        fieldNameArray.push(datafield[i].FieldName.toLowerCase());
      }

      let toFindDuplicates = fieldNameArray.filter(
        (item, index) => fieldNameArray.indexOf(item.toLowerCase()) !== index
      );

      if (toFindDuplicates.length > 0) {
        for (let i = 0; i < toFindDuplicates.length; i++) {
          if (
            indexOfDuplicates.findIndex(
              (e) => e.word.toLowerCase() === toFindDuplicates[i].toLowerCase()
            ) === -1
          ) {
            indexOfDuplicates.push({
              word: toFindDuplicates[i].toLowerCase(),
              indexes: [],
            });
          }

          fieldNameArray.forEach((values, index) => {
            if (values === toFindDuplicates[i]) {
              /* checking for same fieldname */
              let found = indexOfDuplicates.find(
                (e) =>
                  e.word.toLowerCase() === toFindDuplicates[i].toLowerCase()
              ); /*getting object of same word */
              let getIndex = indexOfDuplicates.findIndex(
                (e) =>
                  e.word.toLowerCase() === toFindDuplicates[i].toLowerCase()
              ); /* getting same object word indexes */
              if (!found.indexes.includes(index)) {
                let alreadyPresent = indexOfDuplicates.findIndex((e) =>
                  e.indexes.includes(index)
                );
                if (alreadyPresent > -1) {
                  indexOfDuplicates[alreadyPresent].indexes.splice(
                    (duplicate) => duplicate === index
                  );
                  found.indexes.push(index);
                  indexOfDuplicates.splice(getIndex, 1, found);
                } else {
                  found.indexes.push(index);
                  indexOfDuplicates.splice(getIndex, 1, found);
                }
              }
            }
          });
        }

        for (let j = 0; j < indexOfDuplicates.length; j++) {
          for (let i = 0; i < indexOfDuplicates[j].indexes.length; i++) {
            document.getElementById(
              `checkerrorField-${indexOfDuplicates[j].indexes[i]}`
            ).style.display = "block";
          }
        }
      } else {
        for (let j = 0; j < datafield.length; j++) {
          document.getElementById(`checkerrorField-${j}`).style.display =
            "none";
        }
      }

      setErrorFields(indexOfDuplicates.length > 0 ? indexOfDuplicates : []);
    }
  };

  render() {
    let { datafield, addField, dataName, description, location, primaryIndex } =
      this.props;
    if (!dataName.textName || !location.path || !description.textDes)
      return <Navigate to="/create/data-set/info" replace />;
    return (
      <>
        <Row>
          <Message showIcon type="info" closable>
            First Row is default set for email field.
          </Message>
        </Row>
        <Row
          style={{
            marginTop: 30,
          }}
        >
          <Col md={24}>
            <Form layout="horizontal">
              <Grid fluid>
                <Row>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Name
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Data Type
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Length
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Primary
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Required
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    <Button
                      onClick={() => addField()}
                      appearance="primary"
                      title="Add New Row"
                      style={{ backgroundColor: "#415670", color: "#fff" }}
                    >
                      Add&nbsp;&nbsp;&nbsp;&nbsp;
                      <Plus />
                    </Button>
                  </Col>
                </Row>

                <div
                  style={{
                    marginTop: 10,
                    // overflowY: "scroll",
                    // height: 250,
                    marginBottom: 40,
                  }}
                >
                  {datafield.map((val, idx) => {
                    return (
                      <Row key={idx} style={{ marginBottom: 10 }}>
                        <Col xs={4}>
                          <input
                            // onContextMenu={(e)=>e.preventDefault()}
                            // onPaste={(e)=>e.preventDefault()}
                            required
                            type="text"
                            name={`FieldName-${idx}`}
                            data-id={idx}
                            id={`FieldName-${idx}`}
                            value={datafield[idx].FieldName}
                            className="rs-input"
                            data-class="FieldName"
                            onBlur={this.checkfieldName}
                            onFocus={this.hideError}
                            onChange={this.handleChange}
                            onKeyDown={(e) => this.KeyDownName(e)}
                            autoComplete="off"
                          />
                          <span id={`checkerrorField-${idx}`}>
                            Field name can't be same
                          </span>
                        </Col>

                        <Col xs={4}>
                          <select
                            required
                            data-class="DataType"
                            onChange={this.handleChange}
                            className="rs-input"
                            name={`DataType-${idx}`}
                            data-id={idx}
                            id={`DataType-${idx}`}
                            value={datafield[idx].DataType}
                          >
                            <option value="" disabled hidden>
                              Select an option
                            </option>

                            {idx === 0 ? (
                              <option value="varchar">Varchar</option>
                            ) : (
                              <>
                                <option value="text">Text</option>
                                <option value="varchar">Varchar</option>
                                <option value="int">Integer</option>
                                <option value="date">Date</option>
                                <option value="boolean">Boolean</option>
                                <option value="decimal">Decimal</option>
                              </>
                            )}
                          </select>
                        </Col>

                        <Col xs={4}>
                          <input
                            required
                            type="number"
                            min="1"
                            max="255"
                            name={`Length-${idx}`}
                            disabled={
                              datafield[idx].DataType === "text" ||
                              datafield[idx].DataType === "date" ||
                              datafield[idx].DataType === "decimal" ||
                              datafield[idx].DataType === "boolean"
                            }
                            data-id={idx}
                            id={`Length-${idx}`}
                            value={
                              datafield[idx].DataType === "text" ||
                              datafield[idx].DataType === "date" ||
                              datafield[idx].DataType === "decimal" ||
                              datafield[idx].DataType === "boolean"
                                ? ""
                                : datafield[idx].Length
                            }
                            className="rs-input"
                            onChange={this.handleChange}
                            data-class="Length"
                          />
                        </Col>
                        <Col xs={4}>
                          <select
                            required
                            // disabled={
                            //   (primaryIndex === null
                            //     ? false
                            //     : Number(primaryIndex) === idx
                            //     ? false
                            //     : true) || datafield[idx].DataType === "text"
                            // }
                            data-class="Primary"
                            className="rs-input"
                            name={`Primary-${idx}`}
                            data-id={idx}
                            id={`Primary-${idx}`}
                            value={datafield[idx].Primary}
                            onChange={this.handleChange}
                          >
                            <option value="" hidden>
                              Select a option
                            </option>
                            {idx === 0 ? (
                              <option value=" PRIMARY KEY">True</option>
                            ) : (
                              <option value=" ">False</option>
                            )}
                          </select>
                        </Col>

                        <Col xs={4}>
                          <select
                            required
                            data-class="Required"
                            className="rs-input"
                            name={`Required-${idx}`}
                            data-id={idx}
                            id={`Required-${idx}`}
                            value={datafield[idx].Required}
                            onChange={this.handleChange}
                          >
                            <option value="" disabled hidden>
                              Select a option
                            </option>
                            <option value="NOT NULL">True</option>
                            {idx === 0 ? null : (
                              <option value=" ">False</option>
                            )}
                          </select>
                        </Col>
                        {/* 
                        <Col xs={4}>
                          <select
                            required
                            // disabled={
                            //   (primaryIndex === null
                            //     ? false
                            //     : Number(primaryIndex) === idx
                            //     ? false
                            //     : true) || datafield[idx].DataType === "text"
                            // }
                            data-class="Primary"
                            className="rs-input"
                            name={`Primary-${idx}`}
                            data-id={idx}
                            id={`Primary-${idx}`}
                            value={datafield[idx].Primary}
                            onChange={this.handleChange}
                          >
                            <option value="" hidden>
                              Select a option
                            </option>
                            {idx === 0 ? (
                              <option value=" PRIMARY KEY">True</option>
                            ) : (
                              <option value=" ">False</option>
                            )}
                          </select>
                        </Col> */}

                        <Col
                          xs={4}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          <Trash
                            style={{ fontSize: 20, marginTop: 8 }}
                            title="Delete"
                            onClick={this.remove.bind(this, idx)}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <hr />
                <Row>
                  <Col
                    xs={20}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <Button
                      type="submit"
                      id="submitbutton"
                      style={{ display: "none" }}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    datafield,
    emailfield,
    errorFieldName,
    dataName,
    description,
    location,
    primaryIndex,
  } = state.DataSlice;
  return {
    datafield,
    emailfield,
    errorFieldName,
    dataName,
    description,
    location,
    primaryIndex,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addField: () => dispatch(addField()),
  removeField: (e) => dispatch(removeField(e)),
  handleFieldValue: (id, type) => dispatch(handleFieldValue(id, type)),
  setPrimary: (id) => dispatch(setPrimary(id)),
  setEmailField: (value, index) => dispatch(setEmailField(value, index)),
  setErrorFields: (errorArray) => dispatch(setErrorFields(errorArray)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDataStructure);
