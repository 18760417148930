class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
  }

  updateLocalAccessToken(token) {
    // console.log("update", token);
    let user = JSON.parse(localStorage.getItem("user"));
    // console.log("before", user.token);
    user.token = token;
    // console.log("after", user.token);
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("status");
  }
}

const tokenService = new TokenService();
export default tokenService;
