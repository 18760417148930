import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchDataFolder = createAsyncThunk("data-folder", async () => {
  const response = await axios.get(`/folder/data-folder`);
  return response.data;
});

export const createDataFolder = createAsyncThunk(
  "data-folder/create",
  async (name, parentId, is_folder_or_file) => {
    try {
      const response = await axios.post(`/folder/data-folder`, name, parentId, is_folder_or_file);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteDataFolder = createAsyncThunk("data-folder/delete", async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`/folder/data-folder/?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const renameDataFolder = createAsyncThunk("data-folder/rename", async ({ id, text }) => {
  try {
    const response = await axios.put(`/folder/data-folder/?id=${id}&name=${text}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
