export let template = {
  templateName: {
    textName: "",
    errorInName: false,
    isExistError: false,
    errMessageName: null
  },
  description: {
    textDes: "",
    errorInDescription: false,
    errMessageDes: null
  },
  categories: {
    categoryBundle: [],
    errorInCategory: false
  },
  location: {
    path: null,
    errorInLocation: false
  }
};

export let email = {
  emailName: {
    textName: "",
    errorInName: false,
    isExistError: false,
    errMessageName: null
  },
  description: {
    textDes: "",
    errorInDescription: false,
    errMessageDes: null
  },
  subject: {
    textSubject: "",
    errorInSubject: false,
    errMessageSubject: null
  },
  location: {
    path: null,
    errorInLocation: false
  }
};

export let assets = {
  isGrid:
    JSON.parse(localStorage.getItem("isGrid")) === null
      ? true
      : JSON.parse(localStorage.getItem("isGrid")),
  emails: {
    email: [],
    count: null,
    pages: null,
    page: null
  },
  emailById: {},
  templates: {
    template: [],
    count: null,
    pages: null,
    page: null
  },
  dataSets: {
    dataSet: [],
    count: null,
    pages: null,
    page: null
  },
  loading: false,
  error: null,
  success: true
};

export let field = {
  FieldName: "",
  DataType: "",
  Length: "",
  Required: "",
  Primary: ""
};

export let dataArchive = {
  dataName: {
    textName: "",
    errorInName: false,
    isExistError: false,
    errMessageName: null
  },
  description: {
    textDes: "",
    errorInDescription: false,
    errMessageDes: null
  },

  location: {
    path: null,
    errorInLocation: false
  },
  datafield: [field],
  errorFieldName: [],
  emailfield: {
    index: null,
    value: ""
  },
  primaryIndex: null
};
