import React, { useState } from "react";
import { Modal, Button, Message, Form, ButtonToolbar } from "rsuite";
import { connect } from "react-redux";
import { emailSend } from "../../../Features/Email/EmailThunk";
import TrashIcon from "@rsuite/icons/Trash";
import PlusIcon from "@rsuite/icons/Plus";

const SendTestEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState([{ userEmailAddress: "" }]);

  const handleChange = (value, index) => {
    const updatedEmails = [...emailAddress];
    updatedEmails[index] = { userEmailAddress: value };
    setEmailAddress(updatedEmails);
  };

  const handleAddMore = () => {
    const { alertRef } = props;

    // Check if all existing email fields are filled correctly
    const isValidExistingEmails = emailAddress.every(
      (email) => email.userEmailAddress.trim() !== ""
    );

    if (isValidExistingEmails && emailAddress.length < 5) {
      // Validate the format of each new email address
      for (const email of emailAddress) {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;
        // const emailRegex = /^\s*[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+\s*$/;


        // Check if the email address matches the regex
        if (!emailRegex.test(email.userEmailAddress)) {
          // Use alertRef to display an alert message if any new email address has an invalid format
          alertRef.current.showToaster({
            type: "error",
            message: "Invalid email address found",
            duration: 1,
          });
          return; // Return without adding more email addresses
        }

        // Check if the length of the email address exceeds 250 characters
        if (email.userEmailAddress.length > 250) {
          // Use alertRef to display an alert message if the length exceeds 250 characters
          alertRef.current.showToaster({
            type: "error",
            message: "Email address cannot exceed 250 characters",
            duration: 1,
          });
          return; // Return without adding more email addresses
        }
      }

      // If all checks pass, add another empty email field
      setEmailAddress([...emailAddress, { userEmailAddress: "" }]);
    } else {
      // Use alertRef to display an alert message if any existing email field is not filled correctly or if the maximum limit is reached
      alertRef.current.showToaster({
        type: "error",
        message: isValidExistingEmails
          ? "Maximum 5 email addresses allowed"
          : "Please fill in all email addresses",
        duration: 1,
      });
    }
  };

  const handleRemove = (index) => {
    if (index > 0) {
      const updatedEmails = [...emailAddress];
      updatedEmails.splice(index, 1);
      setEmailAddress(updatedEmails);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const finalEmailAddresses = emailAddress
      .filter((email) => email.userEmailAddress.trim() !== "")
      .map((email) => email.userEmailAddress);

    // console.log("All email addresses:", finalEmailAddresses);

    if (finalEmailAddresses.length > 0) {
      testSend();
    }
  };

  const testSend = async () => {
    // console.log("Inside TestSend");
    try {
      const { emailSend, email, alertRef } = props;

      // Check if any email input box is empty
      const isAnyEmailEmpty = emailAddress.some(
        (email) => email.userEmailAddress.trim() === ""
      );

      console.log(isAnyEmailEmpty);
      if (isAnyEmailEmpty) {
        // Use alertRef to display an alert message if any email input box is empty
        alertRef.current.showToaster({
          type: "error",
          message: "Please fill in all email addresses",
          duration: 1,
        });
        return; // Return without sending the email
      }

      // Validate email length
      for (const email of emailAddress) {
        if (email.userEmailAddress.length > 250) {
          // Use alertRef to display an alert message if the length exceeds 250 characters
          alertRef.current.showToaster({
            type: "error",
            message: "Email address cannot exceed 250 characters",
            duration: 1,
          });
          return; // Return without sending the email
        }
      }
      // Validate email format
      for (const email of emailAddress) {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;

        if (!emailRegex.test(email.userEmailAddress)) {
          // Use alertRef to display an alert message for invalid email addresses
          alertRef.current.showToaster({
            type: "error",
            message: "Invalid email address found",
            duration: 1,
          });
          return; // Return without sending the email
        }
      }
      // Conditon checking (Email Validation)
      const finalEmailAddress = emailAddress.filter(
        (item) =>
          item.userEmailAddress !== null && item.userEmailAddress.trim() !== ""
      );

      // console.log(finalEmailAddress);
      if (finalEmailAddress.length) {
        setLoading(true);
        const response = await emailSend({
          id: email.email_id,
          emailAddress: finalEmailAddress,
        });
        alertRef.current.showToaster(response.payload);
        closeModal();
      } else {
        // Handle validation error
        console.log("Email Validation Error Found");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeModal = () => {
    setEmailAddress([{ userEmailAddress: "" }]);
    setLoading(false);
    const { handleSendModal } = props;
    handleSendModal();
  };

  const { open } = props;

  return (
    <Modal size="sm" backdrop="static" open={open} onClose={closeModal}>
      <Modal.Header>
        <Modal.Title>Test-Send</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Message showIcon type="info" closable={true} header="About Test Send">
          You Can Add Up to 5 email addresses
        </Message>
        <Form onSubmit={handleSubmit}>
          {emailAddress.map((email, index) => (
            <Form.Group controlId={`email-${index}`} key={index}>
              {/* <Form.ControlLabel>Email {index + 1}</Form.ControlLabel> */}
              <Form.Control
                name={`email-${index}`}
                type="email"
                placeholder={`Email ${index + 1}`}
                value={email.userEmailAddress}
                onChange={(value) => handleChange(value, index)}
              />
              {index > 0 && (
                <Button
                  appearance="link"
                  onClick={() => handleRemove(index)}
                  style={{ color: "red", marginLeft: "10px" }}
                >
                  Remove
                </Button>
              )}
              <Form.HelpText tooltip>Email is required</Form.HelpText>
            </Form.Group>
          ))}
          <Form.Group>
            <ButtonToolbar>
              <Button
                appearance="default"
                onClick={handleAddMore}
                disabled={emailAddress.length >= 5}
              >
                Add More
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} appearance="subtle">
          Cancel
        </Button>
        <Button
          appearance="primary"
          loading={loading}
          type="button"
          onClick={testSend}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  email: state.AssetSlice.emailById.email,
});

const mapDispatchToProps = (dispatch) => ({
  emailSend: ({ id, emailAddress }) => dispatch(emailSend({ id, emailAddress })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendTestEmail);
