// import React, { Component } from "react";
// import { Col, Row } from "rsuite";
// import TotalSendInteraction from "./Animation/ReportingTotalSend";
// import OpenInteraction from "./Animation/OpenInteraction";
// import ClickInteraction from "./Animation/ClickInteraction";

// export default class ReportingViaAnimation extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       TotalSend: [
//         {
//           title: "Total Send",
//           value: this.props.report.activities.tr,
//           color: "#DFEE98"
//         },
//         {
//           title: "Delivered",
//           value: this.props.report.activities.status,
//           color: "#9fd86b"
//         }
//       ],
//       OpenCount: [
//         {
//           title: "Total open",
//           value: this.props.report.activities.to,
//           color: "#33cccc"
//         },
//         {
//           title: "Unique open",
//           value: this.props.report.activities.uo,
//           color: "#80dfff"
//         }
//       ],
//       ClickCount: [
//         {
//           title: "Total click",
//           value: this.props.report.activities.tc,
//           color: "#C771D6"
//         },
//         {
//           title: "Unique click",
//           value: this.props.report.activities.uc,
//           color: "#F265D5"
//         }
//       ]
//     };
//     // console.log({ props });
//   }

//   render() {
//     return (
//       <Row>
//         <Col md={7} xs={7} sm={7} className="outer-box-pie">
//           TOTAL CAMPAIGN SEND <br /> <br />
//           <TotalSendInteraction TotalSend={this.state.TotalSend} />
//           <Row style={{ marginTop: 10 }}>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 1 }}>
//               <span className="dot-color-pie" style={{ background: "#DFEE98" }}></span>
//               &nbsp;&nbsp;
//               <span>Total Send : {this.state.TotalSend[0].value}</span>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 3 }}>
//               <span className="dot-color-pie" style={{ background: "#9fd86b" }}></span>
//               &nbsp;&nbsp;
//               <span>Delivered : {this.state.TotalSend[1].value}</span>
//             </Col>
//           </Row>
//         </Col>
//         <Col md={1} xs={1} sm={1}></Col>
//         <Col md={7} xs={7} sm={7} className="outer-box-pie">
//           OPEN COUNT <br /> <br />
//           <OpenInteraction OpenCount={this.state.OpenCount} />
//           <Row style={{ marginTop: 10 }}>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 1 }}>
//               <span className="dot-color-pie" style={{ background: "#33cccc" }}></span>
//               &nbsp;&nbsp;
//               <span>Total opens : {this.state.OpenCount[0].value}</span>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 3 }}>
//               <span className="dot-color-pie" style={{ background: "#80dfff" }}></span>
//               &nbsp;&nbsp;
//               <span>Unique opens : {this.state.OpenCount[1].value}</span>
//             </Col>
//           </Row>
//         </Col>
//         <Col md={1} xs={1} sm={1}></Col>
//         <Col md={7} xs={7} sm={7} className="outer-box-pie">
//           CLICK COUNT <br /> <br />
//           <ClickInteraction ClickCount={this.state.ClickCount} />
//           <Row style={{ marginTop: 10 }}>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 1 }}>
//               <span className="dot-color-pie" style={{ background: "#C771D6" }}></span>
//               &nbsp;&nbsp;
//               <span>Total clicks : {this.state.ClickCount[0].value}</span>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={24} sm={24} xs={24} style={{ paddingTop: 3 }}>
//               <span className="dot-color-pie" style={{ background: "#F265D5" }}></span>
//               &nbsp;&nbsp;
//               <span>Unique clicks : {this.state.ClickCount[1].value}</span>
//             </Col>
//           </Row>
//         </Col>
//         <Col md={1} xs={1} sm={1}></Col>
//       </Row>
//     );
//   }
// }

import React, { Component } from "react";
import { Col, Row } from "rsuite";
import Chart from "chart.js/auto";

export default class ReportingViaAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalSend: [
        {
          title: "Total Send",
          value: this.props.report.activities.tr,
          color: "#DFEE98",
        },
        {
          title: "Delivered",
          value: this.props.report.activities.status,
          color: "#9fd86b",
        },
      ],
      OpenCount: [
        {
          title: "Total open",
          value: this.props.report.activities.to,
          color: "#33cccc",
        },
        {
          title: "Unique open",
          value: this.props.report.activities.uo,
          color: "#80dfff",
        },
      ],
      ClickCount: [
        {
          title: "Total click",
          value: this.props.report.activities.tc,
          color: "#C771D6",
        },
        {
          title: "Unique click",
          value: this.props.report.activities.uc,
          color: "#F265D5",
        },
      ],
      chartRef: React.createRef(), // Add the chartRef property
    };
  }

  updateChart = () => {
    const { chartRef } = this.state;
    const { TotalSend, OpenCount, ClickCount } = this.state;

    const data = {
      labels: [
        "Total Sends",
        "Delivered",
        "Total Opens",
        "Unique Opens",
        "Total Clicks",
        "Unique Clicks",
      ],
      datasets: [
        {
          label: "",
          backgroundColor: [
            "#DFEE98",
            "#9fd86b",
            "#33cccc",
            "#80dfff",
            "#C771D6",
            "#F265D5",
          ],
          data: [
            TotalSend[0].value,
            TotalSend[1].value,
            OpenCount[0].value,
            OpenCount[1].value,
            ClickCount[0].value,
            ClickCount[1].value,
          ],
        },
      ],
    };

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      if (ctx) {
        new Chart(ctx, {
          type: "bar",
          data: data,
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
            },
          },
        });
      }
    }
};


  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.updateChart();
    }
  }

  render() {
    return (
      <Row>
        <Col md={24} sm={24} xs={24}>
          <canvas
            ref={this.state.chartRef}
            width="600"
            height="300"
            className="barcanvas"
          ></canvas>
        </Col>
      </Row>
    );
  }
}
