import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchMontlyReport = createAsyncThunk(
  "reports/montly",
  async (reportsData) => {
    let { startDate, endDate, aggregated_by } = reportsData;
    try {
      const response = await axios.get(
        `/reports/montly?start_date=${startDate}&end_date=${endDate}&aggregated_by=${aggregated_by}`
      );
      // console.log(response.data);

    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

// Campaign Email Reports

export const getCampaign = createAsyncThunk(
  "campaign/getCampaign",
  async ({ type, page, limit, value }) => {
    try {
      const response = await axios.get(
        `/reports/getCampaign?type=${type}&limit=${Number(limit)}&page=${page}&value=${value}`
      );

      return response.data;
    } catch (e) {
      console.log(e.response.data);
      return e.response.data;
    }
  }
);

export const emailReport = createAsyncThunk("reports/campaign/email-report", async ({ id }) => {
  try {
    const response = await axios.get(`/reports/campaign/email?id=${id}`);

    return response.data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
});
