import React, { Component } from "react";

import { connect } from "react-redux";

import { getIPs } from "../Features/Settings/SettingsThunk";

import Loading from "../Loading/Loading";

import "./css/settings.css";

class IPSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ipData: []
    };
  }
  async componentDidMount() {
    try {
      let { getIPs } = this.props;
      let response = await getIPs();
      this.setState({
        loading: false,
        ipData: response.payload.data
      });
    } catch (e) {}
  }
  render() {
    let { loading, ipData } = this.state;
    // console.log(ipData);
    if (loading) return <Loading />;
    return (
      <>
       <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
          // marginBottom: "5%",
        }}
      >
        <h3 className="hero-head">IP Setting</h3>
      </div>
      <div className="setting-container ip-setting">
        <table>
          <thead>
            <th>IP</th>
            <th>POOLS</th>
            <th>Start Date</th>
            <th>Sub Users</th>
            <th>Warm Up</th>
            <th>White Labeled</th>
          </thead>
          <tbody>
            {ipData.map(function (info, j) {
              return (
                <tr key={j}>
                  <td> {info.ip}</td>
                  <td>{info.pools.toString()}</td>
                  <td>{info.start_date}</td>
                  <td>{info.subusers.toString()}</td>
                  <td>{info.warmup.toString()}</td>
                  <td>{info.whitelabeled.toString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      </>
      
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  getIPs
})(IPSetting);
