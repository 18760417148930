import React, { Component } from "react";
import { Row, Col, Content, Container } from "rsuite";
import { connect } from "react-redux";
import { saveCampaign } from "../../Features/CampaignManagement/CampaignPropertiesThunk";
import { Alert } from "../../Utils/Alert";
import { withRouter } from "../../AccountManagement/withRouter";
class CampaignPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      count: "",
    };
    this.alertRef = React.createRef();

    this.props.setClick(this.campaignSave);
  }

  campaignSave = async () => {
    try {
      this.setState({
        loading: true,
      });
      const {
        campaignname,
        subjectLine,
        senderProfile,
        folderId,
        camp_Category,
        suppression,
        suppression_Include,
        throttle,
      } = this.props;
      console.log("campaignname", campaignname)
      console.log("subjectLine", subjectLine)
      var selectedEmail = this.props.selectedEmailProperties;
      var selectedData = this.props.selectedDataProperties;
      var campaignDetails = {
        campaignname: campaignname,
        subjectLine:subjectLine,
        senderProfile: senderProfile,
        suppression_Include,
        suppression,
        category: camp_Category,
        folderId: folderId,
        throttle,
      };
      // console.log(":::::::::::", campaignDetails);
      let { saveCampaign } = this.props;
      const result = await saveCampaign({
        campaignDetails,
        selectedData,
        selectedEmail,
        folderId,
      });
      // console.log(":::::::::::result", result);

      const payload = result.payload;
      // console.log(payload.status);

      const message = payload.message;
      // console.log(message);

      const campaignId = message.campaign_id;
      // console.log(campaignId);

      this.alertRef.current.showToaster(result.payload.message);
      this.props.navigate(`/assetlibrary/edit/campaign/${campaignId}`);
    } catch (e) {
      this.props.navigate("/assetlibrary/campaign");
    }
  };

  render() {
    let { suppression, suppression_Include, throttle } = this.props;
    let { suppressionName, suppressionCount } = suppression;
    return (
      <Content>
        <Alert ref={this.alertRef} />
        <Row style={{ height: "100vh" }}>
          <Col
            md={10}
            className="description-left-side"
            style={{ marginLeft: 10, marginTop: 0, height: "auto" }}
          >
            <div className="description-left-side-campaign">
              <table className="emailDescription " style={{ width: "100%" }}>
                <tr>
                  <td>
                    <b>Campaign Name:</b>
                  </td>
                  <td>{this.props.campaignname}</td>
                </tr>
                <tr>
                  <td>
                    <b>Email Name:</b>
                  </td>
                  <td>{this.props.selectedEmailProperties.emailName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Subject Line:</b>
                  </td>
                  <td>{this.props.subjectLine}</td>
                </tr>
                <tr>
                  <td>
                    <b>Sender Profile:</b>
                  </td>
                  <td>{this.props.senderProfile}</td>
                </tr>
                <tr>
                  <td>
                    <b>Data Set Name:</b>
                  </td>
                  <td>{this.props.selectedDataProperties.dataName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Data Set Count:</b>
                  </td>
                  <td>{this.props.selectedDataProperties.dataCount}</td>
                </tr>
                {suppression_Include && (
                  <>
                    <tr>
                      <td>
                        <b>Supression Set Name:</b>
                      </td>
                      <td>{suppressionName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supression Set count:</b>
                      </td>
                      <td>{suppressionCount}</td>
                    </tr>
                  </>
                )}

                {throttle.isThrottle && (
                  <tr>
                    <td>
                      <b>Throttle / 20 minutes:</b>
                    </td>
                    <td>{throttle.throttleLimit}</td>
                  </tr>
                )}
              </table>
            </div>
          </Col>
          <Col md={1}></Col>
          <Col
            md={10}
            sm={24}
            className="defineproperties"
            style={{height: "500px", overflowY: "auto", width:"39%", marginLeft:"6%" }}
          >
            <img
              src={`${
                this.props.selectedEmailProperties.emailImgPath
              }?${Math.random()}`}
              data-id={this.props.selectedEmailProperties.emailImgPath}
              alt={this.props.selectedEmailProperties.emailImgPath}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        {/* <Row>
      <Col md={2}></Col>
    </Row> */}
      </Content>
    );
  }
}
const mapStateToProps = (state) => ({
  emailList: state.CampaignPropertiesSlice.emailList,
  loadingCampaign: state.CampaignPropertiesSlice.loadingCampaign,
  dataList: state.CampaignPropertiesSlice.loadingCampaign,
  campaignname: state.CampaignPropertiesSlice.campaignname,
  subjectLine:state.CampaignPropertiesSlice.subjectLine,
  suppression_Include: state.CampaignPropertiesSlice.suppression_Include,
  camp_Category: state.CampaignPropertiesSlice.camp_Category,
  senderProfile: state.CampaignPropertiesSlice.senderProfile,
  selectedEmailProperties:
    state.CampaignPropertiesSlice.selectedEmailProperties,
  error: state.CampaignPropertiesSlice.error,
  selectedEmailFolderID: state.CampaignPropertiesSlice.selectedEmailFolderID,
  selectedDataListFolderID:
    state.CampaignPropertiesSlice.selectedDataListFolderID,
  selectedDataProperties: state.CampaignPropertiesSlice.selectedDataProperties,
  errorFirstStepEmail: state.CampaignPropertiesSlice.errorFirstStepEmail,
  errorFirstStepData: state.CampaignPropertiesSlice.errorFirstStepData,
  folderId: state.CampaignPropertiesSlice.folderId,
  suppression: state.CampaignPropertiesSlice.suppression,
  throttle: state.CampaignPropertiesSlice.throttle,
});
export default connect(mapStateToProps, { saveCampaign })(
  withRouter(CampaignPreview)
);
