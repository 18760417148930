import React from "react";
import { connect } from "react-redux";
import convertToUserTimeZone from "../../../hooks/convertToUserTimeZone";

const AboutDataSet = ({ dataset }) => {
  const { dataById } = dataset;

  const renderDataRow = (label, value) => (
    <tr key={label}>
      <td>
        <b>{label} &nbsp;&nbsp;:&nbsp;&nbsp;</b>
      </td>
      <td>{value}</td>
    </tr>
  );

  const renderDataRows = () => {
    const dataRows = [
      { label: "Name", value: dataById.data_list_name },
      { label: "Count", value: `${dataById.data_list_count} records` },
      { label: "Created by", value: dataById.data_list_created_by },
      {
        label: "Created At",
        value: convertToUserTimeZone(dataById.data_list_created_at),
      },
      { label: "Modified by", value: dataById.data_list_modified_by },
      {
        label: "Modified At",
        value: convertToUserTimeZone(dataById.data_list_modified_at),
      },
    ];

    return dataRows.map((row) => renderDataRow(row.label, row.value));
  };

  return (
    <table className="description-table">
      <tbody>{renderDataRows()}</tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  dataset: state.DataSlice,
});

export default connect(mapStateToProps)(AboutDataSet);
