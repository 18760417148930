export const collapseSidebar = [
  "/createnewproject/create/email/info",
  "/createnewproject/create/template/info",
  // ASSETS PATHS
  "/assetlibrary/templates",
  "/assetlibrary/emails",
  "/assetlibrary/images",
  // DATASET
  "/assetlibrary/data",
  "/create/data-set/structure",
  "/create/data-set/preview",
  // IMAGES

  // CAMPAIGN
  "/createcampaign/email/info",
  "/assetlibrary/campaign",

  // REPORTING
  "/reports/campaign/email",

  // SETTINGS
  "/settings/ip",
];

export const pathnames = [
  "/dashboard",
  "/createnewproject",
  "/continueproject",
  "/image-upload",
  "/createcampaign",
  "/assetlibrary",
  "/create/data-set/info",
  "/reports/campaign",
];

export const editorPath = [
  "/createnewproject/create/email/editor",
  "/createnewproject/create/template/editor",
];

export const editorEditPath = [
  {
    path: "/assetlibrary/edit/template/:id",
  },
  {
    path: "/assetlibrary/edit/email/:id",
  },
];

export const hideHeaderSearch = [
  "/assetlibrary/templates",
  "/assetlibrary/emails",
];

export const stepPathDataSet = [
  "/assetlibrary/data",
  "/create/data-set/info",
  "/create/data-set/structure",
  "/create/data-set/preview",
];

export const dynamicCollapseSidebar = [
  {
    path: "/assetlibrary/data/edit/:id/:type",
  },
  {
    path: "assetlibrary/edit/campaign/:id",
  },
  {
    path: "/reports/campaign/:type",
  },
  {
    path: "/reports/campaign/:type/:id",
  },
];

export const assetLocationCommon = {
  "/assetlibrary/emails": "Emails",

  "/assetlibrary/templates": "Templates",

  "/assetlibrary/data": "Data Sets",

  "/assetlibrary/campaign": "Campaigns",
};

export const showHideComponentAssets = {
  "/assetlibrary/emails": "email",
  "/assetlibrary/templates": "template",
  "/assetlibrary/data": "data",
  "/assetlibrary/campaign": "campaign",
};

export const reportLocationCommon = {
  email: "Campaign Emails",
};

export const confirmationModalPaths = {
  template: "/createnewproject",
  email: "/createnewproject",
  dataset: "/dashboard",
};
