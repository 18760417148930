import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMontlyReport } from "../Features/Reports/ReportThunk";
import { Col, Row, Dropdown, Placeholder, Loader } from "rsuite";

import { Pie } from "react-chartjs-2";

import { userStatus } from "../Features/Login/LoginThunk";

class BrowserReport extends Component {
  constructor() {
    super();
    this.state = {
      chartDataOpen: {},
      clicks: "",
      unique_clicks: "",
      selectedYear: new Date().getFullYear(),
      aggregated_by: "month",
      loading: true,
      labelColors: {}, // Add labelColors state to store label colors
      hoveredDevice: null, // Track hovered device for displaying percentage
    };
  }
  async componentDidMount() {
    await this.getChartOverAllData();
  }

  async getChartOverAllData() {
    const { selectedYear, aggregated_by } = this.state;
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    const { fetchMontlyReport } = this.props;

    try {
      let responseData = await fetchMontlyReport({
        startDate,
        endDate,
        aggregated_by,
      });

      if (responseData.payload.status === 1) {
        const stats = responseData.payload.data.responses[2];

        let labels = [];
        let clicks = [];
        let uniqueclicks = [];
        let deviceData = {};
        let labelColors = { ...this.state.labelColors }; // Copy current labelColors

        stats.forEach((entry) => {
          // Define predefined colors for each device type
          const predefinedColors = {
            "Android Phone": "#FF6384",
            Chrome: "#36A2EB",
            Firefox: "#DE76DC",
            Safari: "#0028c1",
            IE: "#4CAF50",
            iPhone: "#741ee5",
            other: "#7B1FA2",
            iPad: "#FBC02D",
            // Add more colors for other device types if needed
          };

          entry.stats.forEach((stat) => {
            const deviceName = stat.name;
            if (!labels.includes(deviceName)) {
              labels.push(deviceName);
              // Check if label already has a color assigned, if not, assign a new color based on device type
              if (!labelColors[deviceName]) {
                labelColors[deviceName] = predefinedColors[deviceName] || "#415670";
              }
            }

            if (!deviceData[deviceName]) {
              deviceData[deviceName] = {
                clicks: 0,
                uniqueClicks: 0,
              };
            }

            deviceData[deviceName].clicks += stat.metrics.clicks;
            deviceData[deviceName].uniqueClicks += stat.metrics.unique_clicks;
          });
        });

        let unique_clicks = 0,
          totalclicks = 0;

        Object.keys(deviceData).forEach((deviceName) => {
          const { clicks, uniqueClicks } = deviceData[deviceName];
          totalclicks += clicks;
          unique_clicks += uniqueClicks;
        });

        labels.forEach((label) => {
          const uniqueClicksPercentage =
            (deviceData[label].uniqueClicks / unique_clicks) * 100;

          uniqueclicks.push(uniqueClicksPercentage.toFixed(2));
        });

        this.setState({
          chartDataOpen: {
            labels: labels,
            datasets: [
              {
                label: "Unique clicks",
                data: uniqueclicks,
                backgroundColor: labels.map(
                  (label) => labelColors[label] || "#000" // Use labelColors to get consistent colors
                ),
              },
            ],
          },
          loading: false,
          unique_clicks,
          labelColors, // Update labelColors in state
        });
      } else {
        console.log("Not Working");
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleYearChange = async (eventKey, event) => {
    this.setState(
      { selectedYear: eventKey, loading: true },
      this.getChartOverAllData
    );
  };

  handleHover = (device) => {
    this.setState({ hoveredDevice: device });
  };

  render() {
    const { loading, chartDataOpen, selectedYear, labelColors, hoveredDevice } = this.state;
    let { theme } = this.props;

    const legendItems =
      chartDataOpen && chartDataOpen.labels
        ? chartDataOpen.labels.map((label, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                position: "relative", // Add position relative for tooltip positioning
                cursor: "pointer", // Add cursor pointer for hover effect
              }}
              onMouseEnter={() => this.handleHover(label)}
              onMouseLeave={() => this.handleHover(null)}
            >
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: labelColors[label] || "#000", // Use labelColors to get consistent colors
                  marginRight: "5px",
                }}
              ></div>
              {label}
              {hoveredDevice === label && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    padding: "3px 8px",
                    borderRadius: "4px",
                    marginLeft: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 9999,
                  }}
                >
                  {/* Display percentage when hovering over the device */}
                  {chartDataOpen.datasets[0].data[index]}%
                </div>
              )}
            </div>
          ))
        : null;

    return (
      <>
        {!loading && chartDataOpen !== "" ? (
          <>
            <Row style={{ paddingTop: 14 }}>
              <Col md={16}>
                <b className="RecentE-number">Unique Click % by Browser</b>
              </Col>
              <Col md={8} style={{ textAlign: "right" }}>
                <Dropdown
                  eventKey="1"
                  title={`${selectedYear}`}
                  onSelect={this.handleYearChange}
                >
                  <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                  <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col md={24} style={{ height: 210, position: "relative" }}>
                <Pie
                  data={chartDataOpen}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    cutout: "20%",
                    color: theme === "dark" ? "#fff" : "#000",
                  }}
                />
                {legendItems && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    {legendItems}
                  </div>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { theme } = state.LoginSlice;
  return {
    theme,
  };
}

export default connect(mapStateToProps, { fetchMontlyReport, userStatus })(
  BrowserReport
);
