import { createSlice } from "@reduxjs/toolkit";
import { checkDataIdInDatabase } from "./CampaignPropertiesThunk";

const initialState = {
  emailList: [],
  dataList: [],
  errorFolder: false,
  loadingCampaign: "",
  campaignname: "",
  subjectLine: "",
  camp_Category: "",
  senderProfile: "",
  senderProfileReplyTo: "",
  suppression_Include: false,
  errorFirstStepEmail: false,
  errorFirstStepData: false,
  error: false,
  throttle: {
    isThrottle: false,
    throttleLimit: 500,
    throttleProgress: null,
    throttleError: false,
  },
  selectedEmailFolderID: "",
  selectedDataListFolderID: "",
  selectedEmailProperties: {
    emailId: "",
    emailName: "",
    emailHtmlPath: "",
    emailSubject: "",
    emailImgPath: "",
    emailFolder: null,
  },
  selectedDataProperties: {
    dataName: "",
    dataId: "",
    dataCount: "",
    dataSetFolder: null,
  },
  save: "",

  folderId: "",

  errorCampaignName: false,
  errorCampaignCat: false,
  errorCampaignLocation: false,
  errorSenderProfile: false,
  errorSelectedEmail: false,
  suppression: {
    suppressionName: null,
    suppressionId: null,
    suppressionFolder: null,
    suppressionCount: null,
  },
  isSuppressionError: false,
  // NOTE Only for simple loading to deal with promises
  loading: false,
  // NOTE It is used to check whether Data is associated with campaign or not - it is array to store all items boolean
  campaignPropertiesCheckedInDataId: false,
};

const CampaignPropertiesSlice = createSlice({
  name: "CampaignPropertySlice",
  initialState,
  reducers: {
    resetData() {
      return initialState;
    },

    errorCampaignName(state, data) {
      state.errorCampaignName = data.payload;
    },
    emailSelected(state, data) {
      state.selectedEmailProperties = data.payload;
      state.errorFirstStepData = false;
      state.errorFirstStepEmail = false;
    },
    campaignnameText(state, data) {
      state.campaignname = data.payload;
    },
    subjectLineValues(state, data) {
      state.subjectLine = data.payload
    },
    selectCampaignCategory(state, data) {
      state.camp_Category = data.payload;
    },
    setSenderProfile(state, data) {
      state.senderProfile = data.payload;
    },
    setSenderProfileReplyTo(state, data) {
      state.senderProfileReplyTo = data.payload;
    },
    dataSelected(state, data) {
      state.selectedDataProperties = data.payload;
    },
    campaignSelectFolderId(state, data) {
      state.folderId = data.payload;
    },

    errorCampFolder(state, data) {
      state.errorFolder = data.payload;
    },
    errorStepEmail(state, data) {
      state.errorFirstStepEmail = data.payload;
    },
    errorStepData(state, data) {
      state.errorFirstStepData = data.payload;
    },
    suppressionState(state, data) {
      state.suppression_Include = data.payload;
    },
    setSupressionSelected(state, data) {
      state.suppression = data.payload;
    },
    errorSuppression(state, data) {
      state.isSuppressionError = data.payload;
    },
    setThrottleSelected(state, data) {
      state.throttle = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* SECTION TO check if any dataset is associated with campaign (Active badge)*/
      .addCase(checkDataIdInDatabase.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkDataIdInDatabase.fulfilled, (state, action) => {
        const { dataId, exists } = action.payload;
        console.log(action.payload);
        // Update the state with the existence status of the dataId
        state.campaignPropertiesCheckedInDataId = exists;
        state.loading = false; // Update loading state
      })
      .addCase(checkDataIdInDatabase.rejected, (state, action) => {
        console.error("Error in checkDataIdInDatabase");
        state.loading = false; // Update loading state in case of error
      });
  },
});

const { actions, reducer } = CampaignPropertiesSlice;
export const {
  emailSelected,
  campaignnameText,
  resetData,
  subjectLineValues,
  selectCampaignCategory,
  setSenderProfile,
  setSenderProfileReplyTo,
  dataSelected,
  campaignSelectFolderId,
  errorCampaignName,
  errorCampFolder,
  suppressionState,
  errorStepEmail,
  setSupressionSelected,
  errorStepData,
  errorSuppression,
  setThrottleSelected,
} = actions;
export default reducer;