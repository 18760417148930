import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCampaignFolder = createAsyncThunk("campaign-folder", async () => {
  const response = await axios.get(`/folder/campaign-folder`);
  return response.data;
});

export const createCampaignFolder = createAsyncThunk(
  "campaign-folder/create",
  async (name, parentId, is_folder_or_file) => {
    try {
      const response = await axios.post(
        `/folder/campaign-folder`,
        name,
        parentId,
        is_folder_or_file
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteCampaignFolder = createAsyncThunk("campaign-folder/delete", async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`/folder/campaign-folder/?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const renameCampaignFolder = createAsyncThunk(
  "campaign-folder/rename",
  async ({ id, text }) => {
    try {
      const response = await axios.put(`/folder/campaign-folder/?id=${id}&name=${text}`);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
