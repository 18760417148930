import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Badge } from "rsuite";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { resetData } from "../../Features/Data/DataSlice";
import { checkDataIdInDatabase } from "../../Features/CampaignManagement/CampaignPropertiesThunk";
import "../assets/css/view-asset-blocks.css";
 
const RecentTemplates = ({
  dataSets,
  loading,
  reset,
  campaignPropertiesCheckedInDataId,
  checkDataIdInDatabase,
}) => {
  const [grid, setGrid] = useState(true);
 
  useEffect(() => {
    const fetchData = async () => {
      if (dataSets && dataSets.dataSet && dataSets.dataSet.length > 0) {
        for (const dataItem of dataSets.dataSet) {
          const dataId = dataItem.data_list_id;
          await checkDataIdInDatabase(dataId);
        }
      }
    };
    fetchData();
  }, [dataSets, checkDataIdInDatabase]);
 
  return (
    <div>
      <Row>
        <Col md={24}>
          <h5 className="assets_recent_emails">Recent Data Sets</h5>
        </Col>
      </Row>
 
      <Row className="new-campaign-row ">
        <Col
          md={24}
          className="grid-view-filter-template-image block-bg-font-color"
        >
          {!loading ? (
            <>
              {dataSets && dataSets.dataSet.length > 0 ? (
                <>
                  {dataSets.dataSet.map((val) => (
                    <div
                      className={`${grid ? `grid` : "list"} pointer-for`}
                      title={val.data_list_name}
                      key={val.data_list_id}
                    >
                      <Link
                        to={`/assetlibrary/data/edit/${val.data_list_id}/info`}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className={`${
                            grid
                              ? "rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border"
                              : ""
                          }`}
                          style={{
                            display: `${grid ? "" : "flex"}`,
                          }}
                        >
                          <div
                            className={`${
                              grid ? "DataResize " : "email_images_list"
                            }`}
                            style={{
                              display: `${grid ? "" : "inline-block"}`,
                            }}
                          >
                            <img
                              className={`${grid ? "email_images" : ""}`}
                              src="https://charlie-dave.s3.amazonaws.com/database.png"
                              data-id={val.data_list_id}
                              alt={val.data_list_name}
                              title={val.data_list_name}
                              style={{
                                width: "48%",
                                marginLeft: "24%",
                                marginTop: "4%",
                              }}
                            />
 
                            <div
                              style={{
                                marginTop: "6%",
                                marginLeft: "4%",
                                color: "#000",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className="DataDetails"
                                title={val.data_list_name}
                              >
                                <b>Name:</b> &nbsp; {val.data_list_name}
                              </div>
                              <div
                                className="DataDetails"
                                title={val.data_list_created_by}
                              >
                                <b>Created:</b> &nbsp;
                                {val.data_list_created_by}
                              </div>
                              <div
                                className="DataDetails"
                                title={val.data_list_modified_by}
                              >
                                <b>Modified:</b>&nbsp;
                                {val.data_list_modified_by}
                              </div>
                              <div
                                className="DataDetails"
                                title={val.data_list_count}
                              >
                                <b>Count:</b>&nbsp; {val.data_list_count}
                              </div>
                              {/* {campaignPropertiesCheckedInDataId[
                                val.data_list_id
                              ] && (
                                <Badge
                                  content="ACTIVE"
                                  color="red"
                                  title="Active with Campaign"
                                  style={{ width: "25%" }}
                                />
                              )} */}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <Row style={{ marginTop: 20 }}>
                  <Col md={7}></Col>
                  <Col md={7} style={{ textAlign: "center" }}>
                    <h5>No Data Found !</h5> <br />
                    <Link
                      onClick={() => reset()}
                      className="link-color-font"
                      to="/create/data-set/info"
                      style={{ fontSize: 25, cursor: "pointer" }}
                    >
                      Click to Create
                    </Link>
                  </Col>
                  <Col md={7}></Col>
                </Row>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  dataSets: state.AssetSlice.dataSets,
  campaignPropertiesCheckedInDataId:
    state.CampaignPropertiesSlice.campaignPropertiesCheckedInDataId,
});
 
const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetData()),
  checkDataIdInDatabase: (dataId) => dispatch(checkDataIdInDatabase(dataId)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(RecentTemplates);
 