import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "rsuite";
import { emailReport } from "../../../Features/Reports/ReportThunk";
import Loading from "../../../Loading/Loading";
import "../assets/css/report.css";
import LeftSideDetails from "./Utils/LeftSideDetails";
import ReportingViaAnimation from "./Utils/ReportingViaAnimation";
import RightSideInfo from "./Utils/RightSideInfo";

import { Navigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";

class EmailById extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      report: {},
      redirect: {
        is: false,
        to: "",
      },
    };
    this._isMounted = false;
    this.props.clickPng(this.imageAsDownload);
    this.props.clickXlsx(this.exportExcel);
  }

  // Here is the condition where to check report are beign fatched by email name and id

  async componentDidMount() {
    try {
      this._isMounted = true;
      let { id, emailReport, setLoading } = this.props;

      let response = await emailReport({ id });
      // console.log(response.payload.data);
      if (this._isMounted) {
        if (response.payload.data) {
          const emailList = response.payload.data.response || [];
          // const eventsdata = response.payload.data.url || [];
          // console.log(eventsdata);
          // console.log(emailList);

          const All_EmailList = emailList
            .map((innerArray) => (Array.isArray(innerArray) ? innerArray : []))
            .flatMap((innerArray) =>
              innerArray
                .map((obj) => ({
                  to_email: obj.to_email,
                  status: obj.status,
                  opens_count: obj.opens_count,
                  clicks_count: obj.clicks_count,
                }))
                .filter((data) => data.to_email)
            );

          // Create a map to store the count of clicks for each URL
          // const clickCountMap = new Map();

          // Update the click count in the map for each click event
          // eventsdata
          //   .filter((event) => event.url && event.event_name === "click")
          //   .forEach((event) => {
          //     const url = event.url;
          //     clickCountMap.set(url, (clickCountMap.get(url) || 0) + 1);
          //   });

          // // Update eventsdata with click count using map
          // const updatedEventsData = eventsdata.map((event) => ({
          //   ...event,
          //   clicks_count: clickCountMap.get(event.url) || 0,
          // }));

          this.setState({
            report: response.payload.data,
            loading: false,
            All_EmailList: All_EmailList,
            // eventsdata: updatedEventsData,
          });

          setLoading();
        } else {
          this.setState({
            redirect: {
              is: true,
              to: `/assetlibrary/edit/campaign/${id}`,
            },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  // Here condition end

  imageAsDownload = () => {
    html2canvas(document.getElementById("reporting")).then((canvas) => {
      let a = document.createElement("a");
      a.download = this.state.report.campaignInfo.c.campaign_name + ".png";
      a.href = canvas.toDataURL("image/png");
      a.click();
    });
  };

  exportExcel = async () => {
    let [FieldName, values] = [[], []];

    let fieldValues = {
      "Campaign Name": this.state.report.campaignInfo.c.campaign_name,
      "Email Name": this.state.report.campaignInfo.e.email_name,
      "Email Subject": this.state.report.campaignInfo.e.email_name,
      "Data List Name": this.state.report.campaignInfo.d.data_list_name,
      "Created by": this.state.report.campaignInfo.c.created_by,
      "Send at": this.state.report.campaignInfo.c.sendTime,
      "Data List count": this.state.report.activities.tr,
      Delivered: this.state.report.activities.status,
      "Total Open": this.state.report.activities.to,
      "Unique Open": this.state.report.activities.uo,
      "Total Click": this.state.report.activities.tc,
      "Unique Click": this.state.report.activities.uc,
    };

    for (var key in fieldValues) {
      FieldName.push(key);
      values.push(fieldValues[key]);
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.aoa_to_sheet([FieldName, values]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      this.state.report.campaignInfo.c.campaign_name + fileExtension
    );
  };

  render() {
    let { loading, report, redirect, All_EmailList, eventsdata } = this.state;

    if (redirect.is) {
      // Check if redirect flag is set
      return <Navigate to={redirect.to} />; // Use the Navigate component to navigate to the specified path
    }

    return (
      <Row className="new-campaign-row">
        {loading && <Loading />}

        {!loading && (
          <Col md={24} id="reporting">
            <Row>
              <Col md={7} className="description-left-side-report">
                <LeftSideDetails report={report} />
              </Col>

              <Col md={16} style={{marginLeft:"3%"}}>
                <ReportingViaAnimation report={report} />
              </Col>
            </Row>

            <RightSideInfo
              report={report}
              All_EmailList={All_EmailList}
              // eventsdata={eventsdata}
            />
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { emailReport })(EmailById);
