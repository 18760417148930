import React, { Component } from "react";
import { Col, Container, Content, Row, Button } from "rsuite";
// import { Link, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import UserInfo from "./UserInfo";
import UserInfoEdit from "./UserInfoEdit";
import CreateUser from "./CreateUser";

class UserMangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "userlist",
      user_id: null,
    };
  }

  activeChange = () => {
    this.setState({
      active: "userlist",
      user_id: "",
    });
  };

  forEditNewUser = (e) => {
    this.setState({
      active: "edituser",
      user_id: e,
    });
  };
  render() {
    return (
      <>
        <Container>
          <Content>
            <Row
              style={{
                background: this.props.color,
                padding: 20,
                paddingBottom: 5,
              }}
            >
              <Col md={1} sm={1}></Col>
              <Col md={22} sm={20}>
                <Row>
                  <Col md={10} sm={1}>
                    <h5>
                      {this.state.active === "create"
                        ? "Create User"
                        : "User List"}
                      <br />
                      <br />
                    </h5>
                  </Col>
                  <Col md={8} sm={1}></Col>
                  <Col md={6} sm={1}>
                    {" "}
                    {this.state.active === "userlist" ? (
                      <Button
                        color="blue"
                        style={{
                          // background: this.props.color,
                          // color: "#000",
                          fontSize: 14,
                          fontWeight: "bold",
                          backgroundColor: "#415670",
                          color: "#fff",
                        }}
                        className="ButtonSetting"
                        onClick={() =>
                          this.setState({
                            active: "create",
                          })
                        }
                      >
                        Create User
                      </Button>
                    ) : (
                      <Button
                        color="blue"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          backgroundColor: "#415670",
                          color: "#fff",
                        }}
                        className="ButtonSetting"
                        onClick={() =>
                          this.setState({
                            active: "userlist",
                          })
                        }
                      >
                        User List
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    {this.state.active === "create" && (
                      <CreateUser activeChange={this.activeChange} />
                    )}
                    {this.state.active === "userlist" && (
                      <UserInfo forEditNewUser={this.forEditNewUser} />
                    )}
                    {this.state.active === "edituser" && (
                      <UserInfoEdit
                        activeChange={this.activeChange}
                        id={this.state.user_id}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={1} sm={1}></Col>
            </Row>

            <Row>
              <Col md={24}>
                <br />
                <br />
                <br />
              </Col>
            </Row>
          </Content>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}

export default connect(mapStateToProps)(UserMangement);
