
import React, { Component } from "react";
import { Col, Row, InputGroup, Input } from "rsuite";
import CampaignFolder from "../../Folder/CampaignFolder";
 
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/view-asset-blocks.css";
import PaginationComponent from "../../Utils/PaginationComponent";
import Loading from "../../Loading/Loading";
import { getCampaign } from "../../Features/Campaign/CampaignThunk";
import { getSenderprofile } from "../../Features/SenderManagement/SenderThunk";
import SearchIcon from "@rsuite/icons/Search";
const ImageStyle = (width, height) => {
  return {
    width,
    height,
  };
};

 
class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 4,
      loading: false,
      total: null,
    };
  }
  componentDidMount() {
    let { page, limit } = this.state;
    this.fetchCampaign({ type: "recent", page, limit, value: null });
    this.props.getSenderprofile();
  }

  fetchCampaign = async ({ type, page, limit=4, value }) => {
    // console.log(type, page, limit=4, value)
    this.setState({
      loading: true,
    });
    try {
      let { getCampaign } = this.props;
 
      await getCampaign({ type, page, limit, value });
      let { campaigns } = this.props.campaignSlice;
      // console.log(campaigns)
 
      this.setState({
        loading: false,
        page: Number(campaigns.page),
        total: campaigns.count,
      });
    } catch (error) {
      console.log(error);
    }
  };


  selectFolder = async (e) => {
    await this.fetchCampaign({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
      limit: this.state.limit,
    });
  };
 
  setNewPage = async (page) => {
    await this.fetchCampaign({
      type: "recent",
      page,
      value: null,
      limit: this.state.limit,
    });
  };


  search = async (e) => {

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    let page = 1
    this.searchTimeout = setTimeout(async () => {
      await this.fetchCampaign({
        type: "search",
        page,
        value: e,
      });
    }, 800);

    this.setNewPage = async (page) => {
      await this.fetchCampaign({
        type: "search",
        page,
        value: e,
      });
    };
  };



  // componentDidMount() {
  //   this.props.getSenderprofile();
  // }
  // componentDidUpdate(prevProps) {
  //   if (!prevProps.senderProfiles && this.props.senderProfiles) {
  //     console.log("Profile fetched successfully:", this.props.senderProfiles);
  //   }
  // }


  render() {
    let { grid, campaignSlice } = this.props;
    let { campaign } = campaignSlice.campaigns;
    // let { campaign } = campaigns;
    // console.log(campaign)
    let { limit, page, loading, total } = this.state;
    return (
      <Row className="new-campaign-row">
        <Col md={5} className="side-folder">
          <CampaignFolder
            selectFolder={this.selectFolder}
            from="assets"
            fetchCampaigns={this.fetchCampaign}
          />
        </Col>
        <Col
          md={19}
          style={{ paddingLeft: "25px" }}
          className={
            grid
              ? "grid-view-filter-template-image block-bg-font-color"
              : "list-view-filter-template-image block-bg-font-color"
          }
        >
          <Row style={{ marginBottom: 20 }}>
            <Col md={12}></Col>
            <Col md={12}>
              <InputGroup
                // value={this.state.searchValue}
                placeholder="Search via name"
              >
                <Input
                  placeholder="Search via name"
                  onChange={(e) => this.search(e)}
                />
                <InputGroup.Addon>
                  <SearchIcon />
                </InputGroup.Addon>
              </InputGroup>
            </Col>
          </Row>
          {!loading ? (
            <>
              {campaign.length > 0 ? (
                <>
                  {campaign.map((val) => (
                    <div
                      className={`${grid ? `grid` : "list"} pointer-for`}
                      title={val.campaign_name}
                      key={val.campaign_id}
                    >
                      <Link
                        to={`/assetlibrary/edit/campaign/${val.campaign_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className={`${grid
                              ? "rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border"
                              : ""
                            }`}
                          style={{
                            display: `${grid ? "" : "flex"}`,
                          }}
                        >
                          <div
                            className={`${grid ? "DataResize " : "email_images_list"
                              }`}
                            style={{
                              display: `${grid ? "" : "inline-block"}`,
                            }}
                          >
                            <img
                              className={`${grid ? "email_images" : ""}`}
                              src={`${val.selected_email_img}?${Date.now()}`}
                              data-id={val.campaign_id}
                              alt={val.campaign_name}
                              title={val.campaign_name}
                              style={ImageStyle("100%", "auto")}
                            />
                          </div>
                          {grid && (
                            <>
                              <div
                                role="rowheader"
                                className={` ${grid
                                    ? "rs-panel-heading asset-name"
                                    : "asset-name"
                                  }`}
                                style={{ padding: "10px 10px" }}
                                tabIndex="-1"
                              >
                                <b>
                                  {val.campaign_name.length < 20
                                    ? val.campaign_name
                                    : `${val.campaign_name.substring(
                                      0,
                                      20
                                    )}....`}
                                </b>
                              </div>
                              <div
                                className="rs-panel-body"
                                style={{ padding: 10 }}
                              ></div>
                            </>
                          )}
 
                          {!grid && (
                            <span
                              style={{ paddingLeft: 5, padding: 16 }}
                              className="asset-name"
                            >
                              <b>Campaign : </b>
                              {val.campaign_name.length < 25
                                ? val.campaign_name
                                : `${val.campaign_name.substring(0, 25)}....`}
                              <b> | Category : </b> {val.campaign_category}
                              <b> | Created at : </b>
                              {val.created_at}
                            </span>
                          )}
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <Row style={{ marginTop: 20 }}>
                  <Col md={7}></Col>
                  <Col md={7} style={{ textAlign: "center" }}>
                    <h5>No Campaign Found !</h5> <br />
                    <Link
                        // onClick={() => reset()}
                        className="link-color-font"
                        to="/createcampaign/email/info"
                        style={{ fontSize: 25, cursor: "pointer" }}
                      >
                        Click to Create
                      </Link>
                  </Col>
                  <Col md={7}></Col>
                </Row>
              )}
            </>
          ) : (
            <Loading />
          )}
          <div className="fixed-pagination">
            <PaginationComponent
              limit={limit}
              page={page}
              setNewPage={this.setNewPage}
              total={total}
            />
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  campaignSlice: state.CampaignSlice,
  grid: state.AssetSlice.isGrid,
});
 
const mapDispatchToProps = (dispatch) => ({
  getCampaign: (type, page, limit, value) =>
    dispatch(getCampaign(type, page, limit, value)),
    getSenderprofile: () => dispatch(getSenderprofile()),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
 