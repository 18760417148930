import React from "react";
import { Button, Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";

import { connect } from "react-redux";
import { reset } from "../Features/Template/TemplateSlice";
import { resetEmail } from "../Features/Email/EmailSlice";
import { resetData } from "../Features/Data/DataSlice";

const ConfirmationModal = (props) => {
  const { confirmation, hide, handleDelete, component, loading } = props;
  // console.log(props);
  return (
    <Modal backdrop="static" open={confirmation} onClose={hide} size="xs">
      <Modal.Header>
        <Modal.Title><RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: "#000" }}>
        
        {`Deleting this ${component} will permanently remove all its contents. Are you sure you want to proceed?`}
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor:"#415670", color:"#fff"}} title="Confirm" onClick={handleDelete} loading={loading}>
          <b>Confirm</b>
        </Button>

        <Button onClick={hide} appearance="subtle" title="Cancel">
          <b>Cancel</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  // data: state.TemplateSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch(reset());
    dispatch(resetEmail());
    dispatch(resetData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
