import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMontlyReport } from "../Features/Reports/ReportThunk";
import { Col, Row, Dropdown, Placeholder, Loader } from "rsuite";
import { MapContainer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import countriesGeoJSON from "./custom.geog.json"; // Import the GeoJSON file

import { userStatus } from "../Features/Login/LoginThunk";
import * as d3 from "d3";

class GeoReport extends Component {
  constructor() {
    super();
    this.state = {
      geoChartData: null,
      selectedYear: new Date().getFullYear(),
      loading: true,
      totalOpens: {}, // Added totalOpens state
    };
  }

  async componentDidMount() {
    await this.getGeoChartData();
  }

  async getGeoChartData() {
    const { selectedYear } = this.state;
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    const { fetchMontlyReport } = this.props;

    try {
      let responseData = await fetchMontlyReport({
        startDate,
        endDate,
        aggregated_by: "month",
      });

      if (responseData.payload.status === 1) {
        const stats = responseData.payload.data.responses[3];

        // Fetch GeoJSON data for countries
        const countriesData = countriesGeoJSON.features;

        let geoChartData = {};
        let totalOpens = {}; // Added totalOpens variable

        stats.forEach((monthlyData) => {
          const date = monthlyData.date;

          monthlyData.stats.forEach((countryData) => {
            const countryCode = countryData.name;
            const metrics = countryData.metrics;

            // Update totalOpens for each country
            totalOpens[countryCode] =
              (totalOpens[countryCode] || 0) + metrics.opens;

            const countryFeature = countriesData.find(
              (feature) =>
                feature.properties.iso_a2.toUpperCase() ===
                countryCode.toUpperCase()
            );

            if (countryFeature) {
              if (!geoChartData[countryCode]) {
                geoChartData[countryCode] = {
                  features: [],
                };
              }

              geoChartData[countryCode].features.push({
                type: "Feature",
                properties: {
                  date: date,
                  metrics: metrics,
                  countryFeature: countryFeature,
                },
                geometry: countryFeature.geometry,
              });
            }
          });
        });

        this.setState({
          geoChartData: geoChartData,
          loading: false,
          totalOpens: totalOpens, // Set totalOpens in state
        });
      } else {
        console.log("Not Working");
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleYearChange = async (eventKey, event) => {
    this.setState(
      { selectedYear: eventKey, loading: true },
      this.getGeoChartData
    );
  };

  render() {
    const { loading, selectedYear, totalOpens } = this.state;

    return (
      <div>
        {!loading && this.state.geoChartData ? (
          <>
            <Row>
              <Col md={16}>
                <b className="RecentE-number">Opens by Region</b>
              </Col>
              <Col md={8} style={{ textAlign: "right" }}>
                <Dropdown
                  eventKey="1"
                  title={`${selectedYear}`}
                  onSelect={this.handleYearChange}
                >
                  <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                  <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <MapContainer
                  center={[50, 0]}
                  zoom={1}
                  style={{ height: "281px", width: "100%" }}
                >
                  {Object.keys(this.state.geoChartData).map((countryCode) => (
                    <GeoJSON
                      key={countryCode}
                      data={{
                        type: "FeatureCollection",
                        features: this.state.geoChartData[countryCode].features,
                      }}
                      style={(feature) => {
                        const opens = totalOpens[countryCode] || 0;

                        // Use a diverging color scale based on count comparison
                        const colorScale = d3
                          .scaleDiverging(d3.interpolateRdBu)
                          .domain([-400, 0, 400]); // Adjust the domain as needed

                        return {
                          fillColor: colorScale(opens - 200), // Adjust the midpoint
                          weight: 1,
                          color: "#62BAED", // Set a constant color for border
                          fillOpacity:
                            opens > 0
                              ? Math.min(Math.abs(opens) / 400, 0.4)
                              : 0,
                        };
                      }}
                      onEachFeature={(feature, layer) => {
                        const opens = totalOpens[countryCode] || 0;
                        const countryName =
                          feature.properties.countryFeature.properties.iso_a2;

                        layer.bindTooltip(
                          `
                          <div>
                             ${countryName}<br />
                             Opens: ${opens}
                          </div>
                        `,
                          {
                            permanent: false,
                            direction: "center",
                          }
                        );
                      }}
                    />
                  ))}
                </MapContainer>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={10} />
            <Loader center content="loading" />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { theme } = state.LoginSlice;
  return {
    theme,
  };
}

export default connect(mapStateToProps, { fetchMontlyReport, userStatus })(
  GeoReport
);
