import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

// export const getRecentAssets = createAsyncThunk("assets", async (limit) => {
//   const response = await axios.get(
//     `/assetlibrary/recent?limit=${Number(limit)}`
//   );
//   return response.data;
// });

export const getDataState = () => JSON.parse(localStorage.getItem("dLD"));

export const isDataExist = createAsyncThunk(
  "data-archive/isExist",
  async ({ dataName, id }) => {
    try {
      const response = await axios.get(
        `/data-archive/data/is-exist?name=${dataName}&id=${id}`
      );
      return response.data;
    } catch (e) {}
  }
);

export const saveDataSet = createAsyncThunk(
  "data-archive/save",
  async (data) => {
    // console.log(data);
    try {
      const response = await axios.post(`/data-archive/data/`, data);
      return response.data;
    } catch (e) {}
  }
);

export const getDataLists = createAsyncThunk(
  "data-archive/get-data",
  async ({ type, page, limit, value }) => {
    const response = await axios.get(
      `/data-archive/data?type=${type}&limit=${Number(
        limit
      )}&page=${page}&value=${value}`
    );

    return response.data;
  }
);

export const getDataById = createAsyncThunk(
  "data-archive/databyId",
  async ({ type, value }) => {
    const response = await axios.get(
      `/data-archive/data?type=${type}&value=${value}`
    );
    return response.data;
  }
);

export const editDetails = createAsyncThunk(
  "data-archive/edit-details",
  async (dataSet) => {
    // console.log(dataSet);
    try {
      const response = await axios.put(`/data-archive/data/property`, dataSet);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const editStructure = createAsyncThunk(
  "data-archive/edit-structure",
  async ({ id, updateValues, FieldToRemove, emailField }) => {
    try {
      const response = await axios.put(`/data-archive/data/structure`, {
        id,
        updateValues,
        FieldToRemove,
        emailField,
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteDataSet = createAsyncThunk(
  "data-archive/delete",
  async (id) => {
    try {
      const response = await axios.delete(`/data-archive/data?id=${id}`);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const fetchRecord = createAsyncThunk(
  "data-archive/data-records",
  async (id) => {
    try {
      const response = await axios.get(`/data-archive/data/records?id=${id}`);
      // console.log(response.data);
      return response.data;
    } catch (e) {
      console.error(e);
      console.log(e);
    }
  }
);

export const uploadData = createAsyncThunk(
  "data-archive/upload-data",
  async (data) => {
    try {
      const response = await axios.post(`/data-archive/data/upload`, data);
      return response.data;
    } catch (e) {
      // console.error("Backend error message:", e.response.data.message);
      let response = {};
      return (response["data"] = {
        status: 0,
        duration: 4,
        message: e.response.data.message,
      });
    }
  }
);

export const dataModification = createAsyncThunk(
  "data-archive/data-modification",
  async (data) => {
    try {
      const response = await axios.put(
        `/data-archive/data/modified-data`,
        data
      );
      // console.log(response.data);
      return response.data;
    } catch (e) {
      let response = {};
      return (response["data"] = {
        status: 0,
        duration: 4,
        message: e.response.data.message,
      });
    }
  }
);
