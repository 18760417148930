import React, { Component } from "react";
import { Col, Row, Modal, Button, Stack, Popover, Whisper } from "rsuite";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../../assets/css/report.css";
// import CampaignReporting from "../../CampaignReporting";

export default class RightSideInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverHover: false,
      uniqueHover: false,
      uniqueClickHover: false,
      setOpen: false,
    };
  }

  // Function to open the modal
  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  // Function to close the modal
  handleClose = () => {
    this.setState({ setOpen: false });
  };

  render() {
    let { report, All_EmailList, eventsdata } = this.props;
    // console.log(All_EmailList);
    // console.log(eventsdata);
    // Create eventsDataMap using map
    // const eventsDataMap = new Map();

    // Populate eventsDataMap
    // eventsdata.forEach((event) => {
    //   const toEmail = event.to_email.toLowerCase();

    //   if (!eventsDataMap.has(toEmail)) {
    //     eventsDataMap.set(toEmail, []);
    //   }

    //   eventsDataMap.get(toEmail).push({
    //     url: event.url,
    //     clicks_count: event.count,
    //   });
    // });

    // console.log("eventsDataMap:", eventsDataMap);

    // console.log("main", report.response);
    let { deliverHover, uniqueHover, uniqueClickHover } = this.state;
    return (
      <>
        <Row>
          <Col md={24} xs={24} sm={24}>
            {" "}
            <span className="subscriber">Expand</span>
          </Col>
        </Row>
        <Row className="EngageReport">
          <Col md={24} xs={24} sm={24}>
            <Col
              md={3}
              xs={3}
              sm={3}
              style={{ background: "#415670", cursor: "pointer" }}
              className="CountBox"
              onClick={this.handleOpen}
            >
              <span className="subscriber">TOTAL SENDS</span>
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  display: "block",
                }}
              >
                <Whisper
                  placement="topEnd"
                  speaker={
                    <Popover
                      title="Expand Report"
                      visible
                      style={{ color: "#000", display: "block" }}
                    ></Popover>
                  }
                  style={{ color: "#000", display: "block" }}
                >
                  <Button
                    appearance="subtle"
                    style={{
                      float: "right",
                      padding: 0,
                      color: "#000",
                    }}
                  >
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      style={{
                        color: "#fff",
                      }}
                    ></i>
                  </Button>
                </Whisper>
              </div>
              <br />
              <span className="subscriber-count">{report.activities.tr}</span>
            </Col>
            <Col md={1} xs={1} sm={1}></Col>
            <Col
              onMouseOver={() =>
                this.setState({
                  deliverHover: true,
                })
              }
              onMouseOut={() =>
                this.setState({
                  deliverHover: false,
                })
              }
              md={3}
              xs={3}
              sm={3}
              style={{ background: "#415670", cursor: "pointer" }}
              className="EmailSentbox"
            >
              <span className="subscriber">DELIVERED</span>
              <br />
              <span className="subscriber-count">
                {deliverHover
                  ? Math.round(
                      (report.activities.status / report.activities.tr) * 100
                    ).toFixed(0) + "%"
                  : report.activities.status}
              </span>
            </Col>
            <Col md={1} xs={1} sm={1}></Col>
            <Col
              md={3}
              xs={3}
              sm={3}
              className="EmailSentbox"
              style={{ background: "#415670" }}
            >
              <span className="subscriber">TOTAL OPENS</span>
              <br />
              <span className="subscriber-count">{report.activities.to}</span>
            </Col>
            <Col md={1} xs={1} sm={1}></Col>
            <Col
              onMouseOver={() =>
                this.setState({
                  uniqueHover: true,
                })
              }
              onMouseOut={() =>
                this.setState({
                  uniqueHover: false,
                })
              }
              md={3}
              xs={3}
              sm={3}
              className="EmailSentbox"
              style={{ background: "#415670", cursor: "pointer" }}
            >
              <span className="subscriber">UNIQUE OPENS</span>
              <br />
              <span className="subscriber-count">
                {uniqueHover
                  ? Math.round(
                      (report.activities.uo / report.activities.tr) * 100
                    ).toFixed(0) + "%"
                  : report.activities.uo}
              </span>
            </Col>
            <Col md={1} xs={1} sm={1}></Col>
            <Col
              md={3}
              xs={3}
              sm={3}
              className="EmailSentbox"
              style={{ background: "#415670" }}
            >
              <span className="subscriber">TOTAL CLICKS </span>
              <br />
              <span className="subscriber-count">{report.activities.tc}</span>
            </Col>

            <Col md={1} xs={1} sm={1}></Col>
            <Col
              onMouseOver={() =>
                this.setState({
                  uniqueClickHover: true,
                })
              }
              onMouseOut={() =>
                this.setState({
                  uniqueClickHover: false,
                })
              }
              md={3}
              xs={3}
              sm={3}
              className="EmailSentbox"
              style={{ background: "#415670", cursor: "pointer" }}
            >
              <span className="subscriber">UNIQUE CLICKS</span>
              <br />
              <span className="subscriber-count">
                {uniqueClickHover
                  ? Math.round(
                      (report.activities.uc / report.activities.tr) * 100
                    ).toFixed(0) + "%"
                  : report.activities.uc}
              </span>
            </Col>
          </Col>
          <Modal
            open={this.state.setOpen}
            onClose={this.handleClose}
            size="xl"
            style={{ marginLeft: 10, marginRight: 30 }}
          >
            <Modal.Header>
              <Modal.Title>SEND LIST</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* #NOTE Here we are fatching all emails and status on click of total send button        */}

              <table id="table-to-xls" class="styled-table">
                <thead>
                  <tr>
                    <th width="300">Email</th>
                    <th width="300">Status</th>
                    <th width="300">Opens Count</th>
                    {/* <th width="300">Clicks Count</th> */}
                    {/* <th width="500">Clicks URL</th> */}
                    <th width="300">Campaign Name</th>
                  </tr>
                </thead>
                <tbody>
                  {All_EmailList.map((item, index) => {
                    // Calculate total clicks count for the email
                    // const totalClicksCount = eventsDataMap.has(item.to_email)
                    //   ? eventsDataMap
                    //       .get(item.to_email)
                    //       .reduce((acc, event) => acc + event.clicks_count, 0)
                    //   : 0;

                    return (
                      <tr key={index}>
                        <td>{item.to_email}</td>
                        <td>{item.status}</td>
                        <td>{item.opens_count}</td>
                        {/* <td>{totalClicksCount}</td> */}
                        {/* <td>
                         
                          {eventsDataMap.has(item.to_email) &&
                          eventsDataMap.get(item.to_email).length > 0 ? (
                            <ul>
                              {eventsDataMap
                                .get(item.to_email)
                                .map((event, idx) => (
                                  <li key={idx}>
                                    {event.url} (ClickCount:{" "}
                                    {event.clicks_count})
                                  </li>
                                ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>{report.campaignInfo.c.campaign_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Stack
                direction="row"
                spacing={20}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename={`Campaign_` + report.campaignInfo.c.campaign_name}
                  sheet="tablexls"
                  buttonText="Download Report"
                  style={{backgroundColor:"#415670", color:"#fff"}}
                />
                <Button
                  onClick={this.handleClose}
                  appearance="default"
                  style={{ backgroundColor: "#f2f2f2" }}
                >
                  Close
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        </Row>
      </>
    );
  }
}
