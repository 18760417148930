import { createSlice } from "@reduxjs/toolkit";
import { campaignById, getCampaign } from "./CampaignThunk";

const initialState = {
  campaigns: {
    campaign: [],
    count: null,
    pages: null,
    page: null
  },
  // senderProfiles: [],
  loadingById: true,
  count: "",
  campaignByID: {},
  emailByID: {},
  dataListById: {},
  loading: false,
  error: false,
  status: true
};

const CampaignSlice = createSlice({
  name: "CampaignSlice",
  initialState: initialState,

  reducers: {
    setViewType(state, data) {
      let isGrid = JSON.parse(localStorage.getItem("isGrid"));
      isGrid = data.payload === "grid" ? true : false;
      localStorage.setItem("isGrid", JSON.stringify(isGrid));
      state.isGrid = data.payload === "grid" ? true : false;
    }
  },

  extraReducers: (builder) => {
    builder

      // FETCHING EMAILS

      .addCase(getCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload.data;

        state.error = false;
      })

      .addCase(getCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.campaigns = [];
      })
      //Fetch Data
      .addCase(campaignById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(campaignById.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignByID = action.payload.data.campaign;
        state.emailByID = action.payload.data.emailById;
        state.dataListById = action.payload.data.dataListById;
        state.suppressionList = action.payload.data.suppressionList;
        state.count = action.payload.data.count;
        state.error = false;
        state.loadingById = false;
      })

      .addCase(campaignById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.campaigns = [];
      })


      //add case for senderProfile
      //TODO - Need to delete it later from here...
      // .addCase(getSenderprofile.pending, (state, action) => {
      //   state.loading = true;
      //   state.error = null; 
      // })
      // .addCase(getSenderprofile.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.senderProfiles = action.payload.data.senderProfiles;
      // })
      // .addCase(getSenderprofile.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message; 
      // })
      
  }
});
const { reducer, actions } = CampaignSlice;
export const { setViewType } = actions;

export default reducer;
