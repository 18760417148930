import React, { Component } from "react";
import { Modal, Button } from "rsuite";

import { Col, Input, TagGroup, Tag, Form, InputGroup, Loader } from "rsuite";
import { connect } from "react-redux";
import TemplateFolder from "../../../Folder/TemplateFolder";

import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { editorTemplateById } from "../../../Features/Editor/EditorThunk";
import { editTemplateProperty } from "../../../Features/Template/TemplateThunk";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class TemplateEditProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: null,
      category: "",
      templateValues: {
        templateName: {
          textName: "",
          errorInName: false,
          isExistError: false,
          errMessageName: null,
        },
        description: {
          textDes: "",
          errorInDescription: false,
          errMessageDes: null,
        },
        categories: {
          categoryBundle: [],
          errorInCategory: false,
        },
        location: {
          path: null,
          errorInLocation: false,
        },
      },
      hideError: false,
    };
  }

  componentDidMount() {
    let { loadingSlice, template } = this.props;
    try {
      if (!loadingSlice) {
        let { templateValues } = this.state;
        templateValues["templateName"].textName = template.template_name;
        templateValues["description"].textDes = template.template_description;
        templateValues["location"].path = template.template_folder_id;
        templateValues["categories"].categoryBundle =
          template.template_category.split(",");
        this.setState({
          templateValues,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  checkTemplateName = async () => {
    let { params } = this.props;
    let { templateValues } = this.state;
    let { templateName } = templateValues;
    try {
      if (templateName.textName.trim().replace(/\s/g, "").length > 0) {
        this.setState({ loading: "check" });
        const response = await axios.get(
          `/template/is-exist?name=${templateName.textName}&id=${params.id}`
        );
        let { data } = response.data;
        templateValues.templateName["isExistError"] = data.error;
        templateValues.templateName["errMessageName"] = data.message;
      }
      this.setState({
        hideError: false,
        templateValues,
        loading: null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  newCategory = (type, index) => {
    let newCategories = [];
    let { category, templateValues } = this.state;
    let { categories } = templateValues;
    if (type === "delete") {
      newCategories = categories.categoryBundle.filter(
        (item, i) => i !== index
      );
      templateValues.categories.categoryBundle = newCategories;
      templateValues.categories.errorInCategory = false;
    } else {
      if (category.trim().replace(/\s/g, "").length > 0) {
        if (
          !categories.categoryBundle.includes(category.trim().toLowerCase())
        ) {
          newCategories = category.trim().toLowerCase()
            ? [...categories.categoryBundle, category.trim().toLowerCase()]
            : categories.categoryBundle;
          templateValues.categories.categoryBundle = newCategories;
          templateValues.categories.errorInCategory = false;
        }
      }
    }

    this.setState({
      templateValues,
      category: "",
    });
  };

  selectFolder = (e) => {
    let { templateValues } = this.state;
    templateValues.location.path = e.nodeData.id;
    templateValues.location.errorInLocation = false;
    this.setState({
      templateValues,
    });
  };

  checkErrorForm = (event) => {
    let { templateValues } = this.state;
    let { categories, location, templateName, description } = templateValues;
    if (
      !templateName.textName ||
      templateName.isExistError ||
      categories.categoryBundle.length === 0 ||
      !location.path ||
      !description.textDes
    ) {
      if (!templateName.textName) {
        templateValues.templateName.textName = "";
        templateValues.templateName.errorInName = true;
        templateValues.templateName.isExistError = false;
        templateValues.templateName.errMessageName = "This field is Required";
      }

      if (categories.categoryBundle.length === 0) {
        templateValues.categories.categoryBundle = [];
        templateValues.categories.errorInCategory = true;
      }
      if (!location.path) {
        templateValues.location.path = null;
        templateValues.location.errorInLocation = true;
      }

      if (!description.textDes) {
        templateValues.description.textDes = "";

        templateValues.description.errorInDescription = true;
        templateValues.description.errMessageDes = "This field is Required";
      }
      this.setState({
        templateValues,
      });
      event.preventDefault();
    } else {
      this.editTemplateProperty();
    }
  };

  editTemplateProperty = async () => {
    try {
      let { templateValues } = this.state;
      let { categories, location, templateName, description } = templateValues;
      let { editTemplateProperty, editorTemplateById, params, alertRef } =
        this.props;
      let templateData = {
        id: params.id,
        name: templateName.textName,
        location: location.path,
        categories: categories.categoryBundle.toString(),
        description: description.textDes,
      };
      this.setState({
        loading: "submit",
      });
      let response = await editTemplateProperty(templateData);
      await editorTemplateById({
        type: "byId",
        value: params.id,
      });

      this.setState({
        loading: null,
      });

      alertRef.current.showToaster(response.payload);
    } catch (e) {
      console.log(e);
    }
  };

  closeModal = async () => {
    let { handleEditModal } = this.props;
    // await reset();

    handleEditModal();
  };

  render() {
    let { hideError, category, templateValues, loading } = this.state;
    let { templateName, description, categories, location } = templateValues;
    let { open } = this.props;

    return (
      <Modal size="lg" backdrop="static" open={open} onClose={this.closeModal}>
        <Modal.Header>
          <Modal.Title>Template Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%", marginBottom: 50 }} fluid>
            <Col md={1}></Col>

            <Col md={10}>
              <Form.Group>
              Template Name*
                <InputGroup inside className="input-group-form">
                  <Form.Control
                    name="templateName"
                    // placeholder="Template Name*"
                    value={templateName.textName}
                    onChange={(value) => {
                      templateValues.templateName["textName"] =
                        value.trimStart();
                      templateValues.templateName["errorInName"] = false;
                      templateValues.templateName["isExistError"] = false;
                      templateValues.templateName["errMessageName"] = null;
                      this.setState({
                        templateValues,
                      });
                    }}
                    className="new-email-text-only-underline"
                    onFocus={() =>
                      this.setState({
                        hideError: true,
                      })
                    }
                    style={{ border: "none !important" }}
                    onBlur={this.checkTemplateName}
                  />
                  {loading === "check" && (
                    <InputGroup.Addon style={{ padding: "8px 12px" }}>
                      <Loader />
                    </InputGroup.Addon>
                  )}
                </InputGroup>
                <span
                  className="custom-error-fields"
                  style={{
                    display: `${
                      (templateName.errorInName || templateName.isExistError) &&
                      !hideError
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  {templateName.errMessageName}
                </span>
              </Form.Group>
              Location*
              <div
                className=" location"
                placeholder="Location"
                style={{
                  background: "#fff",
                  padding: 5,
                  overflowY:"auto"
                }}
              >
                <TemplateFolder
                  selectFolder={this.selectFolder}
                  clickTemplate={(click) => (this.clickTemplate = click)}
                  location={location.path}
                  edit={true}
                />
              </div>
              <span
                className="custom-error-fields"
                style={{
                  display: `${
                    location.errorInLocation && !location.path
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                This field is Required
              </span>
            </Col>
            <Col md={2}></Col>
            <Col md={10}>
            Add-Categories and Press Enter*
              <TagGroup style={{ margin: 0 }}>
                <Input
                  // placeholder="Add-Categories on Enter*"
                  className="new-email-text"
                  name="categories"
                  value={category}
                  onChange={(e) =>
                    this.setState({
                      category: e,
                    })
                  }
                  onBlur={() => this.newCategory("add", undefined)}
                  onPressEnter={() => this.newCategory("add", undefined)}
                />
                {categories.categoryBundle.map((item, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={(tag) => this.newCategory("delete", index)}
                  >
                    {item}
                  </Tag>
                ))}

                <span
                  className="custom-error-fields"
                  style={{
                    display: `${
                      categories.errorInCategory &&
                      categories.categoryBundle.length === 0
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  This field is Required
                </span>
              </TagGroup>
              <Form.Group style={{marginTop:22}}><Form.ControlLabel>Description*</Form.ControlLabel>
              <Form.Control
                rows={8}
                accepter={Textarea}
                value={description.textDes}
                name="description"
                onChange={(value) => {
                  templateValues.description["textDes"] = value.trimStart();
                  templateValues.description["errorInDescription"] = false;
                  templateValues.description["errMessageDes"] = null;

                  this.setState({
                    templateValues,
                  });
                }}
                // placeholder="Description*"
                className=""
                style={{ borderRadius: 0 }}
              />
</Form.Group>              <span
                className="custom-error-fields"
                style={{
                  display: `${
                    description.errorInDescription ? "block" : "none"
                  }`,
                }}
              >
                {description.errMessageDes}
              </span>
            </Col>
            <Col md={1}></Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.closeModal}
            appearance="subtle"
            style={{ backgroundColor: "#415670", color: "#fff" }}
          >
            Cancel
          </Button>
          <Button
            loading={loading === "submit"}
            onClick={this.checkErrorForm}
            style={{ backgroundColor: "#415670", color: "#fff" }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  template: state.EditorSlice.templateById.template,
  loadingSlice: state.EditorSlice.loading,
});

const mapDispatchToProps = (dispatch) => ({
  editorTemplateById: (type, value) =>
    dispatch(editorTemplateById(type, value)),
  editTemplateProperty: (templateData) =>
    dispatch(editTemplateProperty(templateData)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateEditProperty);
