import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Popover, Whisper, Button } from "rsuite";
import { Link } from "react-router-dom";
import { resetEmail } from "../../Features/Email/EmailSlice";
import Loading from "../../Loading/Loading";

import "../assets/css/view-asset-blocks.css";
import convertToUserTimeZone from "../../../hooks/convertToUserTimeZone";

const ImageStyle = (width, height) => {
  return {
    width,
    height,
  };
};

class RecentEmails extends Component {
  render() {
    let { emails, loading, reset } = this.props;
    // console.log(emails);

    return (
      <div>
        <Row>
          <Col md={24}>
            <h5 className="assets_recent_emails">Recent Emails</h5>
          </Col>
        </Row>

        <Row className="new-campaign-row ">
          <Col
            md={24}
            className="grid-view-filter-template-image block-bg-font-color"
          >
            {!loading ? (
              <>
                {emails.email.length > 0 ? (
                  <>
                    {emails.email.map((val) => (
                      <div
                        className="grid pointer-for"
                        title={val.email_name}
                        key={val.email_id}
                      >
                        <Link
                          to={`/assetlibrary/edit/email/${val.email_id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border">
                            <div className="ImageResize ">
                              <img
                                className="email_images"
                                src={`${val.email_img_path}?${Math.random()}`}
                                data-id={val.email_id}
                                alt={val.email_name}
                                title={val.email_name}
                                style={ImageStyle("100%", "100%")}
                              />
                            </div>

                            <div
                              role="rowheader"
                              className="rs-panel-heading asset-name"
                              style={{ padding: "10px 10px" }}
                              tabIndex="-1"
                            >
                              <b>
                                {val.email_name.length < 20
                                  ? val.email_name
                                  : `${val.email_name.substring(0, 20)}....`}
                              </b>

                              <Whisper
                                trigger="hover"
                                placement="topEnd"
                                speaker={
                                  <Popover
                                    title="Info"
                                    visible
                                    style={{ color: "#000" }}
                                  >
                                    <b> Created By</b> : {val.email_created_by}
                                    <br />
                                    <b>Modified By</b> :{val.email_modified_by}
                                    <br />
                                    <b>Created At </b>:{" "}
                                    {convertToUserTimeZone(
                                      val.email_created_at
                                    )}
                                    <br />
                                    <b>Modified At </b>:
                                    {convertToUserTimeZone(
                                      val.email_modified_at
                                    )}
                                    <br />
                                    <b>Subject </b> : {val.email_subject_line}
                                  </Popover>
                                }
                              >
                                <Button
                                  appearance="subtle"
                                  style={{
                                    float: "right",
                                    padding: 0,
                                    color: "#000",
                                  }}
                                >
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </Whisper>
                            </div>
                            <div
                              className="rs-panel-body"
                              style={{ padding: 10 }}
                            >
                              {/* <p>
                                <small className="asset-name">
                                  <b>Category : </b> {val.email_category}
                                </small>
                              </p> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7}></Col>
                    <Col md={7} style={{ textAlign: "center" }}>
                      <h5>No Email Found !</h5> <br />
                      <Link
                        onClick={() => reset()}
                        className="link-color-font"
                        to="/createnewproject/create/email/info"
                        style={{ fontSize: 25, cursor: "pointer" }}
                      >
                        Click to Create
                      </Link>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                )}
              </>
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emails: state.AssetSlice.emails,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentEmails);
