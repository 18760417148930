import { createSlice } from "@reduxjs/toolkit";
import { fetchCampaignFolder } from "./CampaignFolderThunk";

const CampaignFolderSlice = createSlice({
  name: "campaign-folder",
  initialState: {
    loading: false,
    CamapignFolder: [],
    error: null,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignFolder.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchCampaignFolder.fulfilled, (state, action) => {
        state.CamapignFolder = action.payload.data.campaignData;
        state.success = true;
        state.loading = false;
      })

      .addCase(
        fetchCampaignFolder.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.CamapignFolder = [];
        }
      );
  }
});

export default CampaignFolderSlice.reducer;
