import { createSlice } from "@reduxjs/toolkit";
import { isEmailExist, getEmailData } from "./EmailThunk";
import { setCreateNewProjectData } from "../CreateNewProjectService";
import { email } from "../const";

let eD = getEmailData() || email;
let initialState = {
  emailName: eD.emailName,
  description: eD.description,
  subject: eD.subject,
  location: eD.location,
  loading: false,
  error: null,
  message: ""
};

const EmailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetEmail() {
      setCreateNewProjectData();

      const eD = getEmailData();
      return {
        emailName: eD.emailName,
        description: eD.description,
        subject: eD.subject,
        location: eD.location,
        loading: false,
        error: null,
        message: ""
      };
    },
    feedEmail(state, data) {
      let eD = getEmailData();
      eD.emailName = data.payload;
      localStorage.setItem("eD", JSON.stringify(eD));
      state.emailName = data.payload;
    },
    feedSubject(state, data) {
      let eD = getEmailData();
      eD["subject"] = data.payload;
      localStorage.setItem("eD", JSON.stringify(eD));
      state.subject = data.payload;
    },
    selectLocation(state, data) {
      let eD = getEmailData();
      eD["location"] = data.payload;
      localStorage.setItem("eD", JSON.stringify(eD));
      state.location = data.payload;
    },
    feedDescription(state, data) {
      let eD = getEmailData();
      eD.description = data.payload;
      localStorage.setItem("eD", JSON.stringify(eD));
      state.description = data.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(isEmailExist.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isEmailExist.fulfilled, (state, action) => {
        state.emailName.isExistError = action.payload.data.error;
        state.emailName.errMessageName = action.payload.data.message;
        state.loading = false;
        state.error = null;
        if (action.payload.data.error) {
          let eD = getEmailData();
          eD.emailName["isExistError"] = true;
          eD.emailName["errMessageName"] = action.payload.data.message;
          localStorage.setItem("eD", JSON.stringify(eD));
        }
      })

      .addCase(isEmailExist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
const { actions, reducer } = EmailSlice;
export const { resetEmail, feedEmail, feedSubject, selectLocation, feedDescription } = actions;

export default reducer;
