import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTemplateData = () => JSON.parse(localStorage.getItem("eTD"));

export const isTemplateExist = createAsyncThunk(
  "template/isExist",
  async ({ templateName, id }) => {
    const response = await axios.get(`/template/is-exist?name=${templateName}&id=${id}`);
    return response.data;
  }
);

export const saveTemplate = createAsyncThunk("template/create", async (templateData) => {
  console.log(templateData);
  try {
    const response = await axios.post("/template/", templateData);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const editTemplateProperty = createAsyncThunk(
  "template/edit/property",
  async (templateData) => {
    try {
      const response = await axios.put("/template/property", templateData);
      return response.data;
    } catch (e) {
      // console.log(e.response);
      return e.response.data;
    }
  }
);

export const editTemplateDesign = createAsyncThunk(
  "template/edit/design",
  async ({ design, id, html }) => {
    try {
      const response = await axios.put("/template/design", {
        design,
        id,
        html
      });
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const downloadTemplatePdf = createAsyncThunk("template/download/pdf", async (id) => {
  try {
    const response = await axios.get(`/template/download-pdf?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const downloadTemplateImage = createAsyncThunk("template/download/image", async (id) => {
  try {
    const response = await axios.get(`/template/download-image?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteTemplate = createAsyncThunk("template/delete", async ({ id }) => {
  try {
    const response = await axios.delete(`/template/?id=${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});
