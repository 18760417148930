import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMontlyReport } from "../Features/Reports/ReportThunk";
import { Col, Row, Dropdown, Placeholder, Loader } from "rsuite";

import { Bar } from "react-chartjs-2";

import { userStatus } from "../Features/Login/LoginThunk";

class DeviceReport extends Component {
  constructor() {
    super();
    this.state = {
      chartDataOpen: {},
      totalOpens: "",
      unique_opens: "",
      selectedYear: new Date().getFullYear(),
      aggregated_by: "month",
      loading: true,
    };
  }
  async componentDidMount() {
    await this.getChartOverAllData();
  }

  async getChartOverAllData() {
    const { selectedYear, aggregated_by } = this.state;
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    const { fetchMontlyReport } = this.props;

    try {
      let responseData = await fetchMontlyReport({
        startDate,
        endDate,
        aggregated_by,
      });

      // console.log(responseData);

      if (responseData.payload.status === 1) {
        const stats = responseData.payload.data.responses[1];

        // console.log(stats);

        let labels = [];
        let opens = [];
        let uniqueopens = [];
        let deviceData = {}; // New object to store total opens and unique opens per device

        stats.forEach((entry) => {
          entry.stats.forEach((stat) => {
            const deviceName = stat.name;
            // console.log(deviceName);
            if (!labels.includes(deviceName)) {
              labels.push(deviceName);
            }

            // Initialize device data if not present
            if (!deviceData[deviceName]) {
              deviceData[deviceName] = {
                opens: 0,
                uniqueOpens: 0,
              };
            }

            // Update total opens and unique opens for the device
            deviceData[deviceName].opens += stat.metrics.opens;
            deviceData[deviceName].uniqueOpens += stat.metrics.unique_opens;
          });
        });

        // Extract total opens and unique opens from the aggregated device data
        let unique_opens = 0,
          totalOpens = 0;

        Object.keys(deviceData).forEach((deviceName) => {
          const { opens, uniqueOpens } = deviceData[deviceName];
          totalOpens += opens;
          unique_opens += uniqueOpens;
        });

        // Extract opens and unique opens arrays for the chart
        labels.forEach((label) => {
          opens.push(deviceData[label].opens);
          uniqueopens.push(deviceData[label].uniqueOpens);
        });

        this.setState({
          chartDataOpen: {
            labels: labels,
            datasets: [
              {
                label: "Opens",
                data: opens,
                backgroundColor: ["#239BB6"],
              },
              {
                label: "Unique opens",
                data: uniqueopens,
                backgroundColor: ["#DE76DC"],
              },
            ],
          },
          loading: false,
          unique_opens,
          totalOpens,
        });
      } else {
        console.log("Not Working");
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleYearChange = async (eventKey, event) => {
    this.setState(
      { selectedYear: eventKey, loading: true },
      this.getChartOverAllData
    );
  };

  render() {
    const { loading, chartDataOpen, selectedYear } = this.state;
    let { theme } = this.props;
    return (
      <>
        {!loading && chartDataOpen !== "" ? (
          <>
            <Row>
              <Col md={16}>
                <b className="RecentE-number">Device Comparison</b>
              </Col>
              <Col md={8} style={{ textAlign: "right" }}>
                <Dropdown
                  eventKey="1"
                  title={`${selectedYear}`}
                  onSelect={this.handleYearChange}
                >
                  <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                  <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col
                md={24}
                className="device-canvas"
              >
                <Bar
                  data={chartDataOpen}
                  options={{
                    title: {
                      display: true,
                      text: "Open Rate",
                      fontSize: 25,
                    },

                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    color: theme === "dark" ? "#fff" : "#000",
                    scales: {
                      y: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                      x: {
                        ticks: {
                          color: theme === "dark" ? "#fff" : "#000",
                          font: {
                            size: 11,
                          },
                        },
                        border: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                        grid: {
                          color: theme === "dark" ? "#fff" : "#000",
                        },
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Placeholder.Paragraph rows={10} />
            <Loader center content="loading" />
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { theme } = state.LoginSlice;
  return {
    theme,
  };
}
// const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, { fetchMontlyReport, userStatus })(
  DeviceReport
);
