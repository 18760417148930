import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateInfo = createAsyncThunk("accountmanagement/updateInfo", async (data) => {
  try {
    const response = await axios.post(
      "/accountmanagement/updateInfo/",
      { data },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.data;
  } catch (e) {
    return { status: 0, message: "Account Information Not Updated" };
  }
});


export const updatePassword = createAsyncThunk("accountmanagement/changePassword", async (data) => {
  try {
    const response = await axios.post(
      "/accountmanagement/changePassword/",
      { data },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.data;
  } catch (e) {
    let response = {};
      return (response["data"] = {
        status: 0,
        duration: 1,
        message: e.response.data.message,
      });
  }
});