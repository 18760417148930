import React, { Component } from "react";
 
import SMS from "./assets/icons/sms.png";
// import DigitalWhiteImg from "./assets/icons/digital_white.png";
 
import SocialImg from "./assets/icons/social.png";
// import SocialWhiteImg from "./assets/icons/social_white.png";
 
import EmailImg from "./assets/icons/email.png";
// import EmailWhiteImg from "./assets/icons/email_white.png";
 
import { Link, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { resetData } from "../Features/CampaignManagement/CampaignPropertiesSlice";
import { getSenderprofile } from "../Features/SenderManagement/SenderThunk";
class CreateCampaign extends Component {
  async componentDidMount() {
    let { reset } = this.props;
    await reset();
  }
  componentDidMount() {
    this.props.getSenderprofile()
     
this
.props.reset();
  }
  // componentDidUpdate(prevProps) {
  //   if (!prevProps.senderProfiles && this.props.senderProfiles) {
  //     console.log("Profile fetched successfully:", this.props.senderProfiles);
  //   }
  // }


  render() {
    let {
      user: { access, role },
      isLoggedIn,
      success,
    } = this.props;
    let userAccess = JSON.parse(JSON.parse(access));
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8%",
            marginBottom: "5%",
          }}
        >
          <h3 className="hero-head">Channels</h3>
        </div>
 
        <div className="container-parent" style={{ textAlign: "center" }}>
          {/* <h2 className="hero-head">CREATE CAMPAIGN</h2> */}
          <div className=" create-new-project container-flex">
            {/* {userAccess.includes("email") && ( */}
            <div className="new-campaign-detail">
              <Link to="email/info" className="rs-dropdown-item-content">
                <div className="center-div-img-text">
                  <picture>
                    <img src={EmailImg} alt="New Email Campaign" />
                  </picture>
 
                  <p className="create-new-project-type">
                    <b>Create Email Campaign</b>
                  </p>
                </div>
              </Link>
              <p>
                Create and schedule email campaigns for targeted outreach and
                engagement with subscribers.
              </p>
            </div>
 
            <div className="new-campaign-detail">
              <Link to="#" className="rs-dropdown-item-content" style={{cursor:"auto"}}>
                <div className="center-div-img-text">
                  <picture>
                    <img src={SocialImg} alt="New Email" />
                  </picture>
 
                  <p className="create-new-project-type">
                    <b>Create Social Campaign</b>
                  </p>
                </div>
              </Link>
              <p>
                Launch targeted social campaigns, schedule posts, analyze
                performance, and engage audiences.
                <br />
                <b>Coming Soon</b>
              </p>
            </div>
            <div className="new-campaign-detail ">
              <Link to="" className="rs-dropdown-item-content" style={{cursor:"auto"}}>
                <div className="center-div-img-text">
                  <picture>
                    <img src={SMS} alt="New Email" />
                  </picture>
 
                  <p className="create-new-project-type">
                    <b>Create SMS Campaign</b>
                  </p>
                </div>
              </Link>
              <p>
                Create targeted SMS campaigns with user-friendly management for
                efficient marketing.
                <br />
                <b>Coming Soon</b>
              </p>
            </div>
 
            {/* )} */}
 
            {/* <div className="new-campaign-detail pointer-for">
            <Link
              to="create/template/info"
              className="rs-dropdown-item-content"
            >
              <div className="center-div-img-text">
                <picture>
                  <source
                    srcSet={DigitalWhiteImg}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="New Email"
                  />
 
                  <img src={DigitalImg} alt="New Email" />
                </picture>
 
                <p className="create-new-project-type">
                  <b>DIGITAL</b>
                </p>
              </div>
            </Link>
          </div>
          <div className="new-campaign-detail pointer-for">
            <Link
              to="create/template/info"
              className="rs-dropdown-item-content"
            >
              <div className="center-div-img-text">
                <picture>
                  <source
                    srcSet={SocialWhiteImg}
                    media={`(prefers-color-scheme: ${"dark"})`}
                    alt="New Email"
                  />
 
                  <img src={SocialImg} alt="New Email" />
                </picture>
 
                <p className="create-new-project-type">
                  <b>SOCIAL</b>
                </p>
              </div>
            </Link>
          </div> */}
          </div>
 
          <Outlet />
        </div>
      </>
    );
  }
}
 
function mapStateToProps(state) {
  const {
    isLoggedIn,
    success,
    userInfo: { user },
  } = state.LoginSlice;
 
  return {
    isLoggedIn,
    success,
    user,
  };
}
const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    
    dispatch(resetData());
  },
  getSenderprofile: () => dispatch(getSenderprofile()),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);