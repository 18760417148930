import React, { Component } from "react";
import {
  Col,
  Row,
  InputGroup,
  Input,
  Popover,
  Whisper,
  Button,
  Badge,
} from "rsuite";
import DataFolder from "../Folder/DataFolder";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PaginationComponent from "../Utils/PaginationComponent";
import Loading from "../Loading/Loading";

import { getDataLists } from "../Features/Data/DataThunk";
import { resetData } from "../Features/Data/DataSlice";
import { checkDataIdInDatabase } from "../Features/CampaignManagement/CampaignPropertiesThunk";
import SearchIcon from "@rsuite/icons/Search";

class DataLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 4,
      grid: true,
      loading: false,
      total: null,
    };
  }

  async componentDidMount() {
    let { page } = this.state;
    await this.props.reset();
    this.fetchData({ type: "recent", page, value: null });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.dataSets !== this.props.dataSets) {
      const { dataSets, checkDataIdInDatabase } = this.props;
      for (const dataItem of dataSets.dataSet) {
        const dataId = dataItem.data_list_id;
        await checkDataIdInDatabase(dataId);
      }
    }
  }

  fetchData = async ({ type, page, value }) => {
    // console.log("fetchData type", type )
    const { getDataLists } = this.props;
    this.setState({
      loading: true,
    });
    let { limit } = this.state;

    await getDataLists({ type, page, limit, value });
    let { dataList } = this.props;
    // console.log("datalist:::",dataList)
    this.setState({
      loading: false,
      page: Number(dataList.page),
      total: dataList.count,
    });
  };

  selectFolder = async (e) => {
    await this.fetchData({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
    });
  };


  setNewPage = async (page) => {
    await this.fetchData({
      type: "recent",
      page,
      value: null,
    });
  };

  search = async (e) => {

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    let page = 1
    this.searchTimeout = setTimeout(async () => {
      await this.fetchData({
        type: "search",
        page,
        value: e,
      });
    }, 800);

    this.setNewPage = async (page) => {
      await this.fetchData({
        type: "search",
        page,
        value: e,
      });
    };
  };




  render() {
    let { dataList, reset, campaignPropertiesCheckedInDataId } = this.props;
    let { grid, limit, page, loading, total } = this.state;
    return (
      <div>
        <Row className="new-campaign-row">
          <Col md={5} className="side-folder">
            <DataFolder
              selectFolder={this.selectFolder}
              from="assets"
              fetchData={this.fetchData}
              setCancelDataFolder={(click) => (this.clickDataFolder = click)}
            />
          </Col>
          <Col
            md={19}
            style={{ paddingLeft: "25px" }}
            className={
              grid
                ? "grid-view-filter-template-image block-bg-font-color"
                : "list-view-filter-template-image block-bg-font-color"
            }
          >
            <Row style={{ marginBottom: 20 }}>
              <Col md={12}></Col>
              <Col md={12}>
                <InputGroup placeholder="Search via name">
                  <Input
                    placeholder="Search via name"
                    onChange={(e) => this.search(e)}
                  />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </Row>
            {!loading ? (
              <>
                {dataList && dataList.data.length > 0 ? (
                  <>
                    {dataList.data.map((val) => (
                      <div
                        className={`${grid ? `grid` : "list"} pointer-for`}
                        title={val.data_list_name}
                        key={val.data_list_id}
                      >
                        <Link
                          to={`/assetlibrary/data/edit/${val.data_list_id}/info`}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className={`${grid
                                ? "rs-panel rs-panel-default rs-panel-bordered rs-panel-shaded panel-remove-border"
                                : ""
                              }`}
                            style={{
                              display: `${grid ? "" : "flex"}`,
                            }}
                          >
                            <div
                              className={`${grid ? "DataResize " : "email_images_list"
                                }`}
                              style={{
                                display: `${grid ? "" : "inline-block"}`,
                              }}
                            >
                              <img
                                className={`${grid ? "email_images" : ""}`}
                                src="https://charlie-dave.s3.amazonaws.com/database.png"
                                data-id={val.data_list_id}
                                alt={val.data_list_name}
                                title={val.data_list_name}
                                style={{
                                  width: "58%",
                                  marginLeft: "22%",
                                  marginTop: "10%",
                                }}
                              />
                              <div
                                style={{
                                  marginTop: "6%",
                                  marginLeft: "4%",
                                  color: "#000",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  className="DataDetails"
                                  title={val.data_list_name}
                                >
                                  <b>Name:</b> &nbsp; {val.data_list_name}
                                </div>
                                <div
                                  className="DataDetails"
                                  title={val.data_list_created_by}
                                >
                                  <b>Created:</b> &nbsp;
                                  {val.data_list_created_by}
                                </div>
                                <div
                                  className="DataDetails"
                                  title={val.data_list_modified_by}
                                >
                                  <b> Modified:</b>&nbsp;
                                  {val.data_list_modified_by}
                                </div>
                                <div
                                  className="DataDetails"
                                  title={val.data_list_count}
                                >
                                  <b>Count:</b>&nbsp; {val.data_list_count}
                                </div>
                                {/* {campaignPropertiesCheckedInDataId[
                                  val.data_list_id
                                ] && (
                                  <Badge
                                    content="ACTIVE"
                                    color="red"
                                    title="Active with Campaign"
                                    style={{ width: "33%" }}
                                  />
                                )} */}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7}></Col>
                    <Col md={7} style={{ textAlign: "center" }}>
                      <h5>No Data Found !</h5> <br />
                      <Link
                        onClick={() => reset()}
                        className="link-color-font"
                        to="/create/data-set/info"
                        style={{ fontSize: 25, cursor: "pointer" }}
                      >
                        Click to Create
                      </Link>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                )}
              </>
            ) : (
              <Loading />
            )}
            <div className="fixed-pagination">
              <PaginationComponent
                limit={limit}
                page={page}
                setNewPage={this.setNewPage}
                total={total}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataList: state.DataSlice.dataList,
  dataSets: state.AssetSlice.dataSets,
  campaignPropertiesCheckedInDataId:
    state.CampaignPropertiesSlice.campaignPropertiesCheckedInDataId,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetData()),
  getDataLists: (type, page, limit, value) =>
    dispatch(getDataLists(type, page, limit, value)),
  checkDataIdInDatabase: (dataId) => dispatch(checkDataIdInDatabase(dataId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataLists);