import React, { Component } from "react";
import { Col, Row, Form, Grid, Button,Message } from "rsuite";
import { connect } from "react-redux";
import "../Create/assets/css/dataset.css";

import { Plus } from "@rsuite/icons";

import {
  getDataById,
  editStructure,
  fetchRecord,
} from "../../Features/Data/DataThunk";
import DataSetConfirmationModal from "./DataSetConfirmationModal";
import Loading from "../../Loading/Loading";

import DataService from "./DataService";

class EditDataStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      datafield: this.props.structureById,
      WhitespaceError : false,
      errorFieldName: [],
      emailField: {
        isEditEmailfield: false,
        index: null,
        value: this.props.dataById.data_list_email_field,
      },
      FieldToRemove: null,
      primaryIndex: null,
      isFieldAdded: false,
      edit: {
        isEdit: false,
        index: null,
      },
      update: false,
      rawData: [],
      updateValues: null,
      records: false,
    };
    this.props.export(this.exportToCsv);
  }

  async componentDidMount() {
    const { datafield } = this.state;
    // console.log(fetchRecord);

    // console.log(datafield);

    const response = await this.props.fetchRecordAction(this.props.id);

    const ListStatus = response.payload.data.records;

    // console.log(ListStatus);

    this.setState({
      rawData: response.payload.data,

      records: response.payload.data.records,
    });

    const sortedDataField = [...datafield];

    sortedDataField.sort((a, b) => a.ordinalPos - b.ordinalPos);

    for (let i = 0; i < sortedDataField.length; i++) {
      if (
        sortedDataField[i].Primary !== "" &&
        sortedDataField[i].Primary === "PRIMARY KEY"
      ) {
        this.setState({ primaryIndex: i });
      }
    }

    this.setState({ datafield: sortedDataField });

    // console.log(sortedDataField);
  }

  selectEmailField = (e) => {
    let { emailField } = this.state;

    emailField = {
      value: e.target.value,
      index: e.target.options.selectedIndex - 1,
      isEditEmailfield: true,
    };
    this.setState({
      emailField,
    });
  };

  handleChange = (e) => {
    let { emailField, datafield ,} = this.state;
    const {alertRef } = this.props;
 
    const { name, value } = e.target;

 // Additional validation to ensure the value is within the range of 1 to 255
 if (name.startsWith("Length") && (value < 1 || value > 255)) {
  // Display an error message or handle the validation as needed
  alertRef.current.showToaster({
    status: 2,
    duration: 1,
    message: "The Length Value must be between or equal 1 and 255",
  });
  return; // Prevent further processing if the value is invalid
}


    if (
      e.target.dataset.class === "DataType" &&
      (e.target.value === "text" ||
        e.target.value === "date" ||
        e.target.value === "boolean")
    ) {
      datafield[e.target.dataset.id]["Primary"] = "";
      this.setState({
        primaryIndex: null,
        datafield,
      });
    }

    if (
      e.target.dataset.class === "FieldName" &&
      Number(e.target.dataset.id) === Number(emailField.index) &&
      emailField.index !== null
    ) {
      emailField = {
        index: e.target.dataset.id,
        value: e.target.value,
        isEditEmailfield: false,
      };
      this.setState({
        emailField,
      });
    }
    datafield[e.target.dataset.id][e.target.dataset.class] = e.target.value;

    this.setState({
      datafield,
    });

    if (e.target.dataset.class === "Primary")
      this.setState({
        primaryIndex: e.target.dataset.id ? null : e.target.dataset.id,
      });
  };

  remove = (e) => {
    let { alertRef } = this.props;
    let { datafield } = this.state;
    if (!datafield[e.target.getAttribute("data-id")].isEmailField) {
      if (datafield.length - 1 > 1) {
        if (datafield[e.target.getAttribute("data-id")].ordinalPos !== null) {
          this.setState({
            FieldToRemove: datafield[e.target.getAttribute("data-id")],
            confirmation: true,
          });
        } else {
          if (
            datafield[e.target.getAttribute("data-id")].Primary ===
            "PRIMARY KEY"
          ) {
            this.setState({
              primaryIndex: null,
            });
          }

          datafield.splice([e.target.getAttribute("data-id")], 1);
          this.setState({ datafield, isFieldAdded: false });
        }
      } else {
        alertRef.current.showToaster({
          status: 0,
          message: "You can't delete all columns,  delete table instead",
        });
      }
    } else {
      alertRef.current.showToaster({
        status: 0,
        duration: 1,
        message: "Sorry, You can't delete the email field",
      });
    }
  };

  hideError = (e) => {
    // this.checkfieldName(event)
    document.getElementById(
      `checkerrorField-${e.target.dataset.id}`
    ).style.display = "none";
  };

  KeyDownName = (e) => {
    let { alertRef } = this.props;
    let regexp = new RegExp("^[a-zA-Z]+$");

    if (e.keyCode === 32) {
      alertRef.current.showToaster({
        status: 0,
        message: "White Space is not allowed",
      });
      e.preventDefault();
    } else if (!regexp.test(e.nativeEvent.key)) {
      alertRef.current.showToaster({
        status: 0,
        message: "Number and Special Characters are not allowed",
      });
      e.preventDefault();
    }
  };

  // checkfieldName = (event) => {
  //   var status = "Not exist";

  //   var { datafield } = this.state;
  //   console.log(datafield)
  //   for (var i = 0; i < datafield.length; i++) {
  //     var fields = datafield[i].FieldName.toLowerCase();
  //     console.log(fields)
  //     if (event.target.value.toLowerCase() === fields) {
  //       status = "Exist";
  //       this.setState({ status: status });
  //       break;
  //     } else {
  //       this.setState({ status: status });
  //     }
  //   }

  //   if (status === "Exist") {
  //     var checkerrorField = document.getElementsByClassName(
  //       `checkerrorField-${event.target.dataset.id}`
  //     );
  //     for (i = 0; i < checkerrorField.length; i += 1) {
  //       checkerrorField[i].style.display = "block";
  //     }
      
  //   }
  //   return status;
  // };

 
  checkfieldName = (event) => {
    var status = "Not exist";
    var { datafield, WhitespaceError } = this.state;
    console.log(WhitespaceError)
    let { alertRef} = this.props;
    var inputValue = event.target.value.toLowerCase();
    



     // Check for whitespace in the input value
  if (/\s/.test(inputValue)) {
    this.setState({ WhitespaceError: true });
    alertRef.current.showToaster({
      status: 0,
      message: "White Space is not allowed",
      duration: 3
    });
    return; // Exit the function if whitespace is found
  }else {
    this.setState({ WhitespaceError: false });
  }
    
    // Iterate over all elements in the datafield array
    for (var i = 0; i < datafield.length; i++) {
      // Skip fields with edit: true
      if (datafield[i].edit) continue;
  
      var fields = datafield[i].FieldName.toLowerCase();
      console.log(fields);
  
      if (event.target.value.toLowerCase() === fields) {
        status = "Exist";
        this.setState({ status: status });
        break; // No need to continue checking once a match is found
      } else {
        this.setState({ status: status });
      }
    }
  
    // Show elements with the specified class if a match is found
    if (status === "Exist") {
      var checkerrorField = document.getElementsByClassName(
        `checkerrorField-${event.target.dataset.id}`
      );
      for (i = 0; i < checkerrorField.length; i += 1) {
        checkerrorField[i].style.display = "block";
      }
    }
    
    return status;
  };
  
  
  


  addField = () => {
    let { alertRef } = this.props;

    if (!this.state.isFieldAdded) {
      this.setState((prevState) => ({
        datafield: [
          ...prevState.datafield,

          {
            ordinalPos: null,

            FieldName: "",

            DataType: "",

            Length: "",

            Required: "",

            Primary: "",

            edit: false,
          },
        ],

        isFieldAdded: true,
      }));
    } else {
      alertRef.current.showToaster({
        status: 0,

        message: "At a time only one row can be added",
      });
    }
  };

  openEdit = (e) => {
    let { alertRef } = this.props;
    let datafield = [...this.state.datafield];

    let index = e.target.getAttribute("data-id");
    if (index === "1") {
      alertRef.current.showToaster({
        duration: 1,
        status: 0,
        message: "Sorry, You can't edit the email field",
      });
      return;
    }

    let field = { ...datafield[index] };
    field.edit = true;
    datafield[index] = field;
    this.setState({
      datafield,
      updateValues: datafield[index],
      edit: {
        isEdit: true,
        index: index,
      },
    });
  };

  cancelEdit = async (e) => {
    let { getDataById, id } = this.props;

    let datafield = [...this.state.datafield];
    this.setState({
      loading: true,
    });
    await getDataById({
      type: "byId",
      value: id,
    });
    datafield[e.target.getAttribute("data-id")].edit = false;
    this.setState({
      datafield,
      edit: {
        isEdit: false,
        index: null,
      },
      updateValues: null,
      FieldToRemove: null,
      show: false,
      update: false,
      added: false,
      loading: false,
    });
  };

  check = async () => {
    var { updateValues } = this.state;
    var err = [];
    if (updateValues) {
      if (
        updateValues.DataType === "text" ||
        updateValues.DataType === "date" ||
        updateValues.DataType === "boolean" ||
        updateValues.DataType === "decimal"
      ) {
        if (
          updateValues.FieldName !== "" &&
          updateValues.DataType !== "" &&
          updateValues.Required !== ""
        ) {
          err.push(false);
        } else {
          err.push(true);
        }
      } else {
        if (
          updateValues.FieldName !== "" &&
          updateValues.DataType !== "" &&
          updateValues.Length !== "" &&
          updateValues.Required !== ""
        ) {
          err.push(false);
        } else {
          err.push(true);
        }
      }
    } else {
      var { FieldToRemove } = this.state;
      if (FieldToRemove) {
        if (FieldToRemove.ordinalPos !== null) {
          err.push("NO");
        }
      }
    }
    return err.includes(true);
  };

  handleSubmit = async () => {
    try {
      let { alertRef, editStructure, id, getDataById } = this.props;
      let { updateValues, FieldToRemove, emailField, WhitespaceError } = this.state;


         // Check for whitespace errors
    if (WhitespaceError) {
      alertRef.current.showToaster({
        status: 0,
        message: "Please remove whitespace from the filed name",
        duration: 3
      });
      return; // Prevent form submission
    }
      var check = await this.check();
      if (!check) {
        if (this.state.status !== "Exist") {
          this.setState({
            loading: true,
          });
          let response = await editStructure({
            id,
            updateValues,
            FieldToRemove,
            emailField,
          });
          await getDataById({
            type: "byId",
            value: id,
          });
          alertRef.current.showToaster(response.payload);
          this.setState({
            datafield: this.props.structureById,
            edit: {
              isEdit: false,
              index: null,
            },
            loading: false,
            updateValues: null,
            FieldToRemove: null,
            show: false,
            update: false,
            added: false,
            emailField: {
              isEditEmailfield: false,
              index: null,
              value: this.props.dataById.data_list_email_field,
            },
            isFieldAdded: false,
            confirmation: false,
          });
        } else {
          alertRef.current.showToaster({
            status: 0,
            message: check ? "Please fix the errors." : "Field name can't be same.",
            duration: 3
          });
        }
      } else {
        document.getElementById("submitbutton").click();
      }
    } catch (e) {
      console.error(e);
    }
  };

  exportCheck = async () => {
    let { datafield } = this.state;
    let error = [];
    datafield.forEach((element, index, array) => {
      if (element.ordinalPos !== null) {
        error.push(false);
      } else {
        error.push(true);
      }
    });
    return error.includes(true);
  };

  exportToCsv = async () => {
    let { alertRef } = this.props;

    let error = await this.exportCheck();
    if (!error) {
      let { dataById, structureById } = this.props;
      DataService.exportFieldToCsv(
        dataById,
        structureById,
        this.state.records ? this.state.rawData.table : []
      );
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please first save your changes",
      });
    }
  };

  render() {
    let {
      datafield,
      primaryIndex,
      confirmation,
      edit,
      emailField,
      loading,
      records,
    } = this.state;
    // console.log(datafield);
    let { getDataById, id } = this.props;
    if (loading) return <Loading />;
    return (
      <>

<Row>
            <Message showIcon type="info"closable>
            First Row is default set for email field.
            </Message>
        </Row>
        <Row
          style={{
            marginTop: 30,
          }}
        >
          <Col md={24}>
            <Form layout="horizontal">
              <Grid fluid>
                <Row>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Name
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Data Type
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Length
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Required
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                    Primary
                  </Col>
                  <Col xs={4} className="text-align-center font-weight-bold">
                  <Button
  disabled={edit.isEdit || records}
  onClick={() => this.addField()}
  style={{
    backgroundColor: records ? "#CCCCCC" : "#415670", // Change background color if records are found
    color: "#fff",
  }}
  title={records ? "You can't add the structure as it has data value in it" : "Add New Row"}
>
  Add&nbsp;&nbsp;&nbsp;&nbsp;
  <Plus />
</Button>
                  </Col>
                </Row>

                <div
                  style={{
                    marginTop: 10,
                    // overflowY: "auto",
                    // height: 250,
                    marginBottom: 40,
                  }}
                >
                  {datafield.length > 0
                    ? datafield.map(
                      (val, idx) =>
                        val.FieldName !== "material_table_id" && (
                          <Row key={idx} style={{ marginBottom: 10 }}>
                            <Col xs={4}>
                              <input
                              //  onContextMenu={(e)=>e.preventDefault()}
                              //  onPaste={(e)=>e.preventDefault()}
                                required
                                type="text"
                                name={`FieldName-${idx}`}
                                data-id={idx}
                                id={`FieldName-${idx}`}
                                value={datafield[idx].FieldName}
                                className="rs-input"
                                data-class="FieldName"
                                onBlur={this.checkfieldName}
                                onFocus={this.hideError}
                                onChange={this.handleChange}
                                onKeyDown={(e) => this.KeyDownName(e)}
                                disabled={!datafield[idx].edit}
                                data-ordinal={datafield[idx].ordinalPos}
                              />
                              <span
                                className={`checkerrorField-${idx}`}
                                id={`checkerrorField-${idx}`}
                              >
                                Field name can't be same
                              </span>
                            </Col>

                            <Col xs={4}>
                              <select
                                required
                                data-class="DataType"
                                onChange={this.handleChange}
                                className="rs-input"
                                name={`DataType-${idx}`}
                                data-id={idx}
                                id={`DataType-${idx}`}
                                value={datafield[idx].DataType}
                                data-ordinal={datafield[idx].ordinalPos}
                                disabled={!datafield[idx].edit}
                              >
                                <option value="" hidden>
                                  Select a option
                                </option>

                                {idx === 0 ? (
                                  <option value="varchar">Varchar</option>
                                ) : (
                                  <>
                                    <option value="text">Text</option>
                                    <option value="varchar">Varchar</option>
                                    <option value="int">Integer</option>
                                    <option value="date">Date</option>
                                    <option value="boolean">Boolean</option>
                                    <option value="decimal">Decimal</option>
                                  </>
                                )}
                              </select>
                            </Col>

                            <Col xs={4}>
                              <input
                                required
                                type="number"
                                min="1"
                                max="255"
                                name={`Length-${idx}`}
                                disabled={
                                  datafield[idx].DataType === "text" ||
                                  datafield[idx].DataType === "date" ||
                                  datafield[idx].DataType === "decimal" ||
                                  datafield[idx].DataType === "boolean" ||
                                  !datafield[idx].edit
                                }
                                data-id={idx}
                                id={`Length-${idx}`}
                                value={
                                  datafield[idx].DataType === "text" ||
                                    datafield[idx].DataType === "date" ||
                                    datafield[idx].DataType === "decimal" ||
                                    datafield[idx].DataType === "boolean"
                                    ? ""
                                    : datafield[idx].Length
                                }
                                className="rs-input"
                                onChange={this.handleChange}
                                data-class="Length"
                                readOnly={!datafield[idx].edit}
                                data-ordinal={datafield[idx].ordinalPos}
                              />
                            </Col>

                            <Col xs={4}>
                              <select
                                required
                                data-class="Required"
                                className="rs-input"
                                name={`Required-${idx}`}
                                data-id={idx}
                                id={`Required-${idx}`}
                                value={datafield[idx].Required}
                                onChange={this.handleChange}
                                readOnly={!datafield[idx].edit}
                                disabled={!datafield[idx].edit}
                                data-ordinal={datafield[idx].ordinalPos}
                              >
                                <option value="" disabled hidden>
                                  Select a option
                                </option>
                                <option value="NOT NULL">True</option>
                                <option value=" ">False</option>
                              </select>
                            </Col>

                            <Col xs={4}>
                              <select
                                disabled={
                                  (primaryIndex === null
                                    ? false
                                    : Number(primaryIndex) === idx
                                      ? false
                                      : true) ||
                                  datafield[idx].DataType === "text" ||
                                  datafield[idx].DataType === "date" ||
                                  datafield[idx].DataType === "boolean" ||
                                  !datafield[idx].edit
                                }
                                data-class="Primary"
                                className="rs-input"
                                name={`Primary-${idx}`}
                                data-id={idx}
                                id={`Primary-${idx}`}
                                value={datafield[idx].Primary}
                                onChange={this.handleChange}
                                readOnly={!datafield[idx].edit}
                                data-ordinal={datafield[idx].ordinalPos}
                              >
                                <option value="" disabled hidden>
                                  Select a option
                                </option>
                                {idx === 1 ? (
                                  <option value=" PRIMARY KEY">True</option>
                                ) : (
                                  <option value=" ">False</option>
                                )}
                              </select>
                            </Col>

                            <Col
                              data-id={idx}
                              xs={4}
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {!emailField.isEditEmailfield && (
                                <>
                                  {datafield[idx].edit ? (
                                    <>
                                      {/* <i
                                        className="fa fa-trash font-size-icon"
                                        data-id={idx}
                                        data-name={datafield[idx].FieldName}
                                        title={
                                          records
                                            ? "You can't delete the structure as it has data value in it"
                                            : "Delete"
                                        }
                                        data-ordinal={
                                          datafield[idx].ordinalPos
                                        }
                                        onClick={records ? null : this.remove}
                                        style={{
                                          marginTop: 8,
                                          cursor: records
                                            ? "not-allowed"
                                            : "pointer",
                                          opacity: records ? 0.5 : 1,
                                        }}
                                      /> */}
                                      <i
                                        className="fa fa-save font-size-icon"
                                        data-id={idx}
                                        data-name={datafield[idx].FieldName}
                                        data-ordinal={
                                          datafield[idx].ordinalPos
                                        }
                                        onClick={
                                          records ? null : this.handleSubmit
                                        }
                                        title={
                                          records
                                            ? "You can't delete or modify the structure as it has data value in it"
                                            : "Save"
                                        }
                                        style={{
                                          marginTop: 8,
                                          cursor: records
                                            ? "not-allowed"
                                            : "pointer",
                                          opacity: records ? 0.5 : 1,
                                        }}
                                      />
                                      <i
                                        className="fa fa-close font-size-icon"
                                        data-id={idx}
                                        data-name={datafield[idx].FieldName}
                                        data-ordinal={
                                          datafield[idx].ordinalPos
                                        }
                                        onClick={
                                          records ? null : this.cancelEdit
                                        }
                                        title={
                                          records
                                            ? "You can't delete or modify the structure as it has data value in it"
                                            : "Close Edit"
                                        }
                                        style={{
                                          marginTop: 8,
                                          cursor: records
                                            ? "not-allowed"
                                            : "pointer",
                                          opacity: records ? 0.5 : 1,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {edit.index !== idx &&
                                        edit.isEdit === false && (
                                          <>
                                            <i
                                              className="fa fa-trash font-size-icon"
                                              data-id={idx}
                                              data-name={
                                                datafield[idx].FieldName
                                              }
                                              title={
                                                records
                                                  ? "You can't delete the structure as it has data value in it"
                                                  : "Delete"
                                              }
                                              data-ordinal={
                                                datafield[idx].ordinalPos
                                              }
                                              onClick={
                                                records ? null : this.remove
                                              }
                                              style={{
                                                marginTop: 8,
                                                cursor: records
                                                  ? "not-allowed"
                                                  : "pointer",
                                                opacity: records ? 0.5 : 1,
                                              }}
                                            />
                                            <i
                                              className="fa fa-edit font-size-icon"
                                              data-id={idx}
                                              data-name={
                                                datafield[idx].FieldName
                                              }
                                              data-ordinal={
                                                datafield[idx].ordinalPos
                                              }
                                              onClick={
                                                records ? null : this.openEdit
                                              }
                                              title={
                                                records
                                                  ? "You can't edit the structure as it has data value in it"
                                                  : "Edit"
                                              }
                                              style={{
                                                marginTop: 8,
                                                cursor: records
                                                  ? "not-allowed"
                                                  : "pointer",
                                                opacity: records ? 0.5 : 1,
                                              }}
                                            />
                                          </>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        )
                    )
                    : null}
                </div>
                <hr />
                <Row>
                  <Col xs={20} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button
                      type="submit"
                      id="submitbutton"
                      style={{ display: "none" }}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </Form>
          </Col>

          <DataSetConfirmationModal
            confirmation={confirmation}
            hide={() =>
              this.setState({
                confirmation: false,
              })
            }
            handleSubmit={this.handleSubmit}
          />
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { dataById, structureById } = state.DataSlice;
  return {
    dataById,
    structureById,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getDataById: (type, value) => dispatch(getDataById(type, value)),
  editStructure: ({ id, updateValues, FieldToRemove, emailField }) =>
    dispatch(editStructure({ id, updateValues, FieldToRemove, emailField })),
  fetchRecordAction: (id) => dispatch(fetchRecord(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDataStructure);
