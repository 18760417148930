import React, { Component } from "react";
import { Row, Col, Button, Uploader, ButtonToolbar } from "rsuite";
import { validate } from "email-validator";
import * as XLSX from "xlsx";
import "../Create/assets/css/dataset.css";

export default class UploaderFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidEmails: [],
    };
  }
  componentDidMount() {
    const { values } = this.props;

    this.updateInvalidEmails(values);
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (values !== prevProps.values) {
      this.updateInvalidEmails(values);
    }
  }

  updateInvalidEmails = (values) => {
    if (values && values.length > 0) {
      const invalidEmails = this.validateEmails(values);
      this.setState({ invalidEmails });
    }
  };

  hasValue = (file) => {
    return file !== undefined && file !== null && file !== "";
  };

  validateEmails = (values) => {
    const invalidEmails = values.filter((item) => !validate(item[0]));
    return invalidEmails;
  };

  // for exporting invalid emails into excel sheet,

  handleExportInvalidEmails = () => {
    const { invalidEmails } = this.state;
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      ["email"],
      ...invalidEmails.map((item) => [item[0]]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invalid Emails");
    XLSX.writeFile(workbook, "invalid_emails.xlsx");
  };

  // Export option for null data for required filed.

  handleExportRemovedRowsWithHeaders = () => {
    const { removedRowsWithHeaders } = this.props;
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      Object.keys(removedRowsWithHeaders[0]),
      ...removedRowsWithHeaders.map(Object.values),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Removed Rows");
    XLSX.writeFile(workbook, "removed_rows.xlsx");
  };

  // Checking for all duplicates emails to exports.

  handleExportDuplicateEmails = () => {
    const { duplicateEmails } = this.props;
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      Object.keys(duplicateEmails[0]),
      ...duplicateEmails,
      map(Object.values),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "duplicateEmails");
    XLSX.writeFile(workbook, "removed_duplicateEmails.xlsx");
  };

  renderTableHeader = () => {
    const { columnNames, tableStructure } = this.props;
    const firstRowStyle = {
      border: "none",
    };
    const tableHeadStyle = {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      padding: "8px",
      backgroundColor: "#ffffff",
      border: "none",
      padding: "10px",
      borderRadius: "8px",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    };
    const Pstyle = {
      marginBottom: "15px",
    };

    return (
      <>
        <div style={tableHeadStyle}>
          <tr key={"header"} className="tableTr">
            <th
              className="tableHead"
              style={firstRowStyle}
              colSpan={columnNames.length + 1}
            >
              <p style={Pstyle}>
                *Please follow this header format to upload the file.
              </p>
            </th>
          </tr>
          <tr key={"header-titles"} className="tableTr">
            {columnNames.map((columnName, index) => {
              const isRequired = tableStructure.find(
                (field) =>
                  field.COLUMN_NAME === columnName.title &&
                  field.IS_NULLABLE === "NO"
              );
              const asteriskStyle = {
                color: isRequired ? "red" : "black",
                marginRight: "3px",
              };
              return (
                <th className="tableHead" key={index} style={tableHeadStyle}>
                  <span style={asteriskStyle}>{isRequired ? "*" : ""}</span>
                  {columnName.title}
                </th>
              );
            })}
          </tr>
        </div>
      </>
    );
  };

  render() {
    let {
      preview,
      file,
      fileChange,
      remove,
      openPreview,
      records,
      values,
      back,
      upload,
      duplicate,
      validated,
      loadingCsv,
      duplicateEmails,
      removedRowsWithHeaders,
      tableStructure,
    } = this.props;

    // console.log("tableStructure", tableStructure)
    // console.warn("removedRowsWithHeaders", removedRowsWithHeaders)

    // console.warn("duplicateEmails:::::", duplicateEmails)
    // console.log(records)

    // console.log(loadingCsv);

    const isUploadDisabled =
      this.state.invalidEmails.length > 0 ||
      (this.props.values && this.props.values.length === 0);
       // Update loadingCsv based on records
  if (!records || records.length === 0) {
    loadingCsv = false;
  }

    // console.log(this.state.invalidEmails);
    return (
      <div>
        {!preview ? (
          <form id="myForm">
            <Col md={24} style={{ textAlign: "center" }}>
              <table
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // display: "block",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  marginTop: "10px",
                }}
              >
                <tbody>{this.renderTableHeader()}</tbody>
              </table>
              <h6 style={{ marginTop: "14px" }}>Upload New Data</h6>
              <Uploader
                id="uploader"
                className="uploadXlsx "
                action=""
                draggable
                style={{
                  width: 400,
                  margin: "auto",
                  marginTop: 15,
                  marginBottom: 20,
                }}
                autoUpload={false}
                multiple={false}
                accept=".xlsx, .xls, .csv"
                onChange={(e) => fileChange(e)}
              >
                <div style={{ color: "#000", height: 100, padding: 40 }}>
                  <b>Click or Drag files to this area to upload</b>
                </div>
              </Uploader>

              {this.hasValue(file) && (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: 400,
                        marginTop: 20,
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <i
                          className="fa fa-paperclip font-size-icon"
                          title="Attachment"
                        />
                      </div>

                      <div style={{ flexGrow: 8 }}>{file.blobFile.name}</div>
                      <div style={{ flexGrow: 1 }}>
                        <i
                          className="fa fa-close font-size-icon"
                          title="Remove"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => remove()}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: 20 }}>
                    <Button
                      onClick={(e) => openPreview()}
                      title="Proceed"
                      // appearance="primary"
                      style={{ backgroundColor: "#415670", color: "#fff" }}
                      loading={loadingCsv}
                    >
                      Proceed
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </form>
        ) : (
          <Row
            style={{
              marginBottom: 30,
              marginTop: 20,
            }}
          >
            <Col md={2}></Col>
            <Col md={20}>
              {/* Checking if required filed with null values available */}

              {removedRowsWithHeaders.length > 0 && (
                <div style={{ textAlign: "right" }}>
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {`*We have found ${removedRowsWithHeaders.length} null values in required fields. Export to review.`}
                  </p>
                  <Button
                    title="Export Removed Rows"
                    // appearance="primary"
                    style={{ backgroundColor: "#415670", color: "#fff" }}
                    onClick={this.handleExportRemovedRowsWithHeaders}
                  >
                    Export Removed Rows
                  </Button>
                </div>
              )}

              {/* Checking if invalidEmails available */}

              {this.state.invalidEmails.length > 0 && (
                <div style={{ textAlign: "right" }}>
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {`*We have found ${this.state.invalidEmails.length} Invalid email address.`}
                  </p>
                  <Button
                    title="Export Emails"
                    // appearance="primary"
                    style={{ backgroundColor: "#415670", color: "#fff" }}
                    onClick={this.handleExportInvalidEmails}
                  >
                    Export Invalid Emails
                  </Button>
                </div>
              )}

              {/* Checking if duplicateEmails available */}

              {duplicateEmails.length > 0 && (
                <div style={{ textAlign: "right" }}>
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {`*We have found ${duplicateEmails.length} duplicate email(s). Export to review.`}
                  </p>
                  <Button
                    title="Export Duplicate Emails"
                    // appearance="primary"
                    style={{ backgroundColor: "#415670", color: "#fff" }}
                    onClick={this.handleExportDuplicateEmails}
                  >
                    Export Duplicate Emails
                  </Button>
                </div>
              )}

              {/* {duplicate > 0 || validated > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    {duplicate > 0 && (
                      <div style={{ color: "red" }}>
                        {"*Note - "}
                        {duplicate > 0 && `${duplicate} duplicate  `}
                        {duplicate > 0 && validated > 0 && "and "}
                        {validated > 0 && `${validated} invalid `}
                        {`email${duplicate > 1 || validated > 1 ? "s " : " "}has been removed`}
                      </div>
                    )}
                    {this.state.invalidEmails.length > 0 && (
                      <div style={{ color: "red" }}>
                        Invalid email address found
                      </div>
                    )}
                  </div>
                  {this.state.invalidEmails.length > 0 && (
                    <div>
                      <Button
                        title="Export Emails"
                        appearance="primary"
                        onClick={this.handleExportInvalidEmails}
                      >
                        Export Invalid Emails
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ textAlign: "right" }}>
                  <Button
                    title="Export Emails"
                    appearance="primary"
                    onClick={this.handleExportInvalidEmails}
                  >
                    Export Invalid Emails
                  </Button>
                </div>
              )} */}

              <br />
              <div style={{ height: 300, overflow: "scroll" }}>
                <table className="uploadDataTable">
                  <tbody>
                    <tr key={"header"} className="tableTr">
                      {Object.keys(records[0]).map((key, i) => (
                        <th className="tableHead" key={i}>
                          {key}
                        </th>
                      ))}
                    </tr>
                    {values && (
                      <>
                        {values.map((item, key) => (
                          <tr key={item} className="tableTr">
                            {Object.values(item).map((val, key) => (
                              <td className="tableVal" key={key}>
                                {val}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Row style={{ marginTop: 20 }}>
                {preview && (
                  <ButtonToolbar style={{ justifyContent: "center" }}>
                    <Button
                      onClick={() => back()}
                      title="Back"
                      style={{ backgroundColor: "#415670", color: "#fff" }}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={(e) => upload()}
                      title="Upload"
                      // appearance="primary"
                      style={
                        !isUploadDisabled
                          ? { backgroundColor: "#415670", color: "#fff" }
                          : { color: "black", fontWeight: "600" }
                      }
                      disabled={isUploadDisabled}
                    >
                      Upload
                    </Button>
                  </ButtonToolbar>
                )}
              </Row>
            </Col>

            <Col md={2}></Col>
          </Row>
        )}
      </div>
    );
  }
}
