import axios from "../../../Utils/Interceptor/AxiosInterceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTemplateFolder = createAsyncThunk("template-folder", async () => {
  const response = await axios.get(`/folder/template-folder`);
  return response.data;
});

export const createTemplateFolder = createAsyncThunk(
  "template-folder/create",
  async (name, parentId, is_folder_or_file) => {
    try {
      const response = await axios.post(
        `/folder/template-folder`,
        name,
        parentId,
        is_folder_or_file
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteTemplateFolder = createAsyncThunk("template-folder/delete", async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`/folder/template-folder/?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const renameTemplateFolder = createAsyncThunk(
  "template-folder/rename",
  async ({ id, text }) => {
    console.log(id);
    try {
      const response = await axios.put(`/folder/template-folder/?id=${id}&name=${text}`);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
