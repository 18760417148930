import { createSlice } from "@reduxjs/toolkit";
import { getRecentAssets, getEmails, emailById } from "./AssetThunk";
import { assets } from "../const";

const AssetSlice = createSlice({
  name: "assets",
  initialState: assets,

  reducers: {
    setViewType(state, data) {
      let isGrid = JSON.parse(localStorage.getItem("isGrid"));
      isGrid = data.payload === "grid" ? true : false;
      localStorage.setItem("isGrid", JSON.stringify(isGrid));
      state.isGrid = data.payload === "grid" ? true : false;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRecentAssets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRecentAssets.fulfilled, (state, action) => {
        state.templates["template"] = action.payload.data.template;
        state.emails["email"] = action.payload.data.email;
        state.dataSets["dataSet"] = action.payload.data.dataSet;
        state.loading = false;
        state.error = null;
        state.success = true;
      })

      .addCase(getRecentAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.emails = [];
        state.templates = [];
      })

      // FETCHING EMAILS

      .addCase(getEmails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmails.fulfilled, (state, action) => {
        state.emails = action.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      })

      .addCase(getEmails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.emails = [];
      })

      // FETCHING EMAIL BY ID

      .addCase(emailById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(emailById.fulfilled, (state, action) => {
        state.emailById = action.payload.data;
        state.success = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(
        emailById.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          state.loading = false;
          state.error = action.error;
          state.emailById = {};
          state.success = false;
        }
      );
  }
});
const { reducer, actions } = AssetSlice;
export const { setViewType } = actions;

export default reducer;
