import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  editorTemplates,
  editorTemplateById,
} from "../../../Features/Editor/EditorThunk";
import "./model.css";
import {
  Drawer,
  Button,
  Row,
  Col,
  InputGroup,
  Input,
  TagGroup,
  Tag,
} from "rsuite";
import { hideLoading } from "../../../Features/Editor/EditorSlice";
import TemplateFolder from "../../../Folder/TemplateFolder";
import PaginationComponent from "../../PaginationComponent";
import SearchIcon from "@rsuite/icons/Search";

const TemplateLoad = ({
  editorTemplates,
  editorTemplateById,
  hideLoading,
  editorSlice,
  handleTemplateLoadModal,
  templateLoadModal,
  emailEditorRef,
}) => {
  const [state, setState] = useState({
    activeTemplate: null,
    page: 1,
    limit: 6,
    total: null,
    tags: [],
    tagText: "",
  });

  const { activeTemplate, page, limit, total, tags, tagText } = state;

  useEffect(() => {
    const fetchData = async () => {
      await fetchTemplates({ type: "recent", page, value: null });
    };
    fetchData();
  }, []);

  const fetchTemplates = async ({ type, page, value }) => {
    const { template } = editorSlice;
    try {
      await hideLoading(true);
      await editorTemplates({ type, page, limit, value });
      setState((prevState) => ({
        ...prevState,
        total: template.count,
        page: Number(template.page),
      }));
      await hideLoading(false);
    } finally {
      await hideLoading(false);
    }
  };

  const selectFolder = async (e) => {
    await fetchTemplates({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
    });
  };

  const setNewPage = async (newPage) => {
    await fetchTemplates({
      type: "recent",
      page: newPage,
      value: null,
    });
  };

  const search = async (searchValue) => {
    await fetchTemplates({
      type: "search",
      page,
      value: searchValue,
    });
  };

  const sort = async (type, index) => {
    let newCategories = [];
    let newCategory = "";
    if (type === "delete") {
      newCategories = tags.filter((item, i) => i !== index);
      setState({ ...state, tags: newCategories });
    } else {
      if (tagText.trim().replace(/\s/g, "").length > 0) {
        if (!tags.includes(tagText.trim().toLowerCase())) {
          newCategory = tagText.trim().toLowerCase()
            ? [...tags, tagText.trim().toLowerCase()]
            : tags;
          setState({ ...state, tagText: "", tags: newCategory });
        }
      }
    }
    await fetchTemplates({
      type: "sort",
      page,
      value: newCategories,
    });
  };

  // REVIEW - Function for Selecting Template with handling error condition
  const selectedTemplate = async () => {
    try {
      await hideLoading(true);
      await editorTemplateById({
        type: "byId",
        value: activeTemplate,
      });
    } catch (error) {
      console.error("Error loading template:", error);
    } finally {
      await hideLoading(false);
      handleTemplateLoadModal();
    }
  };

  /* NOTE: Once data is fetched and the Redux store is updated with the latest template ,if a template is found, load that template into the email editor.
   */
  const { templateById, loading } = editorSlice;
  const unlayer = emailEditorRef.current?.editor;
  if (unlayer && templateById) {
    unlayer.loadDesign(templateById.json);
  }

  return (
    <Drawer
      size="full"
      placement="right"
      open={templateLoadModal}
      onClose={() => handleTemplateLoadModal()}
      backdrop="static"
    >
      <Drawer.Header>
        <Drawer.Title>Select Template</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={() => handleTemplateLoadModal()}>Cancel</Button>
          <Button
            onClick={() => selectedTemplate()}
            disabled={!activeTemplate || loading}
            style={{ backgroundColor: "#415670", color: "#fff" }}
            loading={loading}
          >
            Confirm
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <Row>
          <Col md={5} className="side-folder-modal">
            <TemplateFolder
              selectFolder={selectFolder}
              from="assets"
              fetchTemplates={fetchTemplates}
            />
          </Col>
          <Col md={19}>
            <Row style={{ margin: "0px 20px" }}>
              <Col md={10}>
                <TagGroup style={{ margin: 0 }}>
                  <Input
                    placeholder="Add categories and press enter"
                    name="categories"
                    value={tagText}
                    onChange={(e) => setState({ ...state, tagText: e })}
                    onBlur={() => sort("add", undefined)}
                    onPressEnter={() => sort("add", undefined)}
                  />
                  {tags.map((item, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={() => sort("delete", index)}
                    >
                      {item}
                    </Tag>
                  ))}
                </TagGroup>
              </Col>
              <Col md={4}></Col>
              <Col md={10}>
                <InputGroup placeholder="Search via name">
                  <Input placeholder="Search via name" onChange={(e) => search(e)} />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </Row>
            {editorSlice.template.template &&
            editorSlice.template.template.length > 0 ? (
              <>
                {editorSlice.template.template.map((child) => (
                  <div
                    data-id={child.template_id}
                    className={`image-wrap ${
                      Number(activeTemplate) === Number(child.template_id)
                        ? "active-template"
                        : ""
                    }`}
                    id={child.template_id}
                    key={child.template_id}
                    onClick={(e) =>
                      setState({ ...state, activeTemplate: e.currentTarget.id })
                    }
                  >
                    <img
                      src={child.template_img_path}
                      alt={child.template_name}
                    />
                  </div>
                ))}
              </>
            ) : (
              <Row style={{ marginTop: 20 }}>
                <Col md={8}></Col>
                <Col md={8} style={{ textAlign: "center" }}>
                  <h5>No Template Found!</h5>
                  <br />
                </Col>
                <Col md={8}></Col>
              </Row>
            )}
            {tagText === "" && tags.length === 0 && (
              <div className="fixed-pagination">
                <PaginationComponent
                  limit={limit}
                  page={page}
                  setNewPage={setNewPage}
                  total={total}
                />
              </div>
            )}
          </Col>
        </Row>
      </Drawer.Body>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  editorSlice: state.EditorSlice,
});

const mapDispatchToProps = (dispatch) => ({
  hideLoading: (isModal) => dispatch(hideLoading(isModal)),
  editorTemplates: (type, page, limit, value) =>
    dispatch(editorTemplates(type, page, limit, value)),
  editorTemplateById: (type, value) =>
    dispatch(editorTemplateById(type, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLoad);
