import { createSlice } from "@reduxjs/toolkit";
import { fetchImageFolder } from "./ImageFolderThunk";

const ImageFolderSlice = createSlice({
  name: "image",
  initialState: {
    loading: false,
    imageFolder: [],
    error: null,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImageFolder.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchImageFolder.fulfilled, (state, action) => {
        state.imageFolder = action.payload.data.image;
        state.success = true;
        state.loading = false;
      })

      .addCase(
        fetchImageFolder.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.imageFolder = [];
        }
      );

    // and provide a default case if no other handlers matched
    //   .addDefaultCase((state, action) => {
    //     state.loading = false;
    //     state.template = [];
    //     state.success = false;
    //     state.error = null;
    //   });
  }
});

export default ImageFolderSlice.reducer;
