import React, { Component } from "react";
import convertToUserTimeZone from "../../../../../hooks/convertToUserTimeZone";

export default class LeftSideDetails extends Component {
  render() {
    let { report } = this.props;
    // console.log("report", report);
    // Check if email_name exists in the report
    if (
      !report ||
      !report.campaignInfo ||
      !report.campaignInfo.e ||
      !report.campaignInfo.e.email_name
    ) {
      // Handle the case where email_name is not available
      return (
        <table className="description-left-side-table">
          <tbody>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Campaign : </b>
              </td>
              <td className="CampaignEmailsDetalis" >
               
                &nbsp;
                <span>{report.campaignInfo.c.campaign_name} </span>
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Email : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;{" "}
                <span style={{ color: "#dd0000" }}>Email is not exists </span>
              </td>
            </tr>

            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Dataset : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp; <span>Data List is not exists </span>{" "}
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Data count : </b>
              </td>
              <td className="CampaignEmailsDetalis" >
                &nbsp;
                <span>
                  {report.activities.tr}{" "}
                  {report.activities.tr > 1 ? "records" : "record"}
                </span>{" "}
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Total Sent : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;
                <span>
                  {report.activities.sc}{" "}
                  {report.activities.sc > 1 ? "records" : "record"}{" "}
                </span>
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Suppressed : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;
                <span>
                  {report.activities.su_c}{" "}
                  {report.activities.su_c > 1 ? "records" : "record"}{" "}
                </span>
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Created by : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;
                <span>{report.campaignInfo.c.created_by} </span>
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Modified by : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;
                <span>{report.campaignInfo.c.last_modified_by} </span>
              </td>
            </tr>
            <tr>
              <td className="CampaignEmailsDetalis">
                <b>Sent at : </b>
              </td>
              <td className="CampaignEmailsDetalis">
                &nbsp;
                <span>
                  {convertToUserTimeZone(report.campaignInfo.c.sendTime)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    return (
      <table className="description-left-side-table">
        <tbody>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Campaign : </b>
            </td>
            <td className="CampaignEmailsDetalis" title={report.campaignInfo.c.campaign_name}>
              {" "}
              &nbsp;
              <span>{report.campaignInfo.c.campaign_name} </span>
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Email : </b>
            </td>
            <td className="CampaignEmailsDetalis" title={report.campaignInfo.e.email_name}>
              &nbsp; <span>{report.campaignInfo.e.email_name} </span>
            </td>
          </tr>

          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Dataset : </b>
            </td>
            <td className="CampaignEmailsDetalis" title={report.campaignInfo.d.data_list_name}>
              &nbsp; <span>{report.campaignInfo.d.data_list_name} </span>{" "}
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Data count : </b>
            </td>
            <td className="CampaignEmailsDetalis">
              &nbsp;
              <span>
                {report.activities.tr}{" "}
                {report.activities.tr > 1 ? "records" : "record"}
              </span>{" "}
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Total Sent : </b>
            </td>
            <td className="CampaignEmailsDetalis">
              &nbsp;
              <span>
                {report.activities.sc}{" "}
                {report.activities.sc > 1 ? "records" : "record"}{" "}
              </span>
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Suppressed : </b>
            </td>
            <td className="CampaignEmailsDetalis">
              &nbsp;
              <span>
                {report.activities.su_c}{" "}
                {report.activities.su_c > 1 ? "records" : "record"}{" "}
              </span>
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Created by : </b>
            </td>
            <td className="CampaignEmailsDetalis">
              &nbsp;
              <span>{report.campaignInfo.c.created_by} </span>
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Modified by : </b>
            </td>
            <td className="CampaignEmailsDetalis">
              &nbsp;
              <span>{report.campaignInfo.c.last_modified_by} </span>
            </td>
          </tr>
          <tr>
            <td className="CampaignEmailsDetalis">
              <b>Sent at : </b>
            </td>
            <td className="CampaignEmailsDetalis" title={convertToUserTimeZone(report.campaignInfo.c.sendTime)}>
              &nbsp;
              <span>
                {convertToUserTimeZone(report.campaignInfo.c.sendTime)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
