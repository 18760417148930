import React, { Component } from "react";
import { connect } from "react-redux";
import SMS from "../../CreateCampaign/assets/icons/sms.png";
// import DigitalWhiteImg from "../../CreateCampaign/assets/icons/digital_white.png";

import SocialImg from "../../CreateCampaign/assets/icons/social.png";
// import SocialWhiteImg from "../../CreateCampaign/assets/icons/social_white.png";

import EmailImg from "../../CreateCampaign/assets/icons/email.png";
// import EmailWhiteImg from "../../CreateCampaign/assets/icons/email_white.png";
import { Link, Outlet } from "react-router-dom";

class CampaignReporting extends Component {
  render() {
    let {
      userInfo: { user },
    } = this.props;

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8%",
            marginBottom: "5%",
          }}
        >
          <h3 className="hero-head">Reports</h3>
        </div>
        <div style={{ textAlign: "center" }} className="container-parent">
          <div className="create-new-project container-flex">
            <div className="new-campaign-detail ">
              <Link to="email" className="rs-dropdown-item-content">
                <div className="center-div-img-text">
                  <picture>
                    <img src={EmailImg} alt="New Email Campaign" />
                  </picture>

                  <p className="create-new-project-type">
                    <b>Email</b>
                  </p>
                </div>
              </Link>
              <p>
                Email campaign reports can provide a quick look at campaign
                performance and help understand engagement trends.
              </p>
            </div>

            <div className="new-campaign-detail ">
              <Link to="#" className="rs-dropdown-item-content" style={{cursor:"auto"}}>
                <div className="center-div-img-text">
                  <picture>
                    <img src={SocialImg} alt="New Email" />
                  </picture>

                  <p className="create-new-project-type">
                    <b>Social</b>
                  </p>
                </div>
              </Link>
              <p>
                
                <b>Coming Soon</b>
              </p>
            </div>
            <div className="new-campaign-detail ">
              <Link to="#" className="rs-dropdown-item-content" style={{cursor:"auto"}} >
                <div className="center-div-img-text">
                  <picture>
                    <img src={SMS} alt="New Email" />
                  </picture>

                  <p className="create-new-project-type">
                    <b>SMS</b>
                  </p>
                </div>
              </Link>
              <p><b>Coming Soon</b></p>
            </div>
          </div>

          <Outlet />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CampaignReporting);
