import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
 
// export const getEmailData = () => JSON.parse(localStorage.getItem("eD"));
 
export const isCampaignNameExist = createAsyncThunk(
  "campaign/isExist",
  async ({ campaignname, id }) => {
    try {
      const response = await axios.get(
        `/campaign/checkCampaignName?name=${campaignname}&id=${id}`
      );
      // console.log(response);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const saveCampaign = createAsyncThunk(
  "campaign/saveCampaign",
  async (campaignDetails) => {
    try {
      // console.log("thunk ", campaignDetails);
      const response = await axios.post(`/campaign/saveCampaign`, {
        campaignDetails,
      });
      // console.log(response);
 
      return response.data;
    } catch (e) {
      console.log(e);
      return e.response.data;
    }
  }
);
 
// TODO
export const checkDataIdInDatabase = createAsyncThunk(
  "campaign/checkDataId",
  async (dataId) => {
    try {
      const response = await axios.get(
        `/campaign/checkDataId?dataId=${dataId}`
      );
      // console.log(response);
      return { dataId, exists: response.data.exists };
    } catch (e) {
      return { dataId, exists: false };
    }
  }
);
 
export const checkEmailIdInDatabase = createAsyncThunk(
  "campaign/checkEmailId",
  async (emailId) => {
    try {
      const response = await axios.get(
        `/campaign/checkEmailId?emailId=${emailId}`
      );
      // console.log(response);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);