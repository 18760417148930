import React, { Component } from "react";
import { Content, Container, Sidebar, Panel, Row, Col, Button, Modal } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { userInfo, userDelete } from "../Features/AccountManagement/UserManagementThunk";
import { connect } from "react-redux";
import { withRouter } from "./withRouter";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { Alert } from "../Utils/Alert";
import { logout } from "../Features/Login/LoginThunk";
class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      loading: true,
      keyId: "",
      open: false
    };
    this.alertRef = React.createRef();
  }
  async componentDidMount() {
    await this.props.userInfo().then((data) => {
      this.setState({
        userList: data.payload.message.user,
        loading: false
      });
    });
  }
  hide = () => {
    this.setState({
      open: false
    });
  };
  confirmtdeleteUser = async (event) => {
    let deleteUser = await this.props.userDelete(this.state.keyId);
    let { payload } = deleteUser;

    // Check if the user is deleting their own account
    if (this.state.keyId === this.props.userInfoDetails.user.user_id) {
      // Log out the user for self deleting the account
      if (payload.status === 1) {
        this.alertRef.current.showToaster(payload);
        this.props.logout({ id: this.state.keyId });
      }
    } else {
      if (payload.status === 1) {
        this.alertRef.current.showToaster(payload);
        // Update user info after successful deletion
        this.props.userInfo().then((data) => {
          this.setState({
            userList: data.payload.message.user,
            loading: false,
          });
        });
      }
    }

    this.setState({ open: false });
  };

  // openEdit = (event) => {
  //   console.log("test", event.target.getAttribute("data-id"));
  //   // this.props.forEditNewUser(event.target.getAttribute("data-id"));
  //   // this.props.navi
  // };
  render() {
    return (
      <>
        {this.state.loading ? (
          <>Loading...</>
        ) : (
          <>
            <Content>
              <Alert ref={this.alertRef} />
              <Modal backdrop="static" open={this.state.open} onClose={this.hide} size="xs">
                <Modal.Header>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: "#000" }}>
                  <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> Are you sure you want to
                  Remove this User?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    appearance="subtle"
                    title="Confirm"
                    onClick={this.confirmtdeleteUser}
                    style={{ color: "#000" }}
                  >
                    <b>Confirm</b>
                  </Button>

                  <Button
                    onClick={this.hide}
                    appearance="subtle"
                    title="Cancel"
                    style={{ color: "#000" }}
                  >
                    <b>Cancel</b>
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* <Col md={2} sm={24}></Col> */}
              {/* <hr style={{ border: "none" }} /> */}
              <Row className="RecentTab">
                {/* <Col md={4}>
        <ButtonToolbar style={{ textAlign: 'right' }}>
          <Link to="/datasetaudience/create">
            <Button className="Createdatasetbtn" title="Create New Data Set">Create New Role</Button></Link>
        </ButtonToolbar>
      </Col> */}
                {/* <Col md={2} sm={24}></Col> */}
                <Col md={20} sm={24}>
                  <div>
                    <div className="table-wrapper">
                      <div className="table-title">
                        <div className="row">
                          {/* <div className="col-sm-8"><h2>  <b>User List Details</b></h2></div> */}
                          {/* <div className="col-sm-4">
                  <button type="button" className="btn btn-info add-new"><i className="fa fa-plus"></i> Add New</button>
                </div> */}
                        </div>
                      </div>
                      <table className="table table-userListRoleIdbordered">
                        <thead>
                          <tr className="border-table-management">
                            <th className="border-table-management">#</th>
                            <th className="border-table-management">Email</th>
                            {/* <th>Password</th> */}
                            <th className="border-table-management">Role</th>
                            <th className="border-table-management">Access</th>
                            <th className="border-table-management">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userList && this.state.userList.length > 0 ? (
                            <>
                              {this.state.userList.map((val, index) => {
                                const accessData = JSON.parse(val.access);
                                const finalData = JSON.parse(accessData);
                                return (
                                  <tr key={index} className="border-table-management">
                                    <td className="border-table-management">
                                      {JSON.stringify(index + 1)}
                                    </td>
                                    <td className="border-table-management">{val.user_email}</td>
                                    {/* <td>{JSON.stringify(val.password)}</td> */}
                                    <td className="border-table-management">{val.role}</td>

                                    <td className="border-table-management">
                                      {/* {this.state.userList.length > 0 &&
                                        this.state.userList.map(
                                          (data, index) => {
                                            let accessData = JSON.parse(
                                              data.access
                                            );
                                            let finalData =
                                              JSON.parse(accessData);
                                            return (
                                              <span key={index}>
                                                {console.log(finalData)}
                                              </span>
                                            );
                                          }
                                        )} */}
                                        {finalData.map((access, index) => {
                                          return (
                                            <span key={index}>
                                              {" "}
                                              {access}
                                              {/* Check if the current index is not the last index of the array */}
                                              {index !== finalData.length - 1 && <>,</>}
                                            </span>
                                          );
                                        })}
                                    </td>
                                    <td className="border-table-management">
                                      {/* &nbsp;&nbsp;&nbsp; */}
                                      <span>
                                        <TrashIcon
                                          title="delete"
                                          data-id={val.user_id}
                                          icon="trash2"
                                          onClick={(e) => {
                                            this.setState({
                                              open: true,
                                              keyId: val.user_id
                                            });
                                          }}
                                          style={{
                                            marginTop: 12,
                                            cursor: "pointer"
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <EditIcon
                                          data-id={val.user_id}
                                          onClick={() => this.props.forEditNewUser(val.user_id)}
                                          icon="edit"
                                          title="Edit"
                                          style={{
                                            marginTop: 12,
                                            cursor: "pointer"
                                          }}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <div>
                              <h3>No Data List found.</h3>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>

                {/* <Col md={2} sm={24}></Col> */}
              </Row>
              <Col md={2} sm={24}></Col>
            </Content>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfoDetails: state.LoginSlice.userInfo,
  loading: state.LoginSlice.loading,
  isLoggedIn: state.LoginSlice.isLoggedIn,
});
const mapDispatchToProps = (dispatch) => ({
  userInfo: () => dispatch(userInfo()),
  userDelete: (userId) => dispatch(userDelete(userId)),
  logout: (userId) => dispatch(logout(userId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserInfo));
