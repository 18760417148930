const convertToUserTimeZone = (gmtTime) => {
  const gmtDate = new Date(gmtTime);
  const userOffset = gmtDate.getTimezoneOffset();
  const userDate = new Date(gmtDate.getTime() - userOffset * 60000);

  // Format the user date to include timezone information
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = `${userDate.getFullYear()}-${(userDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${userDate
    .getDate()
    .toString()
    .padStart(2, "0")} ${userDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${userDate
    .getMinutes()
    .toString()
    .padStart(2, "0")} ${timezone}`;

  return formattedDate;
};

export default convertToUserTimeZone;
