import { useState, useCallback } from "react";

/* NOTE Custom Hook for ToggleFullscreen */

const useFullscreenToggle = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenToggle = useCallback(() => {
    const element = document.documentElement;

    switch (true) {
      case !isFullscreen:
        // Request fullscreen
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        break;

      default:
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
    }

    // Update the state to reflect the current fullscreen status
    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  return { isFullscreen, handleFullscreenToggle };
};

export default useFullscreenToggle;
