import { createSlice } from "@reduxjs/toolkit";
import { login } from "./LoginThunk";
import tokenService from "../../Utils/Interceptor/TokenService";

const LoginSlice = createSlice({
  name: "auth",
  initialState: {
    loading: true,
    isLoggedIn: false,
    error: null,
    userInfo: null,
    success: false,
    alertRef: null,
    theme: localStorage.getItem("theme")
  },
  reducers: {
    themeChange(state, data) {
      state.theme = data.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("status", JSON.stringify(action.payload.status));
          tokenService.setUser(action.payload.data);
          state.isLoggedIn = true;
          state.userInfo = action.payload.data;
          state.success = true;
          state.loading = false;
          state.error = null;
        } else {
          tokenService.removeUser();
          state.isLoggedIn = false;
          state.userInfo = null;
          state.success = true;
          state.loading = false;
          state.error = null;
        }
      })

      .addCase(
        login.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          // console.log(action);
          state.loading = false;
          state.error = action.error;
        }
      );
    // and provide a default case if no other handlers matched
    // .addDefaultCase((state, action) => {
    //   state.loading = true;
    //   state.isLoggedIn = false;
    //   state.userInfo = null;
    //   state.success = false;
    //   state.error = null;
    // });
  }
});
const { actions, reducer } = LoginSlice;
export const { themeChange } = actions;

export default reducer;
