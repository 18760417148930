import React, { useState, useEffect } from "react";
import { ThemeContext, themes, ThemeService } from "./ThemeContext";

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") === "dark" ? themes.dark : themes.light
  );
  function changeTheme(theme) {
    setTheme(theme);
  }

  useEffect(() => {
    switch (theme) {
      case themes.light:
        localStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
        document.body.classList.remove("dark");
        document.body.classList.add("light");
        ThemeService();

        break;
      case themes.dark:
        localStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        ThemeService();

        break;
      default:
        localStorage.setItem("theme", themes.light);
        document.documentElement.setAttribute("data-theme", "light");
        document.body.classList.remove("content");
        ThemeService();

        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
