export const data = [
  {
    label: "Alabama",
    value: "Alabama",
    role: "Alabama"
  },
  {
    label: "Alaska",
    value: "Alaska",
    role: "Alaska"
  },
  {
    label: "Arizona",
    value: "Arizona",
    role: "Arizona"
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    role: "Arkansas"
  },
  {
    label: "California",
    value: "California",
    role: "California"
  },
  {
    label: "Colorado",
    value: "Colorado",
    role: "Colorado"
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    role: "Connecticut"
  },
  {
    label: "Delaware",
    value: "Delaware",
    role: "Delaware"
  },
  {
    label: "Florida",
    value: "Florida",
    role: "Florida"
  },
  {
    label: "Georgia",
    value: "Georgia",
    role: "Georgia"
  },
  {
    label: "Idaho",
    value: "Idaho",
    role: "Idaho"
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    role: "Hawaii"
  },
  {
    label: "Illinois",
    value: "Illinois",
    role: "Illinois"
  },
  {
    label: "Indiana",
    value: "Indiana",
    role: "Indiana"
  },
  {
    label: "Iowa",
    value: "Iowa",
    role: "Iowa"
  },
  {
    label: "Kansas",
    value: "Kansas",
    role: "Kansas"
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    role: "Kentucky"
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    role: "Louisiana"
  },
  {
    label: "Maine",
    value: "Maine",
    role: "Maine"
  },
  {
    label: "Maryland",
    value: "Maryland",
    role: "Maryland"
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    role: "Massachusetts"
  },
  {
    label: "Michigan",
    value: "Michigan",
    role: "Michigan"
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    role: "Minnesota"
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    role: "Mississippi"
  },
  {
    label: "Missouri",
    value: "Missouri",
    role: "Missouri"
  },
  {
    label: "Montana",
    value: "Montana",
    role: "Montana"
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    role: "Nebraska"
  },
  {
    label: "Nevada",
    value: "Nevada",
    role: "Nevada"
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    role: "New Hampshire"
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    role: "New Jersey"
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    role: "New Mexico"
  },
  {
    label: "New York",
    value: "New York",
    role: "New York"
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    role: "North Carolina"
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    role: "North Dakota"
  },
  {
    label: "Ohio",
    value: "Ohio",
    role: "Ohio"
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    role: "Oklahoma"
  },
  {
    label: "Oregon",
    value: "Oregon",
    role: "Oregon"
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    role: "Pennsylvania"
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    role: "Rhode Island"
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    role: "South Carolina"
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    role: "South Dakota"
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    role: "Tennessee"
  },
  {
    label: "Texas",
    value: "Texas",
    role: "Texas"
  },
  {
    label: "Utah",
    value: "Utah",
    role: "Utah"
  },
  {
    label: "Vermont",
    value: "Vermont",
    role: "Vermont"
  },
  {
    label: "Virginia",
    value: "Virginia",
    role: "Virginia"
  },
  {
    label: "Washington",
    value: "Washington",
    role: "Washington"
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    role: "West Virginia"
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    role: "Wisconsin"
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    role: "Wyoming"
  },
  {
    label: "DC",
    value: "DC",
    role: "DC"
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    role: "Puerto Rico"
  }
];
