import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Content, Nav, Row, IconButton } from "rsuite";
import PlayIcon from "@rsuite/icons/legacy/Play";
import "./css/CampaignCanvas.css";
import EmailIcon from "@rsuite/icons/Email";
import SendIcon from "@rsuite/icons/Send";
import DefineCampaignProperties from "./DefineCampaignProperties";
import {
  campaignById,
  deleteCampaign,
} from "../../../Features/Campaign/CampaignThunk";
import Loading from "../../../Loading/Loading";
import SendCampaign from "./SendCampaign";
import { Navigate, Link } from "react-router-dom";

import DeleteConfirmationModal from "../../../Utils/DeleteConfirmationModal";
import Back from "../../../CreateNewProject/Email/assets/icons/Back.png";

const CustomNav = ({ active, onSelect, disableSendButton, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ padding: 10 }}
      className="nav-item-edit"
    >
      <span href="#" className="nav-item-link">
        <Nav.Item
          as="li"
          eventKey="defineproperties"
          title="DEFINE PROPERTIES"
          icon={<EmailIcon />}
        >
          <b> DEFINE PROPERTIES</b>
        </Nav.Item>
      </span>
      &nbsp; &nbsp; &nbsp;
      {!disableSendButton && (
        <span href="#" className="nav-item-link">
          <Nav.Item as="li" eventKey="send" title="SEND" icon={<SendIcon />}>
            <b> SEND / SCHEDULE</b>
          </Nav.Item>
        </span>
      )}
    </Nav>
  );
};

class CampaignCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "defineproperties",
      loading: true,
      isDeleted: false,
      show: false,
      campaignId: "",
      confirmation: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleSelect(activeKey) {
    this.setState({ active: activeKey });
    this.setState({
      active: activeKey,
    });
  }

  async componentDidMount() {
    try {
      const campaignId = this.props.dynamicRoute[0].params.id;
      this.setState({
        campaignId,
      });
      const { campaignById } = this.props;
      await campaignById(campaignId);
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  async handleDelete() {
    try {
      let { deleteCampaign, dynamicRoute, alertRef } = this.props;

      this.setState({
        loading: true,
      });
      let response = await deleteCampaign({ id: dynamicRoute[0].params.id });
      this.setState({
        loading: false,
        isDeleted: true,
        confirmation: false,
      });
      alertRef.current.showToaster(response.payload);
    } catch (e) {
      this.setState({
        loading: false,
        isDeleted: true,
        confirmation: false,
      });
    }
  }

  render() {
    let { confirmation, loading, isDeleted } = this.state;
    const { emailByID, dataListById, count } = this.props;

    if (loading) return <Loading />;
    if (isDeleted) return <Navigate to="/assetlibrary/campaign" replace />;

    const shouldDisableSendButton =
      !emailByID || !dataListById || !dataListById.data_list_name || !count;

    return (
      <div className="content-container">
        <Row className="border-bottom">
          <Col md={3}>
            <div className="next-back-button-horizontal">
              <div
                className="next-back-button"
                style={{ justifyContent: "flex-start", paddingTop: 10 }}
              >
                <Link to="/assetlibrary/campaign" className="link-color-font">
                  <span style={{ cursor: "pointer" }} title="Back">
                    <IconButton placement="left">
                      <PlayIcon style={{ transform: "rotate(180deg)" }} />
                      Back
                    </IconButton>
                  </span>
                </Link>
              </div>
            </div>
          </Col>

          <Col md={14}>
            <CustomNav
              appereance="default"
              active={this.state.active}
              onSelect={this.handleSelect}
              disableSendButton={shouldDisableSendButton}
            />
          </Col>
          <Col md={7} style={{ textAlign: "right" }}>
            {/* <Button
              appearance="primary"
              title="Delete"
              className="ButtonColor"
              onClick={() =>
                this.setState({
                  confirmation: true,
                })
              }
            >
              <b>Delete</b>
            </Button> */}
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col md={24} style={{ marginBottom: 70 }}>
            {this.state.active === "defineproperties" &&
              this.props.loadingById === false && (
                <>
                  <DefineCampaignProperties
                    campaignId={this.state.campaignId}
                    alertRef={this.props.alertRef}
                    dynamicRoute={this.props.dynamicRoute[0].params.id}
                  />
                </>
              )}
            {this.state.active === "send" && !shouldDisableSendButton && (
              <>
                <SendCampaign
                  alertRef={this.props.alertRef}
                  dynamicRoute={this.props.dynamicRoute[0].params.id}
                />
              </>
            )}
          </Col>
        </Row>
        {confirmation && (
          <DeleteConfirmationModal
            confirmation={confirmation}
            hide={() =>
              this.setState({
                confirmation: false,
              })
            }
            handleDelete={this.handleDelete}
            component="Campaign"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { campaignByID, emailByID, dataListById, loadingById, count } =
    state.CampaignSlice;
  return {
    campaignByID,
    emailByID,
    dataListById,
    loadingById,
    count,
  };
}

export default connect(mapStateToProps, { campaignById, deleteCampaign })(
  CampaignCanvas
);
