import axios from "axios";
import tokenService from "./TokenService";

// Function to determine if the app is running locally
// function isRunningLocally() {
//   const localHosts = ['localhost', '127.0.0.1', '::1'];
//   return localHosts.includes(window.location.hostname);
// }

// Function to get the environment ('dev', 'prod', or 'localhost')
function getEnvironment() {
  const hostname = window.location.hostname;
  console.log("hostname:", hostname);
  if (hostname.startsWith('development.charlie')) return 'dev';
  if (hostname.startsWith('test.charlie')) return 'test';
  if (hostname.startsWith('charlie')) return 'prod';
  // if (hostname.startsWith('www')) return 'prod';
  return 'localhost'; // Default to localhost if none match
}

// Set the API base URL based on the environment
function getApiBaseUrl(environment) {
  switch (environment) {
    case 'test':
      return "https://test.apicharlie.dealercontentgroup.com";
    case 'dev':
      return "https://dev.apicharlie.dealercontentgroup.com";
    case 'prod':
      return "https://apicharlie.dealercontentgroup.com";
    case 'localhost':
      return `http://localhost:4000`;
    default:
      return `http://localhost:4000`;
  }
}

const environment = getEnvironment();
const base_url = getApiBaseUrl(environment);

console.log("Environment:", environment);
console.log("Base URL:", base_url);

export const api_url = `${base_url}/api/v1`;
console.log("API URL:", api_url);

const instance = axios.create({
  baseURL: api_url,
  timeout: 40000,
});

instance.interceptors.request.use(
  (config) => {
    const token = tokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = "application/json";
      if (config.url !== "/images/") {
        config.headers["Content-Type"] = "application/json";
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalReq = err.config;
    if (err.response) {
      
      // Access Token was expired
      if (err.response.status === 401 && !originalReq._retry) {
        originalReq._retry = true;
        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: tokenService.getLocalRefreshToken(),
          });
          const { token } = rs.data.data;
          tokenService.updateLocalAccessToken(token);
          instance.defaults.headers.common["Authorization"] = "Bearer " + token;
          return instance(originalReq);
        } catch (_error) {
          console.log("Refresh token failed:", _error);
          tokenService.removeUser(); // logging out the user if refresh fails
          window.location.href = "/login";
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403) {
        try {
          let data = tokenService.getUser();
          // console.log("Axios Interceptor: data:", data);
          await instance.post("/auth/logout", { id: data.user.user_id });
          tokenService.removeUser();
          window.location.href = "/login";
        } catch (e) {
          console.log("Logout failed:", e);
          return Promise.reject(err.response.data);
        }
      }
    }
    return Promise.reject(err);
  }
);

//-------------------------------------------------------------------------------------------------------
export default instance;
