import { createSlice } from "@reduxjs/toolkit";
import {
  getDataState,
  getDataLists,
  isDataExist,
  getDataById,
} from "./DataThunk";
import { setCreateNewProjectData } from "../CreateNewProjectService";
import { dataArchive, field } from "../const";

let dLD = getDataState() || dataArchive;
let initialState = {
  dataName: dLD.dataName,
  description: dLD.description,
  location: dLD.location,
  datafield: dLD.datafield,
  errorFieldName: dLD.errorFieldName,
  emailfield: dLD.emailfield,
  primaryIndex: dLD.primaryIndex,
  dataList: {
    data: [],
    count: null,
    pages: null,
    page: null,
  },
  dataById: {},
  structureById: [
    {
      ordinalPos: null,
      FieldName: "",
      DataType: "",
      Length: "",
      Required: "",
      Primary: "",
      edit: false,
    },
  ],
  loading: false,
  error: null,
  success: true,
};

const DataSlice = createSlice({
  name: "data-archive",
  initialState,
  reducers: {
    resetData() {
      setCreateNewProjectData();

      const dLD = getDataState();
      return {
        dataName: dLD.dataName,
        description: dLD.description,
        location: dLD.location,
        datafield: dLD.datafield,
        errorFieldName: dLD.errorFieldName,
        emailfield: dLD.emailfield,
        primaryIndex: dLD.primaryIndex,
        structureById: [
          {
            ordinalPos: null,
            FieldName: "",
            DataType: "",
            Length: "",
            Required: "",
            Primary: "",
            edit: false,
          },
        ],
        dataList: {
          data: [],
          count: null,
          pages: null,
          page: null,
        },
        dataById: {},
        loading: false,
        error: null,
        success: true,
      };
    },
    feedDataField(state, data) {
      let dLD = getDataState();
      dLD.dataName = data.payload;
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.dataName = data.payload;
    },

    selectLocation(state, data) {
      let dLD = getDataState();
      dLD["location"] = data.payload;
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.location = data.payload;
    },
    feedDescription(state, data) {
      let dLD = getDataState();
      dLD.description = data.payload;
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.description = data.payload;
    },

    addField(state, data) {
      let dLD = getDataState();
      dLD.datafield.push(field);
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.datafield = dLD.datafield;
    },
    // REMOVING FIELD
    removeField(state, data) {
      let dLD = getDataState();
      if (dLD.datafield[data.payload].Primary === "PRIMARY KEY") {
        dLD.primaryIndex = null;
        state.primaryIndex = dLD.primaryIndex;
      }
      if (
        Number(data.payload) === Number(dLD.emailfield.index) &&
        dLD.emailfield.index !== null
      ) {
        dLD.emailfield = {
          value: "",
          index: null,
        };
        state.emailfield = dLD.emailfield;
      }
      dLD.datafield.splice(data.payload, 1);

      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.datafield = dLD.datafield;
    },
    // HANDLING VALUE CHANGE
    handleFieldValue(state, data) {
      let dLD = getDataState();
      if (data.payload.class === "DataType" && data.payload.value === "text") {
        dLD.primaryIndex = null;
        dLD.datafield[data.payload.id]["Primary"] = "";
        state.primaryIndex = dLD.primaryIndex;
      }

      if (
        data.payload.class === "FieldName" &&
        Number(data.payload.id) === Number(dLD.emailfield.index) &&
        dLD.emailfield.index !== null
      ) {
        dLD.emailfield = {
          index: data.payload.id,
          value: data.payload.value,
        };
        state.emailfield = dLD.emailfield;
      }
      dLD.datafield[data.payload.id][data.payload.class] = data.payload.value;

      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.datafield = dLD.datafield;
    },
    // SETTING PRIMARY KEY
    setPrimary(state, data) {
      let dLD = getDataState();
      dLD.primaryIndex =
        dLD.primaryIndex === data.payload ? null : data.payload;
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.primaryIndex = dLD.primaryIndex;
    },
    setEmailField(state, data) {
      let dLD = getDataState();
      dLD.emailfield = data.payload;

      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.emailfield = dLD.emailfield;
    },

    setErrorFields(state, data) {
      let dLD = getDataState();
      dLD.errorFieldName = data.payload;
      localStorage.setItem("dLD", JSON.stringify(dLD));
      state.errorFieldName = dLD.errorFieldName;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getDataLists.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDataLists.fulfilled, (state, action) => {
        console.log(action.payload);
        state.dataList = action.payload.data;
        state.loading = false;
        state.error = null;
        state.success = true;
      })

      .addCase(getDataLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.success = false;
        state.dataList = {
          data: [],
          count: null,
          pages: null,
          page: null,
        };
      })

      //   IS DATA_EXIST
      .addCase(isDataExist.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isDataExist.fulfilled, (state, action) => {
        state.dataName.isExistError = action.payload.data.error;
        state.dataName.errMessageName = action.payload.data.message;
        state.loading = false;
        state.error = null;
        if (action.payload.data.error) {
          let dLD = getDataState();
          dLD.dataName["isExistError"] = true;
          dLD.dataName["errMessageName"] = action.payload.data.message;
          localStorage.setItem("dLD", JSON.stringify(dLD));
        }
      })

      .addCase(isDataExist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // DATA-SET VIA ID
      .addCase(getDataById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDataById.fulfilled, (state, action) => {
        state.dataById = action.payload.data.dataSet.dataList;
        state.structureById = action.payload.data.dataSet.structure;
        state.success = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(
        getDataById.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          state.loading = false;
          state.error = action.error;
          state.dataById = {};
          state.structureById = [
            {
              ordinalPos: null,
              FieldName: "",
              DataType: "",
              Length: "",
              Required: "",
              Primary: "",
              edit: false,
            },
          ];
          state.success = false;
        }
      );
  },
});
const { actions, reducer } = DataSlice;
export const {
  // DATASET FIELD
  resetData,
  feedDataField,
  selectLocation,
  feedDescription,
  // DATASET STRUCTURE
  addField,
  removeField,
  handleFieldValue,
  setPrimary,
  setEmailField,
  setErrorFields,
} = actions;

export default reducer;
