import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "./withRouter";
import {
  Form,
  Button,
  Schema,
  Col,
  SelectPicker,
  Message,
  Row,
  Toggle,
} from "rsuite";
import { connect } from "react-redux";
import {
  editemailusercheck,
  createUser,
} from "../Features/AccountManagement/UserManagementThunk";
import { Alert } from "../Utils/Alert";
const { StringType } = Schema.Types;
 
const model = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("Email address is required."),
  firstname: StringType().isRequired("First name is required."),
  lastname: StringType().isRequired("Last name is required."),
  role: StringType().isRequired("Role is required."),
});
 
const accessOptions = [
  "template",
  "email",
  // "image",
  "data",
  "campaign",
  // "digitalmedia",
  "social",
  "userList",
  "createUser",
  "updateuser",
];
 
const accessOptionsArr = [
  { label: "Template", value: "template", adminOnly: false },
  { label: "Email", value: "email", adminOnly: false },
  // { label: "Image", value: "image", adminOnly: false },
  { label: "Data", value: "data", adminOnly: false },
  { label: "Campaign", value: "campaign", adminOnly: false },
  // { label: "Digital Media", value: "digitalmedia", adminOnly: false },
  { label: "Report", value: "social", adminOnly: false },
  { label: "User List", value: "userList", adminOnly: true },
  { label: "Create User", value: "createUser", adminOnly: true },
  { label: "Update User", value: "updateuser", adminOnly: true },
];
 
function CreateUser(props) {
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [access, setAccess] = useState([]);
  const formRef = useRef();
  const alertRef = useRef(null);
  const [atLeastOneAccessProvided, setAtLeastOneAccessProvided] =
    useState(true);
  useEffect(() => {
    if (formValue.role === "admin") {
      setAccess([
        "template",
        "email",
        // "image",
        "data",
        "campaign",
        // "digitalmedia",
        "social",
        "userList",
        "createUser",
        "updateuser",
      ]);
    } else {
      setAccess([]);
    }
  }, [formValue.role]);
 
  const handleSubmit = async () => {
    if (formRef.current.check()) {
      if (formValue.role === "user" && access.length === 0) {
        setAtLeastOneAccessProvided(false); // Setting state if no access provided for user
        alertRef.current.showToaster({
          type: "error",
          duration: 3,
          message: "Provide Atleast One User Access",
        });
        return;
      }
      setLoadingSubmit(true);
      const user = await props.createUser([formValue, access]);
      const { payload } = user;
      if (payload.status === 1) {
        setLoadingSubmit(false);
        alertRef.current.showToaster(payload);
        props.activeChange();
      }
    }
  };
 
  const handleChange = (field, value) => {
    setFormValue({ ...formValue, [field]: value });
    if (field === "email") {
      setErrors({ ...errors, email: undefined });
      // document.getElementById("checkerror").style.display = "none";
    }
  };
 
  const handleAccessChange = (option, checked) => {
    if (
      formValue.role === "user" &&
      ["userList", "createUser", "updateuser"].includes(option)
    ) {
      return;
    }
    if (checked) {
      setAccess([...access, option]);
    } else {
      setAccess(access.filter((item) => item !== option));
    }
    setErrors({ ...errors, access: undefined });
  };
 
  const checkErrorEmail = async () => {
    if (formValue.email) {
      const check = await props.editemailusercheck(formValue.email);
      const { payload } = check;
      // console.log(payload);
      if (payload.status === 406) {
        setErrors({ ...errors, email: true });
      } else {
        setErrors({ ...errors, email: false });
      }
    }
  };
  const Roledata = ["Admin", "User"].map((item) => ({
    label: item,
    value: item.toLowerCase(),
  }));
  return (
    <>
      <Col md={2}></Col>
 
      <Col md={18}>
        <Alert ref={alertRef} />
        <Message type="info" bordered showIcon closable>
          <strong>Info!</strong> Admin Access are Predefined and Locked
        </Message>
        <Form
          model={model}
          formValue={formValue}
          onChange={setFormValue}
          ref={formRef}
          fluid
          style={{ marginTop: 5 }}
        >
          <Row style={{ marginBottom: 5 }}>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>Email Address</Form.ControlLabel>
                <Form.Control
                  name="email"
                  onBlur={checkErrorEmail}
                  autoComplete="off"
                  errorMessage={errors.email && "Email already exists"}
                  errorPlacement="bottomEnd"
                />
                <Form.HelpText>
                  Onboarding email will be sent to the email address provided.
                </Form.HelpText>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>First Name</Form.ControlLabel>
                <Form.Control
                  name="firstname"
                  type="text"
                  autoComplete="off"
                  errorPlacement="bottomEnd"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>Last Name</Form.ControlLabel>
                <Form.Control
                  name="lastname"
                  type="text"
                  autoComplete="off"
                  errorPlacement="bottomEnd"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.ControlLabel>Define Role</Form.ControlLabel>
            <Form.Control
              name="role"
              accepter={SelectPicker}
              searchable={false}
              label="Role"
              onChange={(value) => handleChange("role", value)}
              onBlur={() => setErrors({ ...errors, role: undefined })}
              errorMessage={errors.role && "Please select a role"}
              data={Roledata}
              style={{ width: 220 }}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel
              style={{ textAlign: "center", fontWeight: 600, marginBottom: 10 }}
            >
              Access
            </Form.ControlLabel>
            <Row>
              {accessOptionsArr.map((option) => (
                <Col
                  md={12}
                  style={{
                    // border: "2px solid red",
                    display: "flex",
                    direction: "row",
                    minHeight: "40px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={option}
                >
                  <span>
                    {option.label}
                    {option.adminOnly && (
                      <Form.HelpText tooltip>Admin Only</Form.HelpText>
                    )}
                  </span>
                  <Toggle
                    checked={access.includes(option.value)}
                    onChange={(checked) =>
                      handleAccessChange(option.value, checked)
                    }
                    disabled={formValue.role === "admin"}
                  />
                </Col>
              ))}
            </Row>
            <Form.ErrorMessage>{errors.access}</Form.ErrorMessage>
          </Form.Group>
          <Button
            appearance="primary"
            style={{ backgroundColor: "#415670", color: "#fff" }}
            size={20}
            onClick={handleSubmit}
            loading={loadingSubmit}
            disabled={errors.email}
          >
            Create User
          </Button>
        </Form>
      </Col>
      <Col md={2}></Col>
    </>
  );
}
 
function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}
 
export default connect(mapStateToProps, { editemailusercheck, createUser })(
  withRouter(CreateUser)
);