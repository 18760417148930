import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Input,
  TagGroup,
  Tag,
  Form,
  InputGroup,
  Loader,
  IconButton,
} from "rsuite";
import "../Email/assets/css/newemail.css";
// import Back from "../Email/assets/icons/Back.png";
// import Next from "../Email/assets/icons/Next.png";
import PlayIcon from "@rsuite/icons/legacy/Play";
import TemplateFolder from "../../Folder/TemplateFolder";

import {
  reset,
  feedTemplate,
  selectLocation,
  addCategory,
  feedDescription,
} from "../../Features/Template/TemplateSlice";

import { isTemplateExist } from "../../Features/Template/TemplateThunk";
// import { Alert } from "../../Utils/Alert";

import { Link } from "react-router-dom";

import ConfirmationModal from "../../Utils/ConfirmationModal";
import ErrorPage from "../../errorPage/ErrorPage";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class CreateTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      confirmation: false,
      hideError: false,
    };
    // this.alertRef = React.createRef();
  }

  selectFolder = (e) => {
    const { selectLocation } = this.props;
    selectLocation({ path: e.nodeData.id, errorInLocation: false });
  };

  checkTemplateName = async () => {
    let { isTemplateExist, data } = this.props;
    let { templateName } = data;

    if (templateName.textName.trim().replace(/\s/g, "").length > 0)
      await isTemplateExist({
        templateName: templateName.textName,
        id: undefined,
      });
    this.setState({
      hideError: false,
    });
  };

  newCategory = (type, index) => {
    let { category } = this.state;
    let { data, addCategory } = this.props;
    let { categories } = data;
    if (type === "delete") {
      const newCategories = categories.categoryBundle.filter(
        (item, i) => i !== index
      );
      addCategory({
        categoryBundle: newCategories,
        errorInCategory: false,
      });
    } else {
      if (category.trim().replace(/\s/g, "").length > 0)
        if (
          !categories.categoryBundle.includes(category.trim().toLowerCase())
        ) {
          const newCategory = category.trim().toLowerCase()
            ? [...categories.categoryBundle, category.trim().toLowerCase()]
            : categories.categoryBundle;

          addCategory({
            categoryBundle: newCategory,
            errorInCategory: false,
          });
          this.setState({ category: "" });
        }
    }
  };

  checkErrorForm = (event) => {
    let { data, addCategory, selectLocation, feedTemplate, feedDescription } =
      this.props;
    let { categories, location, templateName, description } = data;
    if (
      !templateName.textName ||
      templateName.isExistError ||
      categories.categoryBundle.length === 0 ||
      !location.path ||
      !description.textDes
    ) {
      if (!templateName.textName) {
        feedTemplate({
          textName: "",
          errorInName: true,
          isExistError: false,
          errMessageName: "This field is Required",
        });
      }

      if (categories.categoryBundle.length === 0) {
        addCategory({
          categoryBundle: [],
          errorInCategory: true,
        });
      }
      if (!location.path) {
        selectLocation({ path: null, errorInLocation: true });
      }

      if (!description.textDes) {
        feedDescription({
          textDes: "",
          errorInDescription: true,
          errMessageDes: "This field is Required",
        });
      }
      event.preventDefault();
    }
  };

  render() {
    let {
      data,
      feedTemplate,
      feedDescription,
      userInfo: { user },
    } = this.props;
    let { templateName, description, categories, location, loading } = data;
    let { category, confirmation, hideError } = this.state;
    const userAccess = JSON.parse(JSON.parse(user.access));

    if (!userAccess.includes("template")) {
      return <ErrorPage />;
    } else {
      return (
        <>
          <div className="content-container">
            {/* <Alert ref={this.alertRef} /> */}
            {confirmation && (
              <ConfirmationModal
                confirmation={confirmation}
                component="template"
                hide={() =>
                  this.setState({
                    confirmation: false,
                  })
                }
              />
            )}
            <Row>
              <Col md={12}>
                <h2 className="hero-head">Create Template</h2>
              </Col>

              <Col md={12}>
                <div className="next-back-button-horizontal">
                  <div className="next-back-button">
                    <span
                      style={{ cursor: "pointer" }}
                      title="Back"
                      onClick={() =>
                        this.setState({
                          confirmation: true,
                        })
                      }
                    >
                      <IconButton placement="left">
                        <PlayIcon style={{ transform: "rotate(180deg)" }} />
                        Back
                      </IconButton>
                    </span>
                    <Link
                      to="/createnewproject/create/template/editor"
                      className="link-color-font"
                      onClick={(event) => this.checkErrorForm(event)}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={this.nextStep}
                        title="Next"
                      >
                        <IconButton icon={<PlayIcon />} placement="right">
                          Next
                        </IconButton>
                      </span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="create-new-project ">
              <Col md={24}>
                <Row>
                  <div className="creat-email-form">
                    <Form style={{ width: "100%", marginBottom: 50 }} fluid>
                      <Col md={1}></Col>

                      <Col md={10}>
                        <Form.Group>
                          Template Name*
                          <InputGroup inside className="input-group-form">
                            <Form.Control
                              name="templateName"
                              placeholder=""
                              value={templateName.textName}
                              onChange={(value) =>
                                feedTemplate({
                                  textName: value.trimStart(),
                                  errorInName: false,
                                  isExistError: false,
                                  errMessageName: null,
                                })
                              }
                              className="new-email-text"
                              onFocus={() =>
                                this.setState({
                                  hideError: true,
                                })
                              }
                              style={{ border: "none !important" }}
                              onBlur={this.checkTemplateName}
                            />
                            {loading && (
                              <InputGroup.Addon style={{ padding: "8px 12px" }}>
                                <Loader />
                              </InputGroup.Addon>
                            )}
                          </InputGroup>
                          <span
                            className="custom-error-fields"
                            style={{
                              display: `${
                                (templateName.errorInName ||
                                  templateName.isExistError) &&
                                !hideError
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                          >
                            {templateName.errMessageName}
                          </span>
                        </Form.Group>
                        Location*
                        <div
                          className=" location"
                          placeholder="Location *"
                          style={{
                            background: "#fff",
                            padding: 5,
                            overflowY: "auto",
                          }}
                        >
                          <TemplateFolder
                            edit={false}
                            location={location.path}
                            selectFolder={this.selectFolder}
                            // clickTemplate={(click) => (this.clickTemplate = click)}
                          />
                        </div>
                        <span
                          className="custom-error-fields"
                          style={{
                            display: `${
                              location.errorInLocation && !location.path
                                ? "block"
                                : "none"
                            }`,
                          }}
                        >
                          This field is Required
                        </span>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={10}>
                      Add-Categories and Press Enter*
                        <TagGroup style={{ margin: 0 }}>
                          <Input
                            placeholder=""
                            className="new-email-text"
                            name="categories"
                            value={category}
                            onChange={(e) =>
                              this.setState({
                                category: e,
                              })
                            }
                            onBlur={() => this.newCategory("add", undefined)}
                            onPressEnter={() =>
                              this.newCategory("add", undefined)
                            }
                          />
                          {categories.categoryBundle.map((item, index) => (
                            <Tag
                              key={index}
                              closable
                              onClose={(tag) =>
                                this.newCategory("delete", index)
                              }
                            >
                              {item}
                            </Tag>
                          ))}

                          <span
                            className="custom-error-fields"
                            style={{
                              display: `${
                                categories.errorInCategory &&
                                categories.categoryBundle.length === 0
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                          >
                            This field is Required
                          </span>
                        </TagGroup>
                        <Form.Group style={{ marginTop: 22 }}>
                          <Form.ControlLabel>Description*</Form.ControlLabel>{" "}
                          <Form.Control
                            rows={10}
                            accepter={Textarea}
                            value={description.textDes}
                            name="description"
                            onChange={(value) =>
                              feedDescription({
                                textDes: value.trimStart(),
                                errorInDescription: false,
                                errMessageDes: null,
                              })
                            }
                            placeholder=""
                            className=""
                            style={{ borderRadius: 0 }}
                          />
                        </Form.Group>
                        <span
                          className="custom-error-fields"
                          style={{
                            display: `${
                              description.errorInDescription ? "block" : "none"
                            }`,
                          }}
                        >
                          {description.errMessageDes}
                        </span>
                      </Col>
                      <Col md={1}></Col>
                    </Form>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  data: state.TemplateSlice,
  userInfo: state.LoginSlice.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(reset()),
  feedTemplate: (textName, errorInName, isExistError, errMessageName) =>
    dispatch(feedTemplate(textName, errorInName, isExistError, errMessageName)),
  addCategory: ({ categoryBundle, errorInCategory }) => {
    dispatch(addCategory({ categoryBundle, errorInCategory }));
  },
  selectLocation: ({ path, errorInLocation }) => {
    dispatch(selectLocation({ path, errorInLocation }));
  },
  feedDescription: (textDes, errorInDescription, errMessageDes) =>
    dispatch(feedDescription(textDes, errorInDescription, errMessageDes)),
  isTemplateExist: ({ templateName, id }) => {
    dispatch(isTemplateExist({ templateName, id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
