import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  ButtonToolbar,
  Col,
  Row,
  Form,
  SelectPicker,
  Schema,
  Drawer,
} from "rsuite";
import { isCampaignNameExist } from "../../../Features/CampaignManagement/CampaignPropertiesThunk";
import EmailFolder from "../../../Folder/EmailFolder";
import DataFolder from "../../../Folder/DataFolder";
import Loading from "../../../Loading/Loading";
import {
  campaignById,
  editCampaignProperty,
} from "../../../Features/Campaign/CampaignThunk";
import { getDataLists } from "../../../Features/Data/DataThunk";
import { getEmails } from "../../../Features/Asset/AssetThunk";

import SuppressionFolder from "../../../Folder/SuppressionFolder";
import CampaignFolder from "../../../Folder/CampaignFolder";
import LeftSideDetails from "./LeftSideDetails";

class DefineCampaignProperties extends Component {
  constructor(props) {
    super(props);

    //setting the formValue
    var formValue = {
      campaignName: this.props.campaignByID.campaign_name,
      subjectLine: this.props.campaignByID.subject_line,
      campaignStatus: this.props.campaignByID.isSent,
      senderProfile: this.props.campaignByID.sender_profile,
      campaign_category: this.props.campaignByID.campaign_category,
    };
    // console.log(formValue);

    this.state = {
      id: this.props.campaignId,
      formValue: formValue,
      formError: {},
      status: true,
      suppression: this.props.campaignByID.supression,
      suppressionId:
        this.props.campaignByID.supression_list_id &&
        this.props.suppressionList !== null
          ? this.props.campaignByID.supression_list_id
          : null,
      error: "",
      throttle: {
        isThrottle: this.props.campaignByID.isThrottle,
        throttleLimit: this.props.campaignByID.throttleLimit,
        throttleError: false,
      },
      submitError: false,
      // alertShow: false,
      loading: false,
      loadingContent: false,
      emailChanged: false,
      errorType: false,
      dataListChanged: false,
      suppressionListChanged: false,

      selectedDataProperties: {
        selectedDataList:
          this.props.dataListById === null
            ? null
            : this.props.dataListById.data_list_name,
        dataId:
          this.props.dataListById === null
            ? null
            : this.props.dataListById.data_list_id,
        dataCount: this.props.dataListById === null ? null : this.props.count,
      },

      selectedEmailProperties: {
        selected_email_id:
          this.props.emailByID === null ? null : this.props.emailByID.email_id,
        selected_email_subject:
          this.props.emailByID === null
            ? null
            : this.props.emailByID.email_subject_line,
        selected:
          this.props.emailByID === null
            ? null
            : this.props.emailByID.email_name,
        emailHtmlPath:
          this.props.emailByID === null
            ? null
            : this.props.emailByID.email_json_path,
        emailImgPath:
          this.props.emailByID === null
            ? null
            : this.props.emailByID.email_img_path,
      },

      folderId: this.props.campaignByID.campaign_folder_id,

      showDataType: false,

      typeComponent: true,

      showData: false,
      show: false,
      limit: "4",
      NewEmailProperty: {
        selected_email_id: "",
        selected_email_subject: "",
        selected: "",
        emailHtmlPath: "",
        emailImgPath: "",
      },

      NewDataListProperty: {
        selectedDataList: "",
        dataId: "",
        dataCount: "",
        dataEmail: "",
      },

      selectFolderData: this.props.campaignByID.selected_data_folder,
      selectFolderEmail: this.props.campaignByID.selected_email_folder,
      senderProfiles: this.props.senderProfiles
    };

    // console.log(
    //   "this.props.dataListById.data_list_name",
    //   this.props.dataListById
    // );
  }

  // START ----> For checking camapaign Name

  fetchEmails = async ({ type, page, value }) => {
    try {
      const { getEmails } = this.props;
      // this.setState({
      //   loadingContent: true,
      // });
      let { limit } = this.state;

      await getEmails({ type, page, limit, value });
      // this.setState({
      //   loadingContent: false,
      // });
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async ({ type, page, value }) => {
    try {
      const { getDataLists } = this.props;
      // this.setState({
      //   loadingContent: true,
      // });
      let { limit } = this.state;

      await getDataLists({ type, page, limit, value });
      // this.setState({
      //   loadingContent: false,
      // });
    } catch (error) {
      console.error(error);
    }
  };

  checkCampaignname = async () => {
    try {
      let { isCampaignNameExist, dynamicRoute } = this.props;

      let dataCheck = await isCampaignNameExist({
        campaignname: this.state.formValue.campaignName,
        id: dynamicRoute,
      });
      let { data } = dataCheck.payload;
      this.setState({
        error: data.message,
        errorType: data.error,
      });
    } catch (error) {
      console.error(error);
    }
  };

  //Function to change the state for Button Cancel
  cancelEdit = async () => {
    let { campaignById } = this.props;
    const response = await campaignById(this.state.id);
    var { campaign } = response.payload.data;
    var formValueEdit = {
      campaignName: campaign.campaign_name,
      subjectLine:campaign.subject_line,
      senderProfile: campaign.sender_profile,
      campaign_category: campaign.campaign_category,
      campaignStatus: this.props.campaignByID.isSent,
    };
    this.setState(
      {
        alertShow: false,
        status: true,
        formValue: formValueEdit,
        throttle: {
          isThrottle: campaign.isThrottle,
          throttleLimit: campaign.throttleLimit,
          throttleError: false,
        },
        suppression: campaign.supression,
        suppressionId:
          campaign.supression_list_id && campaign !== null
            ? campaign.supression_list_id
            : null,
        loading: false,
      },
      () => {
        this.form.cleanErrors();
      }
    );
  };
  //Function to change the state for Button Cancel

  selectDataList = (e) => {
    let { showDataType } = this.state;
    this.setState({
      dataListChanged: showDataType ? true : false,
      suppressionListChanged: !showDataType ? true : false,
      NewDataListProperty: {
        selectedDataList: e.target.getAttribute("data-name"),
        dataId: e.target.getAttribute("data-id"),
        dataCount: e.target.getAttribute("data-count"),
        dataEmail: e.target.getAttribute("data-email"),
      },
    });
  };

  selectEmail = (e) => {
    this.setState({
      emailChanged: true,
      NewEmailProperty: {
        selected_email_subject: e.target.getAttribute("data-subject"),
        selected: e.target.getAttribute("data-name"),
        emailHtmlPath: e.target.getAttribute("data-path"),
        selected_email_id: e.target.getAttribute("data-id"),
        emailImgPath: e.target.getAttribute("data-imgpath"),
      },
    });
  };

  checkSuppression = async (suppression, suppressionId) => {
    if (suppression) {
      if (suppressionId === null) {
        return true;
      }
    }
    return false;
  };

  checkThrottle = async (throttle) => {
    if (throttle.isThrottle) {
      if (throttle.throttleLimit === 0) {
        return true;
      }
    }
    return false;
  };

  handleSubmit = async () => {
    try {
      const {
        id,
        formValue,
        suppression,
        suppressionId,
        selectedEmailProperties,
        selectedDataProperties,
        folderId,
        throttle,
      } = this.state;

      // console.log(formValue)

      let isSuppresionError = await this.checkSuppression(
        suppression,
        suppressionId
      );
      let isThrottleError = await this.checkThrottle(throttle);
      // console.log(isThrottleError);
      if (
        this.form.check() &&
        selectedDataProperties.dataId !== null &&
        selectedEmailProperties.selected_email_id !== null &&
        !isSuppresionError &&
        !isThrottleError
      ) {
        let { editCampaignProperty, campaignById, alertRef } = this.props;
        this.setState({
          loading: true,
        });
        const response = await editCampaignProperty({
          id,
          formValue,
          suppression,
          suppressionId: suppression ? suppressionId : null,
          selectedEmailProperties,
          selectedDataProperties,
          folderId,
          throttle,
        });

       
        let campaignDetails = await campaignById(id);
        var { campaign } = campaignDetails.payload.data;
        var formValueEdit = {
          campaignName: campaign.campaign_name,
          subjectLine:campaign.subject_line,
          senderProfile: campaign.sender_profile,
          campaign_category: campaign.campaign_category,
          campaignStatus: campaign.isSent,
        };
        console.log(formValueEdit)

        this.setState({
          loading: false,
          status: true,
          formValue: formValueEdit,
          alertShow: false,
        });
        alertRef.current.showToaster(response.payload);
      } else {
        this.setState({
          submitError: true,
        });
      }
    } catch (e) {}
  };

  selectFolderData = async (data) => {
    this.setState({
      showData: true,
      showDataType: true,
      suppressionListChanged: false,
      dataListChanged: false,
    });
    await this.fetchData({
      type: "campaign",
      page: 1,
      value: data.nodeData.id,
    });
  };

  selectFolderEmail = async (data) => {
    let { limit } = this.state;
    this.fetchEmails({
      type: "campaign",
      limit,
      page: 1,

      value: data.nodeData.id,
    });
    this.setState({
      show: true,
    });
  };

  supressionList = () => {
    this.setState({
      suppression: !this.state.suppression,
    });
  };

  selectFolderSupression = async (e) => {
    this.setState({
      showData: true,
      showDataType: false,
      suppressionListChanged: false,
      dataListChanged: false,
    });
    await this.fetchData({
      type: "campaign",
      limit: 5,
      page: 1,
      value: e.nodeData.id,
    });
  };

  confirmData = () => {
    let { alertRef } = this.props;

    if (this.state.dataListChanged) {
      this.setState({
        showData: false,
        selectedDataProperties: this.state.NewDataListProperty,
      });
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Data Set",
      });
    }
  };

  confirmSuppression = () => {
    let { alertRef } = this.props;
    // console.log(alertRef);
    if (this.state.suppressionListChanged) {
      this.setState({
        showData: false,
        suppressionId: this.state.NewDataListProperty.dataId,
      });
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Suppression List",
      });
    }
  };

  confirmEmail = () => {
    let { alertRef } = this.props;
    if (this.state.emailChanged) {
      this.setState({
        show: false,
        selectedEmailProperties: this.state.NewEmailProperty,
      });
    } else {
      alertRef.current.showToaster({
        status: 0,
        message: "Please select an Email",
      });
    }
  };

  selectCampaignFolder = (data) => {
    this.setState({
      folderId: data.nodeData.id,
    });
  };

  render() {
    const {
      formValue,
      suppression,
      suppressionId,
      status,
      showDataType,
      folderId,
      loading,
      selectedEmailProperties,
      selectedDataProperties,
      submitError,
      throttle,
      senderProfiles
    } = this.state;
    const { StringType } = Schema.Types;
    const model = Schema.Model({
      campaignName: StringType().isRequired("This field is required."),
      subjectLine:StringType().isRequired("This field is required."),
      senderProfile: StringType().isRequired("This field is required."),
      campaign_category: StringType().isRequired("This field is required."),
    });
    // console.log(senderProfiles)

    let {
      loadingEmail,
      campaignByID: campaignById,
      emails,
      dataList,
      loadingDataList,
    } = this.props;

    let emailList = emails.email;
    let DataList = dataList.data;
    var category = [
      {
        label: "Charlie Campaigns",
        value: "21521",
        role: "21521",
      },
      {
        label: "Charlie Newsletters",
        value: "32358",
        role: "32358",
      },
      {
        label: "Charlie Events",
        value: "32359",
        role: "32359",
      },
      {
        label: "Charlie Transactional",
        value: "32449",
        role: "32449",
      },
    ];
    const senderprofiledetails = senderProfiles.map(profile => ({
      from: profile.from_name,
      label: profile.from_name,
      value: profile.from_email,
      email: profile.from_email,
      replyTo: profile.reply_to_email
    }));
    if (loading) return <Loading />;
    return (
      <div>
        <Row>
          <LeftSideDetails suppression={suppression} display={false} />
          <Col md={10} sm={24} className="defineproperties">
            <Form
              fluid
              model={model}
              ref={(ref) => (this.form = ref)}
              onChange={(formValue) => {
                this.setState({ formValue });
              }}
              formValue={formValue}
            >
              <Form.Group>
                <Form.ControlLabel className="enter-a-valid-email">
                  <span style={{ fontWeight: 100 }}>Campaign Name*</span>
                </Form.ControlLabel>
                <Form.Control
                  onFocus={() => {
                    this.setState({
                      errorType: false,
                    });
                  }}
                  style={{ background: this.props.color }}
                  readOnly={status}
                  onBlur={this.checkCampaignname}
                  name="campaignName"
                  type="text"
                  className="newfields"
                  id="campaignname"
                />

                <span
                  id="checkerror"
                  className="custom-error-fields"
                  style={{
                    display: `${this.state.errorType ? "block" : "none"}`,
                  }}
                >
                  {this.state.error}
                </span>
              </Form.Group>



              <Form.Group>
                <Form.ControlLabel className="enter-a-valid-email">
                  <span style={{ fontWeight: 100 }}>Subject Line*</span>
                </Form.ControlLabel>
                <Form.Control
                  onFocus={() => {
                    this.setState({
                      errorType: false,
                    });
                  }}
                  readOnly={status}
                  style={{ background: this.props.color }}
                  value={formValue.subjectLine}
                 
                  name="subjectLine"
                  type="text"
                  className="newfields"
                  id="subjectLine"
                />

                <span
                  id="checkerror"
                  className="custom-error-fields"
                  style={{
                    display: `${this.state.errorType ? "block" : "none"}`,
                  }}
                >
                  {this.state.error}
                </span>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel className="enter-a-valid-email">
                  <span style={{ fontWeight: 100 }}>Select Category*</span>
                </Form.ControlLabel>
                <Form.Control
                  readOnly={status}
                  accepter={SelectPicker}
                  value={this.state.category}
                  data={category}
                  searchable={true}
                  style={{ width: "100%" }}
                  name="campaign_category"
                  defaultValue={this.state.method}
                />
              </Form.Group>
              {!this.state.status && (
                <>
                  <Row
                    style={{
                      marginTop: 30,
                    }}
                  >
                    Location*
                    <Col md={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div
                        className="location input-text-space"
                        placeholder="Location"
                        style={{
                          background: "#fff",
                          marginTop: 7,
                          overflowY:"auto"
                        }}
                      >
                        <CampaignFolder
                          selectFolder={this.selectCampaignFolder}
                          from="campaign"
                          edit={true}
                          location={folderId}
                        />
                      </div>
                    </Col>
                  </Row>
                  {folderId === "" && (
                    <span className="error">This field is Required</span>
                  )}
                </>
              )}
              {this.state.status === false && (
                <>
                  <Row style={{ marginTop: 30 }}>
                    Select Email From Folder*
                    <Col
                      className="location input-text-space"
                      md={24}
                      style={{
                        height: 120,
                        border: "1px solid black",

                        marginTop: 10,
                      }}
                    >
                      <EmailFolder
                        selectFolder={this.selectFolderEmail}
                        from="campaign"
                        setCancelEmailFolder={(click) =>
                          (this.clickEmailFolder = click)
                        }
                      />
                    </Col>
                  </Row>
                  {selectedEmailProperties.selected_email_id === null &&
                    submitError && (
                      <span className="error">This field is Required</span>
                    )}
                </>
              )}

              <Form.Group style={{ marginTop: 30 }}>
                <Form.ControlLabel className="enter-a-valid-email">
                  <span style={{ fontWeight: 100 }}>Select Sender Profile*</span>
                </Form.ControlLabel>
                <Form.Control
                  accepter={SelectPicker}
                  data={senderprofiledetails}
                  searchable={true}
                  style={{ width: "100%" }}
                  name="senderProfile"
                />
              </Form.Group>
              {this.state.status === false && (
                <>
                  <Row>
                    Select Data List* &nbsp;
                    <Col
                      md={24}
                      className="location input-text-space"
                      style={{
                        width: "100%",
                        padding: 10,
                        height: 120,
                        border: "1px solid black",

                        marginTop: 10,
                      }}
                    >
                      <DataFolder
                        selectFolder={this.selectFolderData}
                        component={this.state.typeComponent}
                        datafolder={campaignById.selected_data_folder}
                        setCancelDataFolder={(click) =>
                          (this.clickDataFolder = click)
                        }
                      />
                    </Col>
                  </Row>{" "}
                  {selectedDataProperties.dataId === null && submitError && (
                    <span className="error">This field is Required</span>
                  )}
                </>
              )}

              <Row style={{ marginTop: 25 }}>
                Do you want to include Suppression List?
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  disabled={status}
                  className="form-check-input"
                  type="checkbox"
                  checked={suppression}
                  name="campaign"
                  style={{ width: 15 }}
                  onChange={this.supressionList}
                />
                {suppression && !status && (
                  <>
                    <div
                      className="location input-text-space"
                      placeholder="Location"
                      style={{
                        background: "#fff",
                        marginTop: 5,
                      }}
                    >
                      <SuppressionFolder
                        selectFolder={this.selectFolderSupression}
                        from="campaign"
                        setCancelSuppressionFolder={(click) =>
                          (this.clickSuppressionFolder = click)
                        }
                      />
                    </div>
                    {suppression && suppressionId === null && submitError && (
                      <span className="error">This field is Required</span>
                    )}
                  </>
                )}
              </Row>

              <Row style={{ marginTop: 25 }}>
                This email will be throttled at the rate specified below?
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  disabled={status}
                  className="form-check-input"
                  type="checkbox"
                  id="throttle"
                  style={{ width: 15 }}
                  checked={throttle.isThrottle}
                  onChange={() => {
                    throttle.throttleLimit = 500;
                    throttle.isThrottle = !throttle.isThrottle;
                    throttle.throttleError = false;
                    this.setState({
                      throttle,
                    });
                  }}
                />
                {throttle.isThrottle && (
                  <>
                    <br />
                    <br />
                    <input
                      type="number"
                      id="throttle"
                      name="throttle"
                      min="1"
                      max="1000"
                      disabled={status}
                      onChange={(e) => {
                        if (e.target.value <= 1000) {
                          throttle.throttleLimit = Number(e.target.value);
                          throttle.throttleError = false;
                          this.setState({
                            throttle,
                          });
                        } else {
                          this.props.alertRef.current.showToaster({
                            status: 0,
                            message: "Maximum emails per 20 minutes allowed are 1000",
                            duration:3
                          });
                        }
                      }}
                      value={throttle.throttleLimit}
                    />
                    &nbsp;&nbsp;/ 20 minutes
                    {throttle.isThrottle && throttle.throttleError && (
                      <span className="error">
                        This field value is need to be more than 0
                      </span>
                    )}
                  </>
                )}
              </Row>
              {/* {status === true &&
              this.props.emailByID &&
              this.props.dataListById.data_list_name &&
              this.props.count ? (
                <ButtonToolbar style={{ marginTop: 10 }}>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={() => this.setState({ status: false })}
                    id="submitbutton"
                  >
                    Edit
                  </Button>
                </ButtonToolbar>
              ) : this.props.emailByID &&
                this.props.dataListById.data_list_name &&
                this.props.count ? (
                <ButtonToolbar style={{ marginTop: 20 }}>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={() => this.handleSubmit()}
                    id="submitbutton"
                    loading={loading}
                  >
                    Save
                  </Button>
                  <Button
                    appearance="primary"
                    style={{}}
                    type="submit"
                    onClick={this.cancelEdit}
                    id="submitbutton"
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              ) : null} */}
              {status === true &&
              this.props.emailByID &&
              this.props.dataListById &&
              this.props.dataListById.data_list_name &&
              this.props.count ? (
                <ButtonToolbar style={{ marginTop: 10 }}>
                  {status === true && formValue.campaignStatus == "0" ? (
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={() => this.setState({ status: false })}
                      id="submitbutton"
                    >
                      Edit
                    </Button>
                  ) : null}
                </ButtonToolbar>
              ) : status === false ? (
                <ButtonToolbar style={{ marginTop: 20 }}>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={() => this.handleSubmit()}
                    id="submitbutton"
                    loading={loading}
                  >
                    Save
                  </Button>
                  <Button
                    appearance="primary"
                    style={{}}
                    type="submit"
                    onClick={this.cancelEdit}
                    id="submitbutton"
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              ) : null}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={4}></Col>
        </Row>

        <Drawer
          backdrop={false}
          open={this.state.showData}
          onClose={() => {
            this.setState({
              showData: false,
            });
            if (showDataType) {
              this.clickDataFolder();
            } else {
              this.clickSuppressionFolder();
            }
          }}
        >
          <Drawer.Header>
            <Drawer.Title>
              {showDataType ? "Select Data List" : "Select Suppression List"}
            </Drawer.Title>
            <Drawer.Actions>
              <Button
                onClick={
                  showDataType ? this.confirmData : this.confirmSuppression
                }
                style={{ backgroundColor: "#415670", color: "#fff" }}
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    showData: false,
                  });
                  if (showDataType) {
                    this.clickDataFolder();
                  } else {
                    this.clickSuppressionFolder();
                  }
                }}
                style={{ backgroundColor: "#415670", color: "#fff" }}
              >
                Cancel
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Col md={1} sm={24}></Col>
            <Col md={23} sm={24}>
              {loadingDataList && <Loading />}
              {DataList.length > 0 ? (
                <Row className="TemplateList2">
                  <Form>
                    {Object.keys(DataList).map((val, index) => {
                      return (
                        <>
                          <Col md={11} sm={12} key={index}>
                            <div
                              style={{
                                height: 200,
                                width: 200,
                              }}
                            >
                              <input
                                type="radio"
                                name="template-id"
                                id={`dataList${DataList[val].data_list_id}`}
                                data-id={DataList[val].data_list_id}
                                data-name={DataList[val].data_list_name}
                                data-count={DataList[val].data_list_count}
                                data-email={DataList[val].data_list_email_field}
                                key={index}
                                onClick={this.selectDataList}
                                className="radio-custom"
                              ></input>
                              <label
                                htmlFor={`dataList${DataList[val].data_list_id}`}
                                className="radio-custom-label"
                              >
                                <img
                                  src="https://charlie-dave.s3.amazonaws.com/database.png"
                                  alt={DataList[val].data_list_name}
                                  id={`dataList${DataList[val].data_list_id}`}
                                  data-id={DataList[val].data_list_id}
                                  data-name={DataList[val].data_list_name}
                                  data-count={DataList[val].data_list_count}
                                  data-email={
                                    DataList[val].data_list_email_field
                                  }
                                  key={index}
                                  style={{ width: 150 }}
                                  onClick={this.selectDataList}
                                />
                              </label>
                              <p
                                style={{
                                  textAlign: "center",
                                  // marginTop: -20,
                                }}
                              >
                                {DataList[val].data_list_name}
                              </p>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Form>
                </Row>
              ) : (
                <Row>
                  <h3 style={{ textAlign: "center", marginTop: 30 }}>
                    No Data List found in this folder.
                  </h3>
                </Row>
              )}
            </Col>
          </Drawer.Body>
        </Drawer>
        {/* Email LIST */}
        <Drawer
          backdrop={false}
          open={this.state.show}
          onClose={() => {
            this.setState({
              show: false,
            });
            this.clickEmailFolder();
          }}
        >
          <Drawer.Header>
            <Drawer.Title>Select Email</Drawer.Title>
            <br></br>

            <Drawer.Actions>
              <Button
                onClick={() => this.confirmEmail()}
                style={{ backgroundColor: "#415670", color: "#fff" }}
              >
                Confirm
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    show: false,
                    showDataType: false,
                    // selectFolderEmail:
                    //   this.props.campaignById.selected_email_folder,
                  });
                  this.clickEmailFolder();
                }}
                style={{ backgroundColor: "#415670", color: "#fff" }}
              >
                Cancel
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Col md={1} sm={24}></Col>
            <Col md={23} sm={24}>
              {loadingEmail && <Loading />}
              {emailList.length > 0 ? (
                <Row className="TemplateList2">
                  <Form>
                    {Object.keys(emailList).map((val, index) => {
                      return (
                        <>
                          <Col
                            md={11}
                            sm={12}
                            key={index}
                            style={{ marginBottom: 20, height: 220 }}
                          >
                            <div
                              style={{
                                height: 200,
                                width: 200,
                                overflow: "hidden",
                              }}
                            >
                              <input
                                type="radio"
                                name="template-id"
                                id={`emailList${emailList[val].email_id}`}
                                data-id={emailList[val].email_id}
                                data-name={emailList[val].email_name}
                                data-path={emailList[val].email_json_path}
                                data-subject={emailList[val].email_subject_line}
                                data-imgpath={emailList[val].email_img_path}
                                key={index}
                                onClick={this.selectEmail}
                                className="radio-custom"
                              />
                              <label
                                htmlFor={`emailList${emailList[val].email_id}`}
                                className="radio-custom-label"
                              >
                                <img
                                  src={`${emailList[val].email_img_path}`}
                                  alt={emailList[val].email_name}
                                  id={`emailList${emailList[val].email_id}`}
                                  data-id={emailList[val].email_id}
                                  data-name={emailList[val].email_name}
                                  data-path={emailList[val].email_json_path}
                                  data-subject={
                                    emailList[val].email_subject_line
                                  }
                                  data-imgpath={emailList[val].email_img_path}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                  }}
                                  onClick={this.selectEmail}
                                />
                              </label>
                            </div>
                            <p style={{ textAlign: "center", marginTop: 0 }}>
                              {emailList[val].email_name}
                            </p>
                          </Col>
                        </>
                      );
                    })}
                  </Form>
                </Row>
              ) : (
                <Row>
                  <h3 style={{ textAlign: "center", marginTop: 30 }}>
                    No Email found in this folder.
                  </h3>
                </Row>
              )}
            </Col>
          </Drawer.Body>
        </Drawer>

        {/* <Modal
          backdrop="static"
          open={this.state.alertShow}
          onClose={this.cancelEdit}
          size="xs"
        >
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RemindIcon
              style={{
                color: "#ffb300",
                fontSize: 24,
              }}
            />
            Once properties are changed, there will be no Undone. Are you sure
            you want to proceed?
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleSubmit}
              appearance="primary"
              title="Confirm"
              className="ButtonNext"
            >
              Confirm
            </Button>
            <Button
              onClick={this.cancelEdit}
              appearance="subtle"
              className="ButtonNext"
              title="Cancel"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { emailFolder } = state.EmailFolderSlice;
  const { dataFolder } = state.DataFolderSlice;
  const { senderProfiles } = state.SenderSlice;
  // senderProfiles: state.SenderSlice.senderProfiles;
  const {
    campaignByID,
    emailByID,
    dataListById,
    count,
    loadingById,
    suppressionList,

  } = state.CampaignSlice;
  const { emails } = state.AssetSlice;
  const loadingEmail = state.AssetSlice.loading;
  const { dataList } = state.DataSlice;
  const loadingDataList = state.DataSlice.loading;
  return {
    campaignByID,
    emailByID,
    dataListById,
    count,
    suppressionList,
    loadingById,
    emailFolder,
    dataFolder,
    emails,
    loadingEmail,
senderProfiles,
    dataList,
    loadingDataList,
  };
}

export default connect(mapStateToProps, {
  isCampaignNameExist,
  campaignById,
  getEmails,
  getDataLists,
  editCampaignProperty,
})(DefineCampaignProperties);
