import React, { Component } from "react";

import { connect } from "react-redux";

import { Col, Row, Input, Form, InputGroup, Loader, Button } from "rsuite";
import "../../CreateNewProject/Email/assets/css/newemail.css";

import DataFolder from "../../Folder/DataFolder";
import {
  getDataById,
  editDetails,
  fetchRecord,
} from "../../Features/Data/DataThunk";

import axios from "../../Utils/Interceptor/AxiosInterceptor";
import AboutDataSet from "./AboutDataSet";
import DataService from "./DataService";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class EditDataInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideError: false,
      dataValues: {
        dataName: {
          textName: "",
          errorInName: false,
          isExistError: false,
          errMessageName: null,
        },
        description: {
          textDes: "",
          errorInDescription: false,
          errMessageDes: null,
        },

        location: {
          path: null,
          errorInLocation: false,
        },
      },
      rawData: {},
      loading: null,
      records: false,
      locationLoaded: false,
    };
    this.props.export(this.exportCsv);
  }

  async componentDidMount() {
    let { dataset, fetchRecordAction, id } = this.props;
    try {
      if (!dataset.loading) {
        this.setState({
          loading: "fetchRecord",
        });
        let response = await fetchRecordAction(id);
        // console.log(response);
        let { dataValues } = this.state;
        dataValues["dataName"].textName = dataset.dataById.data_list_name;
        dataValues["description"].textDes =
          dataset.dataById.data_list_description;
        dataValues["location"].path = dataset.dataById.datalist_folder_id;
        this.setState({
          dataValues,
          loading: null,
          rawData: response.payload.data,
          records: response.payload.data.records,
          locationLoaded: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  selectFolder = (e) => {
    let { dataValues } = this.state;
    dataValues.location.path = e.nodeData.id;
    dataValues.location.errorInLocation = false;
    this.setState({
      dataValues,
    });
  };

  checkDataName = async () => {
    let { id } = this.props;
    let { dataValues } = this.state;
    let { dataName } = dataValues;
    try {
      if (dataName.textName.trim().replace(/\s/g, "").length > 0)
        this.setState({ loading: "check" });
      const response = await axios.get(
        `/data-archive/data/is-exist?name=${dataName.textName}&id=${id}`
      );

      let { data } = response.data;
      dataValues.dataName["isExistError"] = data.error;
      dataValues.dataName["errMessageName"] = data.message;
      this.setState({
        hideError: false,
        loading: null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  KeyDownName = (e) => {
    let { alertRef } = this.props;
    let { dataName } = this.state.dataValues;
    if (dataName.textName.length > 32 && e.keyCode !== 8) {
      alertRef.current.showToaster({
        status: 0,
        message: "only 32 characters are allowed",
      });
      e.preventDefault();
    }

    let regexp = /^[a-zA-Z0-9-_]+$/;
    if (e.nativeEvent.key.search(regexp) === -1 || e.keyCode === 189) {
      alertRef.current.showToaster({
        status: 0,
        message:
          e.keyCode === 32
            ? "White Space is not allowed"
            : "Special Characters are not allowed",
      });
      e.preventDefault();
    }
  };

  checkErrorForm = (event) => {
    let { dataValues } = this.state;
    let { location, dataName, description } = dataValues;
    try {
      if (
        !dataName.textName ||
        dataName.isExistError ||
        !location.path ||
        !description.textDes
      ) {
        if (!dataName.textName) {
          dataValues.dataName.textName = "";
          dataValues.dataName.errorInName = true;
          dataValues.dataName.isExistError = false;
          dataValues.dataName.errMessageName = "This field is Required";
        }

        if (!location.path) {
          dataValues.location.path = null;
          dataValues.location.errorInLocation = true;
        }

        if (!description.textDes) {
          dataValues.description.textDes = "";

          dataValues.description.errorInDescription = true;
          dataValues.description.errMessageDes = "This field is Required";
        }
        this.setState({
          dataValues,
        });
        event.preventDefault();
      } else {
        this.editDataProperty();
      }
    } catch (e) {
      console.error(e);
    }
  };

  editDataProperty = async () => {
    try {
      let { dataValues } = this.state;
      let { location, dataName, description } = dataValues;
      let { getDataById, editDetails, id, alertRef } = this.props;
      let dataSet = {
        id: id,
        name: dataName.textName,
        location: location.path,
        description: description.textDes,
      };
      this.setState({
        loading: "submit",
      });
      let response = await editDetails(dataSet);
      await getDataById({
        type: "byId",
        value: id,
      });

      this.setState({
        loading: null,
      });

      alertRef.current.showToaster(response.payload);
    } catch (e) {
      console.log(e);
    }
  };

  exportCsv = () => {
    let { dataById, structureById } = this.props.dataset;
    DataService.exportFieldToCsv(
      dataById,
      structureById,
      this.state.records ? this.state.rawData.table : []
    );
  };


  render() {
    let { hideError, dataValues, loading,locationLoaded } = this.state;
    let { dataName, description, location } = dataValues;
    // console.log(locationLoaded)
    return (
      <Row className="create-new-project">
        <Col md={24}>
          <Row>
            <Col md={9} className="description-left-side">
              <AboutDataSet />
            </Col>
            <Col md={1}></Col>

            <Col md={14} className="creat-email-form" style={{ marginTop: 25 }}>
              <Form
                style={{
                  width: "100%",
                  marginBottom: 30,
                  padding: "0px  50px",
                }}
                fluid
              >
                <Col md={24} >
                  <Form.Group>
                  <Form.ControlLabel>Data Set Name*</Form.ControlLabel>
                 
                    <InputGroup inside className="input-group-form">
                      <Form.Control
                        name="datalistName"
                        placeholder=""
                        value={dataName.textName}
                        onChange={(value) => {
                          dataValues.dataName["textName"] = value.trimStart();
                          dataValues.dataName["errorInName"] = false;
                          dataValues.dataName["isExistError"] = false;
                          dataValues.dataName["errMessageName"] = null;
                          this.setState({
                            dataValues,
                          });
                        }}
                        className="new-email-text"
                        onFocus={() =>
                          this.setState({
                            hideError: true,
                          })
                        }
                        style={{ border: "none !important" }}
                        onBlur={this.checkDataName}
                        onKeyDown={(e) => this.KeyDownName(e)}
                      />
                      {loading === "check" && (
                        <InputGroup.Addon style={{ padding: "8px 12px" }}>
                          <Loader />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>

                    
                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${
                          (dataName.errorInName || dataName.isExistError) &&
                          !hideError
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      {dataName.errMessageName}
                    </span>
                  </Form.Group>
                 

                  {locationLoaded ?(
                   <>
                   Location*
                   <div
                    className="location"
                    placeholder="Location"
                    style={{
                      background: "#fff",
                      padding: 5,
                      overflowY:"auto",
                      marginTop:
                        dataName.errorInName || dataName.isExistError ? 10 : 10,
                    }}
                  >
                    {/* {console.log(location)} */}
                    
                    <DataFolder
                      selectFolder={this.selectFolder}
                      edit={true}
                      location={location.path}
                      setCancelDataFolder={(click) =>
                        (this.clickDataFolder = click)
                      }
                    />
                  </div></>):<>Loading</>}
                  <span
                    className="custom-error-fields"
                    style={{
                      display: `${
                        location.errorInLocation && !location.path
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    This field is Required
                  </span>
                  <Form.Group style={{marginTop:22}}><Form.ControlLabel>Description*</Form.ControlLabel>
                  <Form.Control
                    rows={10}
                    accepter={Textarea}
                    value={description.textDes}
                    name="description"
                    onChange={(value) => {
                      dataValues.description["textDes"] = value.trimStart();
                      dataValues.description["errorInDescription"] = false;
                      dataValues.description["errMessageDes"] = null;

                      this.setState({
                        dataValues,
                      });
                    }}
                    placeholder=""
                    className=""
                    style={{ borderRadius: 0 }}
                  />
                  </Form.Group>
                  
                  <span
                    className="custom-error-fields"
                    style={{
                      display: `${
                        description.errorInDescription ? "block" : "none"
                      }`,
                    }}
                  >
                    {description.errMessageDes}
                  </span>

                  <Button
                    style={{
                      marginTop: 20,
                      backgroundColor: "#415670",
                      color: "#fff",
                    }}
                    // className="button-background"

                    title="Save"
                    loading={loading === "submit"}
                    onClick={this.checkErrorForm}
                  >
                    <b>Save</b>
                  </Button>
                </Col>
              </Form>
            </Col>

            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  dataset: state.DataSlice,
});

const mapDispatchToProps = (dispatch) => ({
  getDataById: (type, value) => dispatch(getDataById(type, value)),
  editDetails: (dataSet) => dispatch(editDetails(dataSet)),
  fetchRecordAction: (id) => dispatch(fetchRecord(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDataInfo);
