import React, { Component } from "react";
import RecentTemplates from "./RecentTemplates";
import RecentEmails from "./RecentEmails";
import RecentData from "./RecentData";
import { getRecentAssets } from "../../Features/Asset/AssetThunk";
import { connect } from "react-redux";

class Recent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 4,
      loading: true
    };
  }
  async componentDidMount() {
    const { getRecentAssets } = this.props;
    let { limit } = this.state;
    await getRecentAssets(limit);
    this.setState({
      loading: false
    });
  }
  render() {
    let { loading } = this.state;
    let {
      userInfo: { user }
    } = this.props;
    const userAccess = JSON.parse(JSON.parse(user.access));
    return (
      <div>
        {userAccess.includes("template") && <RecentTemplates loading={loading} />}
        {userAccess.includes("email") && <RecentEmails loading={loading} />}
        {userAccess.includes("data") && <RecentData loading={loading} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userInfo: state.LoginSlice.userInfo });

const mapDispatchToProps = (dispatch) => ({
  getRecentAssets: (limit) => dispatch(getRecentAssets(limit))
});

export default connect(mapStateToProps, mapDispatchToProps)(Recent);
