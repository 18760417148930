import React, { Component } from "react";

import { Col, Input, Form, InputGroup, Loader, Modal, Button } from "rsuite";
import { connect } from "react-redux";
import EmailFolder from "../../../Folder/EmailFolder";

import axios from "../../Interceptor/AxiosInterceptor";

import { emailById } from "../../../Features/Asset/AssetThunk";
import { editEmailProperty } from "../../../Features/Email/EmailThunk";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class EmailEditProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: null,
      category: "",
      emailValues: {
        emailName: {
          textName: "",
          errorInName: false,
          isExistError: false,
          errMessageName: null,
        },
        description: {
          textDes: "",
          errorInDescription: false,
          errMessageDes: null,
        },
        subject: {
          textSubject: "",
          errorInSubject: false,
          errMessageSubject: null,
        },
        location: {
          path: null,
          errorInLocation: false,
        },
      },
      hideError: false,
    };
  }

  componentDidMount() {
    let { loadingSlice, email } = this.props;
    try {
      if (!loadingSlice) {
        let { emailValues } = this.state;
        emailValues["emailName"].textName = email.email_name;
        emailValues["description"].textDes = email.email_description;
        emailValues["location"].path = email.email_folder_id;
        emailValues["subject"].textSubject = email.email_subject_line;
        this.setState({
          emailValues,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  checkemailName = async () => {
    let { params } = this.props;
    let { emailValues } = this.state;
    let { emailName } = emailValues;
    try {
      if (emailName.textName.trim().replace(/\s/g, "").length > 0) {
        this.setState({ loading: "check" });
        const response = await axios.get(
          `/email/is-exist?name=${emailName.textName}&id=${params.id}`
        );
        let { data } = response.data;
        emailValues.emailName["isExistError"] = data.error;
        emailValues.emailName["errMessageName"] = data.message;
      }
      this.setState({
        hideError: false,
        emailValues,
        loading: null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  selectFolder = (e) => {
    let { emailValues } = this.state;
    emailValues.location.path = e.nodeData.id;
    emailValues.location.errorInLocation = false;
    this.setState({
      emailValues,
    });
  };

  checkErrorForm = (event) => {
    let { emailValues } = this.state;
    let { subject, location, emailName, description } = emailValues;
    try {
      if (
        !emailName.textName ||
        emailName.isExistError ||
        !subject.textSubject ||
        !location.path ||
        !description.textDes
      ) {
        if (!emailName.textName) {
          emailValues.emailName.textName = "";
          emailValues.emailName.errorInName = true;
          emailValues.emailName.isExistError = false;
          emailValues.emailName.errMessageName = "This field is Required";
        }

        if (!subject.textSubject) {
          emailValues.subject.textSubject = "";
          emailValues.subject.errorInSubject = true;
          emailValues.subject.errMessageSubject = "This field is Required";
        }
        if (!location.path) {
          emailValues.location.path = null;
          emailValues.location.errorInLocation = true;
        }

        if (!description.textDes) {
          emailValues.description.textDes = "";

          emailValues.description.errorInDescription = true;
          emailValues.description.errMessageDes = "This field is Required";
        }
        this.setState({
          emailValues,
        });
        event.preventDefault();
      } else {
        this.editProperty();
      }
    } catch (e) {
      console.error(e);
    }
  };

  editProperty = async () => {
    try {
      let { emailValues } = this.state;
      let { subject, location, emailName, description } = emailValues;
      let { editEmailProperty, emailById, params, alertRef } = this.props;
      let emailData = {
        id: params.id,
        name: emailName.textName,
        location: location.path,
        subject: subject.textSubject,
        description: description.textDes,
      };
      this.setState({
        loading: "submit",
      });
      let response = await editEmailProperty(emailData);
      await emailById({
        type: "byId",
        value: params.id,
      });

      this.setState({
        loading: null,
      });

      alertRef.current.showToaster(response.payload);
      this.closeModal();
    } catch (e) {
      this.closeModal();
      console.log(e);
    }
  };

  closeModal = async () => {
    let { handleEditModal } = this.props;
    // await reset();

    handleEditModal();
  };

  render() {
    let { hideError, emailValues, loading } = this.state;
    let { emailName, description, subject, location } = emailValues;
    let { open } = this.props;
    return (
      <Modal size="lg" backdrop="static" open={open} onClose={this.closeModal}>
        <Modal.Header>
          <Modal.Title>Email Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: "100%", marginBottom: 50 }} fluid>
            <Col md={1}></Col>

            <Col md={10}>
              <Form.Group>
              Email Name*
                <InputGroup inside className="input-group-form">
                  <Form.Control
                    name="emailName"
                    placeholder=""
                    value={emailName.textName}
                    onChange={(value) => {
                      emailValues.emailName["textName"] = value.trimStart();
                      emailValues.emailName["errorInName"] = false;
                      emailValues.emailName["isExistError"] = false;
                      emailValues.emailName["errMessageName"] = null;
                      this.setState({
                        emailValues,
                      });
                    }}
                    className="new-email-text-only-underline"
                    onFocus={() =>
                      this.setState({
                        hideError: true,
                      })
                    }
                    style={{ border: "none !important" }}
                    onBlur={this.checkemailName}
                  />
                  {loading === "check" && (
                    <InputGroup.Addon style={{ padding: "8px 12px" }}>
                      <Loader />
                    </InputGroup.Addon>
                  )}
                </InputGroup>
                <span
                  className="custom-error-fields"
                  style={{
                    display: `${
                      (emailName.errorInName || emailName.isExistError) &&
                      !hideError
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  {emailName.errMessageName}
                </span>
              </Form.Group>
              Location*
              <div
                className="location"
                placeholder="Location"
                style={{
                  background: "#fff",
                  padding: 5,
                  overflowY:"auto"
                }}
              >
                <EmailFolder
                  selectFolder={this.selectFolder}
                  location={location.path}
                  edit={true}
                  setCancelEmailFolder={(click) =>
                    (this.clickEmailFolder = click)
                  }
                />
              </div>
              <span
                className="custom-error-fields"
                style={{
                  display: `${
                    location.errorInLocation && !location.path
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                This field is Required
              </span>
            </Col>
            <Col md={2}></Col>
            <Col md={10}>
            Subject Line*
              <Input
                placeholder=""
                className="new-email-text"
                name="subject"
                value={subject.textSubject}
                onChange={(value) => {
                  emailValues.subject["textSubject"] = value.trimStart();
                  emailValues.subject["errorInSubject"] = false;
                  emailValues.subject["errMessageDes"] = null;
                  this.setState({
                    emailValues,
                  });
                }}
              />

              <span
                className="custom-error-fields"
                style={{
                  display: `${subject.errorInSubject ? "block" : "none"}`,
                }}
              >
                {subject.errMessageSubject}
              </span>
              <Form.Group style={{marginTop:22}}><Form.ControlLabel>Description*</Form.ControlLabel>
              <Form.Control
                rows={10}
                accepter={Textarea}
                value={description.textDes}
                name="description"
                onChange={(value) => {
                  emailValues.description["textDes"] = value.trimStart();
                  emailValues.description["errorInDescription"] = false;
                  emailValues.description["errMessageDes"] = null;

                  this.setState({
                    emailValues,
                  });
                }}
                placeholder=""
                className=""
                style={{ borderRadius: 0 }}
              /></Form.Group>
              <span
                className="custom-error-fields"
                style={{
                  display: `${
                    description.errorInDescription ? "block" : "none"
                  }`,
                }}
              >
                {description.errMessageDes}
              </span>
            </Col>
            <Col md={1}></Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.closeModal}
            appearance="subtle"
            style={{ backgroundColor: "#415670", color: "#fff" }}
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            loading={loading === "submit"}
            onClick={this.checkErrorForm}
            style={{ backgroundColor: "#415670", color: "#fff" }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.AssetSlice.emailById.email,
  loadingSlice: state.AssetSlice.loading,
});

const mapDispatchToProps = (dispatch) => ({
  emailById: (type, value) => dispatch(emailById(type, value)),
  editEmailProperty: (emailData) => dispatch(editEmailProperty(emailData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailEditProperty);
