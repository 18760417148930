import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getCampaign = createAsyncThunk(
  "campaign/getCampaign",
  async ({ type, page, limit, value }) => {
    try {
      const response = await axios.get(
        `/campaign/getCampaign?type=${type}&limit=${Number(limit)}&page=${page}&value=${value}`
      );

      return response.data;
    } catch (e) {
      console.log(e.response.data);
      return e.response.data;
    }
  }
);

export const campaignById = createAsyncThunk("campaign/getCampaignById", async (campaignID) => {
  try {
    const response = await axios.get(
      `/campaign/getCampaign?type=byId&value=${campaignID}`,
      campaignID
    );
    return response.data;
  } catch (e) {
    console.log(e.response.data);
    return e.response.data;
  }
});

export const editCampaignProperty = createAsyncThunk(
  "campaign/edit-campaign",
  async (editCampaignData) => {
    try {
      const response = await axios.put(`/campaign/editCampaignProperty`, editCampaignData);
      return response.data;
    } catch (e) {}
  }
);

export const scheduleCampaign = createAsyncThunk(
  "campaign/schedule-campaign",
  async ({ scheduledAt, campaignId }) => {
    try {
      const response = await axios.put(`/campaign/schedule-campaign`, {
        scheduledAt,
        campaignId
      });
      return response.data;
    } catch (e) {
      console.log(e.response);
      return e.response.data;
    }
  }
);

export const campaignSend = createAsyncThunk("campaign/campaign-send", async ({ campaignId }) => {
  try {
    const response = await axios.post(`/campaign/campaign-send`, {
      campaignId
    });
    return response.data;
  } catch (e) {
    console.error(e);
    let response = {};
    return (response["data"] = {
      status: 0,
      message: "Something went wrong"
    });
  }
});

// export const getSenderprofile= createAsyncThunk("campaign/getSenderprofile", async()=>{
//   // console.log("Okay i got called:::::::::::::")
//  try{
//     const response=await axios.get('/campaign/getSenderprofile');
//     console.log("response",response.data)
//     return response.data

//  }catch(e){
//  return e.response.data
//  }
// })
// export const UpdateSenderprofileData= createAsyncThunk("campaign/updateSenderprofileData", async()=>{
//   // console.log("Okay i got called:::::::::::::")
//  try{
//     const response=await axios.get('/campaign/updateSenderprofileData');
//     console.log("response",response.data)
//     return response.data

//  }catch(e){
//  return e.response.data
//  }
// })

export const deleteCampaign = createAsyncThunk("campaign/delete", async ({ id }) => {
  try {
    const response = await axios.delete(`/campaign/?id=${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
