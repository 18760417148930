import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Col, Row, Input, Form, InputGroup, Loader, IconButton } from "rsuite";
import "./assets/css/newemail.css";
import PlayIcon from "@rsuite/icons/legacy/Play";
// import Back from "./assets/icons/Back.png";
// import Next from "./assets/icons/Next.png";

import {
  // reset,
  feedEmail,
  selectLocation,
  feedSubject,
  feedDescription,
} from "../../Features/Email/EmailSlice";

import ConfirmationModal from "../../Utils/ConfirmationModal";
import EmailFolder from "../../Folder/EmailFolder";
import { isEmailExist } from "../../Features/Email/EmailThunk";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

class CreateEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: false,
      hideError: false,
    };
  }
  selectFolder = (e) => {
    const { selectLocation } = this.props;
    selectLocation({ path: e.nodeData.id, errorInLocation: false });
  };

  checkEmailName = async () => {
    let { isEmailExist, data } = this.props;
    let { emailName } = data;

    if (emailName.textName.trim().replace(/\s/g, "").length > 0)
      await isEmailExist({
        emailName: emailName.textName,
        id: undefined,
      });
    this.setState({
      hideError: false,
    });
  };

  checkErrorForm = (event) => {
    let { data, feedSubject, selectLocation, feedEmail, feedDescription } =
      this.props;
    let { subject, location, emailName, description } = data;
    if (
      !emailName.textName ||
      emailName.isExistError ||
      !subject.textSubject ||
      !location.path ||
      !description.textDes
    ) {
      if (!emailName.textName) {
        feedEmail({
          textName: "",
          errorInName: true,
          isExistError: false,
          errMessageName: "This field is Required",
        });
      }

      if (!subject.textSubject) {
        feedSubject({
          textSubject: "",
          errorInSubject: true,
          errMessageSubject: "This field is Required",
        });
      }
      if (!location.path) {
        selectLocation({ path: null, errorInLocation: true });
      }

      if (!description.textDes) {
        feedDescription({
          textDes: "",
          errorInDescription: true,
          errMessageDes: "This field is Required",
        });
      }
      event.preventDefault();
    }
  };

  render() {
    let { data, feedEmail, feedDescription, feedSubject } = this.props;
    let { emailName, description, subject, location, loading } = data;
    let { confirmation, hideError } = this.state;
    return (
      <div className="content-container">
        {confirmation && (
          <ConfirmationModal
            confirmation={confirmation}
            component="email"
            hide={() =>
              this.setState({
                confirmation: false,
              })
            }
          />
        )}
        <Row>
          <Col md={12}>
            <h2 className="hero-head">Create Email</h2>
          </Col>

          <Col md={12}>
            <div className="next-back-button-horizontal">
              <div className="next-back-button">
                <span
                  style={{ cursor: "pointer" }}
                  title="Back"
                  onClick={() =>
                    this.setState({
                      confirmation: true,
                    })
                  }
                >
                  <IconButton placement="left">
                    <PlayIcon style={{ transform: "rotate(180deg)" }} />
                    Back
                  </IconButton>
                </span>
                <Link
                  to="/createnewproject/create/email/editor"
                  className="link-color-font"
                  onClick={(event) => this.checkErrorForm(event)}
                >
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    title="Next"
                  >
                    <IconButton icon={<PlayIcon />} placement="right">
                      Next
                    </IconButton>
                  </span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="create-new-project">
          <Col md={24}>
            <Row>
              <div className="creat-email-form">
                <Form style={{ width: "100%", marginBottom: 50 }} fluid>
                  <Col md={1}></Col>

                  <Col md={10}>
                    <Form.Group>
                    Email Name*
                      <InputGroup inside className="input-group-form">
                        <Form.Control
                          name="emailName"
                          // placeholder="Email name *"
                          value={emailName.textName}
                          onChange={(value) =>
                            feedEmail({
                              textName: value.trimStart(),
                              errorInName: false,
                              isExistError: false,
                              errMessageName: null,
                            })
                          }
                          className="new-email-text"
                          onFocus={() =>
                            this.setState({
                              hideError: true,
                            })
                          }
                          style={{ border: "none !important" }}
                          onBlur={this.checkEmailName}
                        />
                        {loading && (
                          <InputGroup.Addon style={{ padding: "8px 12px" }}>
                            <Loader />
                          </InputGroup.Addon>
                        )}
                      </InputGroup>
                      <span
                        className="custom-error-fields"
                        style={{
                          display: `${
                            (emailName.errorInName || emailName.isExistError) &&
                            !hideError
                              ? "block"
                              : "none"
                          }`,
                        }}
                      >
                        {emailName.errMessageName}
                      </span>
                    </Form.Group>

                   
                    Location*
                     <div
                      className=" location"
                      placeholder="Location *"
                      style={{
                        background: "#fff",
                        padding: 5,
                        overflowY:"auto"
                      }}
                    >
                      <EmailFolder
                      edit = {false}
                      location={location.path}
                        selectFolder={this.selectFolder}
                        setCancelEmailFolder={(click) =>
                          (this.clickEmailFolder = click)
                        }
                      />
                    </div>
                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${
                          location.errorInLocation && !location.path
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      This field is Required
                    </span>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={10}>
                  Subject Line*
                    <Input
                      placeholder=""
                      className="new-email-text"
                      name="subject"
                      value={subject.textSubject}
                      onChange={(value) =>
                        feedSubject({
                          textSubject: value.trimStart(),
                          errorInSubject: false,
                          errMessageDes: null,
                        })
                      }
                    />

                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${subject.errorInSubject ? "block" : "none"}`,
                      }}
                    >
                      {subject.errMessageSubject}
                    </span>
                    <Form.Group style={{marginTop:22}}><Form.ControlLabel>Description*</Form.ControlLabel>
                    <Form.Control
                      rows={10}
                      accepter={Textarea}
                      value={description.textDes}
                      name="description"
                      onChange={(value) =>
                        feedDescription({
                          textDes: value.trimStart(),
                          errorInDescription: false,
                          errMessageDes: null,
                        })
                      }
                      placeholder=""
                      className=""
                      style={{ borderRadius: 0 }}
                    /></Form.Group>
                    <span
                      className="custom-error-fields"
                      style={{
                        display: `${
                          description.errorInDescription ? "block" : "none"
                        }`,
                      }}
                    >
                      {description.errMessageDes}
                    </span>
                  </Col>
                  <Col md={1}></Col>
                </Form>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.EmailSlice,
});

const mapDispatchToProps = (dispatch) => ({
  // reset: () => dispatch(reset()),
  feedEmail: (textName, errorInName, isExistError, errMessageName) =>
    dispatch(feedEmail(textName, errorInName, isExistError, errMessageName)),
  feedSubject: ({ textSubject, errorInSubject, errMessageSubject }) => {
    dispatch(feedSubject({ textSubject, errorInSubject, errMessageSubject }));
  },
  selectLocation: ({ path, errorInLocation }) => {
    dispatch(selectLocation({ path, errorInLocation }));
  },
  feedDescription: (textDes, errorInDescription, errMessageDes) =>
    dispatch(feedDescription(textDes, errorInDescription, errMessageDes)),
  isEmailExist: ({ emailName, id }) => {
    dispatch(isEmailExist({ emailName, id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmail);
