import React, { useState, useEffect } from "react";
import { Drawer, Button, Col, Row } from "rsuite";

import {
  getDataLists,
  fetchRecord,
  getDataById,
} from "../../../Features/Data/DataThunk";

import { connect, useSelector, useDispatch } from "react-redux";
import DataFolder from "../../../Folder/DataFolder";
import Loading from "../../../Loading/Loading";

const LoadDataSetModal = ({
  dataLoadModal,
  handleDataSetforMergeTags,
  getDataLists,
  getDataById,
}) => {
  const [state, setState] = useState({
    ActiveDatalistId: null,
    page: 1,
    limit: 20,
    grid: true,
    loading: false,
    total: null,
    DataList: [],
  });

  useEffect(() => {
    // Function to fetch data when component mounts
    const fetchData = async () => {
      try {
        // Call getDataLists thunk to fetch data
        const response = await getDataLists({
          type: "recent",
          page: 1,
          limit: 6,
          value: null,
        });
        console.log(response); // Log the response
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when component mounts
    fetchData();
  }, [getDataLists]);

  const [ActiveDatalistId, fetchedData, setFetchedData] = useState(null);

  const { limit, loading, DataList } = state;

  const dispatch = useDispatch();
  // Corrected variable name to avoid conflict
  const { dataList: fetchedDataList } = useSelector((state) => state.DataSlice);
  console.log(fetchedDataList);

  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      try {
        // Fetch data from fetchedDataList directly
        const { data } = fetchedDataList;
        // console.log(data);

        // Check if data exists
        if (data) {
          const nextPage = Number(data.page) || 1;
          setState((prevState) => ({
            ...prevState,
            loading: false,
            page: nextPage,
            total: data.count,
            DataList: data,
          }));
        } else {
          console.error("Data not found:", fetchedDataList);
          setState((prevState) => ({
            ...prevState,
            loading: false,
            DataList: [],
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({
          ...prevState,
          loading: false,
          DataList: [],
        }));
      }
    };

    fetchData();
  }, [fetchedDataList]);

  const fetchDataList = async ({ type, page, value }) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const Fdata = await getDataLists({ type, page, limit, value });
      const { data } = Fdata.payload.data;

      setState((prevState) => ({
        ...prevState,
        loading: false,
        page: Number(data.page),
        total: data.count,
        DataList: data,
      }));
    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        DataList: [],
      }));
    }
  };

  const selectFolder = async (e) => {
    await fetchDataList({
      type: "byFolderId",
      page: 1,
      value: e.nodeData.id,
    });
  };

  const confirmData = async () => {
    // console.warn("ActiveDatalistId", state.ActiveDatalistId);
    try {
      setState({ ...state, loading: true });
      const res = await getDataById({
        type: "byId",
        value: state.ActiveDatalistId,
      });
      // console.warn("res::", res);
    } catch (error) {
      console.error("Error dispatching getDataById:", error);
    } finally {
      setState({ ...state, loading: false });
      handleDataSetforMergeTags();
    }
  };

  // Group data by folder id
  const groupedData = DataList.reduce((acc, curr) => {
    const folderId = curr.datalist_folder_id;
    if (!acc[folderId]) {
      acc[folderId] = [];
    }
    acc[folderId].push(curr);
    return acc;
  }, {});

  return (
    <>
      <Drawer
        size="full"
        placement="right"
        open={dataLoadModal}
        onClose={handleDataSetforMergeTags}
      >
        <Drawer.Header>
          <Drawer.Title>Select Merge Tag Headers</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleDataSetforMergeTags}>Cancel</Button>
            <Button
              style={{ backgroundColor: "#415670", color: "#fff" }}
              onClick={confirmData}
            >
              Confirm
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Row className="new-campaign-row">
            <Col md={5} className="side-folder">
              {/* Assuming DataFolder is a connected component with selectFolder prop */}
              <DataFolder
                selectFolder={selectFolder}
                from="assets"
                fetchData={fetchDataList}
                setCancelDataFolder={() => {}}
              />
            </Col>
            <Col md={19} sm={19}>
              {loading ? (
                <Loading />
              ) : (
                Object.keys(groupedData).map((folderId) => (
                  <div key={folderId}>
                    <Row className="TemplateList2">
                      {groupedData[folderId].map((dataItem) => (
                        <Col md={6} sm={6} key={dataItem.data_list_id}>
                          <input
                            type="radio"
                            name="template-id"
                            id={`dataList${dataItem.data_list_id}`}
                            data-id={dataItem.data_list_id}
                            data-name={dataItem.data_list_name}
                            data-count={dataItem.data_list_count}
                            data-email={dataItem.data_list_email_field}
                            onClick={(e) => {
                              const selectedDataId = e.currentTarget.dataset.id;
                              // console.log('Clicked input:', e);
                              // console.log('Selected dataset ID:', selectedDataId);
                              setState({
                                ...state,
                                ActiveDatalistId: selectedDataId,
                              });
                            }}
                            className="radio-custom"
                          />
                          <label
                            htmlFor={`dataList${dataItem.data_list_id}`}
                            className="radio-custom-label"
                          >
                            <img
                              title={dataItem.data_list_name}
                              src="https://charlie-dave.s3.amazonaws.com/database.png"
                              alt={dataItem.data_list_name}
                              id={`dataList${dataItem.data_list_id}`}
                              data-id={dataItem.data_list_id}
                              data-name={dataItem.data_list_name}
                              data-count={dataItem.data_list_count}
                              data-email={dataItem.data_list_email_field}
                              style={{ width: 150 }}
                              onClick={(e) => {
                                const selectedDataId =
                                  e.currentTarget.dataset.id;
                                // console.log('Clicked image:', e);
                                // console.log('Selected dataset ID:', selectedDataId);
                                setState({
                                  ...state,
                                  ActiveDatalistId: selectedDataId,
                                });
                              }}
                            />
                            <p style={{ textAlign: "center", color: "black" }}>
                              {dataItem.data_list_name}
                            </p>
                          </label>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))
              )}
            </Col>
          </Row>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataList: state.DataSlice.dataList,
  loadingDataList: state.DataSlice.loading,
  dataSlice: state.DataSlice,
});

const mapDispatchToProps = (dispatch) => ({
  getDataLists: (type, page, limit, value) =>
    dispatch(getDataLists(type, page, limit, value)),
  getDataById: (type, value) => dispatch(getDataById(type, value)),
  fetchRecord: (type, value) => dispatch(fetchRecord(type, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadDataSetModal);
