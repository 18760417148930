
import React from "react";
import { connect } from "react-redux";
import { Col, Content, Row, Button } from "rsuite";
 
import { Link } from "react-router-dom";
 
const AccountInfo = ({ userInfo }) => {
  // console.log(userInfo)
  const userInfoFields = [
    {
      label: "Account Name:",
      value: `${userInfo.user.user_first_name} ${userInfo.user.user_last_name}`,
    },
    { label: "Role:", value: `${userInfo.user.role.toUpperCase()}` },
    { label: "Email:", value: userInfo.user.user_email },
    { label: "Phone Number:", value: userInfo.user.user_phone_number },
    { label: "Company Name:", value: userInfo.user.user_company_name },
    {
      label: "Company Address:",
      value: `${userInfo.user.user_city || "__"}, ${
        userInfo.user.user_state || "__"
      }, ${userInfo.user.user_pincode || "__"}`,
    },
  ];
 
  return (
    <Content>
      <Row className="description-left-side">
        <Col md={2} sm={1}></Col>
        <Col md={18} sm={20}>
          <h5>
            Account Information
            <br />
            <br />
          </h5>
 
          <Link to="infoedit">
            {/* <Button style={{ padding: 0 }} appearance="link">
                Edit Information
                <br />
                <br />
              </Button> */}
              <Button
                style={{ backgroundColor: "#415670", color: "#fff", margin: "0px 0px 20px 0px" }}
                className="AccUpdateButton"
              >
                 Edit Information
              </Button>
            </Link>
 
          <Row>
            {userInfoFields.map((field, index) => (
              <Col key={index} md={12} sm={12}>
                <b>{field.label}</b>&nbsp;&nbsp;&nbsp;
                {field.value}
                <br />
                <br />
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={2} sm={1}></Col>
      </Row>
    </Content>
  );
};
 
const mapStateToProps = (state) => {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
};
 
export default connect(mapStateToProps)(AccountInfo);
 
