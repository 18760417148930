import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "./components/Features/Login/LoginSlice";

import AssetSlice from "./components/Features/Asset/AssetSlice";

import EmailSlice from "./components/Features/Email/EmailSlice";
import EmailFolderSlice from "./components/Features/Folder/EmailFolder/EmailFolderSlice";

import EditorSlice from "./components/Features/Editor/EditorSlice";

import TemplateFolderSlice from "./components/Features/Folder/TemplateFolder/TemplateFolderSlice";
import TemplateSlice from "./components/Features/Template/TemplateSlice";

import DataFolderSlice from "./components/Features/Folder/DataFolder/DataFolderSlice";
import DataSlice from "./components/Features/Data/DataSlice";

import ImageFolderSlice from "./components/Features/Folder/ImageFolder/ImageFolderSlice";
import ImageSlice from "./components/Features/Image/ImageSlice";

import CampaignPropertiesSlice from "./components/Features/CampaignManagement/CampaignPropertiesSlice";
import CampaignFolderSlice from "./components/Features/Folder/CampaignFolder/CampaignFolderSlice";
import CampaignSlice from "./components/Features/Campaign/CampaignSlice";
import SenderSlice from './components/Features/SenderManagement/SenderSlice'

const store = configureStore({
  reducer: {
    LoginSlice,

    AssetSlice,

    EmailSlice,
    EmailFolderSlice,

    EditorSlice,

    TemplateFolderSlice,
    TemplateSlice,

    DataFolderSlice,
    DataSlice,

    ImageFolderSlice,
    ImageSlice,

    CampaignPropertiesSlice,
    CampaignFolderSlice,
    CampaignSlice,
    SenderSlice
  }
});

export default store;
