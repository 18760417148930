import { template, email, dataArchive } from "./const";

export function setCreateNewProjectData() {
  localStorage.setItem("eTD", JSON.stringify(template));
  localStorage.setItem("eD", JSON.stringify(email));
  localStorage.setItem("dLD", JSON.stringify(dataArchive));
  localStorage.setItem("gjsProject", JSON.stringify({}));
}

export function getDataSet() {
  return JSON.parse(localStorage.getItem("dLD"));
}

export function checkToNewProject(component, data) {
  if (component === "template") {
    let { templateName, location, description, categories } = data;
    if (
      templateName.textName === "" ||
      location.path === null ||
      description.textDes === "" ||
      categories.categoryBundle.length === 0
    ) {
      return "/createnewproject/create/template/info";
    } else {
      return;
    }
  } else {
    let { emailName, location, description, subject } = data;
    if (
      emailName.textName === "" ||
      location.path === null ||
      description.textDes === "" ||
      subject.textSubject === ""
    ) {
      return "/createnewproject/create/email/info";
    } else {
      return;
    }
  }
}
