import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

// SECTION Get All Sender Profile from the Database
export const getSenderprofile = createAsyncThunk(
  "sender/getSenderprofile",
  async () => {
    try {
      const response = await axios.get("/sender/getSenderprofile");
    //   console.log("response", response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// // SECTION Create New Sender Profile
export const CreateSenderProfile = createAsyncThunk(
  "sender/createSenderProfile",
  async (formValue) => {
    console.log(formValue);
    try {
      const response = await axios.post("/sender/createSenderProfile");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);


export const createSenderProfile = createAsyncThunk(
  "sender/createSenderProfile",
  async (formattedData) => {
    try {
      const response = await axios.post("/sender/createSenderProfile", formattedData);
      return response.data;
    } catch (e) {
        console.log("e.response.data",e.response.data)
      return e.response.data;
    }
  }
);

// NOTE FOR ADMIN ONLY
export const UpdateSenderprofileData = createAsyncThunk(
  "sender/updateSenderprofileTable",
  async () => {
    try {
      const response = await axios.get("/sender/updateSenderprofileTable");
      return response.data;
    } catch (e) {    
      return e.response.data;
    }
  }
);

export const updateSenderProfile = createAsyncThunk(
  "sender/updateSenderProfile",
  async (data) => {
    console.log("Data is comming", data);
    try {
      const response = await axios.post("/sender/updateSenderProfile", data);
      // console.log("response", response.data);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteSenderProfile = createAsyncThunk(
  "sender/deleleSenderProfile",
  async (id) => {
    console.log("ID for Delete", id);
    try {
      const response = await axios.delete(
        `/sender/deleleSenderProfile/${id}`
      );
        console.log("response",response.data)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
