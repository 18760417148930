import React, { Component } from "react";
import { Button, Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reset } from "../Features/Template/TemplateSlice";
import { resetEmail } from "../Features/Email/EmailSlice";
import { resetData } from "../Features/Data/DataSlice";

import { confirmationModalPaths } from "./Paths/Paths";

class ConfirmationModal extends Component {
  render() {
    let { confirmation, hide, reset, component } = this.props;
    return (
      <Modal backdrop="static" open={confirmation} onClose={() => hide()} size="xs">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000" }}>
          <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> Are you sure you want to go
          back?
        </Modal.Body>
        <Modal.Footer>
          <Link
            to={confirmationModalPaths[component]}
            replace
            style={{ color: "#000" }}
            onClick={() => reset()}
          >
            <Button appearance="subtle" title="Confirm" style={{ color: "#000" }}>
              <b>Confirm</b>
            </Button>
          </Link>

          <Button
            onClick={() => hide()}
            appearance="subtle"
            title="Cancel"
            style={{ color: "#000" }}
          >
            <b>Cancel</b>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  // data: state.TemplateSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch(reset());
    dispatch(resetEmail());
    dispatch(resetData());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
