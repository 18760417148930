import React, { Component } from "react";
import logo from "./assets/icons/Charlie.png";
import "./assets/css/login.css";

import { Form, Schema, Button } from "rsuite";
import { connect } from "react-redux";
import { forgotPassword } from "../Features/Login/LoginThunk";
import { Link, Navigate } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        email: "",
      },
      loading: false,
      isAuth: false,
      
    };
    // this.alertRef = React.createRef();
  }

  handleSubmit = async () => {
    let { email } = this.state.formValue;
    let { alertRef, forgotPassword , setResetemail} = this.props;
    console.log(setResetemail)
    setResetemail(email)
    if (!this.form.check()) {
      return;
    }
    this.setState({
      loading: true,
    });
    let response = await forgotPassword(email);
    this.setState({ loading: false });

    if (response.payload.status === 1) {
      this.setState({ isAuth: true });
    }

    alertRef.current.showToaster(response.payload);
  };

  render() {

    // console.log('Props:', this.props);
    let { alertRef } = this.props;
    let { formValue, loading, isAuth } = this.state;
    if (isAuth) return <Navigate to={`/password-request/?t=req&s=0`} replace />;
    const model = Schema.Model({
      email: Schema.Types.StringType()
        .isEmail("Please enter a valid email address.")
        .isRequired("This field is required."),
    });
    return (
      <div className="wrapper">
        <div className="box left-side-form">
          <div className="login-logo-div">
            <img src={logo} alt="" className="login-logo" />
          </div>

          <div style={{ margin: "0 10%", color: "#000" }}>
            <h4 style={{ color: "#000" }}>Forgot Your Password</h4>
            <p>
              Enter your registered email and we'll send you a link to reset
              your password
            </p>
          </div>
          <Form
            fluid
            model={model}
            ref={(ref) => (this.form = ref)}
            onChange={(formValue, e) => {
              this.setState({
                formValue,
              });
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 32) {
                e.preventDefault();
                let message = {
                  status: 0,
                  message: "Whitespace is not allowed",
                };
                alertRef.current.showToaster(message);
              }
            }}
            onCheck={(formError) => {
              this.setState({ formError });
            }}
            formValue={formValue}
            style={{ marginTop: -15 }}
          >
            <Form.Group className="loginInput">
              <Form.Control
                name="email"
                placeholder="Email"
                className="input-field-login"
              />
            </Form.Group>

            <div className="login-button-div" style={{ marginTop: -15 }}>
              <Button
                className={`loginButton ${
                  !formValue.email ? "field-empty-button-disable " : ""
                }`}
                onClick={this.handleSubmit}
                style={{ padding: "10px 2em" }}
                loading={loading}
              >
                Submit
              </Button>
              &nbsp;
              <Link to="/login" style={{ textAlign: "center", marginTop: -15 }}>
            <span className="forget-paswword" style={{ cursor: "pointer" }}>
              Back to Login
            </span>
          </Link>
            </div>
          </Form>
          
        </div>
        <div className="box right-side-content">
          <div className="text">
            <p>
              "We are what we repeatedly do.
              <br />
              Excellence, then is not an act, but a habit"
            </p>
          </div>
          <div className="DSC-text">
            {/* <p>Powered by DSC India Private limited</p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.LoginSlice,
  // loading: state.data.loading,
  // error: sate.data.error,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(forgotPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
