import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getIPs = createAsyncThunk("settings", async () => {
  try {
    const response = await axios.get(`/settings/ips`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
});
