import { createSlice } from "@reduxjs/toolkit";
import { fetchDataFolder } from "./DataFolderThunk";

const DataFolderSlice = createSlice({
  name: "data-folder",
  initialState: {
    loading: false,
    dataFolder: [],
    error: null,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataFolder.pending, (state, action) => {
        state.loading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchDataFolder.fulfilled, (state, action) => {
        state.dataFolder = action.payload.data.data;
        state.success = true;
        state.loading = false;
      })

      .addCase(
        fetchDataFolder.rejected,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          console.log(action.error);
          state.loading = false;
          state.error = action.error;
          state.dataFolder = [];
        }
      );
  }
});

export default DataFolderSlice.reducer;
