import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Schema,
  Col,
  SelectPicker,
  Message,
  Row,
  Toggle,
  List,
} from "rsuite";
import "./css/create.css";
import { connect } from "react-redux";
import {
  getUserById,
  updateUser,
} from "../Features/AccountManagement/UserManagementThunk";
import { Alert } from "../Utils/Alert";
import { withRouter } from "./withRouter";
import { logout } from "../Features/Login/LoginThunk";
const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("Email address is required."),
  firstname: StringType().isRequired("First name is required."),
  lastname: StringType().isRequired("Last name is required."),
  role: StringType().isRequired("Role is required."),
});
// NOTE: Access options for Admin or all access
const accessOptions = [
  "template",
  "email",
  // "image",
  "data",
  "campaign",
  // "digitalmedia",
  "social",
  "userList",
  "createUser",
  "updateuser",
];
// NOTE Access options array of rendering the UI and values
const accessOptionsArr = [
  { label: "Template", value: "template", adminOnly: false },
  { label: "Email", value: "email", adminOnly: false },
  // { label: "Image", value: "image", adminOnly: false },
  { label: "Data", value: "data", adminOnly: false },
  { label: "Campaign", value: "campaign", adminOnly: false },
  // { label: "Digital Media", value: "digitalmedia", adminOnly: false },
  { label: "Report", value: "social", adminOnly: false },
  { label: "User List", value: "userList", adminOnly: true },
  { label: "Create User", value: "createUser", adminOnly: true },
  { label: "Update User", value: "updateuser", adminOnly: true },
];

const ContactAdminHelpText = ({ role }) => {
  return (
    <>
      Please contact the {role === "admin" ? "SuperAdmin" : "Admin"} if you
      require any changes to your email address.
    </>
  );
};

const UserInfoEdit = (props) => {
  // console.log(props);
  const alertRef = useRef(null);
  const [errors, setErrors] = useState({});
  const formRef = useRef();
  const [formValue, setFormValue] = useState({});
  const [access, setAccess] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.getUserById(props.id);
        const data = response.payload.message.data;
        let alterslash = JSON.parse(data.access);
        let alterQuotes = JSON.parse(alterslash);

        setFormValue({
          email: data.user_email,
          firstname: data.user_first_name,
          lastname: data.user_last_name,
          role: data.role,
          user_id: props.id,
          accessdata: alterQuotes,
        });
        setAccess(alterQuotes);
      } catch (error) {
        setErrors(true);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [props]);
  useEffect(() => {
    if (formValue.role === "admin") {
      setAccess([
        "template",
        "email",
        // "image",
        "data",
        "campaign",
        // "digitalmedia",
        "social",
        "userList",
        "createUser",
        "updateuser",
      ]);
    } else {
      setAccess([]);
    }
  }, [formValue.role]);
  const Roledata = ["Admin", "User"].map((item) => ({
    label: item,
    value: item.toLowerCase(),
  }));
  useEffect(() => {
    if (formValue.accessdata && formValue.accessdata.length > 0) {
      setAccess(formValue.accessdata);
    } else {
      setAccess([]);
    }
  }, [formValue.accessdata]);

  // const handleModifyUser = async () => {
  //   let user = await props.updateUser(formValue);
  //   let { payload } = user;

  //   if (payload.status === 1) {
  //     alertRef.current.showToaster(payload);
  //     props.activeChange();
  //   }
  //   // REVIEW Need to Verify Role also
  //   if (props.id === props.userInfo.user.user_id) {
  //     await props.logout({ id: props.id });
  //   }
  //   // else {
  //   //   // Show error toaster
  //   //   alertRef.current.showToaster(payload);
  //   // }
  // };


  const handleModifyUser = async () => {
    if (formValue.role === "user" && access.length === 0) {
      // If role is user and access is blank, show alert
      alertRef.current.showToaster({
        type: "error",
        duration: 4,
        message: "Provide Atleast One User Access",
      });
      return;
    }
    let user = await props.updateUser(formValue);
    let { payload } = user;
 
    if (payload.status === 1) {
      alertRef.current.showToaster(payload);
      props.activeChange();
    }
    // REVIEW Need to Verify Role also self logout function
    // if (props.id === props.userInfo.user.user_id) {
    //   await props.logout({ id: props.id });
    // }
    // else {
    //   // Show error toaster
    //   alertRef.current.showToaster(payload);
    // }
  };

  const handleAccessChange = (option, checked) => {
    // console.log("I AM triggered handleAccessChange", option, checked);
    if (
      formValue.role === "user" &&
      ["userList", "createUser", "updateuser"].includes(option)
    ) {
      // If the role is "User" and the option is one of the exclusive ones, prevent changes
      return;
    }

    if (checked) {
      setAccess([...access, option]);
      setFormValue({ ...formValue, accessdata: [...access, option] });
    } else {
      setAccess(access.filter((item) => item !== option));
      setFormValue({
        ...formValue,
        accessdata: access.filter((item) => item !== option),
      });
    }
    setErrors({ ...errors, access: undefined });
  };
  const handleRoleChange = (field, value) => {
    // console.log("I AM triggered handleRoleChange", field, value);
    setFormValue({ ...formValue, [field]: value });
    if (field === "email") {
      setErrors({ ...errors, email: undefined });
    }
    if (value === "admin") {
      setFormValue({
        ...formValue,
        [field]: value,
        accessdata: accessOptions,
      });
    }
    if (value === "user") {
      setFormValue({
        ...formValue,
        [field]: value,
        accessdata: access,
      });
      setAccess([]);
    }
  };
  // console.log(props.id, props.userInfo.user.user_id, formValue, access);
  return (
    <>
      <Col md={2}></Col>

      <Col md={18}>
        <Alert ref={alertRef} />
        <Message type="info" bordered showIcon closable>
          <strong>Info!</strong> Admin Access are Predefined and Locked
        </Message>
        <Form
          model={model}
          formValue={formValue}
          onChange={setFormValue}
          ref={formRef}
          fluid
          style={{ marginTop: 5 }}
        >
          <Row style={{ marginBottom: 5 }}>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>Email Address</Form.ControlLabel>
                <Form.Control
                  name="email"
                  // onBlur={checkErrorEmail}
                  autoComplete="off"
                  // errorMessage={errors.email && "Email already exists"}
                  defaultValue={formValue.user_email}
                  disabled={formValue.role === "admin"}
                />
                <Form.HelpText>
                  <ContactAdminHelpText role={formValue.role} />
                </Form.HelpText>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>First Name</Form.ControlLabel>
                <Form.Control
                  name="firstname"
                  type="text"
                  autoComplete="off"
                  defaultValue={formValue.user_first_name}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>Last Name</Form.ControlLabel>
                <Form.Control
                  name="lastname"
                  type="text"
                  autoComplete="off"
                  defaultValue={formValue.user_last_name}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.ControlLabel>Define Role</Form.ControlLabel>
            <Form.Control
              name="role"
              accepter={SelectPicker}
              searchable={false}
              label="Role"
              onChange={(value) => handleRoleChange("role", value)}
              onBlur={() => setErrors({ ...errors, role: undefined })}
              errorMessage={errors.role && "Please select a role"}
              data={Roledata}
              style={{ width: 220 }}
              disabled={formValue.role === "admin"}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel
              style={{ textAlign: "center", fontWeight: 600, marginBottom: 10 }}
            >
              Access
            </Form.ControlLabel>
            <Row>
              {accessOptionsArr.map((option) => (
                <Col
                  md={12}
                  style={{
                    // border:"2px solid red",
                    display: "flex",
                    direction: "row",
                    minHeight: "40px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={option.value}
                >
                  <span>
                    {option.label}
                    {option.adminOnly && (
                      <Form.HelpText tooltip>Admin Only</Form.HelpText>
                    )}
                  </span>

                  <Toggle
                    checked={access.includes(option.value)}
                    onChange={(checked) =>
                      handleAccessChange(option.value, checked)
                    }
                    disabled={formValue.role === "admin"}
                  />
                </Col>
              ))}
            </Row>
            <Form.ErrorMessage>{errors.access}</Form.ErrorMessage>
            {/* <span className="forError">{errors.access}</span> */}
          </Form.Group>
          <Button
            appearance="primary"
            style={{ backgroundColor: "#415670", color: "#fff" }}
            size={20}
            onClick={handleModifyUser}
            loading={loadingSubmit}
          >
            Modify User
          </Button>
        </Form>
      </Col>
      <Col md={2}></Col>
    </>
  );
};

const mapStateToProps = (state) => {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
};

export default connect(mapStateToProps, {
  logout,
  getUserById,
  updateUser,
})(withRouter(UserInfoEdit));
