import axios from "../../Utils/Interceptor/AxiosInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getRecentAssets = createAsyncThunk("assets", async (limit) => {
  const response = await axios.get(`/assetlibrary/recent?limit=${Number(limit)}`);
  return response.data;
});

export const getEmails = createAsyncThunk(
  "assets/get-emails",
  async ({ type, page, limit, value }) => {
    const response = await axios.get(
      `/assetlibrary/email?type=${type}&limit=${Number(limit)}&page=${page}&value=${value}`
    );
    return response.data;
  }
);

export const emailById = createAsyncThunk("assets/emailbyId", async ({ type, value }) => {
  const response = await axios.get(`/assetlibrary/email?type=${type}&value=${value}`);
  return response.data;
});
