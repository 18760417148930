import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Content,
  Schema,
  Input,
  Form,
  ButtonToolbar,
  SelectPicker,
  Whisper,
  Tooltip,
  InputGroup,
  IconButton,
  Divider,
} from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import { data } from "./assets/js/StateName";
import { Link } from "react-router-dom";
import { Alert } from "../Utils/Alert";
import {
  updateInfo,
  updatePassword,
} from "../Features/AccountManagement/AccountManagementThunk";
import { withRouter } from "./withRouter";
import { logout } from "../Features/Login/LoginThunk";
import "./assets/css/editinfo.css";
import { userStatus } from "../Features/Login/LoginThunk";
import InfoIcon from "@rsuite/icons/legacy/Info";
import { RxEyeOpen } from "react-icons/rx";

function TextField(props) {
  const { name, label, accepter, data, readOnly, helpText, ...rest } = props;

  return (
    <Form.Group>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
        <Form.Control
          name={name}
          accepter={accepter}
          {...rest}
          readOnly={readOnly}
          data={data}
        />
      </Whisper>
      {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
    </Form.Group>
  );
}

export const CustomWhisper = () => (
  <Whisper placement="top" speaker={<Tooltip>Required Field</Tooltip>}>
    <InfoIcon />
  </Whisper>
);
class AccountInfoEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValue: "",
      passwordFormValue: "",
      showPassword: false,
    };
    this.alertRef = React.createRef();
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  componentDidMount() {
    var userinfo = this.props.userInfo.user;

    var formValue = {
      firstname: userinfo.user_first_name,
      lastname: userinfo.user_last_name,

      email: userinfo.user_email,
      phoneNo: userinfo.user_phone_number,
      companyname: userinfo.user_company_name,
      city: userinfo.user_city,
      state: userinfo.user_state,
      pincode: userinfo.user_pincode,
    };
    this.setState({
      formValue: formValue,
    });
  }

  //handle submit

  handleSubmit = async () => {
    var formValue = this.state.formValue;
    var user_id = this.props.userInfo.user.user_id;

    var data = {
      formValue,
      user_id,
    };

    if (this.form.check()) {
      this.setState({
        loading: true,
      });

      const userAccountUpdate = await this.props.updateInfo(data);
      let { payload } = userAccountUpdate;

      if (payload.status === 1) {
        this.alertRef.current.showToaster(payload);
        this.props.userStatus();
        this.props.navigate("/accountmanagement");
      } else {
        this.alertRef.current.showToaster(payload);
      }
    }
  };

  // Change Password option.

  handlepasswordSubmit = async () => {
    const { formValue } = this.state;
    console.warn("passwordFormValue", formValue);
    const { oldpassword, newpassword, newconfirmpassword } = formValue;
    console.warn("oldpassword", oldpassword);
    console.warn("oldpassword", newpassword);
    console.warn("oldpassword", newpassword);
    const user_id = this.props.userInfo.user.user_id;
    const data = {
      oldpassword,
      newpassword,
      newconfirmpassword,
      user_id,
    };

    if (!oldpassword || !newpassword || !newconfirmpassword) {
      let message = {
        status: "error",
        duration: 2,
        message: "Please provide all password fields",
      };
      this.alertRef.current.showToaster(message);
      return;
    }
    this.setState({ loading: true });
    try {
      const userAccountUpdate = await this.props.updatePassword(data);
      const { payload } = userAccountUpdate;
      if (payload.status === 1) {
        const dataid = {
          id: user_id,
        };
        this.alertRef.current.showToaster(payload);
        const logoutResponse = await this.props.logout(dataid);
      } else {
        this.alertRef.current.showToaster(payload);
      }
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { formValue } = this.state;
    const { StringType, NumberType } = Schema.Types;

    // Define model for account update form
    const accountUpdateModel = Schema.Model({
      firstname: StringType()
        .isRequired("This field is required.")
        .maxLength(20, "Maximum length is 20 characters.")
        .addRule(
          (value) => !/[^a-zA-Z0-9\s]{2,}/.test(value),
          "Consecutive special characters are not allowed"
        ),
      lastname: StringType()
        .isRequired("This field is required.")
        .maxLength(20, "Maximum length is 20 characters.")
        .addRule((value) => {
          // Check if any two consecutive special characters exist in the value
          return !/[^a-zA-Z0-9\s]{2,}/.test(value);
        }, "Consecutive special characters are not allowed"),

      email: StringType()
        .isRequired("This field is required.")
        .isEmail("Please enter the valid email address."),
      phoneNo: NumberType()
        .isRequired("This field is required.")
        .pattern(
          /^[0-9]{4,12}$/,
          "Phone number must be between 4 to 12 digits"
        ),
      companyname: StringType()
        .isRequired("This field is required.")
        .maxLength(50, "Maximum length is 50 characters."),
      city: StringType()
        .isRequired("This field is required.")
        .maxLength(20, "Maximum length is 20 characters."),
      state: StringType()
        .isRequired("This field is required.")
        .maxLength(20, "Maximum length is 20 characters."),
      pincode: NumberType()
        .isRequired("This field is required.")
        .pattern(/^[1-9][0-9]{3,5}$/, "The pincode must consist of 4 to 6 digits."),
      /*Email validation */
    });

    const passwordChangeModel = Schema.Model({
      oldpassword: StringType().isRequired("Old password is required."),
      newpassword: StringType()
        .isRequired("New password is required.")
        .addRule(
          (value) => value.length >= 8,
          "Password must be at least 8 characters long."
        )
        .addRule(
          (value) => /[!@#$%^&*]/.test(value),
          "Password must contain at least one special character."
        )
        .addRule(
          (value) => /[A-Z]/.test(value),
          "Password must contain at least one capital letter."
        ),

      newconfirmpassword: StringType()
        .isRequired("Confirm new password is required.")
        .addRule(
          (value, data) => value === data.newpassword,
          "Passwords do not match."
        ),
      // .addRule(value => value.length >= 8, "Password must be at least 8 characters long.")
      // .addRule(value => value.length >= 8, "Password must be at least 8 characters long.")
      // .addRule(value => /[!@#$%^&*]/.test(value), "Password must contain at least one special character.")
      // .addRule(value => /[A-Z]/.test(value), "Password must contain at least one capital letter."),
    });

    return (
      <Content>
        <Row>
          <Col md={2} sm={1}></Col>
          <Col md={20} sm={20}>
            <h5>
              <Alert ref={this.alertRef} />
              Account Information
              <br />
              <br />
            </h5>
            <Row>
              <Col md={24} sm={24}>
                <Row>
                  <Col md={24} sm={24}>
                    <Link to="/accountmanagement">
                      {/* <Button style={{ padding: 0 }} appearance="link">
                Cancel
                <br />
                <br />
              </Button> */}
                      <Button
                        style={{
                          backgroundColor: "#415670",
                          color: "#fff",
                          margin: "0px 0px 14px 0px",
                        }}
                        className="AccUpdateButton"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col md={16} sm={16}>
                    <Form
                      fluid
                      model={accountUpdateModel}
                      ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                      }}
                      onCheck={(formError) => {
                        this.setState({ formError });
                      }}
                      formValue={formValue}
                    >
                      <Row>
                        <Col md={12} sm={24} className="AccInfoEdit">
                          <TextField
                            name="firstname"
                            label="First Name"
                            accepter={Input}
                          />
                          <TextField
                            name="lastname"
                            label="Last Name"
                            accepter={Input}
                          />
                          {/* <TextField
                    name="accountID"
                    label="Account ID"
                    accepter={Input}
                    readOnly="true"
                  /> */}

                          {/* <TextField name="password" label="Password" type="password" accepter={Input} autoComplete="off"/> */}

                          <TextField
                            name="email"
                            label="Email"
                            accepter={Input}
                            disabled="disabled"
                            type="email"
                            readOnly="readOnly"
                            helpText="Contact Admin for Changing Email Address"
                          />
                          <TextField
                            name="phoneNo"
                            label="Phone Number"
                            accepter={Input}
                          />
                        </Col>
                        <Col md={1}></Col>
                        <Col md={11} sm={24}>
                          {" "}
                          <TextField
                            name="companyname"
                            label="Company Name"
                            accepter={Input}
                          />
                          <TextField
                            name="city"
                            label="City"
                            accepter={Input}
                          />
                          <TextField
                            name="state"
                            label="State"
                            className="statePicker"
                            accepter={SelectPicker}
                            data={data}
                            helpText="Required Field"
                            style={{
                              width: "100%",
                              outline: "none !important",
                              border: "0px",
                            }}
                          />
                          <TextField
                            name="pincode"
                            label="Zip Code/ Pin Code"
                            accepter={Input}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={24} sm={24}>
                          <ButtonToolbar>
                            <Button
                              style={{
                                backgroundColor: "#415670",
                                color: "#fff",
                                marginLeft: "0px",
                              }}
                              className="AccUpdateButton"
                              type="submit"
                              onClick={this.handleSubmit}
                            >
                              Save Updates
                            </Button>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md={1} sm={1}></Col>
                  <Col
                    md={7}
                    sm={7}
                    style={{
                      borderLeft: "1px solid  lightgray",
                      height: "340px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Form
                      fluid
                      model={passwordChangeModel}
                      // ref={(ref) => (this.form = ref)}
                      onChange={(formValue) => {
                        this.setState({ formValue });
                      }}
                      // onCheck={(formError) => {
                      //   this.setState({ formError });
                      // }}
                      formValue={formValue}
                    >
                      <Col md={24} sm={24}>
                        <TextField
                          name="oldpassword"
                          label="Old Password"
                          // placeholder="Old Password"
                          accepter={Input}
                        ></TextField>

                        <label> New Password</label>
                        <InputGroup className="NewPassword">
                          <TextField
                            style={{ width: "100%" }}
                            name="newpassword"
                            // placeholder="Confirm NewPassword"
                            accepter={Input}
                            type={this.state.showPassword ? "text" : "password"}
                          />
                          <InputGroup.Button
                            onClick={this.togglePasswordVisibility}
                            style={{ margin: 0, height: 38, padding: 0 }}
                          >
                            <IconButton
                              icon={
                                this.state.showPassword ? (
                                  <EyeIcon />
                                ) : (
                                  <EyeSlashIcon />
                                )
                              }
                            />
                          </InputGroup.Button>
                        </InputGroup>

                        <label style={{ marginTop: "20px" }}>
                          Confirm NewPassword
                        </label>
                        <TextField
                          name="newconfirmpassword"
                          // label="Confirm NewPassword"
                          // placeholder="Confirm NewPassword"
                          accepter={Input}
                          // style={{marginTop:"20px"}}
                          // type={this.state.showPassword ? 'text' : 'password'}
                        />

                        <ButtonToolbar>
                          <Button
                            style={{
                              backgroundColor: "#415670",
                              color: "#fff",
                              marginLeft: "0px",
                            }}
                            // className="AccUpdateButton"
                            type="submit"
                            onClick={this.handlepasswordSubmit}
                          >
                            Change Password
                          </Button>
                        </ButtonToolbar>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={2} sm={1}></Col>
        </Row>

        <Row>
          <Col md={24}>
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, loading, userInfo } = state.LoginSlice;
  return {
    isLoggedIn,
    loading,
    userInfo,
  };
}

export default connect(mapStateToProps, {
  updateInfo,
  userStatus,
  updatePassword,
  logout,
})(withRouter(AccountInfoEdit));
